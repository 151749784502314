import React, { Component } from "react";
import * as Action from '../../redux/actionTypes';
import { connect } from "react-redux";
import StepWizard from "react-step-wizard";
import DeviceA from './DeviceA';
import DeviceB from './DeviceB';
import TunnelSettings from './TunnelSettings';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import $ from 'jquery';
import { ErrorPopup } from '../../layOuts/errorPopup';
import ErrorHandlePopup from "../ErrorHandlePopup";

class AddTunnel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            title: '',
            message: '',
            show: false,
            error: false,
            pass: true,
            back: false,
            disableCreate: false,
            deviceData: [
            ],
            tunnelDevicesData: undefined,
            deviceA_networks: [],
            deviceB_networks: [],
            macAddress: [],
            isAxiosError: false
        }
    }
    componentDidMount() {
        this.getDeviceList();
    }

    closeAddTunnel = () => {
        this.props.dispatch({
            type: Action.CLOSE_ADD_TUNNEL,
        });
    }
    getDeviceList = () => {
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.getTunnelDevicelist(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                const data = response?.data;
                this.setState({
                    deviceData: data,
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    }
    getNetworksDeviceA = (id) => {
        let data = {
            device_id: id
        }
        if (String(data.device_id) !== "") {
            this.setState({ showLoader: true })
            ApiCall(urlMapping.getNetworkView(data), (response) => {
                this.setState({
                    showLoader: false,
                })
                if (response?.Success) {
                    this.setState({
                        deviceA_networks: response?.data,
                    });
                } else {
                    this.setState({
                        title: 'Create Tunnel',
                        message: 'No Networks found on device. Network template must be applied to create tunnel',
                        show: true,
                        error: true,
                        showLoader: false,
                    }, () => {
                        $("#errorMessage").modal("show")
                    });
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({ showRefreshModal: true })
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            });
        }
    }
    getNetworksDeviceB = (id) => {
        let data = {
            device_id: id
        }
        if (String(data.device_id) !== "") {
            this.setState({ showLoader: true })
            ApiCall(urlMapping.getNetworkView(data), (response) => {
                this.setState({
                    showLoader: false,
                })
                if (response?.Success) {
                    this.setState({
                        deviceB_networks: response?.data,
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({ showRefreshModal: true })
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Create Tunnel',
                            message: 'No Networks found on device. Network template must be applied to create tunnel',
                            show: true,
                            error: true,
                            showLoader: false,
                            disableCreate: true
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
        }
    }
    checkDeviceTask = async () => {
        let deviceMac = [];

        deviceMac.push(this.state.device_A.device_mac, this.state.device_B.device_mac);

        let data = { device_mac: deviceMac, operation_type: ['Create Tunnel', 'Update Tunnel', 'TunnelConfiguration', 'Firmware Upgrade', 'BGPConfiguration'] }
        this.setState({
            showLoader: true
        });

        const trueArr = [];
        await ApiCall(urlMapping.checkDeviceTask(data), response => {
            this.setState({
                showLoader: false,
                show: false
            });
            $("#errorMessage").modal("hide");
            if (response?.success === true) {
                const data = response.data;
                Object?.keys(data)?.forEach(item => {
                    if (data?.[item]?.status === true) {
                        trueArr?.push(data?.[item]);
                    }
                });
                if (trueArr?.length > 0) {
                    this.setState({
                        title: 'Device Task',
                        // message: trueArr[0].message,
                        message: "An operation on similar/dependent template is in-progress, please wait until the operation completes",
                        show: true,
                        error: true,
                        delete: false,
                        toggledClearRows: !this.state.toggledClearRows,
                        closeTunnel: true
                    }, () => $("#errorMessage").modal("show"));
                }
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Device Task',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
        return trueArr;
    }
    getTunnelDeviceDetails = async () => {
        const haveTask = await this.checkDeviceTask();
        if (haveTask.length === 0) {
            const device_mac = [];
            device_mac.push(this.state.device_A.device_mac);
            device_mac.push(this.state.device_B.device_mac);
            const data = { device_mac }
            this.setState({
                showLoader: true,
            });
            ApiCall(urlMapping.getTunnelDeviceDetails(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success
                    && Object.entries(response?.data?.device_A)?.length !== 0 && Object.entries(response?.data?.device_B)?.length !== 0
                ) {
                    this.getNetworksDeviceA(response?.data?.device_A?.device_id);
                    this.getNetworksDeviceB(response?.data?.device_B?.device_id);
                    this.setState({
                        tunnelDevicesData: response?.data,
                        pass: true
                    })
                } else if (response?.data) {
                    this.setState({
                        macAddress: Object.keys(response?.data).filter((item, key) => response?.data?.[item] == null),
                        tunnelDevicesData: undefined,
                        title: 'Create Tunnel',
                        message: response?.message + `"\n Note: Select "Generate Key" to generate Public key for${response?.message?.split("::")?.[1]}`,
                        show: true,
                        error: true,
                        pass: false
                    }, () => {
                        $("#errorMessage").modal("show")
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({
                            showRefreshModal: true
                        });
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            tunnelDevicesData: undefined,
                            title: 'Create Tunnel',
                            message: response?.message,
                            show: true,
                            error: true,
                            pass: false
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
            if (this.state.closeTunnel)
                this.closeAddTunnel();
        })
    }
    regenerateKey = () => {
        let data = {
            device_macs: this.state.macAddress
        }
        this.setState({ showLoader: true })
        ApiCall(urlMapping.keyRegeneration(data), (response) => {
            this.setState({
                showLoader: false,
            })
            if (response?.success) {
                this.setState({
                    title: 'Create Tunnel',
                    message: 'Public Key Added',
                    show: true,
                    error: false,
                    showLoader: false
                }, () => {
                    $("#errorMessage").modal("show")
                });
            } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                this.setState({
                    title: 'Tenant Error',
                    message: response.response.data.message,
                    showLoader: false,
                    isAxiosError: true,
                    delete: false,
                    error: true
                }, () => $("#errorMessage").modal("show"))
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                this.setState({
                    title: 'Network Error',
                    message: 'Something went wrong. Please try again later.',
                    showLoader: false,
                    isAxiosError: true,
                    delete: false,
                    error: true
                }, () => $("#errorMessage").modal("show"))
            }
            else {
                this.setState({
                    title: 'Create Tunnel',
                    message: response?.message,
                    show: true,
                    error: true,
                    showLoader: false
                }, () => {
                    $("#errorMessage").modal("show")
                });
            }
        }
        );
    }
    stepChange = (currentStep, data) => {
        if (currentStep === 1) {
            const device_A = this.state.deviceData.filter(elm => !data.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)))[0];
            this.setState({
                deviceBData: data,
                device_A
            })
        } else if (currentStep === 2) {
            this.setState({
                device_B: data,
                back: true
            }
                , () => {
                    this.getTunnelDeviceDetails();
                }
            );
        }
    }
    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div className={this.props.open ? "slide-overlay open" : "slide-overlay"}></div>
                <div className={this.props.open ? "sidebar-slide open" : "sidebar-slide"}>
                    <div className="s-header">
                        <h1 className="ss-title">Create Tunnel</h1>
                        <button onClick={() => this.closeAddTunnel()}
                            history={this.props.history}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className="s-body create-tunnel-body">
                        <StepWizard>
                            <DeviceA devices={this.state.deviceData} stepChange={(step, data) => this.stepChange(step, data)} />
                            <DeviceB devices={this.state.deviceBData} stepChange={(step, data) => this.stepChange(step, data)} />

                            <TunnelSettings devices={this.state.tunnelDevicesData}
                                deviceA_networks={this.state.deviceA_networks}
                                deviceB_networks={this.state.deviceB_networks}
                                pass={this.state.pass}
                                getTunnelList={() => this.props.getTunnelList()}
                                closeAddTunnel={() => this.closeAddTunnel()}
                                back={this.state.back}
                                history={this.props.history}
                                disableCreate={this.state.disableCreate} />
                        </StepWizard>
                    </div>
                </div>
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        onSubmit={() => this.regenerateKey()}
                        reset={() => this.resetPopup()}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>)
    }
}

const mapStateToProps = (state) => {
    return ({
        open: state.displayAddTunnel.openAddTunnel,
    });
}
export default connect(mapStateToProps)(AddTunnel);