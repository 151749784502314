/* eslint-disable array-callback-return */
import { decode as base64_decode } from 'base-64';

export const validateIPaddressWithSubnetMask = (e, index) => {
    const value = e.target.value;
    if (value !== '') {
        if (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-9]|0[0-9]|1[0-9]|2[0-9]|3[0-2])$/.test(value)) {
            return (true)
        } const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
            element.innerHTML = 'Invalid value';
        }
        return (false);
    } else {
        const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
            element.innerHTML = 'Invalid value';
        }
        return (false);
    }
}
export const validateIPaddress = (e, index) => {
    const value =
        e.target.value;
    if (value !== '') {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)) {
            return true;
        } else {
            const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = 'Invalid value';
            }
            return false;
        }
    } else {
        const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
            element.innerHTML = 'Invalid value';
        }
        return (false);
    }
}
export const validatePrivateIP = (e, index) => {
    const value =
        e.target.value;
    if (value !== '') {
        if ((/(^127\.)|(^192\.168\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^::1$)|(^[fF][cCdD])/.test(value))) {
            return true
        }
        else
            return false;
    }
    else {
        const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
            element.innerHTML = 'Invalid Value';
        }
        return false;
    }

}

export const removeError = (e, index) => {
    if (e.target) {
        const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'none';
            return false;
        }
    }
}

export const validateMac = (e, index) => {
    const result = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;
    const ans = result.test(e.target.value)
    if (ans && e.target.value !== '00:00:00:00:00:00' && e.target.value.toUpperCase() !== 'FF:FF:FF:FF:FF:FF'
    )
        return true;
    else {
        const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
        if (element)
            element.style.display = 'block';
        return false;
    }
}

export const validateAlphaNumericSpecial = (e) => {
    const regex = /^[A-Za-z0-9!@#$&()`.+,\/"-~%^\\*-=\[.*?\];'_{}|:"<>?]*$/
    const result = regex.test(e.target.value);
    if (!result) {
        const element = document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
        }
    }
    return result;
}
export const validateAlphaNumericOnlyHyphen = (e) => {
    const regex = /^[a-zA-Z0-9\_]*$/
    const result = regex.test(e.target.value);
    if (!result) {
        const element = document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
        }
    }
    return result;
}


export const validateRealm = (e) => {
    const regex = /^[[A-Za-z0-9]*.[A-Za-z0-9]*.[a-z]*[^.]]*$/
    const result = regex.test(e.target.value);
    if (!result) {
        const element = document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
        }
    }
    return result;
}
//LONG-2806 Wrong error messge was shown in wifi template
export const validateAlphaNumericUnderChar = (e, index) => {
    const regex = /^[ A-Za-z0-9!@#$&()`.+,_/"-]*$/
    // /[^a-z0-9]|(?<=['\"])s/;     
    // /^\S+[A-Za-z0-9_]\S/;
    const result = regex.test(e.target.value);
    if (!result || e.target.value.length < 1 || e.target.value.length > 32) {
        const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
        }
    }
    return result;
}
export const validateAlphaNumeric = (e, index) => {
    const regex = /^[a-zA-Z0-9 ]+[a-zA-Z0-9]*$/;
    const result = regex.test(e.target.value);
    if (!result || e.target.value.length < 1 || e.target.value.length > 32) {
        const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
        }
    }
    return result;
}
//LONG-1701_added validation for port
export const validatePort = (e, index) => {
    const regex = /^([\d])+$|^\d+([-]{1,1}\d+)+$/
    const result = regex.test(e.target.value);
    if (!result || e.target.value.length < 1 || e.target.value === 0) {
        const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
            element.innerHTML = 'Invalid value';
        }

    }

}
export const validateAlphaNumericUnder = (e, index) => {
    const regex = /^[a-zA-Z0-9_]*$/;
    const result = regex.test(e.target.value);
    if (!result || e.target.value.length < 1 || e.target.value.length > 32) {
        const element = index ? document.getElementById(`${e.target.name}-${index}`) : document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'block';
            element.innerHTML = 'Invalid value';
        }
    }
    return result;
}
export const validateNumeric = (e) => {
    const regex = /^[0-9]*$/;
    const result = regex.test(e.target.value);
    return result;
}
export const validateDomainName = (e) => {
    const regex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
    const result = regex.test(e.target.value);
    return result;
}
export const validateMailAddress = (e) => {
    const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/
    const result = regex.test(e.target.value);
    return result;
}

export const getEncrytionValue = (value) => {
    switch (value) {
        case 'none':
            return 'Open';
        case 'psk2+aes':
            return 'WPA2 AES PSK';
        case 'psk+tkip+aes':
            return 'WPA/WPA2 Mixed Mode';
        case 'ccmp':
            return 'WPA3 Open Enhanced';
        case 'ccmpaes':
            return 'WPA3 PSK';
        case 'psk2+ccmp':
            return 'WPA3/WPA2 Transition';
        case 'wpa2':
            return 'WPA2 Enterprise';
        case 'wpa3':
            return 'WPA3 Enterprise';
        case 'wpa3-mixed':
            return 'WPA3/WPA2 Enterprise';
        default:
            return "";
    }
}
export const getRadioValues = (value) => {
    switch (value) {
        case '2g':
            return '2.4GHz';
        case '5g':
            return '5GHz';
        case 'both':
            return 'Both';
        default:
            return "";
    }
}

export const getMode = (value) => {
    switch (value) {
        case '11axa':
        case '11axg':
            return '802.11ax';
        case '11na':
            return '802.11an';
        case '11ac':
            return '802.11ac';
        case '11ng':
            return '802.11bgn';
        default:
            return "";
    }
}

export const getBW = (value) => {
    switch (value) {
        case "HT20":
            return "20 MHz";
        case "HT40":
            return "40 MHz";
        case "HT80":
            return "80 MHz";
        default:
            return "";
    }
}

export const getConvertedData = (data) => {
    if (data / 1024 > 1024) {
        let value = data / (1024 * 1024);
        return value.toFixed(2).toString().concat(" MB");
    } else if (data / 1024 > 1) {
        let value = data / 1024;
        return value.toFixed(2).toString().concat(" KB");
    } else {
        return data.toString().concat(" Bytes");
    }
};

export const getTemplateType = (value) => {
    switch (value) {
        case "StaticRoute":
            return "Static Route";
        case "LANConfiguration":
            return "LAN Configuration";
        case "WANConfiguration":
            return "WAN Configuration";
        case "WiFiConfiguration":
            return "WiFi Configuration";
        case "NetworkConfiguration":
            return "Network Configuration";
        case "OSPFConfiguration":
            return "OSPF Configuration";
        case "BGPConfiguration":
            return "BGP Configuration";
        case "FirewallConfiguration":
            return "Firewall Configuration";
        case "TriggerConfiguration":
            return "Trigger Configuration";
        case "QoSConfiguration":
            return "QoS Configuration";
        case "TunnelConfiguration":
            return "Wireguard Configuration";
        case "DeviceProvision":
            return "Device Provision";
        case "DNSFiltering":
            return "DNS Configuration";
        case "SystemConfiguration":
            return "System Configuration";
        case "ModemConfiguration":
            return "Modem Configuration";
        case "CaptivePortal":
            return "Captive Portal Configuration";
        case "IPSecConfiguration":
            return "IPSec Configuration";
        case "DeviceConfiguration":
            return "Device Configuration";
        case "ZabbixConfiguration":
            return "Telemetry Configuration";
        default:
            return value;
    }
}

export const getSeverity = (value) => {
    switch (value) {
        case "INFO":
            return "Information";
        case "WARNING":
            return "Low";
        case "AVERAGE":
            return "Medium";
        case "HIGH":
            return "High";
        case "DISASTER":
            return "Critical";
        default:
            return "";
    }
}

export const getProtocol = (value) => {
    switch (value) {
        case "connected":
            return "Connected";
        case "static":
            return "Static";
        case "rip":
            return "RIP";
        case "bgp":
            return "BGP";
        case "kernel":
            return "Kernel";
        case "ospf":
            return "OSPF";
        default:
            return value;
    }
}

export const getAction = (value) => {
    switch (value) {
        case 'ACCEPT':
        case '1':
            return 'Accept';
        case 'REJECT':
        case '0':
            return 'Reject';
        case 'DROP':
        case '2':
            return 'Drop';
        case 'snat':
            return 'SNAT';
        case 'masq':
            return 'MASQ';
        default:
            return value;
    }
}

export const getModels = (value) => {
    switch (value) {
        case 'FG2000e-3':
        case 'FG2000-3':
        case "FG2000-2":
        case "FG2000-4":
        case "FG2000e-1":
        case "FG2000e-2":
        case "FG2000e-4":
        case "FG2000-1":
            return 'CHIMAY';
        case 'V2000':
            return 'vCPE';
        case 'M2100':
        case "M2000A":
        case "M2000B":
        case "M2000C":
        case "M2000D":
        case "M2000":
            return 'Moretti_Model';
        case "FX2000-1":
        case "FX2000-2":
        case "FX2000-3":
        case "FX2000-4":
        case "FX2000e-1":
        case "FX2000e-2":
        case "FX2000e-3":
        case "FX2000e-4":
            return 'Duvel_Model';
        case "S2000e-1":
        case "S2000e-2":
        case "S2000e-3":
        case "S2000e-4":
            return 'Stormbreaker';
        case "FW2000":
        case "FW2010":
        case "FW2000e":
        case "FW2010-1":
        case "FW2010e-1":
            return 'Firestorm';
        case "ZTNA-B":
            return 'Packetfence';
        case "M3200":
        case "M3100":
        case "M3000B":
        case "M3000A":
        case "M3000D":
        case "M3000C":
        case "M3000B-NA":
            return 'Bradshaw';
        case "FX3100-1":
        case "FX3100-2":
        case "FX3100-1G":
            return 'Orval';
        case "FW3000":
            return 'Sander';
        default:
            return value;
    }
}

export const getMiFiModels = (model) => {
    switch (model) {
        case 'M2100':
        case "M2000A":
        case "M2000B":
        case "M2000C":
        case "M2000D":
        case "M2000":
            return 'Moretti_Model';
        case "FX2000-1":
        case "FX2000-2":
        case "FX2000-3":
        case "FX2000-4":
        case "FX2000e-1":
        case "FX2000e-2":
        case "FX2000e-3":
        case "FX2000e-4":
            return 'Duvel_Model';
        default:
            return model;
    }
}

export const getZoneName = (value) => {
    switch (value) {
        case "device_output":
            return "Device (output)";
        case "device_input":
            return "Device (input)";
        case "any":
            return "Any (forward)";
        default:
            return (value.toLowerCase() === "lan" || value.toLowerCase() === "wan") ? value.toUpperCase() : value;
    }
}

export const getConfig = (value) => {
    let configValue = "";
    value.split(' ').map((item) => {
        switch (item) {
            case "tcp-ack":
                configValue = configValue === "" ? "TCP(ACK)" : configValue + " TCP(ACK)";
                break;
            case "tos-min-delay":
                configValue = configValue === "" ? "DSCP(TOS-MIN-DELAY)" : configValue + " DSCP(TOS-MIN-DELAY)";
                break;
            case "dscp-36":
                configValue = configValue === "" ? "SSH" : configValue + " SSH";
                break;
            case "dscp-46":
                configValue = configValue === "" ? "DNS" : configValue + " DNS";
                break;
            case "dscp-48":
                configValue = configValue === "" ? "DSCP-EF" : configValue + " DSCP-EF";
                break;
            case "port-22":
                configValue = configValue === "" ? "DSCP-CS6" : configValue + " DSCP-CS6";
                break;
            case "port-53":
                configValue = configValue === "" ? "DSCP-AF42" : configValue + " DSCP-AF42";
                break;
            case "control_tunnel":
                configValue = configValue === "" ? "Mgmt. Plane PACKET" : configValue + " Mgmt. Plane PACKET";
                break;
            case "dscp-8":
                configValue = configValue === "" ? "DSCP-CS1" : configValue + " DSCP-CS1";
                break;
            case "udp":
                configValue = configValue === "" ? "UDP" : configValue + " UDP";
                break;
            case "default":
                configValue = configValue === "" ? "DEFAULT QUEUE" : configValue + " DEFAULT QUEUE";
                break;
            case "data_tunnel":
                configValue = configValue === "" ? "DATA TUNNEL PACKET" : configValue + " DATA TUNNEL PACKET";
                break;
            default:
                return item;
        }
    })
    return configValue;
}

export const getRuleProtocol = (value) => {
    switch (value) {
        case "tcp":
            return "TCP";
        case "udp":
            return "UDP";
        case "icmp":
            return "ICMP";
        case "all":
            return "ALL";
        default:
            return value;
    }
}

export const getGroupValue = (value) => {
    let result = '';
    value.split('-')?.map((item) => {
        if (item !== '') {
            switch (item) {
                case 'modp1024':
                    result = result + ' Group-2';
                    break;
                case 'modp1536':
                    result = result + ' Group-5';
                    break;
                case 'modp2048':
                    result = result + ' Group-14';
                    break;
                case 'modp3072':
                    result = result + ' Group-15';
                    break;
                case 'modp4096':
                    result = result + ' Group-16';
                    break;
                case 'modp6144':
                    result = result + ' Group-17';
                    break;
                case 'modp8192':
                    result = result + ' Group-18';
                    break;
                default:
                    result = item;
                    break;
            }
        }
    })
    return result;
};
export const getEncrytion = (value) => {
    let result = '';
    value.split('-')?.map((item) => {
        if (item !== '') {
            switch (item) {
                case 'aes256':
                    result = result + ' AES-256';
                    break;
                case 'aes128':
                    result = result + ' AES-128';
                    break;
                case '3des':
                    result = result + ' 3DES';
                    break;
                case 'blowfish':
                    result = result + ' Blowfish';
                    break;
                case 'cast':
                    result = result + ' CAST';
                    break;
                default:
                    result = item;
                    break;
            }
        }
    })
    return result;
};

export const getHash = (value) => {
    let result = '';
    value.split('-')?.map((item) => {
        if (item !== '') {
            switch (item) {
                case 'md5':
                    result = result + ' MD5';
                    break;
                case 'sha1':
                    result = result + ' SHA 1';
                    break;
                case 'sha256':
                    result = result + ' SHA 256';
                    break;
                default:
                    result = item;
                    break;
            }
        }
    })
    return result;
};


export const guacamoleCredential = {
    username: process.env.REACT_APP_GUACAMOLE_USERNAME,
    password: process.env.REACT_APP_GUACAMOLE_PASSWORD && base64_decode(process.env.REACT_APP_GUACAMOLE_PASSWORD)
}