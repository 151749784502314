/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from "../../layOuts/Loader";
import DataTable from "react-data-table-component";
import * as Action from "../../redux/actionTypes";
import { Link } from 'react-router-dom';
import ErrorHandlePopup from "../ErrorHandlePopup";
import $ from 'jquery';
import { ErrorPopup } from "../../layOuts/errorPopup";
class AttachedDeviceInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      deviceInfo: [],
      showLoader: false,
      deviceListWithId: [],
      isAxiosError: false,
      deviceInfoColumns: [
        {
          name: "Device Name",
          selector: "device_name",
          value: "Device Name",
          minWidth: "140px",
          sortable: true,
          center: true,
          cell: (row) => (
            <span
              title={row.device_name}
              style={{ width: "auto" }}
              className="cursor-pointer"
            >
              <Link to={{ pathname: '/devicedetails', state: { data: row, DeviceList: this.state.deviceListWithId, back: true } }} onClick={() => this.closeAttachDevice()} className="txt-blue">{row.device_name}</Link>
            </span>
          ),
        },
        {
          name: "Device Model",
          value: "Device Model.",
          minWidth: "150px",
          selector: "device_model",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.device_model}>{row.device_model}</span>
          ),
        },
        {
          name: "Device MAC",
          value: "Device Mac.",
          minWidth: "150px",
          selector: "device_mac",
          sortable: true,
          center: true,
          cell: (row) => <span title={row.device_mac.toUpperCase()}>{row.device_mac.toUpperCase()}</span>,
        },
        {
          name: <span className="satatus-header">Status</span>,
          value: "Status",
          selector: "device_status",
          sortable: true,
          sortFunction: (rowA, rowB) => rowA.device_status - rowB.device_status,
          center: true,
          maxWidth: "10px",
          cell: (row) => (
            <div id={`col${row.device_id}`}>
              <span id={`icon${row.device_id}`}>
                {" "}
                <i className={this.returnClassStatus(row.device_status)}></i>
                {this.returnStatus(row.device_status)}
              </span>
            </div>
          ),
        },
      ],
    };
  }
  componentDidMount() {
    this.getAttachedDeviceInfo();
  }
  returnClassStatus = (device_status) => {
    switch (device_status) {
      case 0:
        return "status offline";
      case 1:
        return "status online";
      case 2:
        return "status added";
      case 3:
        return "status available";
      case 4:
        return "status approve";
      default:
        return "";
    }
  };
  returnStatus = (device_status) => {
    switch (device_status) {
      case 0:
        return "Offline";
      case 1:
        return "Online";
      case 2:
        return "Added";
      case 3:
        return "Available";
      case 4:
        return "Auto Approved";
      default:
        return "";
    }
  };

  getAttachedDeviceInfo = () => {
    let data = { template_id: this.props.data.id };
    this.setState({ showLoader: true });
    ApiCall(urlMapping.attachedDeviceInfo(data), (response) => {
      if (response?.Success) {
        this.setState({
          deviceInfo: response?.data?.Attached_Devices,
          showLoader: false,
        }, () => this.getDeviceDetails());
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          })
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState({ showLoader: false });
        }
      }
    });
  };

  getDeviceDetails = () => {
    let data = {}
    Array.isArray(this.state.deviceInfo) && this.state.deviceInfo.map((item) => {
      data = { id: item.device_id, name: item.device_name }
      this.state.deviceListWithId.push(data)
    })
  }

  closeAttachDevice = () => {
    this.props.dispatch({
      type: Action.CLOSE_ATTACH,
    });

  };

  render() {
    return (
      <>
        <div>
          {this.state.showLoader && <Loader />}
          <div
            className={
              this.props.openAttach
                ? "slide-overlay zindex10 open"
                : "slide-overlay zindex10"
            }
          ></div>
          <div
            className={
              this.props.openAttach
                ? "sidebar-slide zindex10 open "
                : "sidebar-slide zindex10"
            }
          >
            <div className="s-header">
              <h1 className="ss-title">Attached DeviceInfo</h1>
              <button onClick={() => this.closeAttachDevice()}>
                <img src="/images/svg-icons/s-cancel.svg" alt="" />
              </button>
            </div>
            <div>
              <div className="s-body pb-3">
                <DataTable
                  columns={this.state.deviceInfoColumns}
                  data={this.state.deviceInfo}
                />
              </div>
            </div>
          </div>
          {this.state.showRefreshModal && <ErrorHandlePopup />}
          {(this.state.isAxiosError) &&
            <ErrorPopup
              title={this.state.title}
              message={this.state.message}
              error={this.state.error}
              delete={this.state.delete}
              reset={() => this.setState({ isAxiosError: false })}
            />
          }
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    openAttach: state.displayAddTemplate.openAttach,
  };
};
export default withRouter(connect(mapStateToProps)(AttachedDeviceInfo));
