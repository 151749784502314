import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Loader } from "../../layOuts/Loader";
import { ErrorPopup } from "../../layOuts/errorPopup";
import ErrorHandlePopup from "../ErrorHandlePopup";
import $ from "jquery";
import urlMapping from "../../redux/API/api";
import ApiCall from "../../redux/API/apiCall";
import _ from "lodash";
import * as Action from '../../redux/actionTypes';
import { setIP } from '../../redux/API/pfAPI';

class DataPolling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            device_id: this.props.id ? this.props.id : "",
            deviceModel: this.props.deviceModel ? this.props.deviceModel : "",
            showRefreshModal: false,
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            title: "",
            message: "",
            show: false,
            error: false,
            showLoader: false,
            showError: false,
            data: {},
            isAxiosError: false,
            responseData: {},
            openTabs: {
                "getTunnelStatistics": false,
                "getMapDetailsFromZabbix": false,
                "getDeviceStatusFromZabbix": false,
                "device_memory_statistics": false,
                "modem_info": false,
                "connected_client_info": false,
                "interface_details": false,
                "radio_status": false,
                "data_usage": false,
                "disk_usage": false,
                "qos": false,
            },
        };
    }

    componentDidMount() {
        this.getPollingData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.id !== this.props.id) || (nextProps.deviceModel !== this.props.deviceModel)) {
            this.setState({
                openTabs: {
                    "getTunnelStatistics": false,
                    "getMapDetailsFromZabbix": false,
                    "getDeviceStatusFromZabbix": false,
                    "device_memory_statistics": false,
                    "modem_info": false,
                    "connected_client_info": false,
                    "interface_details": false,
                    "radio_status": false,
                    "data_usage": false,
                    "disk_usage": false,
                    "qos": false,
                },
                device_id: nextProps.id,
                deviceModel: nextProps.deviceModel,
                data: {}
            }, () => this.getPollingData())
        }
    }

    getPollingData = () => {
        this.setState({
            showLoader: true,
        });
        let data = {
            device_id: this.state.device_id,
        }
        ApiCall(urlMapping.getPollingData(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                let { openTabs } = this.state;
                let data;
                Object.filter = (obj, key) =>
                    Object.fromEntries(Object.entries(obj).filter(key));
                openTabs[Object.keys(response?.data)?.[0]] = true;
                switch (this.state.deviceModel) {
                    case "vCPE":
                        data = Object.filter(response?.data, ([item]) => (item !== "connected_client_info" && item !== "modem_info" && item !== "radio_status" && item !== "getMapDetailsFromZabbix"));
                        break;
                    case "MIFI":
                    case "Stormbreaker":
                    case "Bradshaw":
                    case "Sander":
                    case "Moretti_Model":
                    case "Duvel_Model":
                        data = Object.filter(response?.data, ([item]) => (item !== "qos" && item !== "disk_usage"));
                        break;
                    case "Firestorm":
                    case "Orval":
                        let data1 = response.data;
                        // delete data1['connected_client_info']['keys']['wifi_clients'];
                        data = Object.filter(data1, ([item]) => (item !== "qos" && item !== "disk_usage" && item !== "radio_status"
                        ));
                        break;
                    default:
                        data = response?.data;
                }
                // this.state.deviceModel === "vCPE"
                //         ? Object.filter(response?.data, ([item]) => (item !== "connected_client_info" && item !== "modem_info" && item !== "radio_status" && item !== "getMapDetailsFromZabbix"))
                //         : (this.state.deviceModel === "MIFI" || this.state.deviceModel === "Stormbreaker" || this.state.deviceModel === "Firestorm" || this.state.deviceModel === "Bradshaw"
                //             || this.state.deviceModel === "Orval" || this.state.deviceModel === "Sander" || this.state.deviceModel === "Moretti_Model" || this.state.deviceModel === "Duvel_Model")
                //             ? Object.filter(response?.data, ([item]) => (item !== "qos" && item !== "disk_usage"))
                //             : response?.data,
                this.setState({
                    data,
                    openTabs
                },
                );
            } else {
                this.setState({
                    showLoader: false
                });
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true,
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Telemetry',
                        message: "Unable to get data!",
                        show: true,
                        error: true,
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        });
    }

    getKeyValue = (parameter, keys) => {
        if (parameter === "getTunnelStatistics") {
            switch (keys) {
                case "data_usage":
                    return "Data Usage";
                case "drop_rate":
                    return "Drop Rate";
                case "latency":
                    return "Latency";
                case "tunnel_status":
                    return "Tunnel Status";
                default:
                    return null;
            }
        } else if (parameter === "getMapDetailsFromZabbix") {
            switch (keys) {
                case "location":
                    return "Location";
                default:
                    return null;
            }
        } else if (parameter === "getDeviceStatusFromZabbix") {
            switch (keys) {
                case "dev_status":
                    return "Device Status";
                case "system_local_time":
                    return "System Local Time";
                default:
                    return null;
            }
        } else if (parameter === "disk_usage") {
            switch (keys) {
                case "space_utilization":
                    return "Space Utilization";
                case "total_space":
                    return "Total Space";
                case "space_usage":
                    return "Space Usage";
                default:
                    return null;
            }
        } else if (parameter === "data_usage") {
            switch (keys) {
                case "cellular_data_usage":
                    return "Cellular Data Usage";
                case "control_tunnel_data_usage":
                    return "Mgmt. Plane Data Usage";
                case "tunnel_data_usage":
                    return "Data Tunnel Usage";
                case "wan_data_usage":
                    return "WAN Data Usage";
                default:
                    return null;
            }
        } else if (parameter === "device_memory_statistics") {
            switch (keys) {
                case "available_memory":
                    return "Available Memory";
                case "memory_utilization":
                    return "Memory Utilization";
                case "total_memory":
                    return "Total Memory";
                case "cpu_util":
                    return "CPU Idle Time";
                case "no_of_cpu":
                    return "No of CPU";
                case "p_available_memory":
                    //EMGT-319-EM - Zabbix configuration field validation
                    return "Available Memory In Percentage";
                default:
                    return null;
            }
        } else if (parameter === "modem_info") {
            switch (keys) {
                case "5g_status":
                    return "5g Status";
                case "service_info":
                    return "Service Information";
                case "signal_stats":
                    return "Signal Statistics";
                case "slots_info":
                    return "Slots Information";
                case "ip_details":
                    return "IP Details";
                case "gen_details":
                    return "Modem Information";
                case "sim_apn":
                    return "SIM APN";
                default:
                    return null;
            }
        } else if (parameter === "connected_client_info") {
            switch (keys) {
                case "wired_clients":
                    return "Wired Clients";
                case "wifi_clients":
                    return "Wifi Clients";
                case "wan_clients":
                    return "WAN Clients";
                case "vlan_clients":
                    return "VLAN Clients"
                default:
                    return null;
            }
        } else if (parameter === "interface_details") {
            switch (keys) {
                case "inbound_outbound_packets":
                    return "Inbound Outbound Packets";
                case "link_status":
                    return "Link Status";
                case "network_info":
                    return "Network Information";
                case "operational_status":
                    return "Operational Status";
                default:
                    return null;
            }
        } else if (parameter === "radio_status") {
            switch (keys) {
                case "radio_5g":
                    return "Radio 5g";
                case "radio_2g":
                    return "Radio 2g";
                default:
                    return null;
            }
        } else if (parameter === "qos") {
            switch (keys) {
                case "QoS_Statistics":
                    return "QoS Statistics";
                default:
                    return null;
            }
        }
    };

    getParameterName = (parameter) => {
        switch (parameter) {
            case "getTunnelStatistics":
                return "Tunnel statistics";
            case "getTunnelStatus":
                return "Tunnel status";
            case "getMapDetailsFromZabbix":
                return "Map details";
            case "getDeviceStatusFromZabbix":
                return "Device status";
            case "data_usage":
                return "Device data usage";
            case "device_memory_statistics":
                return "Device Statistics ";
            case "modem_info":
                return "Modem Information";
            case "connected_client_info":
                return "Connected Client Information";
            case "interface_details":
                return "Interface Details";
            case "radio_status":
                return "Radio Status";
            case "disk_usage":
                return "Disk Usage";
            case "qos":
                return "QoS";
            default:
                break;
        }
    };

    calculateUsage = (parameter, key) => {
        let dataUsage = 0;
        const status = this.state.data[parameter].status !== undefined ? this.state.data[parameter].status :
            this.state.data[parameter].keys[key].status;
        if (this.state.data[parameter].keys[key].value !== '' && parseInt(this.state.data[parameter].keys[key].value) !== 0 &&
            !isNaN(this.state.data[parameter].keys[key].value) && status) {
            // calculate no of polls in a day
            let No_of_polls = Math.ceil(86400 / parseInt(this.state.data[parameter].keys[key].value));
            dataUsage = (this.state.data[parameter].keys[key].usage * No_of_polls) / 1000000;
        }
        return dataUsage;
    }
    getTotalParameterUsage = (parameter) => {
        let usage = 0;
        _.map(this.state.data, (data, key) => {
            if (key === parameter) {
                Object.keys(this.state.data[parameter].keys).map((item) => {
                    usage = usage + this.calculateUsage(parameter, item);
                })

            }
        });
        return usage.toFixed(3);
    }
    calculateTotalUsage = () => {
        let usage = 0;
        // _.map(this.state.data, (parameter, key) => {
        Object.keys(this.state.data).map((item) => {
            (Object.keys(this.state?.data?.[item]?.keys)).map((value) => {
                usage = usage + this.calculateUsage(item, value);
            })
        })
        // });
        return usage.toFixed(3);
    }
    setUsageInterval = (e) => {
        if (!isNaN(e.target.value)) {
            const element = document.getElementById(e.target.name);
            element.style.display = "none";
            this.setState({
                showError: false,
            });
            let { data } = this.state;
            data[e.target.name.split('-')[0]].keys[e.target.name.split('-')[1]].value = e.target.value ===
                "" ? 0 : parseInt(e.target.value);
            data[e.target.name.split('-')[0]].isChange = true;
            this.setState({ data });
        }
    }
    checkValidInterval = (e) => {
        // if (e.target.name === 'qos-QoS_Statistics') {
        //     if (parseInt(e.target.value) < 10 || parseInt(e.target.value) > 900) {
        //         const element = document.getElementById(e.target.name);
        //         element.style.display = "block";
        //         this.setState({
        //             showError: true,
        //         });
        //     }
        // } else 
        if (parseInt(e.target.value) < 10 || parseInt(e.target.value) > 86400) {
            const element = document.getElementById(e.target.name);
            element.style.display = "block";
            this.setState({
                showError: true,
            });
        }
    }
    changeStatus = (e) => {
        let { data } = this.state;
        data[e.target.name.split('-')[0]].keys[e.target.name.split('-')[1]].status = (e.target.checked === true ? 1 : 0);
        data[e.target.name.split('-')[0]].isChange = true;
        this.setState({ data });
    }
    changeGlobalStatus = (e) => {
        let { data } = this.state;
        data[e.target.name.split('-')[0]].status = (e.target.checked === true ? 1 : 0);
        data[e.target.name.split('-')[0]].isChange = true;
        this.setState({ data });
    }
    // disableOption = () => {
    //     let result = false;
    //     if (process.env.REACT_APP_AD_USER === 'TRUE') {
    //         if (this.props.userRole !== 'Admin' || this.props.userRole !== 'Manager' ||
    //             sessionStorage.getItem('ssoUser') !== 'true') {
    //             result = true;
    //         }
    //     } else {
    //         if (this.props?.userRole === "Viewer_Group" ||
    //             this.props?.userRole === "Operator_Group") {
    //             result = true;
    //         }
    //     }
    //     return result;
    // }   
    renderKeys = (parameter) => {
        return (
            <>
                <div className="table-cell">
                    <div className="d-flex align-items-center">
                        {this.state.data?.[parameter]?.status !== undefined && <><strong>Status</strong>
                            <label className="switch ml-5">
                                <input
                                    className={
                                        (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                            ? "disabled"
                                            : ""
                                    }
                                    type="checkbox"
                                    name={parameter}
                                    checked={this.state.data?.[parameter]?.status === 1}
                                    onChange={
                                        (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                            ? undefined
                                            : this.changeGlobalStatus
                                    }
                                />
                                <span className={
                                    (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                        (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                            localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                        (this.props?.userRole === "Viewer_Group" ||
                                            this.props?.userRole === "Operator_Group")
                                        ? "slider round disabled"
                                        : "slider round"} />
                            </label></>}
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <strong>Keys</strong>
                        </div>
                        {this.state.data?.[parameter]?.status === undefined && <div className="col-1">
                            <strong>Status</strong>
                        </div>}
                        <div className="col-4">
                            <strong>Refresh Interval(seconds)</strong>
                        </div>
                        <div className="col-4">
                            <strong>Estimated Data usage/day</strong>
                        </div>
                    </div>
                    {/* EMGT-2197 WiFi client hidden for FW and Stormbreaker */}
                    {_.map(this.state.data?.[parameter]?.keys, (i, key) => (
                        (
                        (this.state.deviceModel === "CHIMAY" || key !== "cellular_data_usage") &&
                        !((this.state.deviceModel === "Firestorm" || this.state.deviceModel === "Sander" ||
                            this.state.deviceModel === "Stormbreaker"
                        ) && key === "wifi_clients")
                        ) && <div className="row">
                            <div className="col-3">
                                {this.getKeyValue(parameter, key)}
                            </div>
                            {this.state.data?.[parameter]?.status === undefined &&
                                <div className="col-1 mt-2">
                                    <label className="switch">
                                        <input
                                            className={
                                                (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                    (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                                    (this.props?.userRole === "Viewer_Group" ||
                                                        this.props?.userRole === "Operator_Group")
                                                    ? "disabled"
                                                    : ""
                                            }
                                            type="checkbox"
                                            name={`${parameter}-${key}`}
                                            checked={this.state.data?.[parameter]?.keys[key].status === 1}
                                            onChange={
                                                ((process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                    (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                                    (this.props?.userRole === "Viewer_Group" ||
                                                        this.props?.userRole === "Operator_Group") ||
                                                    parameter === 'data_usage' ||
                                                    key === "dev_status")
                                                    ? undefined :
                                                    (e) => this.changeStatus(e)}
                                        />
                                        <span className={(
                                            (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                    localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group") ||
                                            parameter === 'data_usage' ||
                                            key === "dev_status") ? "slider round disabled" : "slider round"} />
                                    </label>
                                </div>}
                            <div className="col-4">
                                <input
                                    className="form-control w90"
                                    type='text'
                                    name={`${parameter}-${key}`}
                                    value={this.state.data?.[parameter]?.keys[key].value}
                                    onChange={(event) => this.setUsageInterval(event)}
                                    disabled={
                                        (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                        (this.props?.userRole === "Viewer_Group" ||
                                            this.props?.userRole === "Operator_Group") ||
                                        this.state.data?.[parameter]?.keys[key].status === 0 ||
                                        this.state.data?.[parameter]?.status === 0
                                    }
                                    onBlur={this.checkValidInterval}
                                />
                                <div className="error-txt" id={`${parameter}-${key}`}>Value from 10 to 86400 seconds is allowed only</div>
                            </div>
                            <div className="col-4">
                                <label>{`${this.calculateUsage(parameter, key)} MB`} </label>
                                {/* <input className="disabled"
                                    type='text'
                                    value={this.state.data?.[parameter]?.keys[key].usage}
                                    readOnly
                                />
                                <span className="units">MB</span> */}
                            </div>
                        </div>

                    ))}
                </div>
            </>
        );
    };
    checkError = () => {
        let result = false;
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        // Object.keys(this.state.data).map((item) => {
        //     (Object.keys(this.state.data[item].keys)).map((value) => {
        //         result = true;
        //     })
        // })

        return result;
    }
    saveData = () => {
        let jsonData = {};
        _.map(this.state.data, (data, parameter) => {
            if (data?.isChange) {
                delete data?.isChange;
                jsonData = {
                    ...jsonData,
                    [parameter]: {
                        ...data,
                    },
                };
            }
            else {
                if (data?.status !== undefined) {
                    jsonData = {
                        ...jsonData,
                        [parameter]: {
                            status: data.status,
                        },
                    }
                }
            }
        });
        let data = { polling_json: jsonData, device_id: this.state.device_id };
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.savePollingData(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                // this.setState({ data: jsonData });
                this.setState(
                    {
                        title: "Update Success",
                        message: response?.message,
                        show: true,
                        error: false,
                    },
                    () => $("#errorMessage").modal("show")
                );
            } else {
                this.setState({
                    showLoader: false,
                });
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true,
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState(
                        {
                            title: "Update Failed",
                            message: response?.message,
                            show: true,
                            error: true,
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        });
    };

    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => this.getPollingData());

    };
    openValues = (parameter) => {
        let { openTabs } = this.state;
        openTabs[parameter] = !this.state.openTabs[parameter];
        this.setState({ openTabs })
    }

    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div className="white-bg page-height">
                    <div className="ta-box set-pos mb-0">
                        <div className="action-btn d-flex align-items-center">
                            <button className="short-btn" onClick={() => this.getPollingData()} title="Refresh">
                                <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh
                            </button>
                        </div>
                    </div>
                    <div className="s-body">
                        <label><strong>{`Total Data Usage  ${this.calculateTotalUsage()} MB/day`}</strong></label>
                        {_.map(this.state.data, (data, parameter) => (
                            <div className="my-3 data-poll-table">
                                <div className="d-flex align-items-center justify-content-between table-heading">
                                    <strong>
                                        {this.getParameterName(parameter)}
                                    </strong>
                                    <div className="text-right"><label><strong>
                                        {`Data usage  ${this.getTotalParameterUsage(parameter)} MB/day`}
                                    </strong></label>
                                        <img className="" style={{ width: "0.8%", marginLeft: "50px", cursor: "pointer" }}
                                            src="../images/svg-icons/down-arrow.svg" alt=""
                                            onClick={() => this.openValues(parameter)}
                                        ></img></div>
                                </div>

                                {this.state.openTabs[parameter] && <div className="table-cell">
                                    {this.renderKeys(parameter)}
                                </div>}
                            </div>
                        ))}
                    </div>
                    <div className="s-footer">
                        <button
                            className="btn btn-primary"
                            onClick={() => this.saveData()}
                            disabled={this.checkError() || (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                    localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                (this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")}
                        >
                            Save
                        </button>
                    </div>
                    {
                        this.state.show && (
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                reset={() => this.resetPopup()}
                            />
                        )
                    }
                    {(this.state.isAxiosError) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.setState({ isAxiosError: false })}
                        />
                    }
                    {(this.state.showRefreshModal) &&
                        <ErrorHandlePopup
                        />
                    }
                </div >
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        SsoUser: state.auth.SsoUser,
        userRole: JSON.parse(localStorage.getItem('user'))?.userRole
    };
};
export default withRouter(connect(mapStateToProps)(DataPolling));
