/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import Topology from './Topology';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ErrorHandlePopup from "../ErrorHandlePopup";
import $ from "jquery";
import { ErrorPopup } from "../../layOuts/errorPopup";
import Select from 'react-select-virtualized';
import * as Common from "../../utils/common";

class Tunnelview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            title: '',
            message: '',
            show: false,
            error: false,
            delete: false,
            selectedDuration: 1,
            tunnelGraphData: [],
            keyUpdate: Math.random(),
            option: {},
            tunnelData: undefined,
            tunnelInfoStatus: 1,
            isAxiosError: false,
            tunnel_id: "",
            tunnel_type: "",
            tunnelOptions: []
        };
    };

    componentDidMount() {
        this.setState({
            tunnel_id: this.props?.location?.state?.data?.tunnel_id || "",
            tunnel_type: this.props?.location?.state?.data?.tunnel_type || ""
        }, () => {
            this.getOptions();
            this.getTunnelDetails();
            (String(this.state?.tunnel_type) === "Wireguard Tunnel") && this.getTunnelDataUsage(1);
            (String(this.state?.tunnel_type) === "Wireguard Tunnel") && this.getPollingData();
        })
    };

    onDropChange = (e) => {
        if (e) {
            this.setState({
                showLoader: true,
                tunnel_id: e?.value,
                tunnel_type: e?.key
            }, () => {
                this.getTunnelDetails();
                this.getOptions();
            })
        }
    };

    getOptions = () => {
        this.setState({
            showLoader: true
        }, () => {
            ApiCall(urlMapping.getAllTunnelList(), (response) => {
                if (response?.success && response?.data) {
                    let { tunnelOptions } = this.state;
                    tunnelOptions = response.data.map(item => ({
                        value: item?.tunnel_id,
                        title: item?.tunnel_name,
                        label: item?.tunnel_name?.length > 15 ? item?.tunnel_name?.substring(0, 15) + '...' : item?.tunnel_name || "-",
                        key: item?.tunnel_type,
                        icon: (
                            <i
                                className={
                                    (item?.tunnel_type === "IPSec Tunnel")
                                        ? (
                                            (JSON.parse(item?.tunnel_status.replace(/'/g, '"'))?.status === "Online")
                                                ? "status online"
                                                : (JSON.parse(item?.tunnel_status.replace(/'/g, '"'))?.status === "Partial")
                                                    ? "status pending"
                                                    : "status offline"
                                        )
                                        : (
                                            (item?.tunnel_status === "Online")
                                                ? 'status online'
                                                : 'status offline'
                                        )
                                }
                            />
                        ),
                    }));
                    this.setState({
                        tunnelOptions,
                        showLoader: false
                    });
                }
                else {
                    if (
                        parseInt(response?.response?.status) === 401 ||
                        parseInt(response?.response?.status) === 403
                    ) {
                        this.setState({ showRefreshModal: true })
                    } else if (
                        parseInt(response?.response?.status) === 404 &&
                        response?.response?.data
                    ) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response?.response?.data?.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (
                        response?.isAxiosError &&
                        response?.success === undefined &&
                        response?.response?.data?.success === undefined
                    ) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            tunnelOptions: []
                        })
                    }
                }
            })
        });
    };

    getTunnelDetails = () => {
        let data = {};
        if (String(this.state.tunnel_type) !== "IPSec Tunnel") {
            data.tunnel_id = this.state.tunnel_id
        }
        if (String(this.state.tunnel_type) === "IPSec Tunnel") {
            data.ipsec_tunnel_id = this.state.tunnel_id
        }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.tunnelInfo(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (
                response?.success &&
                response?.data
            ) {
                this.setState({
                    tunnelData:
                        (String(this.state.tunnel_type) === "IPSec Tunnel")
                            ? (response?.data || "")
                            : (response?.data?.[0] || ""),
                    keyUpdate: Math.random()
                })
            } else {
                if (
                    parseInt(response?.response?.status) === 401 ||
                    parseInt(response?.response?.status) === 403
                ) {
                    this.setState({ showRefreshModal: true })
                } else if (
                    parseInt(response?.response?.status) === 404 &&
                    response?.response?.data
                ) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response?.response?.data?.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    };

    getPollingData = () => {
        this.setState({
            showLoader: true
        });
        const data = {
            device_id: this.state.tunnel_id,
        }
        ApiCall(urlMapping.getPollingData(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                this.setState({ tunnelInfoStatus: parseInt(response?.data?.getTunnelStatistics?.status) });
            } else {
                this.setState({
                    showLoader: false
                });
                if (
                    parseInt(response?.response?.status) === 401 ||
                    parseInt(response?.response?.status) === 403
                ) {
                    this.setState({
                        showRefreshModal: true,
                    });
                } else if (
                    parseInt(response?.response?.status) === 404 &&
                    response?.response?.data
                ) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Telemetry',
                        message: "Unable to get data!",
                        show: true,
                        error: true,
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        });
    };

    getTunnelDataUsage = (e) => {
        let day = e
        let edate;
        this.setState({ selectedDuration: day })
        if (e === 1) {
            if (moment.utc().format("HH:mm") > moment.utc().format("HH")) {
                edate = moment.utc().add(1, "hours").format("YYYY-MM-DD HH:mm")
            }
            else {
                edate = moment.utc().format("YYYY-MM-DD HH:mm")
            }
        }
        let endDate = e === 1 ? edate : moment.utc().format('YYYY-MM-DD HH:mm')
        let startDate = day === 1 ? moment.utc(edate).subtract(25, 'hours').format("YYYY-MM-DD HH:mm") : day === 7 ?
            moment.utc().subtract(7, 'days').format('YYYY-MM-DD HH:mm') : moment.utc().subtract(30, 'days').format('YYYY-MM-DD HH:mm')
        let data = {
            tunnel_id: this.state.tunnel_id,
            day_no: day,
            start_date: startDate,
            end_date: endDate
        }
        ApiCall(urlMapping.tunnelDataUsage(data), (response) => {
            if (response?.success) {
                this.setState({ tunnelGraphData: response?.data }, () => this.tunnelData())
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    };

    getTimeExact = (time, format) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format(format);
    };

    tunnelData = () => {
        let data_usage = [];
        let time = [];
        this.state.tunnelGraphData.map((item) => {
            data_usage.push(item.data_usage / (1024 * 1024))
            if (this.state.selectedDuration === 1)
                time.push(this.getTimeExact(item.bucket, 'hh:mm A'));
            else
                time.push(this.getTimeExact(item.bucket, 'DD MMMM YYYY'));
        })
        let series = [{
            name: 'Data Usage',
            data: data_usage
        }]
        let data = {
            chart: {
                type: 'area',
                height: 290,
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false,

            },
            exporting: {
                enabled: false
            },
            xAxis: {
                categories: time,
                labels: {
                    formatter: function () {
                        return '<a href="' + time[this.value] + '">' +
                            this.value + '</a>';
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'values in MB'
                },
            },
            tooltip: {
                pointFormat: '{series.name} <b>{point.y} MB</b>'
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                }
            },
            series: series
        }
        this.setState({ option: data });
    };

    getOptionLabel = (item) => (
        <div style={{ display: 'flex', alignItems: 'center' }} title={item?.title}>
            {item?.icon}
            <span>{item.label}</span>
        </div>
    );

    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
        })
    };

    refreshTelemetry = () => {
        this.setState({ showLoader: true });
        const temp = {
            device_A: {
                name: this.state.tunnelData.device_A.device_name,
                control_ip: this.state.tunnelData.device_A.device_a_c_ip
            },
            device_B: {
                name: this.state.tunnelData.device_B.device_name,
                control_ip: this.state.tunnelData.device_B.device_b_c_ip
            },
            interface_name: this.state.tunnelData.settings.tunnel_interface_name
        }
        const data = {
            tunnel: temp,
            device_id: ""
        }
        ApiCall(urlMapping.get_Global_refresh(data), (response) => {
            this.setState({ showLoader: false });
            if (response?.success) {
                this.getTunnelDetails()
            }
            else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    });
                }
            }
        })
    };

    downloadLogs = (id, name) => {
        this.setState({ showLoader: true })
        let device_id = id;
        ApiCall(
            urlMapping.downloadIpsecLogfile(device_id),
            (response) => {
                this.setState({
                    showLoader: false
                });
                if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Download Logs',
                        message: "Unable to download!",
                        show: true,
                        error: true,
                        showLoader: false
                    }, () => $("#errorMessage").modal("show"));
                }
                else if (parseInt(response?.response?.status) === 200) {
                    const url = window.URL.createObjectURL(new Blob([response?.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "Ipsec_Logs_" + name + ".zip");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.setState({
                        showLoader: false,
                        download: true,
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({
                            showRefreshModal: true,
                            showLoader: false,
                        });
                    }
                    else {
                        this.setState({
                            title: 'Download Logs',
                            message: "Unable to download!",
                            show: true,
                            error: true,
                            showLoader: false
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            }
        );
    };

    render() {
        return (
            <div>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section
                    id="rhsMain"
                    className={
                        this.props?.open
                            ? "rhs-main"
                            : "rhs-main expand-w"
                    }>
                    <Header />
                    <div className="no-bg page-height tunnel-view">
                        <div className="page-header bg d-flex align-items-center">
                            <div className="back-page-icon"
                                onClick={() => this.props?.history?.push(
                                    {
                                        pathname: "/tunnels",
                                        state: {
                                            filterData: this.props?.location?.state?.filterData || "",
                                            filterKey: (
                                                this.props?.location?.state?.filterData?.selectedDevice ||
                                                this.props?.location?.state?.filterData?.status
                                            )
                                                ? "FilterClicked"
                                                : "",
                                            searchValue: this.props?.location?.state?.searchValue,
                                            searchKey: this.props?.location?.state?.searchValue ? "SearchClicked" : ""
                                        }
                                    })}>
                                <img src="/images/svg-icons/back-page.svg" alt="" title="Back" />
                            </div>
                            <Select
                                options={this.state.tunnelOptions}
                                placeholder={false}
                                className='select-device'
                                value={
                                    this.state.tunnelOptions?.filter(option =>
                                        option?.value === this.state?.tunnel_id)
                                }
                                onChange={(e) => this.onDropChange(e)}
                                formatOptionLabel={this.getOptionLabel}
                            />
                        </div>
                        {(this.state?.tunnelData !== undefined)
                            ? <div className="row no-gutters mb-10">
                                {(String(this.state.tunnel_type) !== "IPSec Tunnel")
                                    ?
                                    (
                                        <div className="col-md-6">
                                            <div className="bg-white tunnel-info radius-spacing h-100">
                                                <div class="row">
                                                    <div class="col">
                                                        <h1 className="page-title text-blue mb-2">Tunnel Info</h1>
                                                    </div>
                                                </div>
                                                <div className="row p-2">
                                                    <div className="col-md-4 col-sm-12">
                                                        <div className="form-group">
                                                            <label>Tunnel Name</label>
                                                            <strong>{this.state.tunnelData?.settings?.tunnel_name || "-"}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                        <div className="form-group">
                                                            <label>Tunnel Status</label>
                                                            <strong>
                                                                <i
                                                                    className={
                                                                        (this.state.tunnelData?.settings?.tunnel_status === "Online"
                                                                            ? "status online"
                                                                            : (this.state.tunnelData?.settings?.tunnel_status === "Online"
                                                                                ? "status pending"
                                                                                : "status offline")
                                                                        )
                                                                    }>
                                                                </i>{this.state.tunnelData?.settings?.tunnel_status || "-"} </strong>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                        <div className="form-group">
                                                            <label>Tunnel Type</label>
                                                            <strong> {this.state.tunnel_type || "-"}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="graybg-title">Device A</div>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-4 col-sm-12">
                                                            <div className="form-group">
                                                                <label>Device Name</label>
                                                                <strong>{this.state.tunnelData?.device_A?.device_name || "-"}</strong>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group d-flex align-items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.tunnelData?.device_A?.add_zone_in_firewall}
                                                                    style={{
                                                                        cursor: "not-allowed",
                                                                        accentColor: "grey"
                                                                    }}
                                                                />
                                                                <label className="mb-0 mt-1 ml-2">Add Interface to Zone</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group d-flex align-items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.tunnelData?.device_A?.add_bgp_in_tunnel}
                                                                    style={{
                                                                        cursor: "not-allowed",
                                                                        accentColor: "grey"
                                                                    }}
                                                                />
                                                                <label className="mb-0 mt-1 ml-2">Enable BGP</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 col-sm-12">
                                                            <div className="form-group">
                                                                <label>Endpoint Details</label>
                                                                <strong>{this.state.tunnelData?.device_A?.endpoint_details || "-"}</strong>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <div className="form-group">
                                                                <label>Allowed IP</label>
                                                                <strong>
                                                                    {this.state.tunnelData?.device_A?.allowed_ips?.length > 0
                                                                        ? this.state.tunnelData?.device_A?.allowed_ips?.map((item) => item + " ")
                                                                        : "-"
                                                                    }
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <div className="form-group d-flex align-items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.tunnelData?.device_A?.is_fixed_ip || "-"}
                                                                    style={{
                                                                        cursor: "not-allowed",
                                                                        accentColor: "grey"
                                                                    }}
                                                                />
                                                                <label className="mb-0 mt-1 ml-2">Is Fixed IP</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 col-sm-12">
                                                            <div className="form-group">
                                                                <label>Mgmt. Plane IP</label>
                                                                <strong>{this.state.tunnelData?.device_A?.private_ip || "-"}</strong>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <div className="form-group">
                                                                <label>Listen Port</label>
                                                                <strong>{this.state?.tunnelData?.device_A?.own_port || "-"}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.tunnelData?.device_B?.device_id !== ""
                                                        ? <>
                                                            <div className="graybg-title mt-3">Device B</div>
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-md-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Device Name</label>
                                                                            <strong>{this.state.tunnelData?.device_B?.device_name || "-"}</strong>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group d-flex align-items-center">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={this.state.tunnelData?.device_B?.add_zone_in_firewall}
                                                                                style={{
                                                                                    cursor: "not-allowed",
                                                                                    accentColor: "grey"
                                                                                }}
                                                                            />
                                                                            <label className="mb-0 mt-1 ml-2">Add Interface to Zone</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group d-flex align-items-center">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={this.state.tunnelData?.device_B?.add_bgp_in_tunnel}
                                                                                style={{
                                                                                    cursor: "not-allowed",
                                                                                    accentColor: "grey"
                                                                                }}
                                                                            />
                                                                            <label className="mb-0 mt-1 ml-2">Enable BGP</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Endpoint Details</label>
                                                                            <strong>{this.state.tunnelData?.device_B?.endpoint_details || "-"}</strong>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Allowed IP</label>
                                                                            <strong>
                                                                                {this.state.tunnelData?.device_B?.allowed_ips?.length > 0
                                                                                    ? this.state.tunnelData.device_B?.allowed_ips.map((item) => item + " ")
                                                                                    : "-"
                                                                                }
                                                                            </strong>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-12">
                                                                        <div className="form-group d-flex align-items-center">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={this.state.tunnelData?.device_B?.is_fixed_ip}
                                                                                style={{
                                                                                    cursor: "not-allowed",
                                                                                    accentColor: "grey"
                                                                                }}
                                                                            />
                                                                            <label className="mb-0 mt-1 ml-2">Is Fixed IP</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mgmt. Plane IP</label>
                                                                            <strong>{this.state.tunnelData?.device_B?.private_ip || "-"}</strong>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Listen Port</label>
                                                                            <strong>{this.state.tunnelData?.device_B?.own_port || "-"}</strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="page-title border-bottom">Settings</div>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="form-group d-flex mb-1">
                                                                            <label className="mb-0">Keep Alive
                                                                            </label>
                                                                            <label className="switch gray mt-1 ml-5">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="tunnel_keep_alive_status"
                                                                                    checked={this.state.tunnelData?.settings?.tunnel_keep_alive_status}
                                                                                />
                                                                                <span className="slider round" style={{ cursor: "not-allowed" }}></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Keep Alive Interval
                                                                            </label>
                                                                            <strong>{this.state.tunnelData?.settings?.tunnel_keep_alive_interval || "-"}</strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="page-title border-bottom">Tunnel Info</div>
                                                                <img
                                                                    className="float-right refreshTunnelInfo"
                                                                    style={{ width: "40px", cursor: "pointer" }}
                                                                    src="/images/refreshT.png"
                                                                    title="Refresh Telemetry data"
                                                                    alt=""
                                                                    onClick={() => this.refreshTelemetry()}
                                                                />
                                                                <div className="gps-box p-0 mr-0">
                                                                    {this.state.tunnelInfoStatus
                                                                        ? <>
                                                                            <div className="row no-gutters">
                                                                                <div className="col-md-4 col-sm-12">
                                                                                    <div className="di-list">
                                                                                        <small>Average Latency</small>
                                                                                        <span className="text-truncate">
                                                                                            {this.state.tunnelData?.settings?.tunnel_latency || '-'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-sm-12" style={{ left: "16px" }}>
                                                                                    <div className="di-list">
                                                                                        <small>Drop Rate TX</small>
                                                                                        <span className="text-truncate">
                                                                                            {this.state.tunnelData?.settings?.drop_rate_out || '-'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-sm-12" style={{ left: "16px" }}>
                                                                                    <div className="di-list">
                                                                                        <small>Drop Rate RX</small>
                                                                                        <span className="text-truncate">
                                                                                            {this.state.tunnelData?.settings?.drop_rate_in || '-'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row no-gutters">
                                                                                <div className="col-md-4 col-sm-12">
                                                                                    <div className="di-list">
                                                                                        <small>Data Usage(bytes)</small>
                                                                                        <span className="text-truncate">
                                                                                            {this.state.tunnelData?.settings?.tunnel_data_usage || '-'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-sm-12" style={{ left: "10px" }}>
                                                                                    <div className="di-list mb-0">
                                                                                        <small>Created On</small>
                                                                                        <span
                                                                                            className="text-truncate"
                                                                                            title={this.getTimeExact(this.state.tunnelData?.settings?.created_date, 'DD MMMM YYYY hh:mm:ss A') || "-"}>
                                                                                            {this.getTimeExact(this.state?.tunnelData?.settings?.created_date, 'DD MMMM YYYY hh:mm:ss A') || "-"}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : <>
                                                                            <div className="text-center flex-grow-1">Configurations are disabled</div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        : <>
                                                            <div className="graybg-title mt-3">External Endpoint</div>
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-md-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Endpoint Details</label>
                                                                            <strong>{this.state.tunnelData?.device_B?.endpoint_details || "-"}</strong>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Mgmt. Plane IP</label>
                                                                            <strong>{this.state.tunnelData?.device_B?.private_ip || "-"}</strong>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-12">
                                                                        <div className="form-group">
                                                                            <label>Listen Port</label>
                                                                            <strong>{this.state.tunnelData?.device_B?.own_port || "-"}</strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="page-title border-bottom">Settings</div>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="d-flex form-group  mb-1">
                                                                            <label className="mb-0">Keep Alive
                                                                            </label>
                                                                            <label className="switch gray mt-1 ml-5">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="tunnel_keep_alive_status"
                                                                                    checked={this.state.tunnelData?.settings?.tunnel_keep_alive_status}
                                                                                />
                                                                                <span className="slider round" style={{ cursor: "not-allowed" }}></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Keep Alive Interval
                                                                            </label>
                                                                            <strong>{this.state.tunnelData?.settings?.tunnel_keep_alive_interval || "-"}</strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="page-title border-bottom">Tunnel Info</div>
                                                                <img
                                                                    className="float-right refreshTunnelInfo"
                                                                    style={{ width: "40px", cursor: "pointer" }}
                                                                    src="/images/refreshT.png"
                                                                    title="Refresh Telemetry data"
                                                                    alt=""
                                                                    onClick={() => this.refreshTelemetry()}
                                                                />
                                                                <div className="gps-box p-0 mr-0">
                                                                    {parseInt(this.state.tunnelInfoStatus) === 1
                                                                        ? <>
                                                                            <div className="row no-gutters">
                                                                                <div className="col-md-4 col-sm-12">
                                                                                    <div className="di-list">
                                                                                        <small>Average Latency</small>
                                                                                        <span className="text-truncate">
                                                                                            {this.state.tunnelData?.settings?.tunnel_latency || '-'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-sm-12" style={{ left: "16px" }}>
                                                                                    <div className="di-list">
                                                                                        <small>Drop Rate TX</small>
                                                                                        <span className="text-truncate">
                                                                                            {this.state.tunnelData?.settings?.drop_rate_out || '-'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-sm-12" style={{ left: "16px" }}>
                                                                                    <div className="di-list">
                                                                                        <small>Drop Rate RX</small>
                                                                                        <span className="text-truncate">
                                                                                            {this.state.tunnelData?.settings?.drop_rate_in || '-'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row no-gutters">
                                                                                <div className="col-md-4 col-sm-12">
                                                                                    <div className="di-list">
                                                                                        <small>Data Usage(bytes)</small>
                                                                                        <span className="text-truncate">
                                                                                            {this.state.tunnelData?.settings?.tunnel_data_usage || '-'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-sm-12" style={{ left: "10px" }}>
                                                                                    <div className="di-list">
                                                                                        <small>Created On</small>
                                                                                        <span
                                                                                            className="text-truncate"
                                                                                            title={this.getTimeExact(this.state.tunnelData?.settings?.created_date, 'DD MMMM YYYY hh:mm:ss A') || "-"}>
                                                                                            {this.getTimeExact(this.state?.tunnelData?.settings?.created_date, 'DD MMMM YYYY hh:mm:ss A') || "-"}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : <>
                                                                            <div className="text-center flex-grow-1">Configurations are disabled</div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    )
                                    : <div className="col-md-6">
                                        <div className="bg-white tunnel-info radius-spacing mb-10">
                                            <div class="row">
                                                <div class="col">
                                                    <h1 className="page-title text-blue mb-2">Tunnel Info</h1>
                                                </div>
                                            </div>
                                            <div className="row p-2">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Tunnel Name</label>
                                                        <strong> {this.state.tunnelData?.tunnel_name || "-"}</strong>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <div
                                                        className="form-group"
                                                        title={
                                                            this.state?.tunnelData?.tunnel_status ?
                                                                (
                                                                    (
                                                                        JSON?.parse(this.state?.tunnelData?.tunnel_status?.replace(/'/g, '"'))?.IPlist !== "" &&
                                                                        JSON?.parse(this.state?.tunnelData?.tunnel_status?.replace(/'/g, '"'))?.IPlist !== undefined
                                                                    )
                                                                        ? (
                                                                            "The IPSec tunnel with remote subnet"
                                                                            + (
                                                                                (JSON.parse(this.state?.tunnelData?.tunnel_status?.replace(/'/g, '"'))?.IPlist)?.split(",")?.length > 1
                                                                                    ? "s "
                                                                                    : " "
                                                                            )
                                                                            + (JSON.parse(this.state?.tunnelData?.tunnel_status?.replace(/'/g, '"'))?.IPlist)
                                                                            + (
                                                                                (JSON.parse(this.state?.tunnelData?.tunnel_status?.replace(/'/g, '"'))?.IPlist)?.split(",")?.length > 1
                                                                                    ? " are "
                                                                                    : " is "
                                                                            )
                                                                            + "not configured correctly"
                                                                        )
                                                                        : ""
                                                                )
                                                                : "-"
                                                        }
                                                    >
                                                        <label>Tunnel Status</label>
                                                        <strong>
                                                            <i
                                                                className={
                                                                    this.state?.tunnelData?.tunnel_status ?
                                                                        (
                                                                            (JSON.parse(this.state?.tunnelData?.tunnel_status?.replace(/'/g, '"'))?.status === "Online")
                                                                                ? "status online"
                                                                                : (JSON.parse(this.state?.tunnelData?.tunnel_status?.replace(/'/g, '"'))?.status === "Partial")
                                                                                    ? "status pending"
                                                                                    : "status offline"
                                                                        )
                                                                        : "status offline"
                                                                }>
                                                            </i>
                                                            {
                                                                this.state?.tunnelData?.tunnel_status
                                                                    ? JSON.parse(this.state?.tunnelData?.tunnel_status.replace(/'/g, '"'))?.status
                                                                    : "-"
                                                            }
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Tunnel Type</label>
                                                        <strong> {this.state?.tunnelData?.tunnel_type || "-"}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="graybg-title">Device A
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        marginTop: '-19px',
                                                        right: '2.5rem'
                                                    }}
                                                    title="Tunnel Logs Download">
                                                    <img
                                                        style={{
                                                            width: '20px',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => this.downloadLogs(this.state.tunnelData?.raw_json?.vpnConfig?.deviceA?.device_id, this.state.tunnelData?.raw_json?.vpnConfig?.deviceA?.device_name)}
                                                        src="/images/svg-icons/download-arrow.svg" />
                                                </div>
                                            </div>
                                            <div className="p-2">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Device Name</label>
                                                            <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.deviceA?.device_name || '-'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Local IP</label>
                                                            <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.deviceA?.local_ip || '-'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Local Subnet</label>
                                                            <strong>{(this.state.tunnelData?.raw_json?.vpnConfig?.deviceA?.local_network?.join(", ")) || '-'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Remote IP</label>
                                                            <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.deviceA?.remote_ip || '-'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Remote Subnet</label>
                                                            <strong>{(this.state.tunnelData?.raw_json?.vpnConfig?.deviceA?.remote_network)?.join(", ") || '-'}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>IKE Phase 1</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Key Lifetime</label>
                                                            <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.keylife1 || '-'}&nbsp;Seconds</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Encryption</label>
                                                            <strong>{Common.getEncrytion(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.enc_algo1) || '-'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Hash</label>
                                                            <strong>{Common.getHash(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.hash_algo1) || '-'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>DH Group</label>
                                                            <strong>{Common.getGroupValue(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dh_group1) || '-'}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>IKE Phase 2</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Key Lifetime</label>
                                                            <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.keylife2 || '-'}&nbsp;Seconds</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Encryption</label>
                                                            <strong>{Common.getEncrytion(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.enc_algo2) || '-'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Hash</label>
                                                            <strong>{Common.getHash(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.hash_algo2) || '-'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>DH Group</label>
                                                            <strong>{Common.getGroupValue(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dh_group2) || '-'}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Dead Peer Detection</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Enable</label>
                                                            <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_enable || '-'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>DPD Action</label>
                                                            <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_action || '-'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>DPD Delay</label>
                                                            <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_delay || '-'}&nbsp;Seconds</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>DPD Timeout</label>
                                                            <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_timeout || '-'}&nbsp;Seconds</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                (this.state.tunnelData?.raw_json?.vpnConfig?.group?.group_name === undefined && this.state.tunnelData?.raw_json?.vpnConfig?.deviceB !== undefined)
                                                &&
                                                <>
                                                    <div className="graybg-title mt-3">Device B
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                marginTop: '-19px',
                                                                right: '2.5rem'
                                                            }}
                                                            title="Tunnel Logs Download">
                                                            <img
                                                                style={{
                                                                    width: '20px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => this.downloadLogs(this.state.tunnelData?.raw_json?.vpnConfig?.deviceB?.device_id, this.state.tunnelData?.raw_json?.vpnConfig?.deviceB?.device_name)}
                                                                src="/images/svg-icons/download-arrow.svg" />
                                                        </div>
                                                    </div>
                                                    <div className="p-2">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Device Name</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.deviceB?.device_name || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Local IP</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.deviceB?.local_ip || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Local Subnet</label>
                                                                    <strong>{(this.state.tunnelData?.raw_json?.vpnConfig?.deviceB?.local_network?.join(", ")) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Remote IP</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.deviceB?.remote_ip || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Remote Subnet</label>
                                                                    <strong>{(this.state.tunnelData?.raw_json?.vpnConfig?.deviceB?.remote_network)?.join(", ") || '-'}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>IKE Phase 1</label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Key Lifetime</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.keylife1 || '-'}&nbsp;Seconds</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Encryption</label>
                                                                    <strong>{Common.getEncrytion(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.enc_algo1) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Hash</label>
                                                                    <strong>{Common.getHash(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.hash_algo1) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>DH Group</label>
                                                                    <strong>{Common.getGroupValue(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dh_group1) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>IKE Phase 2</label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Key Lifetime</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.keylife2 || '-'}&nbsp;Seconds</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Encryption</label>
                                                                    <strong>{Common.getEncrytion(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.enc_algo2) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Hash</label>
                                                                    <strong>{Common.getHash(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.hash_algo2) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>DH Group</label>
                                                                    <strong>{Common.getGroupValue(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dh_group2) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Dead Peer Detection</label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Enable</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_enable || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>DPD Action</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_action || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>DPD Delay</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_delay || '-'}&nbsp;Seconds</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>DPD Timeout</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_timeout || '-'}&nbsp;Seconds</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                (this.state.tunnelData?.raw_json?.vpnConfig?.group?.group_name !== undefined)
                                                &&
                                                <>
                                                    <div className="graybg-title mt-3">Group
                                                    </div>
                                                    <div className="p-2">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Group Name</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.group?.group_name || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Local IP</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.group?.local_ip || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Local Subnet</label>
                                                                    <strong>{(this.state.tunnelData?.raw_json?.vpnConfig?.group?.local_network?.join(", ")) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Remote IP</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.group?.remote_ip || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Remote Subnet</label>
                                                                    <strong>{(this.state.tunnelData?.raw_json?.vpnConfig?.group?.remote_network)?.join(", ") || '-'}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>IKE Phase 1</label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Key Lifetime</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.keylife1 || '-'}&nbsp;Seconds</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Encryption</label>
                                                                    <strong>{Common.getEncrytion(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.enc_algo1) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Hash</label>
                                                                    <strong>{Common.getHash(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.hash_algo1) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>DH Group</label>
                                                                    <strong>{Common.getGroupValue(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dh_group1) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>IKE Phase 2</label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Key Lifetime</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.keylife2 || '-'}&nbsp;Seconds</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Encryption</label>
                                                                    <strong>{Common.getEncrytion(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.enc_algo2) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Hash</label>
                                                                    <strong>{Common.getHash(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.hash_algo2) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>DH Group</label>
                                                                    <strong>{Common.getGroupValue(this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dh_group2) || '-'}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Dead Peer Detection</label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Enable</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_enable || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>DPD Action</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_action || '-'}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>DPD Delay</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_delay || '-'}&nbsp;Seconds</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>DPD Timeout</label>
                                                                    <strong>{this.state.tunnelData?.raw_json?.vpnConfig?.vpn_data?.dpd_timeout || '-'}&nbsp;Seconds</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="col-md-6">
                                    <div
                                        key={this.state.keyUpdate}
                                        className={
                                            (this.state.tunnel_type === "IPSec Tunnel")
                                                ? "bg-white t-topology radius-spacing h-100"
                                                : "bg-white t-topology radius-spacing mb-10"
                                        }>
                                        <h1 className="page-title text-blue">Tunnel Topology</h1>
                                        <Topology
                                            data={
                                                (this.state.tunnel_type === "IPSec Tunnel")
                                                    ? this.state.tunnelData?.raw_json?.vpnConfig
                                                    : this.state.tunnelData
                                            }
                                            tunnel_type={
                                                (this.state.tunnel_type === "IPSec Tunnel")
                                                    ? this.state.tunnelData?.tunnel_type
                                                    : this.state.tunnel_type
                                            }
                                        />
                                    </div>
                                    {
                                        (String(this.state.tunnelData?.tunnel_type) !== "IPSec Tunnel")
                                        &&
                                        <div className="bg-white tb-usages radius-spacing">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h1 className="page-title text-blue">Total Bandwidth Usages</h1>
                                                <ul className="nav nav-pills">
                                                    <li className="nav-item">
                                                        <button
                                                            className={
                                                                (this.state.selectedDuration === 1)
                                                                    ? "nav-link active"
                                                                    : "nav-link"
                                                            }
                                                            onClick={() => this.getTunnelDataUsage(1)}
                                                        >24 hrs</button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <button
                                                            className={
                                                                (this.state.selectedDuration === 7)
                                                                    ? "nav-link active"
                                                                    : "nav-link"
                                                            }
                                                            onClick={() => this.getTunnelDataUsage(7)}
                                                        >7 Days</button>
                                                    </li>
                                                    <li className="nav-item">
                                                        <button
                                                            className={
                                                                (this.state.selectedDuration === 30)
                                                                    ? "nav-link active"
                                                                    : "nav-link"
                                                            }
                                                            onClick={() => this.getTunnelDataUsage(30)}
                                                        >30 Days</button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="graph-box mt-3 mb-2">
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={this.state.option}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            : <div className="white-bg no-data">NO DATA</div>
                        }

                    </div>
                </section >
                {
                    this.state.show
                    &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                    />
                }
                {
                    this.state.showRefreshModal
                    && <ErrorHandlePopup />
                }
                {
                    this.state.isAxiosError
                    &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div >
        );
    }
};

const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        openEditTunnel: state.displayAddTunnel.openEditTunnel,
        userRole: JSON.parse(localStorage.getItem('user'))?.userRole
    });
};

export default withRouter(connect(mapStateToProps)(Tunnelview));