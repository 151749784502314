import axios from "axios";
import jwt from 'jwt-decode';
// import store from '../store';

const callingApis = async (requestParams) => {
  // const newState = store.store.getState();

  if (requestParams.url.includes('/verify-sso')) {
    requestParams['headers'] = {
      Authorization: `Bearer ${localStorage.getItem('ADtoken')}`,
      'x-application-id': process.env.REACT_APP_AAD_APP_ID,
      // 'x-tenant-id':  requestParams.url.includes('/tenantListForUser') ?
      // `${localStorage.getItem('tenantId')}` : localStorage.getItem('selectedTenantId'),
      'x-idp-issuer': jwt(localStorage.getItem('ADtoken')).idp,
      // 'x-user-id': `${localStorage.getItem('userId')}`,
    }
  } else if (requestParams.url.includes('/tenantListForUser')) {
    requestParams['headers'] = {
      Authorization: `Bearer ${localStorage.getItem('ADtoken')}`,
      'x-application-id': process.env.REACT_APP_AAD_APP_ID,
      // 'x-tenant-name': "AnishGhosh777",
      'x-tenant-id': `${localStorage.getItem('tenantId')}`,
      'x-tenant-name': localStorage.getItem('selectedTenantName'),
      'x-user-id': `${localStorage.getItem('userId')}`,
    }
  } else {
    requestParams['headers'] = {
      Authorization: `Bearer ${localStorage.getItem('ssoUser') === 'true'?
      localStorage.getItem('ADtoken') : localStorage.getItem('ADtoken')}`,
      'x-application-id': process.env.REACT_APP_AAD_APP_ID,
      'x-tenant-name': localStorage.getItem('ssoUser') === 'true' ?
      localStorage.getItem('selectedTenantName') : localStorage.getItem('selectedTenantName'),
      'x-user-id': `${localStorage.getItem('userId')}`,
    }
  }

  const datax = await axios(requestParams);
  const { data } = datax

  let res = { ...data, response: { status: datax.status } }

  return res;
}

const userApiCall = async (requestParams, cb) => {
  var token = localStorage.getItem('ssoUser') === 'true'?
  localStorage.getItem('ADtoken') : localStorage.getItem('ADtoken');
  if ((token !== null && token !== '')) {
    await callingApis(requestParams).then((response) => {
      cb(response);
    }, async (error) => {

      if ((error.response && error.response.status && error.response.status !== 401) || error.isAxiosError) {
        cb(error);
      }
      else {

      }
    });
  } else {
    // const err = { response: { status: 401 } }
    // cb(err);
  }
};

export default userApiCall;