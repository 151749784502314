import React, { Component } from 'react'
import * as Action from '../../redux/actionTypes';
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import { ErrorPopup } from '../../layOuts/errorPopup';
import ErrorHandlePopup from '../ErrorHandlePopup';
import * as Common from '../../utils/common';
import $ from 'jquery';

class AddNewServer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showPassword: false,
            showPassWord: false,
            showLoader: false,
            title: '',
            message: '',
            show: false,
            error: false,
            serverData: this?.props?.serverData,
            isAxiosError: false
            //  {
            //     profile_name: this?.props?.serverData?.profile_name ? this?.props?.serverData?.profile_name : '',
            //     primary_server: {
            //         prim_auth_server: this?.props?.serverData?.ip_address ? this?.props?.serverData?.ip_address : '',
            //         prim_auth_port: this?.props?.serverData?.port ? this?.props?.serverData?.port : '',
            //         prim_auth_secret: this?.props?.serverData?.secret_passphrase ? this?.props?.serverData?.secret_passphrase : ''
            //     },
            //     secondary_server: {
            //         sec_auth_server: '',
            //         sec_auth_port: '',
            //         sec_auth_secret: ''
            //     }
            // }
        }
    }
    componentDidMount() {
        if (process.env.REACT_APP_AD_USER !== 'TRUE')
            this.checkLicenseValidity();
    }
    checkLicenseValidity = () => {
        ApiCall(urlMapping.checkLicenseValidity(), (response) => {
            //LONG-2833 : Able to click on auto-approve before activating license
            if (response?.data?.is_activated) {
                this.setState({
                    activatedLicense: true,
                    showLoader: false
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    closeAddServer = () => {
        this.props.dispatch({
            type: Action.CLOSE_ADD_SERVER,
        });
    }
    handleAddServer = () => {
        let data = {
            data: {
                profile_name: this.state.serverData.profile_name,
                primary_server: this.state.serverData.primary_server,
                secondary_server: this.state.serverData.secondary_server,
            }
        }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.saveServer(data), (response) => {
            if (response?.success) {
                this.setState({
                    showLoader: false,
                    title: 'Server Added',
                    message: response?.message,
                    show: true,
                    error: false,
                }, () => {
                    this.props.getServerData();
                    $("#errorMessage").modal("show");
                });
            }
            else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                        title: 'Server Added',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    handleChangeServer = () => {
        let data = {
            data: {
                server_id: this.props?.server_id,
                profile_name: this.state.serverData.profile_name,
                primary_server: this.state.serverData.primary_server,
                secondary_server: this.state.serverData.secondary_server,
            }
        }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.changeServer(data), (response) => {
            if (response?.success) {
                this.setState({
                    showLoader: false,
                    title: 'Server Updated',
                    message: response?.message,
                    show: true,
                    error: false,
                }, () => {
                    this.props.getServerData();
                    $("#errorMessage").modal("show");
                });
            }
            else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                        title: 'Server Added',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    setvalue = (e) => {
        this.removeError(e);
        const name = e.target.name;
        const value = e.target.value;
        let { serverData } = this.state;
        if (name === "profile_name") {
            serverData[name] = value;
        } else if (name.includes('prim')) {
            serverData.primary_server[name] = value;
        } else {
            serverData.secondary_server[name] = value;
        }
        this.setState({
            serverData
        })
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            if (this.state.title === 'Tenant Error') {
                this.props.dispatch({
                    type: Action.LOGOUT,
                });
            }
            $("#errorMessage").modal("hide");
            this.closeAddServer();
        })
    }
    removeError = (e) => {
        const element = document.getElementById(e.target.name);
        if (element)
            element.style.display = 'none';
    }
    validateProfileName = (e) => {
        if (e.target.value === "" && !Common.validateAlphaNumeric(e)) {
            const element = document.getElementById(e.target.name);
            element.style.display = "block";
            element.innerHTML = "Enter 8 to 20 alphanumeric characters";
            this.setState({
                showError: true,
            });
        }
        // if (e.target.value === "" && (e.target.name === 'profile_name' || e.target.name.includes('prim'))) {
        //     element.innerHTML = "Enter 8 to 20 alphanumeric characters";
        //     element.style.display = "block"
        //     this.setState({
        //         showError: true
        //     })
        // } 
        else {
            if (!Common.validateAlphaNumeric(e) ||
                e.target.value.length < 8 || e.target.value.length > 20) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = "Enter 8 to 20 alphanumeric characters";
                this.setState({
                    showError: true,
                });
            } else {
                const element = document.getElementById(e.target.name);
                element.style.display = "none";
                element.innerHTML = "";
                this.setState({
                    showError: false,
                });
            }
        }
    }
    validateIP = (e) => {
        if ((e.target.name === "prim_auth_server" && e.target.value === '') ||
            Common.validateIPaddress(e) === false || e.target.value === '0.0.0.0' ||
            e.target.value === '255.255.255.255') {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = 'Invalid IP address';
                this.setState({
                    showError: true,
                })
            }
        } else if ((e.target.name === "sec_auth_server" && e.target.value === '') ||
            Common.validateIPaddress(e) === false || e.target.value === '0.0.0.0' ||
            e.target.value === '255.255.255.255') {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = 'Invalid IP address';
                this.setState({
                    showError: true,
                })
            }
        }
    }
    validatePort = (e) => {
        if ((parseInt(e.target.value) < 1024 || parseInt(e.target.value) > 49151) || (!Common.validateNumeric(e))) {
            const element = document.getElementById(e.target.name);
            element.style.display = "block";
            this.setState({ showError: true });
        }
    }
    validateServerSecret = (e) => {
        if (e.target.value === "") {
            const element = document.getElementById(e.target.name);
            element.style.display = "block";
            this.setState({
                showError: true,
            });
        }
        if (e.target.value.length < 8 || e.target.value.length > 64) {
            const element = document.getElementById(e.target.name);
            element.style.display = "block";
            this.setState({
                showError: true,
            });
        } else {
            const element = document.getElementById(e.target.name);
            element.style.display = "none";
            element.innerHTML = "";
            this.setState({
                showError: false,
            });
        }
    }
    checkError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.serverData?.profile_name === '' || this.state.serverData?.primary_server.prim_auth_server === '' ||
            this.state.serverData?.primary_server.prim_auth_port === '' || this.state.serverData?.primary_server.prim_auth_secret === '')
            result = true;
        if (this.state.serverData?.secondary_server?.sec_auth_server !== '' ||
            this.state.serverData?.secondary_server?.sec_auth_port !== '' ||
            this.state.serverData?.secondary_server?.sec_auth_secret !== '') {
            if (this.state.serverData?.secondary_server?.sec_auth_server === '' ||
                this.state.serverData?.secondary_server?.sec_auth_port === '' ||
                this.state.serverData?.secondary_server?.sec_auth_secret === '')
                result = true;
        }
        return result;
    }
    requiredSec = () => {
        let result = false;
        if (this.state.serverData?.secondary_server?.sec_auth_server !== '' ||
            this.state.serverData?.secondary_server?.sec_auth_port !== '' ||
            this.state.serverData?.secondary_server?.sec_auth_secret !== '') {
            if (this.state.serverData?.secondary_server?.sec_auth_server === '' ||
                this.state.serverData?.secondary_server?.sec_auth_port === '' ||
                this.state.serverData?.secondary_server?.sec_auth_secret === '')
                result = true;
        }
        return result;
    }
    showPass = (value) => {
        const x = document.getElementById(value);
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        {
            value === "passWord" ?
                this.setState(prevState => ({
                    showPassword: !prevState.showPassword
                })) :
                this.setState(prevState => ({
                    showPassWord: !prevState.showPassWord
                }))
        }
    }
    render() {
        return (
            <div>{this.state.showLoader && <Loader />}
                <div className={this.props.openAddserver ? "slide-overlay open" : "slide-overlay"}></div>
                <div className={this.props.openAddserver ? "sidebar-slide open" : "sidebar-slide"}>
                    <div className="s-header">
                        <h1 className="ss-title">{this.props.server_id ? "Edit Server" : "Add New Server"}</h1>
                        <button onClick={() => this.closeAddServer()}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="form-group">
                            <label>Profile Name
                                <span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Name of the server configuration">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="profile_name"
                                onChange={(e) => this.setvalue(e)}
                                value={this.state.serverData?.profile_name}
                                onBlur={(e) => this.validateProfileName(e)}
                            />
                            <div className="error-txt" id='profile_name'>Enter 8 to 20 alphanumeric characters</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <span className="mr-5">
                                <strong>Primary Server</strong>
                            </span>
                        </div>
                        <div className="form-group">
                            <label>Authentication Server IP
                                <span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="RADIUS server IP">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='prim_auth_server'
                                onChange={(e) => this.setvalue(e)}
                                value={this.state.serverData?.primary_server?.prim_auth_server}
                                onBlur={this.validateIP} />
                            <div className="error-txt" id="prim_auth_server">Invalid IP address</div>
                        </div>
                        <div className="form-group">
                            <label>Authentication Server Port
                                <span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="External server port">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='prim_auth_port'
                                onChange={(e) => this.setvalue(e)}
                                value={this.state.serverData?.primary_server?.prim_auth_port}
                                onBlur={this.validatePort} />
                            <div className="error-txt" id="prim_auth_port">Enter value from 1024 to 49151</div>
                        </div>
                        <div className="form-group">
                            <label>Authentication Server Secret
                                <span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Shared secret used to communicate between the router and the external server">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                name='prim_auth_secret'
                                id='passWord'
                                onChange={(e) => this.setvalue(e)}
                                value={this.state.serverData?.primary_server?.prim_auth_secret}
                                onBlur={this.validateServerSecret}
                            />
                            <span className="sh-icon">
                                <img src={this.state.showPassword ? "/images/show.png" : "/images/hide.png"}
                                    alt="" onClick={() => this.showPass("passWord")}
                                />
                            </span>
                            <div className="error-txt" id="prim_auth_secret">Value must be in range between 8 and 64.</div>
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-center mb-3">
                            <span className="mr-5"><strong>Secondary Server</strong>
                            </span></div>
                        <div className="form-group">
                            <label>Authentication Server IP
                                {this.requiredSec() && <span className="red-txt">*</span>}
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="RADIUS server IP">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <input type="text" className="form-control" name='sec_auth_server'
                                onChange={(e) => this.setvalue(e)}
                                value={this.state.serverData?.secondary_server?.sec_auth_server}
                                onBlur={this.validateIP} />
                            <div className="error-txt" id="sec_auth_server">Invalid IP address</div>
                        </div>
                        <div className="form-group">
                            <label>Authentication Server Port
                                {this.requiredSec() && <span className="red-txt">*</span>}
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="External server port">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <input type="text" className="form-control" name='sec_auth_port'
                                onChange={(e) => this.setvalue(e)}
                                value={this.state.serverData?.secondary_server?.sec_auth_port}
                                onBlur={this.validatePort} />
                            <div className="error-txt" id="sec_auth_port">Enter value from 1024 to 49151</div>
                        </div>
                        <div className="form-group">
                            <label>Authentication Server Secret
                                {this.requiredSec() && <span className="red-txt">*</span>}
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Shared secret used to communicate between the router and the external server">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <input type="password" className="form-control" name='sec_auth_secret'
                                id='PassWord'
                                onChange={(e) => this.setvalue(e)}
                                value={this.state.serverData?.secondary_server?.sec_auth_secret}
                                onBlur={this.validateProfileName} />
                            <span className="sh-icon">
                                <img src={this.state.showPassWord ? "/images/show.png" : "/images/hide.png"}
                                    alt="" onClick={() => this.showPass("PassWord")}
                                />
                            </span>
                            <div className="error-txt" id="sec_auth_secret">Enter 8 to 20 alphanumeric characters only</div>
                        </div> */}
                        <div className="s-footer">
                            <button className="btn btn-light" onClick={() => this.closeAddServer()}>Cancel</button>
                            {
                                <button
                                    className="btn btn-primary"
                                    disabled={this.checkError()}
                                    onClick={this.props.server_id ? () => this.handleChangeServer() : () => this.handleAddServer()}
                                >
                                    {this.props.server_id ? 'Update' : 'Add'}
                                </button>
                            }
                        </div>
                    </div>
                </div>
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        openAddserver: state.auth.openAddServer,
    });
}
export default connect(mapStateToProps)(AddNewServer);
