/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import DataTable from "react-data-table-component";
import $ from 'jquery';
import { ErrorPopup } from '../../layOuts/errorPopup';
import { LinkPopup } from '../../layOuts/linkPopup';
import * as Common from "./../../utils/common";
import ErrorHandlePopup from "../ErrorHandlePopup";
class AddDeviceGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            error: false,
            show: false,
            showDevice: false,
            selectedGroup: '',
            delete: false,
            deviceGroup: false,
            multipleSelect: '',
            multipleSelect1: '',
            showLink: false,
            groupData: [],
            selectedModel: '',
            toggledClearDeviceRows: false,
            toggledClearSelectedDeviceRows: false,
            pageNo: 1,
            pageSize: 10,
            group_name: '',
            searchVal: "",
            selectedGroupId: this.props.id,
            data: {
                group_name: "",
                group_desc: "",
            },
            deviceList: [],
            isClonable: true,
            keyUpdate: '',
            updateFirmwareData: [],
            selectedDeviceData: [],
            currentDetails: {
                name: "",
                devices: [],
            },
            selectedFW: '',
            isAxiosError: false,
            deviceListColumns: [
                {
                    name: "Device Name",
                    selector: "device_name",
                    value: "Device Name",
                    center: false,
                    sortable: true,
                    minWidth: "175px",
                    cell: (row) => (
                        <span className="dnamemac" id={`icon${row.device_id}`} title={row.device_name}>
                            <i className="status online"></i>
                            <span className="hname">{row.device_name}</span>
                            <span className="maca">{row.device_mac}</span>
                            {row.group_name && <span className="maca-grp dg-text ml-2">{row.group_name}</span>}
                            {row.is_hub && <span className="maca-hub dg-text ml-2" >Hub</span>}
                        </span>
                    ),
                },
                {
                    name: "Model",
                    selector: "device_model",
                    value: "Device Model",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_model}>
                        {row.device_model}</span>
                    ),
                },
                {
                    name: "Firmware Version",
                    selector: "device_firmware_version",
                    value: "Firmware Version",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_firmware_version}>
                        {row.device_firmware_version}</span>
                    ),
                },
            ],
            deviceListColumnsDiff: [
                {
                    name: "Device Name",
                    selector: "device_name",
                    value: "Device Name",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_name}>
                        {row.device_name}</span>
                    ),
                },
                {
                    name: "MAC Address ",
                    selector: "device_mac",
                    value: "Mac Address",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_mac}>
                        {row.device_mac}</span>
                    ),
                },
                {
                    name: "Firmware ",
                    selector: "device_firmware_version",
                    value: "Firmware",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_firmware_version}>
                        {row.device_firmware_version}</span>
                    ),
                },
                {
                    name: "Model",
                    selector: "device_model",
                    value: "Device Model",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_model}>
                        {row.device_model}</span>
                    ),
                },
            ],
        };
    }
    componentDidMount() {
        if (Object.keys(this.props.deviceGroupDetails).length !== 0) {
            let currentDetails = {
                name: this.props.deviceGroupDetails.group_name,
                devices: [],
            }
            this.props.deviceGroupDetails.attached_devices.map(val => currentDetails.devices.push(val.device_id))
            this.setState({
                currentDetails,
                data: {
                    group_name: this.props.deviceGroupDetails.group_name,
                    group_desc: '',
                },
                selectedDeviceData: this.props.deviceGroupDetails.attached_devices,
                selectedFW: this.props.deviceGroupDetails.attached_devices[0]?.device_firmware_version ?
                    this.props.deviceGroupDetails.attached_devices[0]?.device_firmware_version : ''
            })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.deviceGroupDetails).length !== 0) {
            this.setState({
                group_name: nextProps.deviceGroupDetails.group_name,
                group_desc: '',
            })
        }
    }
    //LONG-1768_Device group name validation added
    setGroupValues = (e) => {
        this.removeError(e);
        let data = this.state.data;
        data[e.target.name] = e.target.value;
        this.setState({
            data: data
        })
    }
    //LONG-1768_Device group name validation added
    setGroupValuesValidation = (e) => {
        this.removeError(e);
        if (e.target.value.trim() !== "") {
            this.setState({ showLoader: true })
            let data = {
                group_id: this.state.selectedGroupId || "",
                group_name: e.target.value
            }
            ApiCall(urlMapping.gpNameCheck(data), (response) => {
                this.setState({ showLoader: false })
                if (response?.success) {
                    const element = document.getElementById("group_name");
                    element.style.display = "none";
                    this.setState({
                        showError: false,
                    });
                }
                else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({ showRefreshModal: true })
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        const element = document.getElementById("group_name");
                        element.style.display = "block";
                        element.innerHTML = "Group name already exists!";
                        this.setState({
                            showError: true,
                        });
                    }
                }
            })
            if (!Common.validateAlphaNumeric(e)) {
                this.setState({
                    showError: true
                })
            }
            else {
                if (e.target.value.length < 1 || e.target.value.length > 32) {
                    const element = document.getElementById("group_name");
                    element.style.display = "block";
                    element.innerHTML = "Enter 1 to 32 characters";
                    this.setState({
                        showError: true,
                    });
                }
            }
        }
    }
    removeError = (e) => {
        const element = document.getElementById("group_name");
        if (element) element.style.display = "none";
        this.setState({
            showError: false,
        });
    };
    isSubstring = (s1) => {
        this.setState({ searchVal: s1 })

    }
    confirmSave = () => {
        this.setState({
            title: 'Add Device Group',
            message: 'Adding a device to the Device Group will do Bootstrap reset  of all existing templates, configurations and apply new templates mapped to this Device Group. Please click OK to proceed further.',
            show: true,
            error: true,
            delete: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    saveData = () => {
        let data = {};
        data.group_name = this.state.data.group_name;
        data.group_desc = this.state.data.group_desc;
        let deviceId = [];
        let deviceModel = [];
        this.state.selectedDeviceData.map(element => (
            deviceId.push(element.device_id)
        ));
        this.state.selectedDeviceData.map(element => (
            deviceModel.push(element.device_model)
        ));
        data.attached_devices = deviceId.toString();
        data.supported_models = deviceModel[0].toString();
        data.min_supported_fw_version = '3.0.0';
        // TASK : Added device group cloning functionality
        data.group_clone_uuid = this.state.selectedGroup;
        let isClonable = this.state.selectedGroup ? this.state.groupData?.find(grp => grp?.group_uuid === this.state.selectedGroup)?.is_cloneable : true;
        this.setState({ isClonable: isClonable })
        $("#errorMessage").modal("hide");
        this.setState({ show: false }, () => {
            if (isClonable) {
                if (this.state.selectedGroupId === '') {
                    this.setState({ showLoader: true, show: false })
                    ApiCall(urlMapping.addDeviceGroup(data), (response) => {
                        this.setState({ showLoader: false })
                        if (response?.success) {
                            this.setState({
                                title: 'Create Device Group',
                                message: "Create Device Group initiated successfully. To view operation logs ",
                                showLink: true,
                                delete: false,
                                error: false,
                            },
                                () => $("#exampleModal").modal("show"))
                        }
                        else {
                            if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                                this.setState({ showRefreshModal: true })
                            } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                                this.setState({
                                    title: 'Tenant Error',
                                    message: response.response.data.message,
                                    showLoader: false,
                                    isAxiosError: true,
                                    delete: false,
                                    error: true
                                }, () => $("#errorMessage").modal("show"))
                            } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                this.setState({
                                    title: 'Network Error',
                                    message: 'Something went wrong. Please try again later.',
                                    showLoader: false,
                                    isAxiosError: true,
                                    delete: false,
                                    error: true
                                }, () => $("#errorMessage").modal("show"))
                            } else {
                                this.setState({
                                    title: 'Create Device Group',
                                    message: response?.message,
                                    show: true,
                                    delete: false,
                                    error: true,
                                }, () => $("#errorMessage").modal("show"))
                            }
                        }
                    })
                }
                else {
                    this.setState({ showLoader: true, show: false })
                    let id = this.state.selectedGroupId;
                    ApiCall(urlMapping.updateDeviceGroup(data, id), (response) => {
                        this.setState({ showLoader: false })
                        if (response?.success) {
                            this.setState({
                                title: 'Update Device Group',
                                message: "Update Device Group initiated successfully. To view operation logs ",
                                showLink: true,
                                delete: false,
                                error: false,
                            },
                                () => $("#exampleModal").modal("show"))
                        }
                        else {
                            if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                                this.setState({ showRefreshModal: true })
                            } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                                this.setState({
                                    title: 'Tenant Error',
                                    message: response.response.data.message,
                                    showLoader: false,
                                    isAxiosError: true,
                                    delete: false,
                                    error: true
                                }, () => $("#errorMessage").modal("show"))
                            } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                this.setState({
                                    title: 'Network Error',
                                    message: 'Something went wrong. Please try again later.',
                                    showLoader: false,
                                    isAxiosError: true,
                                    delete: false,
                                    error: true
                                }, () => $("#errorMessage").modal("show"))
                            } else {
                                this.setState({
                                    title: 'Update Device Group',
                                    message: response?.message,
                                    show: true,
                                    delete: false,
                                    error: true,
                                }, () => $("#errorMessage").modal("show"))
                            }
                        }
                    })
                }
            } else {
                this.setState({
                    title: 'Create Device Group',
                    message: 'To clone a device group, all the previous operation should be completed.',
                    show: true,
                    error: true,
                    view: false,
                    reset: true
                }, () => {
                    $("#errorMessage").modal("show")
                });
            }
        })
    }
    getDeviceList = () => {
        this.setState({ showLoader: true })
        ApiCall(urlMapping.deviceGroupLookUp(this.state.pageNo, this.state.pageSize), (response) => {
            this.setState({ showLoader: false })
            if (response?.success) {
                let onlineDevices = this.props?.id ? response?.data?.filter(item =>
                    item.device_model === this.props?.deviceGroupDetails?.supported_models) : response?.data;
                if (this.state.selectedDeviceData.length > 0) {
                    this.state.selectedDeviceData.map((devices) => (
                        onlineDevices = onlineDevices.filter(item => item.device_id !== devices.device_id)
                    ))
                }
                this.setState({ deviceList: onlineDevices })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    deviceAdd = () => {
        let { selectedDeviceData } = this.state;
        if (this.state.multipleSelect.length > 0)
            this.state.multipleSelect.map((item) => (selectedDeviceData.push(item)))
        // TASK : Added device group cloning functionality
        let data = { "model_name": this.state.multipleSelect[0].device_model }
        ApiCall(urlMapping.groupInfoByModel(data), (response) => {
            if (response?.success) {
                this.setState({
                    groupData: response?.data
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
        this.setState({
            selectedDeviceData,
            selectedModel: selectedDeviceData[0].device_model,
            selectedFW: selectedDeviceData[0].device_firmware_version,
            deviceList: [],
            toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
            keyUpdate: Math.random()
        });
    };
    multipleSelect = (rows) => {
        if (rows.selectedRows.length > 0) {
            const Model = rows.selectedRows[0].device_model;
            this.setState({
                multipleSelect: rows.selectedRows,
                selectedModel: Model,
                selectedFW: rows.selectedRows[0].device_firmware_version
            }
            );
        }
        // Fix for enabling device models as per the selected device model
        else if (this.state.selectedDeviceData.length === 0) {
            this.setState({
                multipleSelect: "",
                selectedModel: "",
                selectedFW: ''
            })
        }
    }
    multipleSelect1(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect1: "" });
        else {
            this.setState({ multipleSelect1: rows.selectedRows }
            )
        }
    }
    getoptions = () => {
        let options = [<option value="">Select Device Group</option>];
        this.state.groupData.map((group) => {
            if (group.group_name !== "")
                options.push(<option value={group.group_uuid}>{group.group_name}</option>)
        })
        return options;
    }
    deleteSelectedDevice = () => {
        let newList = [...this.state.selectedDeviceData];
        if (this.state.multipleSelect1.length > 0) {
            this.state.multipleSelect1.map((item) => (
                newList.splice(newList.indexOf(item), 1)
            ));
        }
        this.setState({
            selectedDeviceData: newList,
            toggledClearSelectedDeviceRows: true,
            keyUpdate: Math.random(),
            multipleSelect1: "",
        },
            // Fix for enabling device models as per the selected device model
            () => {
                if (this.state.selectedDeviceData.length === 0) {
                    this.setState({
                        selectedModel: "",
                        // Fix for setting device groups dropdown to "select Device Group" when all devices are deleted 
                        selectedGroup: "",
                        selectedFW: ''
                    })
                }
            });
    };
    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                $("#errorMessage").modal("hide");
                this.props.closeAddDeviceGroup();
                this.props.getDeviceGroupList();
            }
        );
    };
    closeDeviceGroup = () => {
        this.setState({
            // LONG-3194-EM - Filtered device should be reset after cancel/close operation
            searchVal: "",
            deviceGroup: false,
            deviceList: [],
            multipleSelect: "",
            toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
            toggledClearSelectedDeviceRows: true,
            keyUpdate: Math.random()
        });
    };
    resetSelected = () => {
        if (this.state.selectedDeviceData?.length === 0) {
            this.setState({
                selectedModel: "",
                searchVal: "",
                selectedFW: ''
            })
        }
    }
    openOperationList = (value) => {
        $("#exampleModal").modal("hide");
        // LONG-1781 : Fix for empty popup showing on device group page after editing the device group
        this.props.closeAddDeviceGroup();
        this.props.props.history.push({
            pathname: '/operations',
            state: { data: value }
        })
    }
    chooseAction = () => {
        if (this.state.isClonable) {
            this.saveData();
        }
        else {
            this.setState(
                {
                    show: false,
                    error: false,
                },
                () => {
                    $("#errorMessage").modal("hide");
                    this.props.closeAddDeviceGroup();
                    this.props.getDeviceGroupList();
                }
            );
        }
    }
    checkModel = (row) => {
        let result = false
        if (this.state.selectedModel !== "" && this.state.selectedModel !== row.device_model)
            result = true
        if (this.state.multipleSelect.length > 0 && this.state.multipleSelect[0]?.device_model !== row.device_model)
            result = true
        if (row.group_name !== "")
            if (this.state.selectedGroupId !== "" && row.group_name !== this.state?.currentDetails?.name)
                result = true
        if (this.state.selectedGroupId === "" && row.group_name !== '' && row.group_name !== null)
            result = true
        if (this.state.selectedFW !== "" && row.device_firmware_version !== this.state.selectedFW)
            result = true
        return result
    }
    closeLinkpopup = () => {
        $("#exampleModal").modal("hide");
        this.setState({
            show: false,
            id: ''
        },
            // Fix to close the add device popup after successfully creating device group
            () => {
                this.props.closeAddDeviceGroup();
                this.props.getDeviceGroupList();
            })
    }
    // TASK : Added device group cloning functionality 
    getDeviceGroups = (e) => {
        if (e.target.value !== "") {
            this.setState({
                selectedGroup: e.target.value
            })
        }
    }
    checkUpdate = (e) => {
        let result = false
        let newDevice = false
        this.state.selectedDeviceData.forEach(item => {
            if (!this.state?.currentDetails?.devices?.includes(item.device_id)) {
                newDevice = true
            }
        })
        if (this.state.showError)
            result = true
        if (this.state.selectedDeviceData.length < 1)
            result = true
        if (this.state.data.group_name.trim().length < 1 || this.state.data.group_name.trim().length > 32)
            result = true
        if (this.state.selectedGroupId !== '' && this.state.data.group_name === this.state?.currentDetails?.name &&
            (!newDevice && this.state?.currentDetails?.devices?.length === this.state.selectedDeviceData?.length))
            result = true
        return result
    }
    render() {
        return (
            <div>
                <div className={this.state.deviceGroup ? "disabled-slide-650" : ""}></div>
                {this.state.showLoader && <Loader />}
                <div className={this.props.openUpdateFirmware ? "slide-overlay open" : "slide-overlay"}></div>
                <div className={this.props.openUpdateFirmware ? "sidebar-slide open" : "sidebar-slide"}>
                    <div className="s-header">
                        <h1 className="ss-title">{this.state.selectedGroupId !== '' ? 'Update Device Group' : 'Create Device Group'}</h1>
                        <button onClick={() => this.props.closeAddDeviceGroup()}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 form-group">
                                <label>Group Name
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of device group">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="group_name"
                                    required={true}
                                    minLength='1'
                                    maxLength='32'
                                    value={this.state.data.group_name}
                                    onBlur={(e) => this.setGroupValuesValidation(e)}
                                    onChange={(e) => this.setGroupValues(e)} />
                                <div className="error-txt" id="group_name">Invalid value</div>
                            </div>
                            {/* TASK : Added device group cloning functionality */}
                            {this.props.id === "" && <div className="col-md-6 col-sm-12 form-group">
                                <label>Device Groups
                                </label>
                                <select
                                    className="form-control"
                                    disabled={!this.state.selectedDeviceData.length}
                                    name="Device_Group"
                                    onChange={this.getDeviceGroups}
                                >
                                    <option value="">Select Device Group</option>
                                    {
                                        this.state.groupData.map((group) => {
                                            if (group.group_name !== "")
                                                return (<option value={group.group_uuid}>{group.group_name}</option>)
                                        })
                                    }
                                </select>
                            </div>}
                        </div>
                        <div className="page-sub-title">
                            <div>
                                <strong>Devices</strong>
                                <img src="/images/svg-icons/add.svg" className="add-btn ml-2" alt=""
                                    onClick={() => { this.setState({ deviceGroup: true }); this.getDeviceList() }} />
                            </div>
                            <div className="device-action">
                                <span>{this.state.selectedDeviceData ? this.state.selectedDeviceData.length : '0'} Devices Added</span>
                                <span className={(this.state.selectedDeviceData.length > 0 && this.state.multipleSelect1.length > 0) ? "icon-box" : "icon-box disabled"}>
                                    <img src="/images/svg-icons/delete.svg" alt="" onClick={() => this.deleteSelectedDevice()} />
                                </span>
                            </div>
                        </div>
                        <div key={this.state.keyUpdate}>
                            <DataTable
                                columns={this.state.deviceListColumnsDiff}
                                data={this.state.selectedDeviceData}
                                selectableRows
                                highlightOnHover
                                fixedHeader
                                onSelectedRowsChange={(e) => this.multipleSelect1(e)}
                                clearSelectedRows={this.state.toggledClearSelectedDeviceRows}
                            />
                        </div>
                    </div>
                    {(this.state.show) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.resetPopup()}
                            onSubmit={() => this.chooseAction()}
                        />}
                    <div className="s-footer">
                        <button
                            className="btn btn-light"
                            onClick={() => this.props.closeAddDeviceGroup()}>
                            Cancel</button>
                        <button
                            className="btn btn-primary"
                            disabled={this.checkUpdate()}
                            onClick={() => { this.confirmSave() }}>
                            {this.state.selectedGroupId !== '' ? 'Update' : 'Create'}</button>
                    </div>
                </div>
                <div className={this.state.deviceGroup ? "sidebar-slide zindex10 w-450 second-open"
                    : "sidebar-slide zindex10 w-450"}>
                    <div className="s-header">
                        <h1 className="ss-title">Devices</h1>
                        <button onClick={() => { this.closeDeviceGroup(); this.resetSelected() }}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="filter-box d-flex align-items-center">
                            <div className="search-box2 mr-2">
                                {/* LONG-3194-EM - Filtered device should be reset after cancel/close operation */}
                                <input
                                    type="text"
                                    value={this.state.searchVal}
                                    placeholder="Search.."
                                    onChange={(e) => this.isSubstring((e.target.value.toLowerCase()))} />
                                <img src="/images/svg-icons/search.svg" alt="" />
                            </div>
                        </div>
                        <div className="devices-table">
                            <DataTable
                                columns={this.state.deviceListColumns}
                                data={this.state.deviceList.filter(item => item.device_name.toLowerCase().includes(this.state.searchVal))}
                                selectableRows
                                highlightOnHover
                                fixedHeader
                                selectableRowDisabled={(row) => this.checkModel(row) || row.is_hub || row.is_ippt}
                                onSelectedRowsChange={(data) => this.multipleSelect(data)}
                                clearSelectedRows={this.state.toggledClearDeviceRows}
                            />
                        </div>
                    </div>
                    <div className="s-footer">
                        <button
                            className="btn btn-light"
                            onClick={() => { this.closeDeviceGroup(); this.resetSelected() }}>
                            Cancel</button>
                        <button
                            className="btn btn-primary"
                            disabled={this.state.multipleSelect === ''}
                            onClick={() => { this.deviceAdd(); this.closeDeviceGroup() }}>
                            Add</button>
                    </div>
                </div>
                {(this.state.showLink) &&
                    <LinkPopup
                        title={this.state.title}
                        message={this.state.message}
                        open={() => this.openOperationList(this.state.id)}
                        close={() => this.closeLinkpopup()}
                    />
                }
                {this.state.show && (
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                    />
                )}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        openUpdateFirmware: state.displayUpdateFirmware.openUpdateFirmware
    };
};
export default connect(mapStateToProps)(AddDeviceGroup);
