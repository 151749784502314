import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Loader } from '../../layOuts/Loader';
import moment from "moment";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from 'jquery';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../DataTableDemo.css';
import { Dropdown } from 'primereact/dropdown';
import ErrorHandlePopup from "../ErrorHandlePopup";
import * as Action from "../../redux/actionTypes";

class SyncWithPlaybooks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      count: 0,
      pageSize: 10,
      first: 1,
      last: 10,
      next: true,
      prev: true,
      showLoader: false,
      showRefreshModal: false,
      collectionsData: [],
      repoUrl: '',
      isAxiosError: false,
      collectionColumns: [
        {
          field: "collection_version",
          header: "Collection Version",
          frozen: false,
          value: "Collection Version",
          sortable: false,
          body: this.collectionVersion,
          style: { width: "30px", flexGrow: 1, minWidth: "20px" },
        },
        {
          field: 'models_supported', header: 'Supported Models',
          value: 'Supported Models', body: this.supportedModels,
          frozen: false,
          alignFrozen: 'left',
          sortable: false,
          style: { width: '170px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'models_interfaces', header: 'Supported Interfaces',
          value: 'Supported Interfaces',
          body: this.supportedInterfaces, frozen: false,
          sortable: false,
          style: { width: '170px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: "updated_date",
          header: "Updated on",
          frozen: false,
          value: "Updated on",
          sortable: false,
          body: this.updatedDate,
          style: { width: "70px", flexGrow: 1, minWidth: "50px" },
        },
      ]
    };
  }

  componentDidMount() {
    this.getPlaybooks();
    this.getRepoUrl();
  }
  getRepoUrl = () => {
    this.setState({
      showLoader: true
    })
    ApiCall(urlMapping.getRepoUrl(), (response) => {
      if (response?.success) {
        this.setState({
          showLoader: false,
          repoUrl: response?.data?.repo_url
        });
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
  }
  supportedModels = (row) => {
    let allModels = [];
    allModels.push(JSON.parse(row.models_supported))
    return (
      <div>
        {row.models_supported !== '' ?
          <span className="dm-cell">
            {/* <ul className="dm-list"> */}
            {Object.entries(allModels[0]).map(([key, value]) => {
              return <div>
                <li>{`${key} : `}</li>
                <li>
                  {value.map(item =>
                    <ul className="dm-list"><li>{item}</li> </ul>)}
                </li>
              </div>
            })}
          </span>
          : '-'
        }
      </div>
    )

  }

  supportedInterfaces = (row) => {
    let allInterfaces = [];
    allInterfaces.push(JSON.parse(row.models_interface))
    return (
      <div>

        {row.models_interface !== '' ?
          <span className="dm-cell">
            {/* <ul className="dm-list"> */}
            {Object.entries(allInterfaces[0]).map(([key, value]) => {
              return <div>
                <li>{`${key} : `}</li>
                <li>
                  {value.map(item =>
                    <ul className="dm-list"><li>{item}</li> </ul>)}
                </li>
              </div>
            })}
            {/* </ul> */}
          </span>
          : '-'
        }
      </div>
    )
  }
  collectionVersion = (row) => {
    return (
      <span
        className="date-time"
        title={row.collection_version}
      >
        {row.collection_version}
      </span>
    );
  };
  updatedDate = (row) => {
    return (
      <span
        className="date-time"
        title={this.getTime(row.updated_date)}
      >
        {this.getTime(row.updated_date)}
      </span>
    );
  };
  getTime = (time) => {
    let date = moment.utc(time);
    date = date.local();
    return date.format("DD MMMM YYYY hh:mm:ss A");
  };
  resetPopup = () => {
    this.setState({
      show: false,
      error: false,
    }, () => {
      if (this.state.title === 'Tenant Error') {
        this.props.dispatch({
          type: Action.LOGOUT,
        });
      }
      $("#errorMessage").modal("hide");
      this.getPlaybooks();
    })
  }
  getPlaybooks = () => {
    this.setState({
      showLoader: true,
    });
    ApiCall(urlMapping.getCollectionsData(this.state.pageNo, this.state.pageSize), (response) => {
      const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
      const last = response.next ? this.state.pageSize * this.state.pageNo : response?.count;
      this.setState({
        showLoader: false
      })
      if (parseInt(response?.response?.status) === 200) {
        this.setState({
          collectionsData: response?.results,
          next: response?.next ? false : true,
          prev: response?.previous ? false : true,
          first: first,
          last: last,
          count: response?.count
        })
      }
      else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showRefreshModal: true
          });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
  }
  syncPlaybooks = () => {
    this.setState({
      showLoader: true,
    });
    ApiCall(urlMapping.syncCollections(), (response) => {
      this.setState({
        showLoader: false,
      });
      if (response?.success) {
        this.setState({
          title: 'Sync Playbooks',
          message: 'Playbooks Updated Successfully',
          show: true,
        }, () => {
          $("#errorMessage").modal("show");
        })
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showRefreshModal: true
          });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false,
            title: 'Sync Playbooks',
            message: response?.message,
            show: true,
            error: true,
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
  }
  setPerPage = (e) => {
    this.setState({
      pageNo: 1,
      pageSize: e.value
    }, () => this.getPlaybooks()
    )
  }
  nextPageChangeHandle = () => {
    this.setState({
      pageNo: this.state.pageNo + 1
    }, () => this.getPlaybooks()
    )
  }
  prevPageChangeHandle = () => {
    this.setState({
      pageNo: this.state.pageNo - 1
    }, () => this.getPlaybooks()
    )
  }
  firstPageChangeHandle = () => {
    this.setState({
      pageNo: 1
    }, () => this.getPlaybooks()
    )
  }
  lastPageChangeHandle = () => {
    this.setState({
      pageNo: Math.ceil(this.state.count / this.state.pageSize)
    }, () => this.getPlaybooks()
    )
  }
  render() {
    let colArray = this.state.collectionColumns;
    const dynamicColumns = colArray.map((col, i) => {
      return <Column key={col.field} field={col.field} resizable={col.resizable}
        sortable={col.sortable}
        header={col.header} body={col.body} style={col.style} alignFrozen={col.alignFrozen} frozen={col.frozen} />;
    });
    const template2 = {
      layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink',
      'RowsPerPageDropdown': (options) => {
        const dropdownOptions = [
          { label: 10, value: 10 },
          { label: 20, value: 20 },
          { label: 30, value: 30 },
          { label: 40, value: 40 },
          { label: 50, value: 50 }
        ];

        return (
          <React.Fragment>
            <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Rows per page: </span>
            <Dropdown value={this.state.pageSize} options={dropdownOptions} onChange={(e) => this.setPerPage(e)} />
          </React.Fragment>
        );
      },
      'CurrentPageReport': (options) => {
        return (
          <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
            {this.state.first} - {this.state.last} of {this.state.count}
          </span>
        )
      },
      'FirstPageLink': () => {
        return (
          <button type="button"
            className=" p-link1"
            onClick={() => this.firstPageChangeHandle()}
            disabled={this.state.prev}
          >
            <img
              className={this.state.prev ? "p-link3" : "p-link2"}
              src={this.state.prev ? "/images/svg-icons/arrow-pre-disabled.svg" : "/images/svg-icons/arrow-pre.svg"}
              alt=""
            />
          </button>
        )
      },
      'PrevPageLink': () => {
        return (
          <button type="button"
            className=" p-link1"
            onClick={() => this.prevPageChangeHandle()}
            disabled={this.state.prev}
          >
            <i className="pi pi-chevron-left"></i>
          </button>
        )
      },
      'NextPageLink': () => {
        return (
          <button
            type="button"
            className="p-link1"
            onClick={() => this.nextPageChangeHandle()}
            disabled={this.state.next}
          >
            <i className="pi pi-chevron-right"></i>
          </button>
        )
      },
      'LastPageLink': () => {
        return (
          <button type="button"
            onClick={() => this.lastPageChangeHandle()}
            className=" p-link1"
            disabled={this.state.next}
          >
            <img
              className={this.state.next ? "p-link3" : "p-link2"}
              src={this.state.next ? "/images/svg-icons/arrow-next-disabled.svg" : "/images/svg-icons/arrow-next.svg"}
              alt=""
            />
          </button>
        )
      },
    };
    return (
      <>
        {this.state.showLoader && <Loader />}
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="pills-ma-playbook"
              role="tabpanel"
              aria-labelledby="pills-ma-playbook-tab"
            >
              <div className="white-bg">
                <div className="ta-box set-pos mb-0">
                  <div className="action-btn d-flex align-items-center">
                    <button onClick={
                      () => this.getPlaybooks()
                    }>
                      <img src="/images/svg-icons/refreshing.svg" alt="" />{" "}
                      Refresh
                    </button>
                    <button
                      className="button-lst"
                      disabled={(process.env.REACT_APP_AD_USER === 'TRUE' &&                       
                      localStorage.getItem('ssoUser') !== 'true'&&   sessionStorage.getItem('ssoUser') !== 'true') ||
                        (this.props?.userRole === "Viewer_Group" ||
                          this.props?.userRole === "Operator_Group")}
                      onClick={
                        () => this.syncPlaybooks()
                      }>
                      <img src="/images/svg-icons/synchronize.svg" alt="" />{" "}
                      Sync
                    </button>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                      title={this.state.repoUrl ? `Debian Repo URL: ${this.state.repoUrl}` : ''}>
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="card swpb">
                  <DataTable
                    value={this.state.collectionsData}
                    scrollable scrollHeight="400px"
                    loading={this.state.loading}
                    removableSort
                    resizableColumns
                    columnResizeMode="fit"
                    showGridlines
                    paginator
                    paginatorTemplate={template2}
                    first={this.state.firstDefault}
                    rows={this.state.pageSize}
                    paginatorClassName="p-jc-end"
                    // selectionMode="checkbox"
                    // selection={this.state.multipleSelect}
                    // onSelectionChange={e => this.onSelectRow(e)}
                    dataKey="models_supported"
                    scrollDirection="both"
                    className="p-mt-3">
                    {/* <Column 
                                // selectionMode="multiple" 
                                // style={{ flexGrow: 1, flexBasis: '80px' }} 
                                ></Column> */}
                    {dynamicColumns}
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(this.state.show) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            reset={() => this.resetPopup()}
          />
        }
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
        {this.state.showRefreshModal && <ErrorHandlePopup />}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    userRole: String(state.auth.role)
  };
};
export default withRouter(connect(mapStateToProps)(SyncWithPlaybooks));
