import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import {  PublicClientApplication } from '@azure/msal-browser';
import './App.css';
import { msalConfig } from './authConfig';
import { Provider } from 'react-redux';
import store from './redux/store';
import Login from './components/login/Login';
import DeviceList from './components/deviceList/NewDeviceList';
import ConfiguredDeviceList from './components/deviceList/ConfiguredDeviceList';
import DeviceDetails from './components/device/DeviceDetails';
import Templates from './components/templates/TemplateList';
import TunnelsList from './components/tunnels/TunnelsList';
import Operations from './components/operations/OperationsList';
import Tunnelview from './components/tunnelsView/TunnelView';
import Dashboard from './components/dashboard/Dashboard';
import Warnings from './components/warnings/Warnings';
import D3TopoLogy from './components/dashboard/D3TopoLogy';
import TemplateInfo from './components/templates/TemplateInfo';
import DeviceFirmware from './components/administration/DeviceFirmware';
import DeviceGroupList from './components/device/DeviceGroupList';
import DeviceGroupDetails from './components/device/DeviceGroupList/DeviceGroupDetails';
import DefaultTemplateList from './components/templates/DefaultTemplateList';
import UserList from './components/userList/userList';
import Troubleshooting from './components/troubleshoot/Troubleshooting';
import Configuration from './components/configuration/Configuration';
import AAAServer from './components/configuration/Packetfence/AAAServer';
import ADUserList from './components/ADUser/userList';
import SSOUser from './components/SSOUser';

const Pages = (props) => {    
    const instance = new PublicClientApplication(msalConfig);
    const ADUser =  process.env.REACT_APP_AD_USER;

    return (
        <Provider store={store.store}>
            <MsalProvider instance={instance}>
                <BrowserRouter>
                    <Switch>
                    <Route path="/home" component={() => <SSOUser />} />
                            <Route exact path="/" component={
                                ADUser !== 'TRUE'? () => <Login /> :
                                () => <Dashboard />} />
                            <Route exact path="/dashboard" component={() => <Dashboard />} />
                            <Route exact path="/devices" component={() => <DeviceList />} />
                            <Route exact path="/devicedetails" component={() => <DeviceDetails />} />
                            <Route exact path="/devices/configured" component={() => <ConfiguredDeviceList />} />
                            <Route exact path="/templates" component={() => <Templates />} />
                            <Route exact path="/tunnels" component={() => <TunnelsList />} />
                            <Route exact path="/tunnelsView" component={() => <Tunnelview />} />
                            <Route exact path="/operations" component={() => <Operations />} />
                            <Route exact path="/warnings" component={() => <Warnings />} />
                            <Route exact path="/configuration" component={() => <Configuration />} />
                            <Route exact path="/D3TopoLogy" component={() => <D3TopoLogy />} />
                            <Route exact path="/templateInfo" component={() => <TemplateInfo />} />
                            <Route exact path="/deviceFirmware" component={() => <DeviceFirmware />} />
                            <Route exact path="/deviceGroup" component={() => <DeviceGroupList />} />
                            <Route exact path="/troubleshoot" component={() => <Troubleshooting />} />
                            <Route exact path="/devicegroupdetails" component={() => <DeviceGroupDetails />} />
                            <Route exact path="/bootstrapTemplates" component={() => <DefaultTemplateList />} />
                            <Route exact path="/userList" component={
                                ADUser === 'TRUE' ? () => <ADUserList /> : () => <UserList />} />
                            <Route exact path="/aaaserver" component={() => <AAAServer />} />
                    </Switch>

                </BrowserRouter>
            </MsalProvider>
        </Provider>
    );
};

const App = ({ instance }) => {
    return (
        <Pages />
    );
}

export default App;
