import React, { Component } from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Loader } from '../layOuts/Loader';
import userApiCall from "../redux/API/ADUserApiCall";
import userUrlMapping from "../redux/API/ADUserAPI";
import { ErrorPopup } from '../layOuts/errorPopup';
import ErrorHandlePopup from './ErrorHandlePopup';
import * as Action from '../redux/actionTypes';
import Header from '../layOuts/Header';
import $ from "jquery";

class SelectTenant extends Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            showLoader: false,
            showRefreshModal: false,
            title: "",
            error: false,
            delete: false,
            message: "",
            tenantList: [],
            selectedTenant: '',
            selectedTenantName: '',
            tier: '',
            isHide: false
        }
    }
    componentDidMount() {
        if (localStorage.getItem('ssoUser') !== 'true'
            && localStorage.getItem('ADtoken') !== undefined && localStorage.getItem('ADtoken') !== '')
            this.getTenantList();
    }
    UNSAFE_componentWillReceiveProps(nextProps, instance) {
        if (instance) {
            this.setState({ auth: instance })
        }
        if (localStorage.getItem('ssoUser') !== 'true' && localStorage.getItem('ADtoken') && localStorage.getItem('ADtoken') !== '')
            this.getTenantList();
    }
    getTenantList = () => {
        this.setState({ showLoader: true })
        userApiCall(userUrlMapping.getTenants(), (response) => {
            if (response?.tenants) {
                this.setState({
                    showLoader: false,
                })
                this.setState({
                    tenantList: response.tenants
                })
                if (response.tenants?.length === 1) {
                    if (response.tenants[0].status === "SUSPEND") {
                        localStorage.setItem('activeUser', false);
                        this.setState({
                            title: 'Login Error',
                            message: 'This tenant has been suspended. please contact Inseego Support.',
                            showLoader: false,
                            delete: false,
                            show: true,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else if (response.tenants[0].status === "DELETE") {
                        localStorage.setItem('activeUser', false);
                        this.setState({
                            title: 'Login Error',
                            message: 'This tenant has been deleted. please contact Inseego Support.',
                            showLoader: false,
                            delete: false,
                            show: true,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        localStorage.setItem("selectedTenantId", response.tenants[0].tenantId);
                        localStorage.setItem("selectedTenantName", response.tenants[0].tenantName);
                        localStorage.setItem("tier", response.tenants[0].tierName);
                        localStorage.setItem('activeUser', true);
                        this.getUserDetails();
                    }
                }else if(response.tenants?.length === 0) {
                    this.setState({
                        title: 'Tenant Error',
                        message: "Tenant does not exist",
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        show: true,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
            else {
                if (response?.response?.data?.apierror?.status === "NOT_FOUND") {
                    this.setState({
                        title: 'Tenant Error',
                        message: "Tenant does not exist",
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        show: true,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response.isAxiosError) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                this.setState({ showLoader: false })
            }
        })
    }
    setTenant = (e) => {
        this.setState({
            selectedTenant: e.target.value,
            selectedTenantName: this.state.tenantList.filter(tenant => tenant.tenantId === parseInt(e.target.value))[0]?.tenantName,
            tier: this.state.tenantList.filter(tenant => tenant.tenantId === parseInt(e.target.value))[0]?.tierName
        });
    }
    getUserDetails = () => {
        userApiCall(userUrlMapping.getUserDetails(), (response) => {
            if (response?.data) {
                localStorage.setItem('user',JSON.stringify(response.data));
                this.props.dispatch({
                    type: Action.LOGIN_SUCCESS,
                    superuser: false,
                    // user: response.data,
                    // role: response.data.userRole
                });
                //  this.props?.history?.push("/userList");
            }
        })
    }
    login = () => {
        if (this.state.tenantList.filter(item => item.tenantId === parseInt(this.state.selectedTenant))[0].status === "SUSPEND") {
            this.setState({
                title: 'Login Error',
                message: 'This tenant has been suspended. please contact Inseego Support.',
                showLoader: false,
                delete: false,
                show: true,
                error: true,
                isHide: true
            }, () => $("#errorMessage").modal("show"))
        } else if (this.state.tenantList.filter(item => item.tenantId === parseInt(this.state.selectedTenant))[0].status === "DELETE") {
            this.setState({
                title: 'Login Error',
                message: 'This tenant has been deleted. please contact Inseego Support.',
                showLoader: false,
                delete: false,
                show: true,
                error: true,
                isHide: true
            }, () => $("#errorMessage").modal("show"))
        } else {
            this.setState({
                isHide: false
            })
            localStorage.setItem("selectedTenantId", this.state.selectedTenant);
            localStorage.setItem("selectedTenantName", this.state.selectedTenantName);
            localStorage.setItem("tier", this.state.tier);
            localStorage.setItem('activeUser', true);
            this.getUserDetails();
        }
    }
    logOut = () => {
        this.props.dispatch({
            type: Action.LOGOUT,
        });
    }
    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                $("#errorMessage").modal("hide");
                this.logOut();
            }
        );
    };
    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                {/* <SSOUser /> */}
                <Header />
                {(!this.state.isHide && this.state.tenantList?.length > 1) &&
                    <div class="overlayBg">
                        <div class="inseego-wrap">
                            <img src="/images/logo-color.png" alt="" />
                            <div className="logo-txt"> 5G SD EDGE Manager </div>
                            <select
                                className="form-control"
                                name='tenants'
                                onChange={(e) => this.setTenant(e)}
                                value={this.state.selectedTenant}>
                                <option value="">Select Tenant</option>
                                {this.state.tenantList.map((tenant) => {
                                    return (<option value={tenant.tenantId}>{tenant.tenantName}</option>)
                                })}
                            </select>
                            <div class="action">
                                <button class="cancel" onClick={this.logOut}>Cancel</button>
                                <button class="continue" onClick={this.login}>Continue</button>
                            </div>
                        </div>
                    </div>}
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                    />
                }
                {(this.state.showRefreshModal) &&
                    <ErrorHandlePopup
                    />
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        openAddADUser: state.displayAddDevice.openAddADUser,
        isSuperAdmin: state.auth.isSuperAdmin,
        SsoUser: state.auth.SsoUser
    });
}
export default withRouter(connect(mapStateToProps)(SelectTenant));

