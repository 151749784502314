import { LogLevel } from "@azure/msal-browser";
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AAD_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_AAD_APP_CLIENT_SECRET,
    authority: `${process.env.REACT_APP_AAD_APP_AUTHORITY}B2C_1A_INSEEGO_SDEDGE_SIGNUP_SIGNIN`,
    // redirectUri: "http://localhost:8080/",
    // postLogoutRedirectUri: "http://localhost:8080/",
    redirectUri: process.env.REACT_APP_AAD_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_AAD_APP_REDIRECT_URI,
    validateAuthority: false,
    navigateToLoginRequestUrl: false,
    
    knownAuthorities: [process.env.REACT_APP_AAD_APP_KNOWN_AUTHORITY,'devpegasustoolkit.b2clogin.com','qapegasustoolkit.b2clogin.com'],
  },

  system: {
    tokenRenewalOffsetSeconds: 120,
    iframeHashTimeout: 10000,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message)
        }
      }
    }
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};
export const b2cPolicies = {
  names: {
    signUpSignIn: 'b2c_1a_inseego_sdedge_signup_signin',
    changePassword: 'b2c_1a_inseego_sdedge_passwordchange',
    setPassword: 'b2c_1a_inseego_sdedge_passwordreset',
    ssoLogin: 'b2c_1_ptk_to_sdedge_signup_signin'
  },
  authorities: {
    signUpSignIn: {
      authority: `${process.env.REACT_APP_AAD_APP_AUTHORITY}B2C_1A_INSEEGO_SDEDGE_SIGNUP_SIGNIN/`,
    },
    changePassword: {
      authority: `${process.env.REACT_APP_AAD_APP_AUTHORITY}B2C_1A_INSEEGO_SDEDGE_PASSWORDCHANGE/`,
    }, 
    setPassword: {
      authority: `${process.env.REACT_APP_AAD_APP_AUTHORITY}B2C_1A_INSEEGO_SDEDGE_PASSWORDRESET/`,
    },
    ssoLogin: {
      authority: `${process.env.REACT_APP_AAD_APP_AUTHORITY}B2C_1_PTK_TO_SDEDGE_SIGNUP_SIGNIN/`
    }   
  },
}

export const loginRequest = {
  scopes: ['openid', 'profile', 'offline_access'],
  // extraQueryParameters: {ui_locales: language}
};

