import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Loader } from '../../layOuts/Loader';
import ErrorHandlePopup from '../ErrorHandlePopup';
import apiCall from '../../redux/API/apiCall';
import urlMapping from '../../redux/API/api';
import $ from 'jquery';
import * as Common from "../../utils/common"
import { ErrorPopup } from '../../layOuts/errorPopup';
import * as Action from "../../redux/actionTypes";

class SMTP_Server extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            showAlerts: false,
            error: false,
            showPassword: false,
            isAxiosError: false,
            smtpData: {
                smptServer: "",
                smptEmail: "",
                password: "",
                smptServerPort: 587,
            }
        }
    }
    componentDidMount = () => {
        this.getSMTPSettings();
    }
    getSMTPSettings = () => {
        $("#alerts").modal("hide");
        this.setState({ showLoader: true, showAlerts: false })
        apiCall(urlMapping.get_SMTP_Server(), (response) => {
            if (response?.success) {
                let data = this.state.smtpData
                data.smptServer = response?.data?.smtp_details?.host
                data.smptServerPort = response?.data.smtp_details?.port
                data.smptEmail = response?.data?.smtp_details?.host_mail
                data.password = response?.data?.smtp_details?.host_password
                this.setState({
                    smtpData: data,
                    showLoader: false
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    }
    setSMTPData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                error: false
            })
        }
        let { smtpData } = this.state;
        const name = e.target.name;
        const value = e.target.value
        smtpData[name] = value;
        this.setState({
            smtpData
        })
    }
    validate = (e) => {
        if (e.target.name === "smptServer") {
            if (!Common.validateDomainName(e)) {
                const element = document.getElementById(e.target.name);
                element.style.display = 'block';
                this.setState({ error: true })
            }
            else {
                this.removeError(e)
            }
        }
        else if (e.target.name === "smptServerPort") {
            if (parseInt(e.target.value) > 65535 || parseInt(e.target.value) < 1) {
                const element = document.getElementById(e.target.name);
                element.style.display = 'block';
                this.setState({ error: true })
            }
            else {
                this.removeError(e)
            }
        }
        else if (e.target.name === "smptEmail") {
            if (!Common.validateMailAddress(e)) {
                const element = document.getElementById(e.target.name);
                element.style.display = 'block';
                this.setState({ error: true })
            }
            else {
                this.removeError(e)
            }
        }
        else if (e.target.name === "password") {
            if (e.target.value === "") {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({ error: true });
            }
            else {
                this.removeError(e)
            }
        }
        else {
            this.removeError(e)
        }
    }
    removeError = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) element.style.display = "none";
        this.setState({
            error: false,
        });
    };
    showPass = () => {
        const x = document.getElementById("Password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }
    disableCheck = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.smtpData.smptServer === "" || this.state.smtpData.smptServerPort === "" || this.state.smtpData.smptEmail === "" ||
            this.state.smtpData.password === "")
            result = true;
        if (process.env.REACT_APP_AD_USER === 'TRUE' &&
            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
            localStorage.getItem('ssoUser') !== 'true'&&  sessionStorage.getItem('ssoUser') !== 'true'))
            result = true;
        return result;
    }
    submit = () => {
        this.setState({ showLoader: true })
        let data = {}
        data.host = this.state.smtpData.smptServer
        data.port = this.state.smtpData.smptServerPort
        data.host_mail = this.state.smtpData.smptEmail
        data.host_password = this.state.smtpData.password
        data.tls = "1"
        data.ssl = "0"
        let tempData = {
            smtp_details: data
        }
        apiCall(urlMapping.SMTP_Server(tempData), (response) => {
            if (response) {
                this.setState({
                    showLoader: false,
                    message: response?.message,
                    showAlerts: true
                }, () => $("#alerts").modal("show"))
            }
            else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    }
    resetPopup = () => {
        this.setState(
            {
                isAxiosError: false,
                error: false,
            },
            () => {
                if (this.state.title === 'Tenant Error') {
                    this.props.dispatch({
                      type: Action.LOGOUT,
                    });
                  }
                $("#errorMessage").modal("hide");
            }
        );
    };
    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div className="white-bg page-height">
                    <div className="ta-box set-pos mb-0">
                        <div className="action-btn d-flex align-items-center">
                            <button
                                onClick={() =>
                                    this.getSMTPSettings()
                                }
                            >
                                <img src="/images/svg-icons/refreshing.svg" alt="" />{" "}
                                Refresh
                            </button>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div className='mt-1 col-md-3'>
                            <label>SMTP Server</label>
                            <span className="red-txt">*</span>
                        </div>
                        <div className='col-md-3 p-0'>
                            <input
                                type="email"
                                class="form-control"
                                name="smptServer"
                                onChange={this.setSMTPData}
                                onBlur={(e) => this.validate(e)}
                                disabled={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' && 
                                sessionStorage.getItem('ssoUser') !== 'true')) || 
                                this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group"}
                                defaultValue={this.state.smtpData.smptServer}
                            />
                            <div className="error-txt" id="smptServer">Invalid Domain Name</div>
                        </div>
                        <div className="mt-1 pl-1 col-md-6 info-txt">(eg. : mail.example.com)</div>
                    </div>
                    <div class="row form-group">
                        <div className='col-md-3'>
                            <label className='mt-1'>SMTP Server Port</label>
                            <span className="red-txt">*</span>
                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                title="587 is the default port. &#013;For any other port make sure you have configured it on Email server.">
                                <img src="/images/svg-icons/info.svg" alt="" />
                            </div>
                        </div>
                        <div className='col-md-3 p-0'>
                            <input
                                type="number"
                                name="smptServerPort"
                                class="form-control"
                                placeholder="Enter the Port"
                                onChange={this.setSMTPData}
                                onBlur={(e) => this.validate(e)}
                                disabled={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' && 
                                localStorage.getItem('ssoUser') !== 'true'&&  sessionStorage.getItem('ssoUser') !== 'true')) || 
                                this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group"}
                                defaultValue={this.state.smtpData.smptServerPort}
                                min="1"
                                max="65535"
                            />
                            <div className="error-txt" id="smptServerPort">Keep alive port between 1 to 65535</div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div className='mt-1 col-md-3'>
                            <label>SMTP Email</label>
                            <span className="red-txt">*</span>
                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Email should support SMTP configurations">
                                <img src="/images/svg-icons/info.svg" alt="" />
                            </div>
                        </div>
                        <div className='col-md-3 p-0'>
                            <input
                                type="text"
                                class="form-control"
                                name="smptEmail"
                                placeholder="Enter the Mail Address"
                                onChange={this.setSMTPData}
                                onBlur={(e) => this.validate(e)}
                                disabled={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' && 
                                localStorage.getItem('ssoUser') !== 'true'&& sessionStorage.getItem('ssoUser') !== 'true')) || 
                                this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group"}
                            />
                            <div className="error-txt" id="smptEmail">Invalid Email Address</div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div className='col-md-3'>
                            <label className='mt-1'>Password</label>
                            <span className="red-txt">*</span>
                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                title="Password should be accessible for SMTP server &#013;like App Password in Gmail SMTP">
                                <img src="/images/svg-icons/info.svg" alt="" />
                            </div>
                        </div>
                        <div className='col-md-3 p-0'>
                            <input
                                type="password"
                                class="form-control"
                                name="password"
                                id="Password"
                                placeholder="Enter Password"
                                onChange={this.setSMTPData}
                                disabled={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' && 
                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) || 
                                this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group"}
                                onBlur={(e) => this.validate(e)}
                                defaultValue={this.state.smtpData.password}
                            />
                            <div className="error-txt" id="password">Password cannot be empty!</div>
                            <span className="sh-icon-smtp">
                                <img src={this.state.showPassword ? "/images/show.png" : "/images/hide.png"}
                                    alt="" onClick={() => this.showPass()}
                                />
                            </span>
                        </div>
                    </div>
                    <div className='modal-footer border-top-0 justify-content-center pb-4'>
                        <button
                            type="submit"
                            class="btn btn-primary"
                            disabled={this.state.error ||
                                this.disableCheck() ||
                                this.props?.userRole === "Viewer_Group" ||
                                this.props?.userRole === "Operator_Group"}
                            onClick={() => this.submit()}
                        >
                            Save
                        </button>
                    </div>
                </div>
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                    />
                }
                {this.state.showAlerts &&
                    <div className="modal fade" id="alerts" tabIndex="-1" role="dialog" aria-labelledby="alertsLabel" aria-hidden="true" data-backdrop="static">
                        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body pt-5">
                                    <div className="error-msg">
                                        <span className="success-icon"></span>
                                        <div className="txt1">SMTP Server Settings</div>
                                        <div className="txt2">{this.state.message}</div>
                                    </div>
                                </div>
                                <div className="modal-footer border-top-0 justify-content-center pb-4">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal"
                                        onClick={() => this.getSMTPSettings()}
                                    >OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        SsoUser: state.auth.SsoUser,
        userRole: JSON.parse(localStorage.getItem('user'))?.userRole
    });
}
export default withRouter(connect(mapStateToProps)(SMTP_Server));