import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import ErrorHandlePopup from '../ErrorHandlePopup';
import $ from "jquery";
import { ErrorPopup } from '../../layOuts/errorPopup';
class DynamicRouteDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showRefreshModal: false,
            pageNo: 1,
            pageSize: 10,
            count: 0,
            id: this.props.data,
            deviceData: [],
            deviceDataProps: this.props.data,
            deviceListWithId: [],
            domainName: "",
            domainName1: "",
            totalCount: 0,
            filterList: [],
            currentDeviceID: '',
            contents: [],
            neighbourInfo: [],
            text: [],
            title: '',
            message: '',
            show: false,
            error: false,
            deviceLogData: [],
            showLoader: false,
            showError: false,
            logsPageNo: 1,
            logsPageSize: 10,
            logsCount: 0,
            isConnected: false,
            selectedInfo: '',
            isAxiosError: false,
            neighbourInfoCommands: [
                {
                    backendValue: "show ip bgp summary",
                    command: "BGP Summary"
                },
                {
                    backendValue: "show ip route bgp",
                    command: "BGP Route Info with Connection Status"
                },
                {
                    backendValue: "show ip route ospf",
                    command: "OSPF Route Info with Connection Status"
                },
                {
                    backendValue: "show ip ospf route",
                    command: "OSPF Route Table"
                },
                {
                    backendValue: "show ip ospf database",
                    command: "OSPF Database"
                },
                {
                    backendValue: "show ip bgp",
                    command: "BGP Detailed Route Info"
                },
                {
                    backendValue: "show ip route",
                    command: "Route Info with Connection Status"
                }
            ]

        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.deviceIdWhenDropChanges && this.props.deviceIdWhenDropChanges !== '') {
            this.setState({
                neighbourInfo: [],
                selectedInfo: ''
            })
        }
    }
    selectedCommand = (e) => {
        this.setState({
            selectedInfo: e.target.value
        })
    }
    handleExecute = () => {
        let data = {
            device_id: this.props.data.device_id,
            diagnostic_type: "neighbour_info",
            diagnostic: this.state.selectedInfo
        }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.deviceTroubleshoot(data), (response) => {
            if (response?.success) {
                this.setState({
                    neighbourInfo: [...response?.data?.[0], ...response?.data?.[1]],
                    showLoader: false
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        showLoader: false
                    })
                }
            }
        })
    }
    render() {
        return (
            <>
                {this.state.showLoader && <Loader />}
                <div className="white-bg">
                    <p>Provides routing and neighbor information for the dynamic routing protocols.</p>
                    <div className="row no-gutters">
                        <div className="col-md-4 col-sm-5">
                            <div className="form-group mr-1">
                                <label>Device:</label>&nbsp;
                                <span>{this.props.data.device_name}</span>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-5">
                            <div className="form-group ml-1">
                                {/* Implemented Dynamic Route Details page */}
                                <label>Neighbor information</label>
                                <select className="form-control" onChange={this.selectedCommand} value={this.state.selectedInfo}>
                                    <option value="">Select</option>
                                    {this.state.neighbourInfoCommands.map((item, index) => {
                                        return (<option key={index} value={item.backendValue}>{item.command}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-2">
                            <div className="form-group">
                                <label className="d-block">&nbsp;</label>
                                <button className="btn btn-primary ml-2 btn-top-space"
                                    onClick={() => { this.handleExecute() }}
                                    disabled={!this.state.selectedInfo}
                                >Execute</button>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-md-8">
                            <ul className="drd-list">
                                {this.state.neighbourInfo.map((item) => {
                                    return (<li>{item}</li>)
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        deviceIdWhenDropChanges: state.displayAddDevice.deviceIdWhenDropChanges
    });
}
export default withRouter(connect(mapStateToProps)(DynamicRouteDetails));