import $ from 'jquery';
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import apiCall from '../../redux/API/apiCall';
import urlMapping from "../../redux/API/api";
import * as Action from '../../redux/actionTypes';
import { Loader } from '../../layOuts/Loader';
import axios from "axios";
const envURL = process.env.REACT_APP_LOGIN_URL + 'api-user/';
const refresh_token = localStorage.getItem('refresh');
let timer

class InactivePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            timer: 100,
            stayLogin: false
        }
    };

    componentDidMount() {
        $('.modal').modal('hide');
        $("#inactive").modal("show");
        this.timerCall();
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
    };

    componentWillUnmount() {
        clearInterval(timer);
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    };

    handleVisibilityChange = () => {
        clearInterval(timer);
        this.timerCall();
    };

    timerCall = () => {
        timer = setInterval(() => {
            $("#exampleModal").modal("hide");
            $("#errorMessage").modal("hide");
            $("#refreshFail").modal("hide");
            if (this.state.timer !== 0) {
                this.setState((prevState) => ({
                    timer: prevState.timer - 1,
                }));
            } else {
                clearInterval(timer);
                this.logoutNow();
            }
        }, 1000);
    };

    logoutNow = () => {
        this.setState({
            showLoader: true
        })
        if(process.env.REACT_APP_AD_USER !== 'TRUE') {
        const data = {
            refresh: refresh_token
        }
        apiCall(urlMapping.logout(data), (response) => {
            this.setState({
                showLoader: false
            })
            if (response) {
                this.props.dispatch({
                    type: Action.LOGOUT,
                });
                localStorage.clear("token");
                localStorage.clear("refresh");
                localStorage.clear("username");
                $("#refreshFail").modal("hide");
                $("#inactive").modal("hide")
                $('.modal-backdrop').remove();
                this.props.history.push("/");
            }
        });
    }else {
        this.props.dispatch({
            type: Action.LOGOUT,
        });
        localStorage.removeItem("token");
        this.props.history.push("/");
        $("#refreshFail").modal("hide");
        $('.modal-backdrop').remove();
    }
    };

    stayLogin = async () => {
        if (refresh_token) {
            const response = await axios.post(`${envURL}token/refresh/`, {
                refresh: refresh_token,
            });
            if (response?.data?.access)
                localStorage.setItem('token', response.data.access);
        }
        await this.setState({
            showLoader: false
        }, () => {
            clearInterval(timer);
            this.props.onSubmit();
        })
    };

    render() {
        return (
            <>
                {this.state.showLoader && <Loader />}
                <div
                    className="modal fade inactive"
                    id="inactive"
                    tabIndex="-1"
                    data-backdrop="static"
                    role="dialog"
                    aria-labelledby="inactiveTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body pt-5">
                                <div className="error-msg">
                                    <span className="error-icon">!</span>
                                    <div className="txt1">Session Timeout</div>
                                    <div className="txt2">Your session is about to expire due to inactivity.<br />Please click on 'Stay logged-in', otherwise the session will expire automatically.
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-top-0 justify-content-center pb-4">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-dismiss="modal"
                                    onClick={() => this.logoutNow()}
                                >Logout</button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    disabled={this.state.timer === 0}
                                    onClick={() => this.setState({ showLoader: true }, () => this.stayLogin())}
                                >{"Stay logged-in (" + `${this.state.timer}` + ")"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated
    });
};

export default withRouter(connect(mapStateToProps)(InactivePopup));
