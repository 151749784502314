import { config } from "../../config";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const userURL = `${process.env.REACT_APP_AAD_API_BASEURL}user-server-1.BUILD-SNAPSHOT/`;

const userUrlMapping = {
getTenantUser:function (pageNo,pageSize) {
    return {
      url: userURL + `api/v1/users?pageNo=${pageNo}&pageSize=${pageSize}`,
      method: "POST",
      dataType: "json",
      data:{"search":[],"sort":[]},
      cache: false, 
      contentType: "application/json, text/plain, */*",
      timeout: 40000,
    };
  },
  getRoles:function () {
    return {
      url: userURL + `users/roles`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json, text/plain, */*",
      timeout: 40000,
    };
  },
  updateUser:function (data,userId) {
    return {
      url: userURL + `users/id/${userId}`,
      method: "PUT",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json, text/plain, */*",
      timeout: 40000,
    };
  },
  addUser:function (data) {
    return {
      url: userURL + `users`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json, text/plain, */*",
      timeout: 40000,
    };
  },
  deleteUser:function (data) {
    return {
      url: userURL + `users`,
      method: "DELETE",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json, text/plain, */*",
      timeout: 40000,
    };
  },
  getUserDetails:function (userId) {
    return {
      url: userURL + `users/api/v1/id/${localStorage.getItem('userId')}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json, text/plain, */*",
      timeout: 40000,
    };
  },
  getTenants:function () {
    return {
      url: `${process.env.REACT_APP_AAD_API_BASEURL}pegasus-tenant/v2/tenants/applications/${process.env.REACT_APP_AAD_APP_ID}/tenantListForUser?size=1000&sort=tenantName&page=1`,
      method: "GET",
      dataType: "json",
      // data: data,
      cache: false,
      contentType: "application/json, text/plain, */*",
      timeout: 40000,
    };
  },
  createSSOUser: function (data) {
    return {
      url: userURL + `v1/users/sso`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json",
      timeout: 40000,
    };
  },
  verifySSOUser: function (data) {
    return {
      url: userURL + `users/verify-sso`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json",
      timeout: 40000,
    };
  },

}

export default userUrlMapping;