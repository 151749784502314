import * as Action from "../actionTypes";


const initialState = {
    authenticated: false,
    openSide: false,
    isSuperAdmin: false,
    user: '',
    server_ip: '',
    server_hostname: '',
    is_configured: false,
    openAddServer: false,
    logout: false,
    tenantId: '',
    tenantName: '',
    SsoUser: false,
    role: ''
};

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case Action.LOGIN:
            return {
                ...state,
                authenticated: false,
                user: '',
                logout: false
            }

        case Action.LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                isSuperAdmin: action.superuser,
                user: action.user,
                logout: false,
                role: action.role
            }

        case Action.LOGIN_FAILURE:
            return {
                ...state,
                authenticated: false,
                user: '',
            }
        case Action.LOGOUT:
            return {
                ...state,
                authenticated: false,                
                user: '',
                logout: true,
            }
        case Action.LOGOUT_SUCCESS:
            return {
                ...state,
                authenticated: false,
                user: '',
                logout: false
            }
        case Action.REMOVE_USER : 
        return {
            ...state,
            user: '',
            authenticated: false, 
        }
        case Action.GET_AUTH_DETAILS:
            return {
                ...state,
                server_ip: action.payload.device_public_ip,
                server_hostname: action.payload.z_hostname,
            }
        case Action.IS_AUTH_SERVER_CONFIGURED:
            return {
                ...state,
                is_configured: action.payload
            }
        case Action.OPEN_ADD_SERVER:
            return {
                ...state,
                openAddServer: true
            }
        case Action.CLOSE_ADD_SERVER:
            return {
                ...state,
                openAddServer: false
            }
        case Action.SET_TENANT:
            return {
                ...state,
                tenantId: action.tenantId,
                tenantName: action.tenantName
            }
        case Action.DELETE_TENANT:
            return {
                ...state,
                tenantId: "",
                tenantName: ""
            }
        case Action.SET_SSOUSER:
            return {
                ...state,
                SsoUser: action.payload
            }
        default:
            return state;
    }
}

export const displaySidebarMenu = (state = initialState, action) => {
    switch (action.type) {
        case Action.OPEN_SIDEBAR_MENU:
            return {
                ...state,
                openSide: true
            }
        case Action.CLOSE_SIDEBAR_MENU:
            return {
                ...state,
                openSide: false
            }
        default:
            return state;
    }
};
