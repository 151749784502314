import React, { Component } from 'react'
import { Calendar } from 'primereact/calendar';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import $ from 'jquery';
import ErrorHandlePopup from '../ErrorHandlePopup';
import moment from 'moment-timezone';
import Select from 'react-select';
import { ErrorPopup } from '../../layOuts/errorPopup';

class Report extends Component {
    constructor(props) {
        super(props)
        this.state = {
            availableParameters: [],
            selectedOptions: [],
            selectedParameters: [],
            startDate: '',
            endDate: '',
            device_id: this.props.id ? this.props.id : "",
            minDate: '',
            showLoader: false,
            showRefreshModal: false,
            deviceModel: this.props.deviceModel ? this.props.deviceModel : "",
            showCalendar: false,
            isAxiosError: false,
        }
    }
    componentDidMount = () => {
        if (this.props.startDate) {
            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate,
            }, () => { this.parameter() })
        }
        else {
            let time = moment().subtract(1, 'h')
            this.setState({
                startDate: new Date(time),
                endDate: new Date()
            })
        }
        this.availableParameters();
        this.timePeriod();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id) {
            this.setState({
                device_id: nextProps.id,
                deviceModel: nextProps.deviceModel,
                startDate: new Date(moment().subtract(1, 'h')),
                endDate: new Date(),
                selectedParameters: [],
                selectedOptions: []
            }, () => { this.availableParameters() })
        }
    }
    timePeriod = () => {
        let date = moment().subtract(3, 'months').format("YYYY/MM/DD");
        this.setState({
            minDate: date,
        })
    }
    availableParameters = () => {
        if (this.state.deviceModel === "vCPE") {
            this.setState({
                availableParameters:
                    [
                        { label: "device_status", value: "device_status" },
                        { label: "device_data_usage", value: "device_data_usage" },
                        { label: "device_memory_stats", value: "device_memory_stats" },
                    ],
            })
        }
        else if (this.state.deviceModel === "Packetfence") {
            this.setState({
                availableParameters:
                    [
                        { label: "device_status", value: "device_status" },
                        { label: "device_memory_stats", value: "device_memory_stats" },
                    ],
            })
        }
        else {
            this.setState({
                availableParameters:
                    [
                        { label: "device_status", value: "device_status" },
                        { label: "device_data_usage", value: "device_data_usage" },
                        { label: "device_memory_stats", value: "device_memory_stats" },
                        { label: "connected_client_list", value: "connected_client_list" },
                        { label: "modem_info", value: "modem_info" },
                        { label: "location", value: "location" },
                    ],
            })
        }
    }
    parameter = () => {
        let { selectedParameters } = this.state;
        let { availableParameters } = this.state;
        let { selectedOptions } = this.state;
        if (this.props.deviceModel !== "vCPE") {
            selectedParameters.push("device_status", "location")
            selectedOptions = availableParameters.filter(
                (item) => item.value === "device_status" || item.value === "location");
        }
        else {
            selectedParameters.push("device_status")
            selectedOptions = availableParameters.filter(
                (item) => item.value === "device_status");
        }
        this.setState({
            selectedParameters,
            availableParameters,
            selectedOptions
        })
    }
    setStartTime = (e) => {
        let startDate = e.value ? e.value : ''
        this.setState({
            startDate: startDate,
            endDate: ''
        })
    }
    setEndTime = (e) => {
        let endDate = e.value ? e.value : ''
        this.setState({
            endDate: endDate
        })
    }
    handleChange = (value) => {
        let selectedParameters = []
        if (value.length !== 0) {
            value.map((item) => {
                selectedParameters.push(item.value)
            })
        }
        else {
            selectedParameters = []
        }
        this.setState({
            selectedParameters: selectedParameters,
            selectedOptions: value
        })
    }
    downloadReport = () => {
        this.setState({
            showLoader: true
        })
        let data = {
            device_id: this.state.device_id,
            parameter_types: this.state.selectedParameters,
            start_time: parseInt(new Date(this.state.startDate).getTime().toString().slice(0, 10)),
            end_time: parseInt(new Date(this.state.endDate).getTime().toString().slice(0, 10)),
            user_time_zone: moment.tz.guess()
        }
        ApiCall(urlMapping.downloadOperationReportfile(data), (response) => {
            if (parseInt(response?.response?.status) === 200) {
                const url = new Blob([response?.data])
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(url);
                link.setAttribute('download', "Reports.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    showLoader: false
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                        message: "No data found on selected intervals"
                    })
                    $("#login").modal("show")
                }
            }
        }
        )
    }
    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div className="white-bg page-height">
                    <div>
                        <label>Parameters:</label>
                    </div>
                    <div className="form-group w-5">
                        <Select
                            isMulti
                            name="colors"
                            options={this.state.availableParameters}
                            onChange={(value) => this.handleChange(value)}
                            value={this.state.selectedOptions}
                            className="basic-multi-select"
                            classNamePrefix="Select Interface"
                        />
                    </div>
                    <div className='d-flex'>
                        <div className="form-group mr-2" id="schedulerDiv">
                            <div>
                                <label>Start Time:</label>
                                <div className="pt-2">
                                    <Calendar
                                        readOnlyInput={false}
                                        id="navigatorstemplate"
                                        value={this.state.startDate}
                                        placeholder={"Start date..."}
                                        onChange={(e) => this.setStartTime(e)}
                                        showTime
                                        hourFormat="12"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        hideOnDateTimeSelect={true}
                                        yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                                        minDate={new Date(this.state.minDate)}
                                        maxDate={new Date()}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="form-group mr-2" id="schedulerDiv">
                            <div>
                                <label>End Time:</label>
                                <div className="pt-2">
                                    <Calendar
                                        id="navigatorstemplate"
                                        value={this.state.endDate}
                                        onChange={(e) => this.setEndTime(e)}
                                        placeholder={"End date..."}
                                        disabled={this.state.startDate === '' ? true : false}
                                        showTime
                                        hourFormat="12"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        hideOnDateTimeSelect={true}
                                        yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                                        minDate={this.state.startDate}
                                        maxDate={new Date()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="btn btn-primary"
                        disabled={this.state.selectedParameters.length === 0 || this.state.endDate === ''}
                        onClick={() => this.downloadReport()}>
                        Download</button>
                </div>

                <div className="modal fade login" id="login" tabIndex="-1" data-backdrop="static"
                    role="dialog" aria-labelledby="loginTitle" aria-hidden="true"
                >
                    <div className="modal-dialog modal-sd modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body pt-5">
                                <div className="error-msg">
                                    <span className="error-icon">!</span>
                                    <div className="txt1">{"Reports"}</div>
                                    <div className="txt2">{this.state.message}
                                    </div>
                                </div>
                                <div className="modal-footer border-top-0 justify-content-center">
                                    <button type="button" className="btn btn-danger"
                                        data-dismiss="modal"
                                    >{'OK'}</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
    });
}
export default withRouter(connect(mapStateToProps)(Report));