import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import moment from 'moment';
import DataTable from "react-data-table-component";
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from 'jquery';
import ErrorHandlePopup from '../ErrorHandlePopup';

class DeviceCheckpoint extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showRefreshModal: false,
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            title: '',
            message: '',
            show: false,
            error: false,
            delete: false,
            deviceLogData: [],
            deviceData: this.props.deviceData ? this.props.deviceData : this.props.history.location.state.data,
            device_id: this.props.device_id,
            showLoader: false,
            multipleSelect: [],
            create: false,
            createError: false,
            restore: false,
            isAxiosError: false,
            columns: [
                {
                    name: "Backup uuid",
                    selector: "checkpoint_uuid",
                    sortable: true,
                    cell: (row) => (
                        <span title={row.checkpoint_uuid}>
                            {row.checkpoint_uuid !== "" && row.checkpoint_uuid !== "null" && row.checkpoint_uuid !== null
                                ? row.checkpoint_uuid
                                : "-"}
                        </span>
                    )
                },
                {
                    name: "Created By",
                    selector: "created_by",
                    sortable: true,
                    cell: (row) => (
                        <span title={row.created_by} >
                            {row.created_by !== "" && row.created_by !== "null" && row.created_by !== null
                                ? row.created_by
                                : "-"}
                        </span>
                    )
                },
                {
                    name: "Created Date",
                    selector: "created_date",
                    sortable: true,
                    cell: (row) => (
                        <span >
                            {moment.utc(row.created_date).local().format('DD MMMM YYYY hh:mm:ss A')}
                        </span>
                    ),
                },
                {
                    value: "Action",
                    center: true,
                    cell: (row) => (
                        <div className="da-box">
                            <img className="a-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="/images/svg-icons/ellipsis.svg" alt="" />
                            <div className="drop-box dropdown-menu">
                                <ul>
                                    <li className={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                        (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' && 
                                        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ? 'disabled' : ''}
                                        onClick={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                            localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ? undefined :
                                            () => { this.setState({ multipleSelect: [row] }); this.confirmDelete() }}><img className="delete" src="/images/svg-icons/delete.svg" alt="" /> Delete</li>
                                    <li className={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                        (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ? 'disabled' : ''}
                                        onClick={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                            localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ? undefined :
                                            () => { this.setState({ multipleSelect: [row] }); this.confirmRestore() }}><img className="delete" src="/images/svg-icons/retry.svg" alt="" /> Restore</li>
                                </ul>
                            </div>
                        </div>
                    ),
                    allowOverflow: true,
                    button: true,
                    width: "56px",
                },
            ],
        }
    }


    componentDidMount() {
        this.getDeviceCheckpoints();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.deviceData) {

            if (Object.keys(nextProps.deviceData).length !== 0) {
                this.setState({
                    deviceData: nextProps.deviceData,
                    device_id: nextProps.id
                }, () => this.getDeviceCheckpoints())
            }
        } else {
            if (Object.keys(nextProps && nextProps.history && nextProps.history.location && nextProps.history.location.state &&
                nextProps.history.location.state.data)) {
                this.setState({
                    deviceData: nextProps.history.location.state.data,
                    device_id: nextProps.history.location.state.data.device_id
                }, () => this.getDeviceCheckpoints())
            }
        }
    }
    getDeviceCheckpoints = async () => {
        this.setState({
            showLoader: true,
        });
        let device_mac = this.state.deviceData.device_mac;

        await ApiCall(urlMapping.deviceCheckpoints(device_mac), (response) => {
            if (response?.success) {
                this.setState({
                    showLoader: false,
                    deviceLogData: response?.data,
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    })
                }
            }
        })
    }

    createCheckpoint = () => {
        let data = {};
        if (this.state.createError) {
            data = {
                device_mac: this.state.deviceData.device_mac,
                is_delete: true
            }
        } else {
            data = {
                device_mac: this.state.deviceData.device_mac,
                is_delete: false
            }
        }

        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.createCheckpoint(data), (response) => {
            this.setState({
                showLoader: false,
                show: false
            })
            if (response?.success) {
                $("#errorMessage").modal("hide");
                this.setState({
                    title: "Create Checkpoint",
                    message: response?.message,
                    show: true,
                    error: false,
                    delete: false,
                    create: false,
                    createError: false
                }, () => {
                    this.getDeviceCheckpoints();
                    $("#errorMessage").modal("show");
                }
                );
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    $("#errorMessage").modal("hide");
                    this.setState(
                        {
                            title: "Create Checkpoint",
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false,
                            create: false,
                            createError: true
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        })
    }

    deleteCheckpoint = () => {
        let uuid = this.state.multipleSelect[0].checkpoint_uuid;

        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.deleteCheckpoint(uuid), (response) => {
            $("#errorMessage").modal("hide");
            this.setState({
                showLoader: false,
                show: false
            })
            if (response?.success) {
                this.setState({
                    title: "Delete Checkpoint",
                    message: response?.message,
                    show: true,
                    error: false,
                    delete: false,
                    create: false,
                }, () => {
                    this.getDeviceCheckpoints();
                    $("#errorMessage").modal("show");
                }
                );
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState(
                        {
                            title: "Delete Checkpoint",
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false,
                            create: false
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        })
    }
    restoreCheckpoint = () => {
        let data = {
            checkpoint_id: this.state.multipleSelect[0].checkpoint_uuid
        }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.restoreCheckpoint(data), (response) => {
            $("#errorMessage").modal("hide");
            this.setState({
                showLoader: false,
                show: false
            })
            if (response?.success) {
                this.setState({
                    title: "Restore Checkpoint",
                    message: response?.message,
                    show: true,
                    error: false,
                    delete: false,
                    create: false,
                    restore: false
                }, () => {
                    this.getDeviceCheckpoints();
                    $("#errorMessage").modal("show");
                }
                );
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState(
                        {
                            title: "Restore Checkpoint",
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false,
                            create: false,
                            restore: false
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        })
    }
    confirmDelete = () => {
        this.setState(
            {
                title: "Delete Checkpoint",
                message: "Are you sure you want to delete checkpoint?",
                show: true,
                error: true,
                delete: true,
                create: false,
                restore: false,
            },
            () => {
                $("#errorMessage").modal("show");
            }
        );
    };
    confirmRestore = () => {
        this.setState(
            {
                title: "Restore Checkpoint",
                message: "Are you sure you want to restore checkpoint?",
                show: true,
                error: false,
                delete: true,
                create: false,
                restore: true
            },
            () => {
                $("#errorMessage").modal("show");
            }
        );
    };

    chooseAction = (deleteAct, createAct, restoreAct) => {
        if (deleteAct && createAct) {
            this.createCheckpoint();
        }
        else if (this.state.createError) {
            this.createCheckpoint();
        }
        else if (deleteAct && restoreAct) {
            this.restoreCheckpoint();
        }
        else if (deleteAct) {
            this.deleteCheckpoint();
        }
        else {
            return "undefined";
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
            createError: false
        }, () => {
            $("#errorMessage").modal("hide");
        })
    }

    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div className="white-bg">
                    <div className="ta-box set-pos mb-0">
                    </div>
                    <div className="no-bg page-height">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="pills-ma-snapshot" role="tabpanel" aria-labelledby="pills-ma-snapshot-tab">
                                    <div className="white-bg">
                                        <div className="table-box">
                                            <div>

                                            </div>
                                            < DataTable
                                                className="new-device-list"
                                                columns={
                                                    this.state.columns
                                                }
                                                data={this.state.deviceLogData}
                                                highlightOnHover
                                                fixedHeader
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.show &&
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                delete={this.state.delete}
                                createError={this.state.createError}
                                reset={() => this.resetPopup()}
                                onSubmit={() => this.chooseAction(this.state.delete, this.state.create, this.state.restore)}
                            />
                        }
                        {(this.state.isAxiosError) &&
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                delete={this.state.delete}
                                reset={() => this.setState({ isAxiosError: false })}
                            />
                        }
                    </div>
                    {this.state.showRefreshModal && <ErrorHandlePopup />}
                </div >
            </div>
        );

    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        userRole: JSON.parse(localStorage.getItem('user'))?.userRole,
        SsoUser: state.auth.SsoUser
    });
}
export default withRouter(connect(mapStateToProps)(DeviceCheckpoint));

