/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import apiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import DataTable from "react-data-table-component";
import $ from "jquery";
import * as Action from "../../../../redux/actionTypes";
import * as Common from "../../../../utils/common";
import Select from 'react-select';
import debounce from 'lodash/debounce';
import { ErrorPopup } from "../../../../layOuts/errorPopup";
import isEqual from 'lodash/isEqual';

class HubSpokeTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            title: '',
            message: '',
            show: false,
            showLink: false,
            error: false,
            adding: false,
            multipleSelect: "",
            multipleSelect1: "",
            toggledClearDeviceRows: false,
            toggledClearSelectedDeviceRows: false,
            applytemplate: false,
            devicesTemplate: false,
            applyTemplateData: {},
            deviceList: [],
            selectedDeviceData: [],
            networkViewData: [],
            hide: true,
            searchVal: "",
            selectedModel: '',
            deviceData: undefined,
            is_change_in_tunnel: false,
            device_A_zone: true,
            device_B_zone: true,
            BGP_device_A_zone: true,
            BGP_device_B_zone: true,
            selectedTemplateId: this.props?.id ? this.props?.id : "",
            template_type: this.props?.templateData?.template_type || "",
            template_name: this.props?.templateData?.template_name || "",
            template_desc: this.props?.templateData?.template_desc || "",
            device_model: this.props?.templateData?.device_model || "",
            collection_version: this.props?.templateData?.collection_version || "",
            fw_version: this.props?.templateData?.fw_version || "",
            chimay: this.props.chimay,
            cbr: this.props.cbr,
            mifi: this.props.mifi,
            isAxiosError: false,
            isExternalEndPoint: this?.props?.isExternalEndPoint || false,
            deviceListColumns: [{
                name: "Device Name",
                selector: "device_name",
                value: "Device Name",
                center: false,
                sortable: true,
                minWidth: "150px",
                cell: (row) => (
                    <span className="dnamemac" id={`icon${row?.device_id}`} title={row?.device_name}>
                        <span className="hname"><i className="status online"></i>{row?.device_name}</span>
                        <span className="maca">{row?.device_mac.toUpperCase()}</span>
                        <span className={row?.group_name ? "maca-grp dg-text ml-2" : ""}>{row?.group_name}</span>
                    </span>
                ),
            },
            {
                name: "Model",
                selector: "device_model",
                value: "Device Model",
                center: true,
                sortable: true,
                cell: (row) => (
                    <span title={row?.device_model}>
                        {row?.device_model}</span>
                ),
            }
            ],
            deviceListColumnsDiff: [
                {
                    name: "Device Name",
                    selector: "device_name",
                    value: "Device Name",
                    center: true,
                    sortable: true,
                    cell: (row) => (
                        <span title={row?.device_name || row?.hub_device_name}>
                            {row?.device_name || row?.hub_device_name}</span>
                    ),
                },
                {
                    name: "MAC Address ",
                    selector: "device_mac",
                    value: "Mac Address",
                    center: true,
                    sortable: true,
                    cell: (row) => (
                        <span title={row?.device_mac?.toUpperCase() || row?.hub_device_mac?.toUpperCase()}>
                            {row?.device_mac?.toUpperCase() || row?.hub_device_mac?.toUpperCase()}</span>
                    ),
                },
                {
                    name: "Firmware ",
                    selector: "device_firmware_version",
                    value: "Firmware",
                    center: true,
                    sortable: true,
                    cell: (row) => (
                        <span title={row?.device_firmware_version || row?.hub_fw_version}>
                            {row?.device_firmware_version || row?.hub_fw_version}</span>
                    ),
                },
                {
                    name: "Model",
                    selector: "device_model",
                    value: "Device Model",
                    center: true,
                    sortable: true,
                    cell: (row) => (
                        <span title={row?.device_model || row?.hub_device_model}>
                            {row?.device_model || row?.hub_device_model}</span>
                    ),
                },
            ],
            deviceListGroupColumnsDiff: [
                {
                    name: "Group Name",
                    selector: "group_name",
                    value: "Group Name",
                    // center: true,
                    sortable: true,
                    cell: (row) => (
                        <span title={row?.group_name}>
                            {row?.group_name}</span>
                    ),
                },
                {
                    name: "Model",
                    selector: "supported_models",
                    value: "supported_models",
                    center: true,
                    sortable: true,
                    cell: (row) => (
                        <span title={row?.supported_models}>
                            {row?.supported_models}</span>
                    ),
                }
            ],
        };
    };

    componentDidMount() {
        if (Object.keys(this.props.templateData).length !== 0) {
            this.setState({
                applyTemplateData: this.props.templateData,
            });
            if (this.props.view || this.props.info) {
                let selectedDeviceData = [];
                selectedDeviceData?.push(this.props.templateData?.hub_details)
                this.setState({ selectedDeviceData: selectedDeviceData })
            }
        }
        if (this.props.networkViewData) {
            this.setState({
                networkViewData: this.props.networkViewData
            })
        }
        if (this.props.templateData.template_schema_json) {
            if (this.props?.templateData?.template_schema_json?.device_A?.tunnel_link &&
                this.props?.templateData?.template_schema_json?.device_B?.tunnel_link)
                if (this.props?.deviceA_networks &&
                    this.props.deviceB_networks) {
                    let new_DeviceA_network = [];
                    let new_DeviceB_network = [];
                    if (Array.isArray(this.props?.deviceA_networks)) {
                        new_DeviceA_network = this.props?.deviceA_networks.filter((item) => item.networkName === this.props?.templateData?.template_schema_json?.device_A?.tunnel_link)
                        this.props.templateData.template_schema_json.device_A.tunnel_link =
                            (new_DeviceA_network.length !== 0)
                                ? (
                                    new_DeviceA_network?.[0]?.networkName
                                        ? new_DeviceA_network?.[0]?.networkName
                                        : ""
                                )
                                : "";
                    }
                    if (Array.isArray(this.props?.deviceB_networks)) {
                        new_DeviceB_network = this.props.deviceB_networks.filter((item) => item.networkName === this.props?.templateData?.template_schema_json?.device_B?.tunnel_link)
                        this.props.templateData.template_schema_json.device_B.tunnel_link =
                            (new_DeviceB_network.length !== 0)
                                ? (
                                    new_DeviceB_network?.[0]?.networkName
                                        ? new_DeviceB_network?.[0]?.networkName
                                        : ""
                                )
                                : "";
                    }
                }
            this.setState({
                deviceData: JSON.parse(JSON.stringify(this.props.templateData.template_schema_json)),
                deviceA_networks: this.props.deviceA_networks,
                deviceB_networks: this.props.deviceB_networks,
            })
        }
        if (this?.props?.templateData?.template_schema_json?.device_A ||
            this.props?.templateData?.template_schema_json?.device_B) {
            this.setState({
                BGP_device_A_zone: this.props?.templateData?.template_schema_json?.device_A?.add_bgp_in_tunnel,
                BGP_device_B_zone: this.props?.templateData?.template_schema_json?.device_B?.add_bgp_in_tunnel,
                device_A_zone: this.props?.templateData?.template_schema_json?.device_A?.add_zone_in_firewall,
                device_B_zone: this.props?.templateData?.template_schema_json?.device_B?.add_zone_in_firewall,
            })
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.templateData).length !== 0) {
            this.setState({
                selectedTemplateId: nextProps.id ? nextProps.id : '',
                applyTemplateData: nextProps.templateData || {},
                template_type: nextProps?.templateData?.template_type || "",
                template_name: nextProps?.templateData?.template_name || "",
                template_desc: nextProps?.templateData?.template_desc || "",
                device_model: nextProps?.templateData?.device_model || "",
                collection_version: nextProps?.templateData?.collection_version || "",
                fw_version: nextProps?.templateData?.fw_version || ""
            });
        }
        if (nextProps.networkViewData) {
            this.setState({
                networkViewData: nextProps.networkViewData
            })
        }
        if (nextProps?.templateData?.template_schema_json?.device_A?.tunnel_link &&
            nextProps?.templateData?.template_schema_json?.device_B?.tunnel_link)
            if (nextProps?.deviceA_networks &&
                nextProps.deviceB_networks) {
                let new_DeviceA_network = [];
                let new_DeviceB_network = [];
                if (Array.isArray(nextProps?.deviceA_networks)) {
                    new_DeviceA_network = nextProps?.deviceA_networks.filter((item) => item.networkName === nextProps?.templateData?.template_schema_json?.device_A?.tunnel_link)
                    nextProps.templateData.template_schema_json.device_A.tunnel_link = new_DeviceA_network.length !== 0
                        ? (new_DeviceA_network?.[0]?.networkName ? new_DeviceA_network?.[0]?.networkName : "")
                        : "";
                }
                if (Array.isArray(nextProps?.deviceB_networks)) {
                    new_DeviceB_network = nextProps.deviceB_networks.filter((item) => item.networkName === nextProps?.templateData?.template_schema_json?.device_B?.tunnel_link)
                    nextProps.templateData.template_schema_json.device_B.tunnel_link =
                        (new_DeviceB_network.length !== 0)
                            ? (
                                new_DeviceB_network?.[0]?.networkName
                                    ? new_DeviceB_network?.[0]?.networkName
                                    : ""
                            )
                            : "";
                }
            }
        if (nextProps.view || nextProps.info) {
            let selectedDeviceData = [];
            if (nextProps.templateData?.hub_details !== undefined) {
                selectedDeviceData?.push(nextProps.templateData?.hub_details)
            }
            this.setState({ selectedDeviceData: selectedDeviceData })
        } else {
            if (nextProps.templateData.template_schema_json) {
                this.setState({
                    deviceData: JSON.parse(JSON.stringify(nextProps.templateData.template_schema_json)),
                })
            }
        }
        if (nextProps.deviceA_networks) {
            this.setState({
                deviceA_networks: nextProps.deviceA_networks,
            })
        }
        if (nextProps.deviceB_networks) {
            this.setState({
                deviceB_networks: nextProps.deviceB_networks,
            })
        }
        if (nextProps.devices) {
            this.setState({
                deviceData: JSON.parse(JSON.stringify(nextProps.devices)),
                selectedAvailablePortA: nextProps.devices?.device_A.own_port,
                selectedAvailablePortB: nextProps.devices?.device_B.own_port
            })
        }
        if (nextProps?.templateData?.template_schema_json?.device_A ||
            nextProps?.templateData?.template_schema_json?.device_B) {
            this.setState({
                BGP_device_A_zone: nextProps?.templateData?.template_schema_json?.device_A?.add_bgp_in_tunnel,
                BGP_device_B_zone: nextProps?.templateData?.template_schema_json?.device_B?.add_bgp_in_tunnel,
                device_A_zone: nextProps?.templateData?.template_schema_json?.device_A?.add_zone_in_firewall,
                device_B_zone: nextProps?.templateData?.template_schema_json?.device_B?.add_zone_in_firewall,
            })
        }
        if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
            !isEqual(this.props?.cbr, nextProps?.cbr) ||
            !isEqual(this.props?.mifi, nextProps?.mifi)) {
            this.setState({
                chimay: nextProps.chimay,
                cbr: nextProps.cbr,
                mifi: nextProps.mifi
            })
        }
        if (
            nextProps?.create &&
            nextProps?.selectedModelList !== null &&
            nextProps?.selectedModelList !== "" &&
            nextProps?.selectedModelList !== undefined
        ) {
            this.setState({
                selectedDeviceData: [],
                toggledClearSelectedDeviceRows: !this.state.toggledClearSelectedDeviceRows,
                multipleSelect: "",
                adding: false
            })
        }
    };

    getOptions = (option) => {
        let optionsArray = [];
        if (option === 'deviceA_port') {
            optionsArray = this.props?.devices?.device_A?.available_ports?.map((item) => {
                return { label: item, value: item }
            });
        }
        else {
            if (this.state.isExternalEndPoint) {
                optionsArray = this.props?.devices?.device_A?.available_ports?.map((item) => {
                    return { label: item, value: item }
                });
            }
            else {
                optionsArray = this.props?.devices?.device_B?.available_ports?.map((item) => {
                    return { label: item, value: item }
                });
            }
        }
        return optionsArray;
    };

    closeDeviceTemplate = () => {
        this.setState({
            searchVal: "",
            devicesTemplate: false,
            deviceList: [],
            multipleSelect: "",
            toggledClearDeviceRows: !this.state.toggledClearDeviceRows
        });
    };

    getDeviceByModel = () => {
        let commonDevice1 = this.state.selectedDeviceData;
        let data = {
            device_model:
                this.props?.selectedModelList
                    ? this.props?.selectedModelList?.toString()
                    : this.state.device_model.toString()
        };
        this.setState({
            showLoader: true,
        });
        apiCall(urlMapping.getDeviceLookUp(data), (response) => {
            this.setState({
                showLoader: false
            });
            if (response?.results) {
                let onlineDevices = response?.results
                // A comparer used to determine if two entries are equal.
                const isSameUser = (onlineDevices, commonDevice1) => onlineDevices?.device_id === commonDevice1?.device_id;
                // Get items that only occur in the left array,
                // using the compareFunction to determine equality.
                const onlyInLeft = (left, right, compareFunction) =>
                    left.filter(leftValue =>
                        !right.some(rightValue =>
                            compareFunction(leftValue, rightValue)));
                const onlyInA = onlyInLeft(onlineDevices, commonDevice1, isSameUser);
                const onlyInB = onlyInLeft(commonDevice1, onlineDevices, isSameUser);
                onlineDevices = [...onlyInA, ...onlyInB];
                this.setState({
                    deviceList: onlineDevices,
                    selectedModel: commonDevice1?.length > 0 ? commonDevice1?.[0]?.device_model : ''
                });
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    };

    // LONG-3222-EM - Not able to search(Filter) the device inside the Tunnel template
    isSubstring = (s1) => {
        this.setState({ searchVal: s1 })
    };

    multipleSelect1 = (rows) => {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    };

    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                $("#errorMessage").modal("hide");
                if (this.state.hide)
                    //Page blank issue in add & apply template
                    //LONG-3223-EM - Page got Vanish In Tunnel template
                    this.props.closeAddTemplate();
            }
        );
    };

    deviceAdd = () => {
        if (this.state.multipleSelect.length === 1) {
            this.setState({
                adding: true,
                exactModel: this.state.multipleSelect[0].device_model,
                selectedDeviceData: this.state.multipleSelect,
                deviceList: [],
                multipleSelect: "",
                toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
            }, () => this.closeDeviceTemplate())
        } else {
            this.setState({
                title: 'Apply Template',
                message: 'You can add only one device',
                show: true,
                error: true,
                hide: false
            }, () => $("#errorMessage").modal("show"));
        }
    };

    closeAll = () => {
        if (this.props.template_schema_json !== undefined) {
            this.setState({
                template_schema_json: this.props.template_schema_json,
                applied: false
            })
        }
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.props.closeAddTemplate()
    };

    saveData = () => {
        let data = {};
        data.template_name = this.state.template_name;
        data.template_desc = this.state.template_desc;
        data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
        data.template_type = this.state.template_type;
        data.collection_version = this.state.collection_version;
        data.fw_version = this.state.fw_version;
        data.template_schema_json = {};
        data.device_mac = this.state.selectedDeviceData[0].device_mac;
        data.template_order = 3;
        if (this.state.selectedTemplateId === "") {
            data.template_version = {
                chimay: this.state.chimay,
                cbr: this.state.cbr,
                mifi: this.state.mifi
            }
        }
        this.setState({
            showLoader: true,
        });
        apiCall(urlMapping.createTemplate(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                this.props.refreshList();
                this.props.closeAddTemplate();
                this.props.openApplyTemplate(response?.data?.id, response?.message);
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Create Template',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        });
    };

    handleChange = (e, name) => {
        if (name === "device_A_zone") {
            if (e.target.checked) {
                this.setState({
                    device_A_zone: true
                })
            }
            else {
                this.setState({
                    device_A_zone: false
                })
            }
        }
        else if (name === "device_B_zone") {
            if (e.target.checked) {
                this.setState({
                    device_B_zone: true
                })
            }
            else {
                this.setState({
                    device_B_zone: false
                })
            }
        }
        else if (name === "BGP_device_A_zone") {
            if (e.target.checked) {
                this.setState({
                    BGP_device_A_zone: true
                })
            }
            else {
                this.setState({
                    BGP_device_A_zone: false
                })
            }
        }
        else if (name === "BGP_device_B_zone") {
            if (e.target.checked) {
                this.setState({
                    BGP_device_B_zone: true
                })
            }
            else {
                this.setState({
                    BGP_device_B_zone: false
                })
            }
        }
    };

    // Error message added if no Local Endpoint IP is selected
    checkLocalEndpoints = () => {
        if (
            (
                this.state.deviceData?.device_B?.endpoint_details === null ||
                this.state.deviceData?.device_B?.endpoint_details === 'null' ||
                this.state.deviceData?.device_B?.endpoint_details === ''
            ) &&
            (
                this.state.deviceData?.device_A?.endpoint_details === null ||
                this.state.deviceData?.device_A?.endpoint_details === 'null' ||
                this.state.deviceData?.device_A?.endpoint_details === ''
            )
        ) {
            const element = document.getElementById("point");
            if (element) {
                element.style.display = "block";
                this.setState({
                    showError: true,
                });
            }
        }
        else {
            const element = document.getElementById("point");
            if (element) {
                element.style.display = "none";
                this.setState({
                    showError: false
                });
                this.applyTemplate()
            }
        }
    };

    validateIPaddress = (ipaddress, name) => {
        let DomainName = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]+?\.[a-zA-Z]{2,3}$/.test(ipaddress)
        //Disable Apply if local endpoints IP is empty for group
        if (this.props.group_id && ipaddress === '') {
            this.setState({
                showError: true,
            });
            return (false);
        }
        if (ipaddress !== '') {
            if (DomainName === false) {
                if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
                    return (true);
                }
                else {
                    const element = document.getElementById(name);
                    if (element) {
                        element.style.display = "block";
                        this.setState({
                            showError: true,
                        });
                    }
                }
                return (false);
            }
            else {
                const element = document.getElementById(name);
                if (element) {
                    element.style.display = "none";
                    this.setState({
                        showError: false,
                    });
                    return (true);
                }
            }
        }
        // Error message added if no Local Endpoint IP is selected
        else {
            if (
                (
                    this.state.deviceData?.device_B?.endpoint_details === null ||
                    this.state.deviceData?.device_B?.endpoint_details === 'null' ||
                    this.state.deviceData?.device_B?.endpoint_details === ''
                ) &&
                (
                    this.state.deviceData?.device_A?.endpoint_details === null ||
                    this.state.deviceData?.device_A?.endpoint_details === 'null' ||
                    this.state.deviceData?.device_A?.endpoint_details === ''
                )
            ) {
                const element = document.getElementById("point");
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showError: true,
                    });
                    return (false);
                }
            }
            else {
                const element = document.getElementById("point");
                if (element) {
                    element.style.display = "none";
                    this.setState({
                        showError: false,
                    });
                    return (true);
                }
            }
        }
    };

    handleAvailablePorts = (e, port) => {
        if (port === 'PortA') {
            this.setState({
                selectedAvailablePortA: e && e.value ? e.value : ''
            })
        }
        else {
            this.setState({
                selectedAvailablePortB: e && e.value ? e.value : ''
            })
        }
    };

    setValue = (e) => {
        this.removeError(e);
        //EMGT-404-EM - Able to apply the Wireguard Configuration with Invalid value in local end point IP
        const name = e.target.name;
        if (
            (
                name === 'device_B_endpoint' ||
                name === 'device_A_endpoint'
            ) &&
            e.target.value !== ""
        ) {
            const element = document.getElementById("point");
            if (element) {
                element.style.display = 'none';
                this.setState({
                    showError: false
                })
            }
        }
        let value = '';
        let data = JSON.parse(JSON.stringify(this.state.deviceData));
        if (e.target.name === 'tunnel_keep_alive_status') {
            data.settings.tunnel_keep_alive_status = e.target.checked === true ? 1 : 0;
            value = e.target.checked === true ? 1 : 0;
            this.setState({
                [name]: value,
                deviceData: data,
                is_change_in_tunnel: true
            });
        } else if (e.target.name === 'tunnel_keep_alive_interval') {
            data.settings.tunnel_keep_alive_interval = parseInt(e.target.value);
            this.setState({
                [name]: value,
                deviceData: data,
                is_change_in_tunnel: true
            });
            if (e.target.value === '' || parseInt(e.target.value) > 120 || parseInt(e.target.value) < 1) {
                const element = document.getElementById(name);
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
        }
        else {
            value = e.target.value;
            if (name === 'device_A_endpoint') {
                data.device_A.endpoint_details = value;
            } else if (name === 'device_B_endpoint') {
                data.device_B.endpoint_details = value;
            }
            if (name === 'device_A_tunnel_link') {
                data.device_A.tunnel_link = value;
            } else if (name === 'device_B_tunnel_link') {
                data.device_B.tunnel_link = value;
            }
            // Issue fixed in tunnel create button
            if (name === 'tunnel_name') {
                if (value.length > 32) {
                    const element = document.getElementById(name);
                    element.style.display = "block";
                    element.innerHTML = "Enter name up to 32 characters";
                    this.setState({
                        showError: true,
                    });
                } else {
                    data.settings.tunnel_name = value;
                }
            }
            if (name === "private_ip") {
                data.device_B.private_ip = value;
            }
            this.setState({
                [name]: value,
                deviceData: data,
                tunnelName: true,
                is_change_in_tunnel: true
            });
        }
    };

    removeError = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'none';
            this.setState({
                showError: false
            })
        }
    };

    setEndpoint = (e) => {
        const value = e.target.dataset.value;
        const name = e.target.dataset.name;
        let data = JSON.parse(JSON.stringify(this.state.deviceData));
        if (name === 'device_A_endpoint') {
            data.device_A.endpoint_details = value;
            // EMGT-404-EM - Able to apply the Wireguard Configuration with Invalid value in local end point IP
            const element = document.getElementById("device_A_endpoint");
            if (element) {
                element.style.display = 'none';
            }
            const element1 = document.getElementById("point");
            if (element1) {
                element1.style.display = 'none';
                this.setState({
                    showError: false
                })
            }
        } else if (name === 'device_B_endpoint') {
            data.device_B.endpoint_details = value;
            const element = document.getElementById("device_B_endpoint");
            if (element) {
                element.style.display = 'none';
            }
            const element1 = document.getElementById("point");
            if (element1) {
                element1.style.display = 'none';
                this.setState({
                    showError: false
                })
            }
        }
        this.setState({
            [name]: value,
            //EMGT-272 : EM - Wireguard Configuration field validation
            showError: false,
            deviceData: data
        });
    };

    checkError = () => {
        // EMGT-404-EM - Able to apply the Wireguard Configuration with Invalid value in local end point IP
        let result = false;
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;") {
                result = true;
            }
        }
        if (this.props.applying) {
            result = true;
        }
        if (
            this.state.template_name === "" ||
            this.state.template_type === ""
        ) {
            result = true;
        }
        if (!this.props.apply && !this.props.edit) {
            if (this.state.selectedDeviceData.length < 1) {
                result = true;
            }
        }
        if (
            this.state.showError || this.state.show || this.state.showLink ||
            (
                !this.props?.group_id && (this.state.selectedAvailablePortA === '' ||
                    this.state.selectedAvailablePortB === ''
                )
            )
        ) {
            result = true;
        }
        if
            (
            this.props?.group_id === "" ||
            this.props?.group_id === null ||
            this.props?.group_id === undefined
        )
            if (
                this.state.deviceData?.device_B?.public_key === "" ||
                this.state.deviceData?.device_B?.private_ip === ""
            ) {
                result = true;
            }
        if (
            this.state.deviceData?.device_A?.load_balance === '1' &&
            (
                this.state.deviceData?.device_A?.tunnel_link === undefined ||
                this.state.deviceData?.device_A?.tunnel_link === ''
            )
        ) { result = true; }
        if (
            this.state.deviceData?.device_B?.load_balance === '1' &&
            (
                this.state.deviceData?.device_B?.tunnel_link === undefined ||
                this.state.deviceData?.device_B?.tunnel_link === ''
            )
        ) {
            result = true;
        }
        // LONG-3175 : Able to apply Wireguard Configuration template before configuration    
        // if (this.state.deviceData?.device_A?.endpoint_details === "" && this.state.deviceData?.device_B?.endpoint_details === "")
        //     result = true;
        if (this.props?.disableApply) {
            result = true;
        }
        if (
            this.props?.group_id &&
            this.state.deviceData?.device_B?.endpoint_details === ""
        ) { result = true }
        if (
            this.state?.isExternalEndPoint &&
            this.state.deviceData?.device_A?.endpoint_details === ""
        ) { result = true }
        return result;
    };

    getNetworkname = (value, device) => {
        let network = "";
        let networks = device === 'device_A' ? this.state.deviceA_networks : this.state.deviceB_networks;
        networks?.map((item) => {
            if (item.network_zone === 'wan' && item.networkName === value) {
                network = item.name;
            }
        })
        return network;
    };

    debounceApply = debounce((fn, event) => {
        fn(event)
    }, 500);

    applyTemplate = () => {
        let { template_schema_json } = this.state;
        let data = JSON.parse(JSON.stringify(this.state.deviceData));

        data.device_A.public_key =
            (this.state.device_A_publicKey === undefined)
                ? data.device_A.public_key
                : this.state.device_A_publicKey;
        data.device_A.endpoint_details =
            (this.state.device_A_endpoint === undefined)
                ? data.device_A.endpoint_details
                : this.state.device_A_endpoint;
        data.device_A.allowed_ips =
            (Common.getModels(this.state.deviceData.device_A.device_model) === "CHIMAY"
                || Common.getModels(this.state.deviceData.device_A.device_model) === "vCPE")
                ? ["0.0.0.0/0"]
                : data.device_A.allowed_ips;
        data.device_A.add_zone_in_firewall = this.state.device_A_zone ? true : false;
        data.device_A.add_bgp_in_tunnel =
            !(Common.getModels(this.state.deviceData?.device_A?.device_model) === "CHIMAY"
                || Common.getModels(this.state.deviceData?.device_A?.device_model) === "vCPE")
                ? false
                : this.state.BGP_device_A_zone ? true : false;
        data.device_A.own_port =
            this.state.selectedAvailablePortA
                ? this.state.selectedAvailablePortA
                : data.device_A.own_port;

        data.device_B.public_key =
            (this.state.device_B_publicKey === undefined)
                ? data.device_B.public_key
                : this.state.device_B_publicKey;
        data.device_B.endpoint_details =
            (this.state.device_B_endpoint === undefined)
                ? data.device_B.endpoint_details
                : this.state.device_B_endpoint;

        if (!this.state.isExternalEndPoint) {
            data.device_B.allowed_ips =
                (Common.getModels(this.state.deviceData.device_B.device_model) === "CHIMAY"
                    || Common.getModels(this.state.deviceData.device_B.device_model) === "vCPE")
                    ? ["0.0.0.0/0"]
                    : data.device_B.allowed_ips
            data.device_B.add_zone_in_firewall = this.state.device_B_zone ? true : false;
            data.device_B.add_bgp_in_tunnel =
                !(Common.getModels(this.state.deviceData?.device_B?.device_model) === "CHIMAY"
                    || Common.getModels(this.state.deviceData?.device_B?.device_model) === "vCPE")
                    ? false
                    : this.state.BGP_device_B_zone ? true : false;
        }

        data.device_B.own_port =
            this.state.selectedAvailablePortB
                ? this.state.selectedAvailablePortB
                : data.device_B.own_port;

        data.settings.tunnel_name =
            this.state.tunnel_name
                ? this.state.tunnel_name
                : data.settings.tunnel_name;
        data.settings.is_change_in_tunnel = this.state.is_change_in_tunnel;
        if (data.settings.tunnel_keep_alive_status === 0)
            data.settings.tunnel_keep_alive_interval = 0;
        data.settings.tunnel_type = "Hub & Spoke";

        delete data.device_A['used_tunnel_link_interface'];
        delete data.device_B['used_tunnel_link_interface'];
        delete data.device_A['used_tunnel_endpoint'];
        delete data.device_B['used_tunnel_endpoint'];

        template_schema_json = data;
        this.props.dispatch({
            type: Action.SET_APPLY_TEMPLATE,
        });
        this.props.applyTemplate(template_schema_json);
    };

    deleteSelectedDevice = () => {
        let newList = [...this.state.selectedDeviceData];
        if (this.state.multipleSelect.length > 0) {
            this.state.multipleSelect.map((item) => {
                newList?.splice(newList?.indexOf(item), 1);
            });
        }
        this.setState({
            selectedDeviceData: newList,
            toggledClearSelectedDeviceRows: !this.state.toggledClearSelectedDeviceRows,
            multipleSelect: "",
            adding: false
        });
    };

    checkModel = (model) => {
        let device = Common.getModels(model);
        let disabled = false;
        if (device === "Moretti_Model" || device === "Duvel_Model" || device === 'Stormbreaker' || device === 'Firestorm')
            disabled = true;
        return disabled;
    };

    handleExternalEndpointPublicKey = (e) => {
        const element = document.getElementById("external_public_key");
        if (element) {
            element.style.display = "none";
            this.setState({
                showError: false,
            });
        }
        const { value } = e.target;
        this.setState(prevState => ({
            deviceData: {
                ...prevState.deviceData,
                device_B: {
                    ...prevState.deviceData.device_B,
                    public_key: value
                }
            }
        }));
    };

    validatePublicKey = (e) => {
        const regex = /^\S{32,}$/;
        const isValid = regex.test(e.target.value);
        if (!isValid) {
            const element = document.getElementById("external_public_key");
            if (element) {
                element.style.display = "block";
                this.setState({
                    showError: true,
                });
                return (false);
            }
        }
        else {
            const element = document.getElementById("external_public_key");
            if (element) {
                element.style.display = "none";
                this.setState({
                    showError: false,
                });
                return (true);
            }
        }
    };

    validateIPaddressWithSubnetMask = (e) => {
        if (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-9]|0[0-9]|1[0-9]|2[0-9]|3[0-2])$/.test(e))
            return false;
        return true;
    };

    handleAllowedIpDeviceA = (e, key) => {
        if (e.key === 'Enter' || key === "blur") {
            const value = e.target.value.trim();
            const element = document.getElementById(`device_A_allowedIPs`);
            if (element) {
                if (value !== "") {
                    if (
                        this.validateIPaddressWithSubnetMask(value) ||
                        !value.includes('/') ||
                        value.split('/')[1] < 8 ||
                        value.split('/')[1] > 32
                    ) {
                        element.style.display = 'block';
                        element.innerHTML = 'Invalid IP';
                        this.setState({
                            showError: true
                        });
                    } else if (this.state.deviceData?.device_A?.allowed_ips?.includes(value)) {
                        element.style.display = 'block';
                        element.innerHTML = 'Entered IP already added!';
                        this.setState({
                            showError: true
                        });
                    } else {
                        element.style.display = 'none';
                        element.innerHTML = "";
                        let { deviceData } = this.state;
                        deviceData.device_A.allowed_ips = [...deviceData?.device_A?.allowed_ips, value];
                        this.setState({
                            deviceData,
                            checkValue: false,
                            showError: false
                        });
                        $("input[name=device_A_allowedIPs]").val("");
                    }
                } else {
                    element.style.display = 'none';
                    element.innerHTML = "";
                    this.setState({
                        showError: false
                    });
                }
            }
        }
    };

    setDeviceAAllowedIP = (e) => {
        const value = e.target.value;
        const element = document.getElementById(`device_A_allowedIPs`);
        if (element) {
            if (!this.state.deviceData?.device_A?.allowed_ips?.includes(value)) {
                element.style.display = 'none';
                element.innerHTML = "";
                let { deviceData } = this.state;
                deviceData.device_A.allowed_ips = [...deviceData?.device_A?.allowed_ips, value];
                this.setState({
                    deviceData,
                    checkValue: false,
                    showError: false
                });
                $("input[name=device_A_allowedIPs]").val("");
            }
        }
    };

    handleAllowedIpDeviceB = (e, key) => {
        if (e.key === 'Enter' || key === "blur") {
            const value = e.target.value.trim();
            const element = document.getElementById(`device_B_allowedIPs`);
            if (element) {
                if (value !== "") {
                    if (
                        this.validateIPaddressWithSubnetMask(value) ||
                        !value.includes('/') ||
                        value.split('/')[1] < 8 ||
                        value.split('/')[1] > 32
                    ) {
                        element.style.display = 'block';
                        element.innerHTML = 'Invalid IP';
                        this.setState({
                            showError: true
                        });
                    } else if (this.state.deviceData?.device_B?.allowed_ips?.includes(value)) {
                        element.style.display = 'block';
                        element.innerHTML = 'Entered IP already added!';
                        this.setState({
                            showError: true,
                        });
                    } else {
                        element.style.display = 'none';
                        element.innerHTML = "";
                        let { deviceData } = this.state;
                        deviceData.device_B.allowed_ips = [...deviceData?.device_B?.allowed_ips, value];
                        this.setState({
                            deviceData,
                            checkValue: false,
                            showError: false
                        });
                        $("input[name=device_B_allowedIPs]").val("");
                    }
                } else {
                    element.style.display = 'none';
                    element.innerHTML = "";
                    this.setState({
                        showError: false
                    });
                }
            }
        }
    };

    setDeviceBAllowedIP = (e) => {
        const value = e.target.value;
        const element = document.getElementById(`device_B_allowedIPs`);
        if (element) {
            if (!this.state.deviceData?.device_B?.allowed_ips?.includes(value)) {
                element.style.display = 'none';
                element.innerHTML = "";
                let { deviceData } = this.state;
                deviceData.device_B.allowed_ips = [...deviceData?.device_B?.allowed_ips, value];
                this.setState({
                    deviceData,
                    checkValue: false,
                    showError: false
                });
                $("input[name=device_B_allowedIPs]").val("");
            }
        }
    };

    render() {
        return (
            <div>
                <div className="hub-spoke">
                    {
                        (
                            !this.props.info &&
                            !this.props.view &&
                            !this.props.reapply &&
                            !this.props.edit &&
                            !this.props.apply
                        )
                        &&
                        <div className="page-sub-title">
                            <div>
                                <strong>Device</strong>
                                <img
                                    src="/images/svg-icons/add.svg"
                                    className="add-btn ml-2"
                                    onClick={() => {
                                        this.setState({
                                            devicesTemplate: true
                                        }, () => this.getDeviceByModel());
                                    }}
                                    alt=""
                                />
                            </div>
                            <div className="device-action">
                                <span className="text-muted">
                                    {this.state.selectedDeviceData?.length} devices added
                                </span>
                                <span className={(this.state.selectedDeviceData?.length > 0 && this.state.multipleSelect?.length > 0) ? "icon-box" : "icon-box disabled"}>
                                    <img
                                        src="/images/svg-icons/delete.svg"
                                        alt=""
                                        onClick={(e) => this.deleteSelectedDevice(e)}
                                    />
                                </span>
                            </div>
                        </div>
                    }
                    {
                        this.props?.create
                        &&
                        <>
                            {
                                (this.state.selectedDeviceData?.length > 0)
                                &&
                                <>
                                    <div className="device-table">
                                        <DataTable
                                            columns={this.state.deviceListColumnsDiff}
                                            data={this.state.selectedDeviceData}
                                            selectableRows
                                            highlightOnHover
                                            fixedHeader
                                            onSelectedRowsChange={(data) => this.multipleSelect1(data)}
                                            clearSelectedRows={this.state.toggledClearSelectedDeviceRows}
                                        />
                                    </div>
                                </>
                            }
                        </>
                    }
                    {
                        this.props?.info
                        &&
                        <>
                            {
                                (this.state.selectedDeviceData?.length > 0)
                                &&
                                <>
                                    <DataTable
                                        columns={this.state.deviceListColumnsDiff}
                                        data={this.state.selectedDeviceData}
                                        highlightOnHover
                                        fixedHeader
                                    />
                                </>
                            }
                        </>
                    }
                    {
                        this.props.apply
                        &&
                        <>
                            <div>
                                <div className="page-sub-title border-bottom">
                                    <strong>{this.state.deviceData?.device_A?.device_name}</strong>
                                </div>
                                <div className="pl-2 pr-2 mt-3">
                                    <div className="d-flex row mb-3">
                                        <div className="col-md-6 d-flex align-items-center">
                                            {
                                                (this.props.view || this.props.reapply)
                                                    ? <input
                                                        type="checkbox"
                                                        style={this.props.view ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        checked={this.state.device_A_zone}
                                                        disabled={Common.getModels(this.state.deviceData?.device_A?.device_model) === "Moretti_Model" ||
                                                            Common.getModels(this.state.deviceData?.device_A?.device_model) === "Duvel_Model"}
                                                    />
                                                    : <input
                                                        type="checkbox"
                                                        style={this.props.view ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        checked={this.state.device_A_zone}
                                                        disabled={Common.getModels(this.state.deviceData?.device_A?.device_model) === "Moretti_Model" ||
                                                            Common.getModels(this.state.deviceData?.device_A?.device_model) === "Duvel_Model"}
                                                        onChange={(e) => this.handleChange(e, "device_A_zone")}
                                                    />
                                            }
                                            <label className="ml-2 mb-0">Add Interface to Zone</label>
                                            <div
                                                className="tooltip-c"
                                                data-toggle="tooltip"
                                                data-placement="right"
                                                title="Add Interface to Zone"
                                            >
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            {
                                                (this.props.view || this.props.reapply)
                                                    ? <input
                                                        type="checkbox"
                                                        style={this.props.view ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        checked={this.state.BGP_device_A_zone}
                                                        disabled={Common.getModels(this.state.deviceData?.device_A?.device_model) === "Moretti_Model" ||
                                                            Common.getModels(this.state.deviceData?.device_A?.device_model) === "Duvel_Model"}
                                                    />
                                                    : !(
                                                        Common.getModels(this.state.deviceData?.device_A?.device_model) === "CHIMAY" ||
                                                        Common.getModels(this.state.deviceData?.device_A?.device_model) === "vCPE"
                                                    )
                                                        ? <input
                                                            type="checkbox"
                                                            style={{ cursor: "not-allowed" }}
                                                            checked={false}
                                                            disabled
                                                        />
                                                        : <input
                                                            type="checkbox"
                                                            style={this.props.view ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                            checked={this.state.BGP_device_A_zone}
                                                            disabled={Common.getModels(this.state.deviceData?.device_A?.device_model) === "Moretti_Model" ||
                                                                Common.getModels(this.state.deviceData?.device_A?.device_model) === "Duvel_Model"}
                                                            onChange={(e) => this.handleChange(e, "BGP_device_A_zone")}
                                                        />}
                                            <label className="ml-2 mb-0">Enable BGP</label>
                                            <div
                                                className="tooltip-c"
                                                data-toggle="tooltip"
                                                data-placement="right"
                                                title="Enable BGP"
                                            >
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            this.props.group_id
                                            &&
                                            <div className="alert alert-secondary" role="alert">
                                                Note:
                                                <strong>Public Key</strong>
                                                and
                                                <strong>Listen Port</strong>
                                                will be selected Dynamically
                                            </div>
                                        }
                                        {
                                            !this.props.group_id
                                            &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Public Key
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="The key used to encrypt data. Each peer has its own public key"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="device_A_publicKey"
                                                        // onChange={(e) => this.setValue(e)}
                                                        minLength="35"
                                                        maxLength="50"
                                                        disabled
                                                        title={this.state.deviceData?.device_A?.public_key}
                                                        //Long-1789 Public key is not updating in Create Tunnel page when changing the device in select device option	
                                                        value={this.state.deviceData?.device_A?.public_key}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>}
                                        <div className="col-md-6">
                                            <div className="form-group with-arrow">
                                                <label>Local Endpoint IP
                                                    {/* Local Endpoint IP mandatory for device group only */}
                                                    {this.props.group_id && <span className="red-txt">*</span>}
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="The public-facing internet IP address of the local device"
                                                    >
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                {
                                                    (this.props.view || this.props.reapply)
                                                        ?
                                                        <>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                className="form-control"
                                                                value={
                                                                    (this.state.deviceData?.device_B?.endpoint_details === null ||
                                                                        this.state.deviceData?.device_B?.endpoint_details === 'null')
                                                                        ? ''
                                                                        : this.state.deviceData?.device_B?.endpoint_details
                                                                }
                                                            />
                                                        </> :
                                                        <>
                                                            <input
                                                                type="text"
                                                                name="device_B_endpoint"
                                                                onChange={(e) => this.setValue(e)}
                                                                onBlur={(e) => this.validateIPaddress(e.target.value, e.target.name)}
                                                                value={
                                                                    (this.state.deviceData?.device_B?.endpoint_details === null ||
                                                                        this.state.deviceData?.device_B?.endpoint_details === 'null')
                                                                        ? ''
                                                                        : this.state.deviceData?.device_B?.endpoint_details
                                                                }
                                                                className="form-control"
                                                            />
                                                            <i className="drop-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                            <div className="dropdown-menu max-height-200" aria-labelledby="dropdownMenuButton">
                                                                <ul>
                                                                    {
                                                                        this.state.deviceData?.device_B?.available_endpoint_details.map((item) => {
                                                                            if (item)
                                                                                return (
                                                                                    <li
                                                                                        data-name="device_B_endpoint"
                                                                                        data-value={item}
                                                                                        onClick={(e) => this.setEndpoint(e)}
                                                                                    >{item}</li>
                                                                                )
                                                                        })}
                                                                </ul>
                                                            </div>
                                                        </>}
                                                <div className="error-txt" id="device_B_endpoint">Invalid value</div>
                                            </div>
                                        </div>
                                        {
                                            !this.props.group_id
                                            &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Listen Port
                                                        <span className="red-txt">*</span>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Listen port for data tunnel"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    {/* EMGT-253-EM- Page break when reapplying Wireguard Configuration */}
                                                    {
                                                        (
                                                            this.props.edit ||
                                                            this.props.view ||
                                                            this.props.info ||
                                                            this.props.reapply
                                                        )
                                                            ? <input
                                                                type="text"
                                                                disabled
                                                                className="form-control"
                                                                value={this.state.deviceData?.device_A.own_port}
                                                            />
                                                            //EMGT-253-Page break when reapplying Wireguard Configuration
                                                            : <Select
                                                                options={this.getOptions('deviceA_port')}
                                                                isClearable
                                                                isSearchable
                                                                className="custom-dropdown w-105"
                                                                classNamePrefix="react-select-container"
                                                                value={
                                                                    this.getOptions('deviceA_port')?.filter(option =>
                                                                        option?.label === this.state.selectedAvailablePortA)
                                                                }
                                                                onChange={(e) => this.handleAvailablePorts(e, 'PortA')}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                {
                                                    this.props.view
                                                        ? <>
                                                            <label>WAN Binding
                                                                {
                                                                    this.state.deviceData?.device_A?.load_balance === '1'
                                                                    &&
                                                                    <span className="red-txt">*</span>
                                                                }
                                                                <div
                                                                    className="tooltip-c"
                                                                    data-toggle="tooltip"
                                                                    data-placement="right"
                                                                    title="Select the WAN interface to be mapped with the tunnel."
                                                                >
                                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                                </div>
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                name="device_A_tunnel_link"
                                                                value={this.state.deviceData?.device_A?.tunnel_link}
                                                                disabled
                                                            >
                                                                <option value="">Select Interface</option>
                                                            </select>
                                                        </>
                                                        : <>
                                                            <label>WAN Binding
                                                                {
                                                                    this.state.deviceData?.device_A?.load_balance === '1'
                                                                    &&
                                                                    <span className="red-txt">*</span>
                                                                }
                                                                <div
                                                                    className="tooltip-c"
                                                                    data-toggle="tooltip"
                                                                    data-placement="right"
                                                                    title="Select the WAN interface to be mapped with the tunnel."
                                                                >
                                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                                </div>
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                name="device_A_tunnel_link"
                                                                value={this.state.deviceData?.device_A?.tunnel_link}
                                                                disabled={this.state.deviceData?.device_A?.load_balance !== '1'}
                                                                onChange={(e) => this.setValue(e)}>
                                                                <option value="">Select Interface</option>
                                                                {
                                                                    this.state.deviceA_networks && this.state.deviceA_networks.length > 0 && this.state.deviceA_networks.map((item) => {
                                                                        if (item.network_zone === 'wan')
                                                                            return (
                                                                                <option
                                                                                    value={item.networkName}
                                                                                    disabled={
                                                                                        this.props.edit
                                                                                            ? this.props.usedInterfaces_deviceA?.includes(item.networkName)
                                                                                            : this.state.deviceData?.device_A?.used_tunnel_link_interface?.includes(item.networkName)
                                                                                    }
                                                                                >
                                                                                    {item.name}
                                                                                </option>
                                                                            )
                                                                    })
                                                                }
                                                            </select>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            {/* Hiding remote allowed ips for Chimay and CBR devices */}
                                            {
                                                !(
                                                    Common.getModels(this.state.deviceData?.device_A?.device_model) === "CHIMAY" ||
                                                    Common.getModels(this.state.deviceData?.device_A?.device_model) === "vCPE"
                                                ) &&
                                                <>
                                                    <div className="form-group">
                                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                                            <label className="mb-0">Remote Allowed IPs
                                                                <div
                                                                    className="tooltip-c"
                                                                    data-toggle="tooltip"
                                                                    data-placement="right"
                                                                    title="These are the CIDR formatted remote subnets of the opposite side of the tunnel">
                                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="allowedip-box with-arrow">
                                                            {
                                                                this.state.deviceData?.device_A?.allowed_ips?.map((item) => {
                                                                    if (item !== null && item !== 'null' && item !== '') {
                                                                        return (
                                                                            <div className="tag-item" key={item}>
                                                                                {item}
                                                                                <button
                                                                                    type="button"
                                                                                    className="button"
                                                                                    disabled={this.props.view}
                                                                                    onClick={() => {
                                                                                        let { deviceData } = this.state;
                                                                                        deviceData.device_A.allowed_ips = deviceData.device_A.allowed_ips.filter(val => String(val) !== String(item));
                                                                                        this.setState({
                                                                                            deviceData
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <img src="/images/svg-icons/cancel.svg" alt="" />
                                                                                </button>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })
                                                            }
                                                            <input
                                                                type="text"
                                                                name="device_A_allowedIPs"
                                                                onKeyDown={(e) => this.handleAllowedIpDeviceA(e)}
                                                                onBlur={(e) => this.handleAllowedIpDeviceA(e, "blur")}
                                                                autoComplete="off"
                                                            />
                                                            <i
                                                                className="drop-arrow"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false">
                                                            </i>
                                                            <div
                                                                className="dropdown-menu max-height-200"
                                                                aria-labelledby="dropdownMenuButton"
                                                            >
                                                                <ul>
                                                                    {this.state.deviceData?.device_B?.available_allowed_ips
                                                                        ?.filter(item => String(item) !== "0.0.0.0/0")
                                                                        ?.map((item) => {
                                                                            if (item) {
                                                                                return (
                                                                                    <li
                                                                                        key={item}
                                                                                        data-name="device_A_allowedIPs"
                                                                                        data-value={item}
                                                                                        onClick={(e) => this.setDeviceAAllowedIP(e)}
                                                                                    >
                                                                                        {item}
                                                                                    </li>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="error-txt" id="device_A_allowedIPs">Invalid value</div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* // Error message added if no Local Endpoint IP is selected */}
                            <div className="error-txt" id="point">Please enter atleast one of the Local Endpoint IPs</div>
                            <div>
                                <div className="page-sub-title border-bottom">
                                    <strong>{
                                        this.state.isExternalEndPoint
                                            ? "External Endpoint"
                                            : this.props.group_id
                                                ? this.props.group_name
                                                : this.state.deviceData?.device_B?.device_name
                                    }
                                    </strong>
                                </div>
                                <div className="pl-2 pr-2 mt-3">
                                    {!this.state.isExternalEndPoint &&
                                        <div className="d-flex row mb-3">
                                            <div className="col-md-6 d-flex align-items-center">
                                                {(this.props.view || this.props.reapply)
                                                    ? <input
                                                        type="checkbox"
                                                        style={this.props.view ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        checked={this.state.device_B_zone}
                                                        disabled={Common.getModels(this.state.deviceData?.device_B?.device_model) === "Moretti_Model" ||
                                                            Common.getModels(this.state.deviceData?.device_B?.device_model) === "Duvel_Model"}
                                                    />
                                                    : <input
                                                        type="checkbox"
                                                        style={this.props.view ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        checked={this.state.device_B_zone}
                                                        disabled={Common.getModels(this.state.deviceData?.device_B?.device_model) === "Moretti_Model" ||
                                                            Common.getModels(this.state.deviceData?.device_B?.device_model) === "Duvel_Model"}
                                                        onChange={(e) => this.handleChange(e, "device_B_zone")}
                                                    />}
                                                <label className="ml-2 mb-0">Add Interface to Zone</label>
                                                <div
                                                    className="tooltip-c"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="Add Interface to Zone">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center">
                                                {(this.props.view || this.props.reapply)
                                                    ? <input
                                                        type="checkbox"
                                                        style={this.props.view ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        checked={this.state.BGP_device_B_zone}
                                                        disabled={Common.getModels(this.state.deviceData?.device_B?.device_model) === "Moretti_Model" ||
                                                            Common.getModels(this.state.deviceData?.device_B?.device_model) === "Duvel_Model"}
                                                    />
                                                    : !(
                                                        Common.getModels(this.state.deviceData?.device_B?.device_model) === "CHIMAY" ||
                                                        Common.getModels(this.state.deviceData?.device_B?.device_model) === "vCPE"
                                                    )
                                                        ? <input
                                                            type="checkbox"
                                                            style={{ cursor: "not-allowed" }}
                                                            checked={false}
                                                            disabled
                                                        />
                                                        : <input
                                                            type="checkbox"
                                                            style={this.props.view ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                            checked={this.state.BGP_device_B_zone}
                                                            disabled={Common.getModels(this.state.deviceData?.device_B?.device_model) === "Moretti_Model" ||
                                                                Common.getModels(this.state.deviceData?.device_B?.device_model) === "Duvel_Model"}
                                                            onChange={(e) => this.handleChange(e, "BGP_device_B_zone")}
                                                        />}
                                                <label className="ml-2 mb-0">Enable BGP</label>
                                                <div
                                                    className="tooltip-c"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="Enable BGP">
                                                    <img src="/images/svg-icons/info.svg" alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !this.state.isExternalEndPoint
                                        &&
                                        <div className="row">
                                            {!this.props.group_id &&
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Public Key
                                                            <div
                                                                className="tooltip-c"
                                                                data-toggle="tooltip"
                                                                data-placement="right"
                                                                title="The key used to encrypt data. Each peer has its own public key"
                                                            >
                                                                <img src="/images/svg-icons/info.svg" alt="" />
                                                            </div>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="device_B_publicKey"
                                                            minLength="35"
                                                            maxLength="50"
                                                            disabled
                                                            title={this.state.deviceData?.device_B?.public_key}
                                                            value={this.state.deviceData?.device_B?.public_key}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.props.group_id
                                                &&
                                                <div className="alert alert-secondary" role="alert">
                                                    Note:
                                                    <strong>Public Key</strong>
                                                    and
                                                    <strong>Listen Port</strong>
                                                    will be selected Dynamically
                                                </div>
                                            }
                                            {
                                                !this.props.group_id
                                                &&
                                                <div className="col-md-6">
                                                    <div className="form-group with-arrow">
                                                        <label>Local Endpoint IP
                                                            <div
                                                                className="tooltip-c"
                                                                data-toggle="tooltip"
                                                                data-placement="right"
                                                                title="The public-facing internet IP address of the local device"
                                                            >
                                                                <img src="/images/svg-icons/info.svg" alt="" />
                                                            </div>
                                                        </label>
                                                        {
                                                            (this.props.view || this.props.reapply)
                                                                ? <>
                                                                    <input
                                                                        type="text"
                                                                        disabled
                                                                        className="form-control"
                                                                        value={(this.state.deviceData?.device_A?.endpoint_details === null ||
                                                                            this.state.deviceData?.device_A?.endpoint_details === 'null') ? '' :
                                                                            this.state.deviceData?.device_A?.endpoint_details} />
                                                                </>
                                                                : <>
                                                                    <input
                                                                        type="text"
                                                                        name="device_A_endpoint"
                                                                        onChange={(e) => this.setValue(e)}
                                                                        onBlur={(e) => this.validateIPaddress(e.target.value, e.target.name)}
                                                                        value={
                                                                            (this.state.deviceData?.device_A?.endpoint_details === null
                                                                                || this.state.deviceData?.device_A?.endpoint_details === 'null')
                                                                                ? ''
                                                                                : this.state.deviceData?.device_A?.endpoint_details
                                                                        }
                                                                        className="form-control" />
                                                                    <i className="drop-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                    <div className="dropdown-menu max-height-200" aria-labelledby="dropdownMenuButton">
                                                                        <ul>
                                                                            {this.state.deviceData?.device_A?.available_endpoint_details?.map((item) => {
                                                                                if (item)
                                                                                    return (
                                                                                        <li
                                                                                            data-name="device_A_endpoint"
                                                                                            data-value={item}
                                                                                            onClick={(e) => this.setEndpoint(e)}
                                                                                        >{item}</li>
                                                                                    )
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </>
                                                        }
                                                        {/* EMGT-404-EM - Able to apply the tunnel configuration with Invalid value in local end point IP */}
                                                        <div className="error-txt" id="device_A_endpoint">Invalid value</div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !this.props.group_id
                                                &&
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Listen Port
                                                            <span className="red-txt">*</span>
                                                            <div
                                                                className="tooltip-c"
                                                                data-toggle="tooltip"
                                                                data-placement="right"
                                                                title="Listen port for data tunnel"
                                                            >
                                                                <img src="/images/svg-icons/info.svg" alt="" />
                                                            </div>
                                                        </label>
                                                        {/* EMGT-253-EM- Page break when reapplying tunnel configuration */}
                                                        {
                                                            (
                                                                this.props.edit ||
                                                                this.props.view ||
                                                                this.props.info ||
                                                                this.props.reapply
                                                            )
                                                                ? <input
                                                                    type="text"
                                                                    disabled
                                                                    className="form-control"
                                                                    value={this.state.deviceData?.device_B?.own_port}
                                                                />
                                                                //EMGT-253-Page break when reapplying Tunnel Configuration
                                                                : <Select
                                                                    options={this.getOptions('deviceB_port')}
                                                                    isClearable
                                                                    isSearchable
                                                                    className="custom-dropdown w-105"
                                                                    classNamePrefix="react-select-container"
                                                                    value={
                                                                        this.getOptions('deviceB_port')?.filter(option =>
                                                                            option?.label === this.state.selectedAvailablePortB)
                                                                    }
                                                                    onChange={(e) => this.handleAvailablePorts(e, 'PortB')}
                                                                />
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    {
                                                        this.props.view
                                                            ? <>
                                                                <label>WAN Binding
                                                                    {this.state.deviceData?.device_B?.load_balance === '1' &&
                                                                        <span className="red-txt">*</span>}
                                                                    <div
                                                                        className="tooltip-c"
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Select the WAN interface to be mapped with the tunnel.">
                                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                                    </div>
                                                                </label>
                                                                <select
                                                                    className="form-control"
                                                                    name="device_B_tunnel_link"
                                                                    value={(this.state.deviceData?.device_B?.tunnel_link)}
                                                                    disabled
                                                                >
                                                                    <option value="">Select Interface</option>
                                                                </select>
                                                            </>
                                                            : <>
                                                                <label>WAN Binding
                                                                    {
                                                                        this.state.deviceData?.device_B?.load_balance === '1'
                                                                        &&
                                                                        <span className="red-txt">*</span>
                                                                    }
                                                                    <div
                                                                        className="tooltip-c"
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Select the WAN interface to be mapped with the tunnel."
                                                                    >
                                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                                    </div>
                                                                </label>
                                                                <select
                                                                    className="form-control"
                                                                    name="device_B_tunnel_link"
                                                                    value={(this.state.deviceData?.device_B?.tunnel_link)}
                                                                    disabled={this.state.deviceData?.device_B?.load_balance !== '1'}
                                                                    onChange={(e) => this.setValue(e)}>
                                                                    <option value="">Select Interface</option>
                                                                    {
                                                                        this.props.group_id
                                                                            ? (
                                                                                this.state.networkViewData &&
                                                                                this.state.networkViewData.length > 0 &&
                                                                                this.state.networkViewData.map((item) => {
                                                                                    if (item.network_zone === 'wan') {
                                                                                        return (
                                                                                            <option
                                                                                                value={item.networkName}
                                                                                                disabled={this.props.usedInterfaces_deviceB.includes(item.networkName)}
                                                                                            >
                                                                                                {item.name}
                                                                                            </option>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            )
                                                                            : (
                                                                                this.state.deviceB_networks &&
                                                                                this.state.deviceB_networks.length > 0 &&
                                                                                this.state.deviceB_networks.map((item) => {
                                                                                    if (item.network_zone === 'wan')
                                                                                        return (
                                                                                            <option
                                                                                                value={item.networkName}
                                                                                                disabled={this.props.edit
                                                                                                    ? this.props.usedInterfaces_deviceB?.includes(item.networkName)
                                                                                                    : this.state.deviceData.device_B?.used_tunnel_link_interface?.includes(item.networkName)
                                                                                                }>
                                                                                                {item.name}
                                                                                            </option>
                                                                                        )
                                                                                })
                                                                            )
                                                                    }
                                                                </select>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                {/* Hiding remote allowed ips for Chimay and CBR devices */}
                                                {
                                                    !(
                                                        Common.getModels(this.state.deviceData?.device_B?.device_model) === "CHIMAY" ||
                                                        Common.getModels(this.state.deviceData?.device_B?.device_model) === "vCPE"
                                                    ) &&
                                                    <>
                                                        <div className="form-group">
                                                            <div className="d-flex align-items-center justify-content-between mb-1">
                                                                <label className="mb-0">Remote Allowed IPs
                                                                    <div
                                                                        className="tooltip-c"
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="These are the CIDR formatted remote subnets of the opposite side of the tunnel">
                                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="allowedip-box with-arrow">
                                                                {
                                                                    this.state.deviceData?.device_B?.allowed_ips?.map((item) => {
                                                                        if (item !== null && item !== 'null' && item !== '') {
                                                                            return (
                                                                                <div className="tag-item" key={item}>
                                                                                    {item}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        disabled={this.props.view}
                                                                                        onClick={() => {
                                                                                            let { deviceData } = this.state;
                                                                                            deviceData.device_B.allowed_ips = deviceData.device_B.allowed_ips.filter(val => String(val) !== String(item));
                                                                                            this.setState({
                                                                                                deviceData
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        <img src="/images/svg-icons/cancel.svg" alt="" />
                                                                                    </button>
                                                                                </div>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })
                                                                }
                                                                <input
                                                                    type="text"
                                                                    name="device_B_allowedIPs"
                                                                    onKeyDown={(e) => this.handleAllowedIpDeviceB(e)}
                                                                    onBlur={(e) => this.handleAllowedIpDeviceB(e, "blur")}
                                                                    autoComplete="off"
                                                                />
                                                                <i
                                                                    className="drop-arrow"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                </i>
                                                                <div
                                                                    className="dropdown-menu max-height-200"
                                                                    aria-labelledby="dropdownMenuButton"
                                                                >
                                                                    <ul>
                                                                        {this.state.deviceData?.device_A?.available_allowed_ips
                                                                            ?.filter(item => String(item) !== "0.0.0.0/0")
                                                                            ?.map((item) => {
                                                                                if (item) {
                                                                                    return (
                                                                                        <li
                                                                                            key={item}
                                                                                            data-name="device_B_allowedIPs"
                                                                                            data-value={item}
                                                                                            onClick={(e) => this.setDeviceBAllowedIP(e)}
                                                                                        >
                                                                                            {item}
                                                                                        </li>
                                                                                    );
                                                                                }
                                                                                return null;
                                                                            })}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="error-txt" id="device_B_allowedIPs">Invalid value</div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.isExternalEndPoint &&
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Public Key
                                                        <span className="red-txt">*</span>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="The key used to encrypt data. Each peer has its own public key">
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="device_B_publicKey"
                                                        minLength="35"
                                                        maxLength="50"
                                                        title={this.state.deviceData?.device_B?.public_key}
                                                        value={this.state.deviceData?.device_B?.public_key}
                                                        onChange={(e) => this.handleExternalEndpointPublicKey(e)}
                                                        onBlur={(e) => this.validatePublicKey(e)}
                                                        className="form-control"
                                                    />
                                                    <div className="error-txt" id="external_public_key">The public key must contain at least 32 characters.</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group with-arrow">
                                                    <label>Local Endpoint IP
                                                        <span className="red-txt">*</span>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="The public-facing internet IP address of the local device">
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="device_A_endpoint"
                                                        onChange={(e) => this.setValue(e)}
                                                        onBlur={(e) => this.validateIPaddress(e.target.value, e.target.name)}
                                                        value={
                                                            (this.state.deviceData?.device_A?.endpoint_details === null ||
                                                                this.state.deviceData?.device_A?.endpoint_details === 'null')
                                                                ? ''
                                                                : this.state.deviceData?.device_A?.endpoint_details
                                                        }
                                                        className="form-control"
                                                    />
                                                    <div className="error-txt" id="device_A_endpoint">Invalid value</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Listen Port
                                                        <span className="red-txt">*</span>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Listen port for data tunnel">
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <Select
                                                        options={this.getOptions('deviceB_port')}
                                                        isClearable
                                                        isSearchable
                                                        className="custom-dropdown w-105"
                                                        classNamePrefix="react-select-container"
                                                        value={
                                                            this.getOptions('deviceB_port')?.filter(option =>
                                                                option?.label === this.state.selectedAvailablePortB)
                                                        }
                                                        onChange={(e) => this.handleAvailablePorts(e, 'PortB')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group with-arrow">
                                                    <label>Local Tunnel IP
                                                        <span className="red-txt">*</span>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="The tunnel IP address of the external endpoint device.">
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="private_ip"
                                                        onChange={(e) => this.setValue(e)}
                                                        onBlur={(e) => this.validateIPaddress(e.target.value, e.target.name)}
                                                        title={this.state.deviceData?.device_B?.private_ip}
                                                        value={this.state.deviceData?.device_B?.private_ip}
                                                        className="form-control"
                                                    />
                                                    <div className="error-txt" id="private_ip">Invalid value</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <div className="page-sub-title border-bottom"><strong>Settings</strong></div>
                                <div className="pl-2 pr-2 mt-3 gray-bg">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tunnel Name
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Name of tunnel"
                                                    >
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <input
                                                    type="text"
                                                    defaultValue={this.state.deviceData?.settings?.tunnel_name}
                                                    disabled={this.props.view || this.props.reapply}
                                                    className="form-control"
                                                    name="tunnel_name"
                                                    onChange={(e) => this.setValue(e)}
                                                />
                                                <div className="error-txt" id="tunnel_name">Invalid value</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex align-items-center justify-content-between mt-4 pt-1">
                                                <label className="mb-0">Keep Alive
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Keep alive status of tunnel"
                                                    >
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        disabled={this.props.view || this.props.reapply}
                                                        checked={this.state.deviceData?.settings?.tunnel_keep_alive_status === 1}
                                                        name="tunnel_keep_alive_status"
                                                        onChange={(e) => this.setValue(e)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            this.state.deviceData?.settings?.tunnel_keep_alive_status === 1 &&
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Keep Alive Interval
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Keep alive interval of tunnel"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            disabled={this.props.view || this.props.reapply}
                                                            defaultValue={this.state.deviceData?.settings?.tunnel_keep_alive_interval}
                                                            className="form-control"
                                                            min="1"
                                                            max="120"
                                                            name="tunnel_keep_alive_interval"
                                                            onChange={(e) => this.setValue(e)}
                                                        />
                                                    </div>
                                                    <span className="units">Seconds</span>
                                                    <div className="error-txt" id="tunnel_keep_alive_interval">Keep alive interval can be 1 to 120</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                {/* Remote Device */}
                <div
                    className={
                        this.state.devicesTemplate
                            ? "sidebar-slide zindex10 w-350 second-open r-757"
                            : "sidebar-slide zindex10 w-350"
                    }
                >
                    <div className="s-header">
                        <h1 className="ss-title">Devices</h1>
                        <button
                            onClick={() => this.closeDeviceTemplate()}
                        >
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="filter-box d-flex align-items-center">
                            <div className="search-box2 mr-2">
                                {/* LONG-3222-EM - Not able to search(Filter) the device inside the Tunnel template */}
                                {/* LONG-3194-EM - Filtered device should be reset after cancel/close operation */}
                                <input
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.searchVal}
                                    onChange={(e) => this.isSubstring((e.target.value.toLowerCase()))}
                                />
                                <img src="/images/svg-icons/search.svg" alt="" />
                            </div>
                        </div>
                        <div className="device-table">
                            <DataTable
                                columns={this.state.deviceListColumns}
                                data={this.state.deviceList.filter(item => item.device_name.toLowerCase().includes(this.state.searchVal))}
                                selectableRows
                                highlightOnHover
                                fixedHeader
                                selectableRowDisabled={
                                    (row) => (
                                        (
                                            this.state.selectedModel !== '' &&
                                            this.state.selectedModel !== row.device_model
                                        ) ||
                                        (
                                            row.group_name !== "" &&
                                            row.group_name !== null
                                        ) ||
                                        this.checkModel(row.device_model)
                                    )
                                }
                                onSelectedRowsChange={(data) => this.multipleSelect1(data)}
                                clearSelectedRows={this.state.toggledClearDeviceRows}
                            />
                        </div>
                    </div>
                    <div className="s-footer">
                        <button
                            className="btn btn-light"
                            onClick={() => this.closeDeviceTemplate()}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={this.state.multipleSelect === '' || this.state.adding}
                            onClick={() => {
                                this.deviceAdd();
                            }}
                        >
                            Add
                        </button>
                    </div>
                </div>
                {/* Remote Device */}
                {
                    !this.props.view &&
                    <div className="s-footer">
                        <button
                            className="btn btn-light"
                            onClick={
                                (this.props.apply && !this.props.edit && !this.props.reapply)
                                    ? () => this.props.closeAddTemplate()
                                    : () => this.closeAll()
                            }>
                            {/* 1665 reapply page issue */}
                            {(this.props.apply && !this.props.edit && !this.props.reapply) ? 'Back' : 'Cancel'}</button>
                        {/* LONG-1691 If Apply is given continuously,Template are getting pushed more times-Issue fixed */}
                        <button
                            className="btn btn-primary"
                            disabled={this.checkError()}
                            onClick={() =>
                                //Disable Apply if local endpoints IP is empty for group
                                (this.props.apply && !this.props.group_id)
                                    ? this.checkLocalEndpoints()
                                    : this.props.group_id
                                        ? this.applyTemplate()
                                        : this.saveData()
                            }
                        >
                            {this.props.apply
                                ? 'Apply'
                                : (
                                    (this.state.selectedTemplateId !== '')
                                        ? 'Update'
                                        : 'Save'
                                )
                            }
                        </button>
                    </div>
                }
                {
                    this.state.show &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {
                    (this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        applying: state.displayAddTemplate.applying,
        openStaticTemplate: state.displayAddTemplate.openStaticTemplate
    };
};

export default connect(mapStateToProps)(HubSpokeTemplate);