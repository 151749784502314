import React, { Component } from "react";

export default class DeviceB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchVal: "",
            deviceData: this.props.devices,
            device_B: {},
        }
    }
    static getDerivedStateFromProps = (props, state) => {
        if (props.devices) {
            state.deviceData = props.devices
        }
        return state;
    }
    setDeviceB = (e) => {
        this.setState({
            device_B: e,
        })
    }

    isSubstring = (s1, s2) => {
        return s2.includes(s1)

    }
    handleNext = () => {
        this.props.stepChange(2, this.state.device_B);
        this.props.nextStep();
    }
    render() {
        return (
            <div className="tunnel-box">
                <div className="graybg-title">Select Device B</div>
                <div className="filter-box d-flex align-items-center pb-3 border-bottom">
                    <div className="search-box2 mr-2">
                        <input type="text" placeholder="Search.." onChange={(e) => this.setState({ searchVal: e.target.value })} />
                        <img src="/images/svg-icons/search.svg" alt="" />
                    </div>


                </div>
                <div className="scroll-height-tunnel">
                    {this.state.deviceData && this.state.deviceData.map((device) => {
                        return (
                            this.isSubstring(this.state.searchVal.toLowerCase(), device.device_name.toLowerCase()) ?
                                <div className="d-flex align-items-center mt-3 mb-3 border-bottom pb-3">
                                    <div className="device-icon">
                                        <img src="/images/svg-icons/wifi-router.svg" alt="" />
                                        <div className={device.device_status === 1 ? "status online" : "status offline"}></div>
                                    </div>
                                    {/* LONG-3597-EM - User should not able to create tunnel between devices present in device group */}
                                    <div className="d-title flex-grow-1 ml-3 mr-3"
                                        onClick={((device.group_name !== "" && device.group_name !== null) || device.is_ippt)
                                            ? ""
                                            : () => this.setDeviceB(device)}>
                                        <strong>{device.device_name}</strong>
                                        <small>{device.device_mac.toUpperCase()}</small><br />
                                        <small className={device.group_name ? "dg-text" : ""}>{device.group_name}</small>
                                    </div>
                                    <div className="d-radio">
                                        <label className="checkbox round tick">
                                            <input type="radio" name="device_B"
                                                onChange={() => this.setDeviceB(device)}
                                                checked={device === this.state.device_B}
                                                disabled={(device.group_name !== "" && device.group_name !== null) || device.is_ippt} />
                                            <div className="check"></div>
                                        </label>
                                    </div>
                                </div> : <div></div>)
                    })}
                </div>
                <div className="s-footer">
                    <div className="slide-pagination">2/3</div>
                    <button className="btn btn-light" onClick={() => this.props.goToStep(1)}>Back</button>
                    <button className="btn btn-primary"
                        disabled={Object.entries(this.state.device_B).length === 0
                            || this.state.deviceData.filter(item => item.device_mac === this.state.device_B.device_mac).length === 0
                        }
                        onClick={() => {
                            this.handleNext()
                        }}>Next</button>
                </div>
            </div>
        )
    }
}
