export const INVALID_TENANT_AND_APP_ID_PAIR = "Tenant doesn't exist in the application!";
export const ERROR_MESSAGE = "This request can not be performed!";
export const INVALID_HEADER_PARAM = "Invalid parameters provided!";
export const INVALID_ROLE_ID = "Provided role is invalid!";
export const EMAIL_ID_ALREADY_REGISTERED = "Email already registered!";
export const INVALID_EMAIL_ID = "Email ID is invalid!";
export const BAD_REQUEST = "Requested parameters/json is invalid!";
export const INVALID_FIRST_NAME = "First name provided is not valid!";
export const INVALID_LAST_NAME = "Last name provided is not valid!";
export const METHOD_NOT_ALLOWED = "Method type is not valid!";
export const INVALID_DEFAULT_ADMIN_VALUE = "Default admin value is not valid!";
export const DEFAULT_ADMIN_ALREADY_EXISTS = "Default admin already exists!";
export const INVALID_ROLE_FOR_DEFAULT_ADMIN = "Invalid role for default admin!";
export const INVALID_FIRST_NAME_LENGTH = "First name cannot be more than 100 characters!";
export const INVALID_LAST_NAME_LENGTH = "Last name cannot be more than 100 characters!";
export const INVALID_EMAIL_LENGTH = "Email cannot be more than 100 characters!";
export const INVAID_USER_ID = "User ID provided is invalid!";
export const FIRST_NAME_CANNOT_BE_EMPTY = "First name cannot be empty!";
export const LAST_NAME_CANNOT_BE_EMPTY = "Last name cannot be empty!";
export const EMAIL_CANNOT_BE_EMPTY = "Email cannot be empty!";
export const ATLEAST_ONE_ADMIN_SHOULD_BE_THERE_IN_APPLICATION = "At least one admin is required!";
export const FAILED_TO_DELETE_USERS = "Unable to delete user!";
export const FAILED_TO_UPDATE_USER = "Unable to update user!";
export const PARAMS_VALIDATION_ERROR = "Invalid parameters passed!";
export const RESET_PASSWORD_LINK_SENT_SUCCESSFULLY = "Link sent to reset password successfully";
export const FAILED_TO_SEND_RESET_PASSWORD_LINK = "Unable to send reset password link!";