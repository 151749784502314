/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import $ from 'jquery';
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import { Loader } from '../../../../layOuts/Loader';
import { ErrorPopup } from '../../../../layOuts/errorPopup';
import { Netmask } from 'netmask';
import * as Common from '../../../../utils/common';
import ErrorHandlePopup from "../../../ErrorHandlePopup";
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import * as Action from "../../../../redux/actionTypes";
class WanConfigCR extends Component {
    constructor(props) {
        super(props);
        let selectInterface = '';
        if (this.props?.template_schema_json) {
            if (this.props?.template_schema_json?.wan_data?.length === 1)
                selectInterface = this.props?.template_schema_json?.wan_data?.[0]?.networkName;
        }
        this.state = {
            showRefreshModal: false,
            keepAlive: false,
            selectedConfig: 'wan',
            showError: false,
            showTunnelerror: false,
            selectedTemplateId: this.props?.id ? this.props?.id : "",
            template_type: this.props?.templateData?.template_type || "",
            template_name: this.props?.templateData?.template_name || "",
            template_desc: this.props?.templateData?.template_desc || "",
            device_model: this.props?.templateData?.device_model || "",
            collection_version: this.props?.templateData?.collection_version || "",
            fw_version: this.props?.templateData?.fw_version || "",
            chimay: this.props.chimay,
            cbr: this.props.cbr,
            mifi: this.props.mifi,
            selectInterface: selectInterface && selectInterface !== 'wan_1G' ? selectInterface : 'wan',
            usedPriority: [],
            weightError: false,
            ruleWeightError: false,
            selectedRule: [],
            networkViewData: [{
                name: "Ethernet WAN",
                networkName: "wan",
                interfaceName: "eth2",
            }],
            autoWanstatus: "0",
            availablePrioirities: [],
            update: false,
            tunnelNetworks: [],
            availableTunnelNetworks: [],
            keepAliveData: {
                "status": "0",
                "networkName": "wan",
                "interfaceName": "eth2",
                "keepaliveMethod": "ping",
                "priority": "",
                "statusLoadBal": "0",
                "weight": "",
                "up": "2",
                "down": "2",
                "count": "1",
                "reliability": "1",
                "trackAddress1": "inseego.com",
                "trackAddress2": "8.8.4.4",
                "trackAddress3": "",
                "refreshIntervalPing": "10",
                "refreshIntervalDNS": "10",
                "hostname": "www.google.com",
                "retryCount": "10",
                "activeInterval": "30",
                "standbyInterval": "20",
                "failedInterval": "20",
                "hostname1": "www.google.com",
                "hostname2": "www.inseego.com",
                "hostname3": "www.bing.com",
            },
            template_schema_json: {
                "enforceMwan3": "0",
                "wan_data": [{
                    "status": "0",
                    "statusLoadBal": "0",
                    "weight": "",
                    "networkName": "wan",
                    "interfaceName": "eth0",
                    "keepaliveMethod": "ping",
                    "priority": "",
                    "up": "2",
                    "down": "2",
                    "count": "1",
                    "reliability": "1",
                    "trackAddress1": "inseego.com",
                    "trackAddress2": "8.8.4.4",
                    "trackAddress3": "",
                    "refreshIntervalPing": "10",
                    "refreshIntervalDNS": "10",
                    "hostname": "www.google.com",
                    "retryCount": "10",
                    "activeInterval": "30",
                    "standbyInterval": "20",
                    "failedInterval": "20",
                    "hostname1": "www.google.com",
                    "hostname2": "www.inseego.com",
                    "hostname3": "www.bing.com",
                }],
                tun_data: {
                    rule_data: this.props.template_schema_json?.tun_data?.rule_data ? this.props.template_schema_json?.tun_data?.rule_data : []
                },
                tun_interfaces_data: []
            },
            interfaces_keepalive_data: {
                "interfaceName": "",
                "keepaliveMethod": "ping",
                "weight": "0",
                "up": "2",
                "down": "2",
                "count": "1",
                "reliability": "1",
                "trackAddress1": "",
                "trackAddress2": "",
                "trackAddress3": "",
                "refreshIntervalPing": "10",
                "refreshIntervalDNS": "10",
                "hostname": "www.google.com",
                "dns_server": "8.8.4.4",
                "retryCount": "10",
                "activeInterval": "30",
                "standbyInterval": "20",
                "failedInterval": "20",
                "hostname1": "www.google.com",
                "hostname2": "www.inseego.com",
                "hostname3": "www.bing.com",
            },
            addRuleData: {
                "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "rule_name": "",
                "src_ip": ["ANY"],
                "src_port": "ANY",
                "dest_ip": ["ANY"],
                "dest_port": "ANY",
                "proto": "all",
                "order": "",
                "interfaceName": [],
                "weight": [],
                "isdeleted": '0'
            },
            selectedZone: '',
            isAxiosError: false,
            networkSet: true
        }
    }
    componentDidMount() {
        if (this.props.template_schema_json) {
            if (this.props?.template_schema_json?.wan_data?.length === 1) {
                let template_schema_json = JSON.parse(JSON.stringify(this.undefinedValueCheck(this.props?.template_schema_json)));
                let wan_data = template_schema_json.wan_data;
                wan_data.map((network) => {
                    if (network.networkName === this.props.template_schema_json?.wan_data?.[0]?.networkName) {
                        network = (this.undefinedValueCheck(this.props.template_schema_json))?.wan_data?.[0];
                    }
                    return network;
                })
                template_schema_json.wan_data = wan_data;
                this.setState({
                    template_schema_json
                })
            } else
                this.setState({
                    template_schema_json: JSON.parse(JSON.stringify(this.undefinedValueCheck(this.props?.template_schema_json))),
                })
        }
        let { selectInterface, usedPriority } = this.state;
        //apply,edit,info and view case
        if (this.props.networkViewData) {
            let newData = [];
            const networkViewData = this.props.networkViewData?.filter(item => item?.network_zone === 'wan' && item?.name !== 'Cellular');
            const tunNetworks = this.props.networkViewData?.filter(item => item?.network_zone === 'datatunnel' && item?.ip_address !== '');
            let template_schema_json = JSON.parse(JSON.stringify(this.undefinedValueCheck(this.props?.template_schema_json)));
            let tunnelNetworks = [];
            let { availableTunnelNetworks } = this.state;
            let allNetworks = [];
            tunNetworks.map((tunnel) => {
                let tun_network = {
                    interfaceName: '',
                    networkTunnelLink: '',
                    remote_deviceName: '',
                    remote_networkTunnelLink: '',
                    remote_tunnelIp: ''
                }
                tun_network.interfaceName = tunnel?.interfaceName;
                tun_network.networkTunnelLink = tunnel?.networkTunnelLink;
                tun_network.remote_deviceName = tunnel?.remote_deviceName;
                tun_network.remote_networkTunnelLink = tunnel?.remote_networkTunnelLink;
                tun_network.remote_tunnelIp = tunnel?.remote_tunnelIp;
                tunnelNetworks.push(tun_network);
            })
            this.props.networkViewData?.filter(item => item?.network_zone === 'wan' || (item?.network_zone === 'datatunnel' && item?.ip_address !== ''))?.map((network) => {
                availableTunnelNetworks?.push(network)
            })
            allNetworks = availableTunnelNetworks;
            template_schema_json.tun_interfaces_data = availableTunnelNetworks;
            //apply,info and view case
            if (!this.props?.edit) {
                let newTun_interfacedata = [];
                tunNetworks.map((tunnel) => {
                    let interfaceData = {
                        "interfaceName": "",
                        "keepaliveMethod": "ping",
                        "weight": "0",
                        "up": "2",
                        "down": "2",
                        "count": "1",
                        "reliability": "1",
                        "trackAddress1": "",
                        "trackAddress2": "",
                        "trackAddress3": "",
                        "refreshIntervalPing": "10",
                        "refreshIntervalDNS": "10",
                        "hostname": "www.google.com",
                        "dns_server": "8.8.4.4",
                        "retryCount": "10",
                        "activeInterval": "30",
                        "standbyInterval": "20",
                        "failedInterval": "20",
                        "hostname1": "www.google.com",
                        "hostname2": "www.inseego.com",
                        "hostname3": "www.bing.com",
                    };
                    interfaceData.interfaceName = tunnel?.interfaceName;
                    interfaceData.trackAddress1 = tunnel?.remote_tunnelIp;
                    newTun_interfacedata?.push(interfaceData);
                })
                template_schema_json.tun_interfaces_data = newTun_interfacedata;
                if (template_schema_json.wan_data.filter(item => item.networkName === 'wan_1G').length > 0) {
                    template_schema_json.wan_data.filter(item => item.networkName === 'wan_1G')[0].networkName = 'wan';
                }
                networkViewData.map((network, index) => {
                    if (template_schema_json?.wan_data?.filter(item => item.networkName === network.networkName)?.length > 0 ||
                        ((!this.props.edit) &&
                            template_schema_json?.wan_data?.filter(item => item?.networkName === network?.networkName)?.[0])) {
                        if (template_schema_json.wan_data?.filter(item => item?.networkName === network?.networkName)?.[0]) {
                            // if (template_schema_json.wan_data.filter(item => item.networkName === network.networkName)[0].status === '1') {
                            //     template_schema_json.wan_data.filter(item => item.networkName === network.networkName)[0].priority = '1';
                            // }
                            template_schema_json.wan_data.filter(item => item.networkName === network.networkName)[0].interfaceName = network?.interfaceName;
                            newData.push(template_schema_json.wan_data?.filter(item => item?.networkName === network?.networkName)?.[0]);
                        }
                    } else {
                        newData.push({
                            "status": template_schema_json?.wan_data?.[0] ?
                                template_schema_json?.wan_data?.[0]?.status : '0',
                            "statusLoadBal": template_schema_json?.wan_data?.[0] ?
                                template_schema_json?.wan_data?.[0]?.statusLoadBal : "0",
                            "weight": "",
                            "networkName": network?.networkName,
                            "interfaceName": network?.interfaceName,
                            "keepaliveMethod": template_schema_json?.wan_data?.[0]?.keepaliveMethod,
                            "priority": template_schema_json?.wan_data?.[0]?.status === '1' ?
                                (index + 1)?.toString() : "",
                            "up": "2",
                            "down": "2",
                            "count": "1",
                            "reliability": "1",
                            "trackAddress1": "inseego.com",
                            "trackAddress2": "8.8.4.4",
                            "trackAddress3": "",
                            "refreshIntervalPing": "10",
                            "refreshIntervalDNS": "10",
                            "hostname": "www.google.com",
                            "retryCount": "10",
                            "activeInterval": "30",
                            "standbyInterval": "20",
                            "failedInterval": "20",
                            "hostname1": "www.google.com",
                            "hostname2": "www.inseego.com",
                            "hostname3": "www.bing.com",
                        })
                    }
                    if (newData?.length > 0 && newData?.[0]?.status === '1') {
                        usedPriority = newData.map((item) => item?.priority);
                        usedPriority.filter(item => item !== '');
                    }
                })
                if (newData?.length === 0) {
                    template_schema_json = JSON.parse(JSON.stringify(this.undefinedValueCheck(this.props?.template_schema_json)))
                    this.setState({
                        networkViewData,
                        template_schema_json,
                        selectInterface,
                        usedPriority,
                        tunnelNetworks,
                        availableTunnelNetworks,
                        allNetworks
                    })
                }
                else {
                    template_schema_json.wan_data = newData;
                    this.setState({
                        networkViewData,
                        template_schema_json,
                        selectInterface,
                        usedPriority,
                        tunnelNetworks,
                        availableTunnelNetworks,
                        allNetworks
                    }, () => {
                        if (template_schema_json?.wan_data?.[0]?.statusLoadBal === "1") {
                            this.calculateWeight();
                        }
                    })
                }
            } else {
                template_schema_json.tun_interfaces_data.map((item) => {
                    if (tunnelNetworks.filter(value => value?.interfaceName === item?.interfaceName)?.length === 0) {
                        template_schema_json.tun_interfaces_data = template_schema_json?.tun_interfaces_data?.filter(value => value?.interfaceName !== item?.interfaceName)
                    }
                })
                tunNetworks.map((tunnel) => {
                    let interfaceData = {
                        "interfaceName": "",
                        "keepaliveMethod": template_schema_json?.tun_interfaces_data?.[0]?.keepaliveMethod,
                        "weight": "0",
                        "up": "2",
                        "down": "2",
                        "count": "1",
                        "reliability": "1",
                        "trackAddress1": "",
                        "trackAddress2": "",
                        "trackAddress3": "",
                        "refreshIntervalPing": "10",
                        "refreshIntervalDNS": "10",
                        "hostname": "www.google.com",
                        "dns_server": "8.8.4.4",
                        "retryCount": "10",
                        "activeInterval": "30",
                        "standbyInterval": "20",
                        "failedInterval": "20",
                        "hostname1": "www.google.com",
                        "hostname2": "www.inseego.com",
                        "hostname3": "www.bing.com",
                    };
                    if (template_schema_json?.tun_interfaces_data?.length > 0) {
                        interfaceData.interfaceName = tunnel?.interfaceName;
                        interfaceData.trackAddress1 = template_schema_json?.tun_interfaces_data?.[0]?.keepaliveMethod === 'ping' ?
                            tunnel.remote_tunnelIp : '';
                        interfaceData.dns_server = template_schema_json?.tun_interfaces_data?.[0]?.keepaliveMethod === 'dns' ?
                            '8.8.4.4' : '';
                        if (template_schema_json.tun_interfaces_data.filter(item => item.interfaceName === tunnel.interfaceName)?.length === 0)
                            template_schema_json.tun_interfaces_data?.push(interfaceData);
                    }
                })
                // edit and reapply   
                networkViewData.map((network) => {
                    if (template_schema_json.wan_data?.filter(item => item?.networkName === network?.networkName)?.length > 0 ||
                        ((!this.props.edit) &&
                            template_schema_json.wan_data?.filter(item => item?.networkName === network?.networkName)?.[0])) {
                        // LONG-2894- interface update in wan template on changing interface in network configuration
                        template_schema_json.wan_data.filter(item => item.networkName === network.networkName)[0].interfaceName = network?.interfaceName;
                        newData.push(template_schema_json.wan_data?.filter(item => item?.networkName === network?.networkName)?.[0]);
                    } else {
                        newData.push({
                            "status": template_schema_json?.wan_data?.[0]?.status,
                            "statusLoadBal": template_schema_json?.wan_data?.[0]?.statusLoadBal,
                            "weight": network?.weight,
                            "networkName": network?.networkName,
                            "interfaceName": network?.interfaceName,
                            "keepaliveMethod": template_schema_json?.wan_data?.[0]?.keepaliveMethod,
                            "priority": "",
                            "up": "2",
                            "down": "2",
                            "count": "1",
                            "reliability": "1",
                            "trackAddress1": "inseego.com",
                            "trackAddress2": "8.8.4.4",
                            "trackAddress3": "",
                            "refreshIntervalPing": "10",
                            "refreshIntervalDNS": "10",
                            "hostname": "www.google.com",
                            "retryCount": "10",
                            "activeInterval": "30",
                            "standbyInterval": "20",
                            "failedInterval": "20",
                            "hostname1": "www.google.com",
                            "hostname2": "www.inseego.com",
                            "hostname3": "www.bing.com",
                        })
                    }
                    template_schema_json?.wan_data?.length > 0 && template_schema_json?.wan_data?.map((item) => {
                        if (!usedPriority?.includes(item?.priority)) {
                            usedPriority?.push(item?.priority);
                        }
                    })
                    usedPriority = usedPriority?.filter(item => item !== '');
                })
                template_schema_json.wan_data = newData;
            }
            template_schema_json?.tun_data?.rule_data?.map((rule) => {
                availableTunnelNetworks = this.state.addRuleData.interfaceName.length > 0 ? this.state.allNetworks?.filter(network =>
                    !this.state.addRuleData.interfaceName?.includes(network.interfaceName)) : this.state.allNetworks;
                // rule.interfaceName.map((intf) => {
                //     availableTunnelNetworks = availableTunnelNetworks.filter(item => item.interfaceName !== intf);
                // })
            });
            if (newData?.length === 0) {
                let new_template_schema_json = JSON.parse(JSON.stringify(this.undefinedValueCheck(this.props.template_schema_json)));
                // Long-3282 Randomly Interface wiregaurd interface Name is not showing in Tunnel loadbalancing page
                new_template_schema_json.tun_interfaces_data = template_schema_json?.tun_interfaces_data;
                this.setState({
                    networkViewData,
                    template_schema_json: new_template_schema_json,
                    selectInterface,
                    usedPriority,
                    tunnelNetworks,
                    availableTunnelNetworks,
                    allNetworks
                })
            }
            else {
                template_schema_json.wan_data = newData;
                this.setState({
                    networkViewData,
                    template_schema_json,
                    selectInterface,
                    usedPriority,
                    tunnelNetworks,
                    availableTunnelNetworks,
                    allNetworks
                })
            }
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.templateData).length !== 0) {
            this.setState({
                selectedTemplateId: nextProps.id ? nextProps.id : '',
                template_type: nextProps?.templateData?.template_type || "",
                template_name: nextProps?.templateData?.template_name || "",
                template_desc: nextProps?.templateData?.template_desc || "",
                device_model: nextProps?.templateData?.device_model || "",
                collection_version: nextProps?.templateData?.collection_version || "",
                fw_version: nextProps?.templateData?.fw_version || ""
            });
        }
        if (this.state.networkSet || !isEqual(this.props?.template_schema_json, nextProps?.template_schema_json)) {
            if (nextProps?.template_schema_json?.wan_data?.length === 1) {
                let template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
                template_schema_json.enforceMwan3 = this.undefinedValueCheck(nextProps?.template_schema_json)?.enforceMwan3;
                let wan_data = template_schema_json?.wan_data;
                wan_data.map((network, index) => {
                    if (network.networkName === nextProps.template_schema_json?.wan_data?.[0]?.networkName) {
                        wan_data[index] = (this.undefinedValueCheck(nextProps?.template_schema_json))?.wan_data[0];
                    }
                })
                template_schema_json.wan_data = wan_data;
                this.setState({
                    template_schema_json
                })
            } else {
                this.setState({
                    template_schema_json: JSON.parse(JSON.stringify(this.undefinedValueCheck(nextProps?.template_schema_json))),
                })
            }
        }
        if (nextProps?.networkViewData?.length > 0)
            if (this.state.networkSet || (!isEqual(this.props?.networkViewData, nextProps?.networkViewData) && nextProps?.networkViewData?.length > 0)) {
                const tunNetworks = nextProps?.networkViewData?.filter(item => item?.network_zone === 'datatunnel' && item?.ip_address !== '');
                let tunnelNetworks = [];
                let availableTunnelNetworks = [];
                let allNetworks = [];
                tunNetworks.map((tunnel) => {
                    let tun_network = {
                        interfaceName: '',
                        networkTunnelLink: '',
                        remote_deviceName: '',
                        remote_networkTunnelLink: '',
                        remote_tunnelIp: ''
                    }
                    tun_network.interfaceName = tunnel?.interfaceName;
                    tun_network.networkTunnelLink = tunnel?.networkTunnelLink;
                    tun_network.remote_deviceName = tunnel?.remote_deviceName;
                    tun_network.remote_networkTunnelLink = tunnel?.remote_networkTunnelLink;
                    tun_network.remote_tunnelIp = tunnel?.remote_tunnelIp;
                    tunnelNetworks.push(tun_network);
                });
                nextProps.networkViewData?.filter(item => item?.network_zone === 'wan' || (item?.network_zone === 'datatunnel' && item?.ip_address !== '')).map((network) => {
                    availableTunnelNetworks.push(network)
                })
                allNetworks = availableTunnelNetworks;
                const networkViewData = nextProps?.networkViewData?.filter(item => item?.network_zone === 'wan' && item?.name !== 'Cellular');
                let template_schema_json = this.state.template_schema_json;
                // template_schema_json.tun_interfaces_data = availableTunnelNetworks;
                //Long-3418 load balance change fixed in view template
                if (nextProps?.edit || nextProps?.view || nextProps?.reapply) {
                    template_schema_json = JSON.parse(JSON.stringify(this.undefinedValueCheck(nextProps?.template_schema_json)));
                    if (template_schema_json?.tun_interfaces_data?.length > 0) {
                        template_schema_json?.tun_interfaces_data?.map((item) => {
                            if (tunnelNetworks?.filter(value => value?.interfaceName === item?.interfaceName)?.length === 0) {
                                template_schema_json.tun_interfaces_data = template_schema_json?.tun_interfaces_data?.filter(value => value?.interfaceName !== item?.interfaceName)
                            }
                            let extraNetworks = [];
                            tunNetworks.map((network) => {
                                if (template_schema_json?.tun_interfaces_data?.filter(item => item?.interfaceName === network?.interfaceName)?.length === 0) {
                                    extraNetworks.push(network);
                                }
                            })
                            extraNetworks.map((tunnel) => {
                                let interfaceData = {
                                    "interfaceName": tunnel?.interfaceName,
                                    "keepaliveMethod": template_schema_json?.tun_interfaces_data?.length > 0 ?
                                        template_schema_json?.tun_interfaces_data?.[0]?.keepaliveMethod : 'ping',
                                    "weight": "0",
                                    "up": "2",
                                    "down": "2",
                                    "count": "1",
                                    "reliability": "1",
                                    "trackAddress1": "",
                                    "trackAddress2": "",
                                    "trackAddress3": "",
                                    "refreshIntervalPing": "10",
                                    "refreshIntervalDNS": "10",
                                    "hostname": "www.google.com",
                                    "dns_server": "8.8.4.4",
                                    "retryCount": "10",
                                    "activeInterval": "30",
                                    "standbyInterval": "20",
                                    "failedInterval": "20",
                                    "hostname1": "www.google.com",
                                    "hostname2": "www.inseego.com",
                                    "hostname3": "www.bing.com",
                                };
                                interfaceData.interfaceName = tunnel?.interfaceName;
                                interfaceData.trackAddress1 = interfaceData?.keepaliveMethod === 'ping' ?
                                    tunnel?.remote_tunnelIp : '';
                                interfaceData.dns_server = interfaceData?.keepaliveMethod === 'dns' ?
                                    '8.8.4.4' : '';
                                template_schema_json?.tun_interfaces_data?.push(interfaceData);
                            })
                        })
                    } else {
                        let newTun_interfacedata = [];
                        // Long-3282 Randomly Interface wiregaurd interface Name is not showing in Tunnel loadbalancing page
                        tunNetworks.map((tunnel) => {
                            let interfaceData = {
                                "interfaceName": tunnel?.interfaceName,
                                "keepaliveMethod": template_schema_json?.tun_interfaces_data?.length > 0 ?
                                    template_schema_json?.tun_interfaces_data?.[0]?.keepaliveMethod : 'ping',
                                "weight": "0",
                                "up": "2",
                                "down": "2",
                                "count": "1",
                                "reliability": "1",
                                "trackAddress1": "",
                                "trackAddress2": "",
                                "trackAddress3": "",
                                "refreshIntervalPing": "10",
                                "refreshIntervalDNS": "10",
                                "hostname": "www.google.com",
                                "dns_server": "8.8.4.4",
                                "retryCount": "10",
                                "activeInterval": "30",
                                "standbyInterval": "20",
                                "failedInterval": "20",
                                "hostname1": "www.google.com",
                                "hostname2": "www.inseego.com",
                                "hostname3": "www.bing.com",
                            };
                            interfaceData.interfaceName = tunnel?.interfaceName;
                            interfaceData.trackAddress1 = interfaceData?.keepaliveMethod === 'ping' ?
                                tunnel?.remote_tunnelIp : '';
                            interfaceData.dns_server = interfaceData?.keepaliveMethod === 'dns' ?
                                '8.8.4.4' : '';
                            newTun_interfacedata?.push(interfaceData);
                        })
                        template_schema_json.tun_interfaces_data = newTun_interfacedata;
                    }
                } else {
                    template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
                    let newTun_interfacedata = [];
                    tunNetworks.map((tunnel) => {
                        let interfaceData = {
                            "interfaceName": "",
                            "keepaliveMethod": template_schema_json?.tun_interfaces_data?.length > 0 ?
                                template_schema_json.tun_interfaces_data?.[0]?.keepaliveMethod : 'ping',
                            "weight": "0",
                            "up": "2",
                            "down": "2",
                            "count": "1",
                            "reliability": "1",
                            "trackAddress1": "",
                            "trackAddress2": "",
                            "trackAddress3": "",
                            "refreshIntervalPing": "10",
                            "refreshIntervalDNS": "10",
                            "hostname": "www.google.com",
                            "dns_server": "8.8.4.4",
                            "retryCount": "10",
                            "activeInterval": "30",
                            "standbyInterval": "20",
                            "failedInterval": "20",
                            "hostname1": "www.google.com",
                            "hostname2": "www.inseego.com",
                            "hostname3": "www.bing.com",
                        };

                        interfaceData.interfaceName = tunnel?.interfaceName;
                        interfaceData.trackAddress1 = interfaceData?.keepaliveMethod === 'ping' ?
                            tunnel?.remote_tunnelIp : '';
                        interfaceData.dns_server = interfaceData?.keepaliveMethod === 'dns' ?
                            '8.8.4.4' : '';
                        newTun_interfacedata?.push(interfaceData);
                    })
                    template_schema_json.tun_interfaces_data = newTun_interfacedata;
                }
                let { selectInterface, usedPriority } = this.state;
                let newData = [];
                networkViewData.map((network) => {
                    if (template_schema_json?.wan_data?.filter(item => item?.networkName === network?.networkName)?.length > 0 ||
                        ((!nextProps.edit) &&
                            template_schema_json?.wan_data?.filter(item => item?.networkName === network?.networkName)?.[0])) {
                        // LONG-2894- interface update in wan template on changing interface in network configuration
                        template_schema_json.wan_data.filter(item => item?.networkName === network?.networkName)[0].interfaceName = network?.interfaceName;
                        newData.push(template_schema_json?.wan_data?.filter(item => item?.networkName === network?.networkName)?.[0]);

                    } else {
                        newData.push({
                            "status": template_schema_json?.wan_data?.[0] ?
                                template_schema_json?.wan_data?.[0]?.status : "0",
                            "statusLoadBal": template_schema_json?.wan_data?.[0] ?
                                template_schema_json?.wan_data?.[0]?.statusLoadBal : "0",
                            "weight": network?.weight,
                            "networkName": network?.networkName,
                            "interfaceName": network?.interfaceName,
                            "keepaliveMethod": template_schema_json?.wan_data[0]?.keepaliveMethod,
                            "priority": "",
                            "up": "2",
                            "down": "2",
                            "count": "1",
                            "reliability": "1",
                            "trackAddress1": "inseego.com",
                            "trackAddress2": "8.8.4.4",
                            "trackAddress3": "",
                            "refreshIntervalPing": "10",
                            "refreshIntervalDNS": "10",
                            "hostname": "www.google.com",
                            "retryCount": "10",
                            "activeInterval": "30",
                            "standbyInterval": "20",
                            "failedInterval": "20",
                            "hostname1": "www.google.com",
                            "hostname2": "www.inseego.com",
                            "hostname3": "www.bing.com",
                        })
                    }
                    if (newData.length > 0) {
                        usedPriority = newData?.map((item) => item?.priority);
                        usedPriority = usedPriority?.filter(item => item !== '');
                    }
                })
                template_schema_json.wan_data = newData;
                if (newData.length === 0) {
                    this.setState({
                        networkViewData,
                        selectInterface,
                        template_schema_json: {
                            "enforceMwan3": "0",
                            "wan_data": [{
                                "status": "0",
                                "statusLoadBal": "0",
                                "weight": "",
                                "networkName": "wan",
                                "interfaceName": "eth2",
                                "keepaliveMethod": "ping",
                                "priority": "",
                                "up": "2",
                                "down": "2",
                                "count": "1",
                                "reliability": "1",
                                "trackAddress1": "inseego.com",
                                "trackAddress2": "8.8.4.4",
                                "trackAddress3": "",
                                "refreshIntervalPing": "10",
                                "refreshIntervalDNS": "10",
                                "hostname": "www.google.com",
                                "retryCount": "10",
                                "activeInterval": "30",
                                "standbyInterval": "20",
                                "failedInterval": "20",
                                "hostname1": "www.google.com",
                                "hostname2": "www.inseego.com",
                                "hostname3": "www.bing.com",
                            }]
                        },
                        usedPriority,
                        tunnelNetworks,
                        availableTunnelNetworks,
                        allNetworks
                    })
                }
                else {
                    template_schema_json.wan_data = newData;
                    this.setState({
                        networkViewData,
                        template_schema_json,
                        selectInterface,
                        usedPriority,
                        tunnelNetworks,
                        availableTunnelNetworks,
                        allNetworks
                    })
                }
            } else {
                let { usedPriority } = this.state;
                if (!isEqual(this.props?.template_schema_json, nextProps?.template_schema_json)) {
                    nextProps?.template_schema_json?.wan_data?.length > 0 && this.undefinedValueCheck(nextProps?.template_schema_json)?.wan_data?.map((item) => {
                        if (!usedPriority?.includes(item.priority)) {
                            usedPriority?.push(item.priority);
                        }
                    })
                }
                this.setState({
                    usedPriority
                })
            }
        if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
            !isEqual(this.props?.cbr, nextProps?.cbr) ||
            !isEqual(this.props?.mifi, nextProps?.mifi)) {
            this.setState({
                chimay: nextProps.chimay,
                cbr: nextProps.cbr,
                mifi: nextProps.mifi
            })
        }
        this.setState({
            networkSet: false
        })
    }
    undefinedValueCheck = (value) => {
        if (value) {
            let { template_schema_json } = this.state;
            template_schema_json.enforceMwan3 = value?.enforceMwan3 || "";
            template_schema_json.wan_data = [];
            Array.isArray(value?.wan_data) && value?.wan_data?.map((item) => {
                if (item) {
                    let wan_data = {
                        "status": item?.status || "",
                        "networkName": item?.networkName || "",
                        "interfaceName": item?.interfaceName || "",
                        "keepaliveMethod": item?.keepaliveMethod || "ping",
                        "priority": item?.priority || "",
                        "statusLoadBal": item?.statusLoadBal || "0",
                        "weight": item?.weight || "",
                        "up": item?.up || "2",
                        "down": item?.down || "2",
                        "count": item?.count || "1",
                        "reliability": item?.reliability || "1",
                        "trackAddress1": item?.trackAddress1 || "",
                        "trackAddress2": item?.trackAddress2 || "",
                        "trackAddress3": item?.trackAddress3 || "",
                        "refreshIntervalPing": item?.refreshIntervalPing || "10",
                        "refreshIntervalDNS": item?.refreshIntervalDNS || "10",
                        "hostname": item?.hostname || "",
                        "retryCount": item?.retryCount || "10",
                        "activeInterval": item?.activeInterval || "30",
                        "standbyInterval": item?.standbyInterval || "20",
                        "failedInterval": item?.failedInterval || "20",
                        "hostname1": item?.hostname1 || "",
                        "hostname2": item?.hostname2 || "",
                        "hostname3": item?.hostname3 || ""
                    }
                    template_schema_json?.wan_data?.push(wan_data);
                }
            })
            template_schema_json.tun_data.rule_data = [];
            Array.isArray(value?.tun_data?.rule_data) && value?.tun_data?.rule_data?.map((item) => {
                if (item) {
                    let rule_data = {
                        "uuid": item?.uuid || '',
                        "rule_name": item?.rule_name || "",
                        "src_ip": item?.src_ip || ["ANY"],
                        "src_port": item?.src_port || "ANY",
                        "dest_ip": item?.dest_ip || ["ANY"],
                        "dest_port": item?.dest_port || "ANY",
                        "proto": item?.proto || "all",
                        "order": item?.order || "",
                        "interfaceName": item?.interfaceName || [],
                        "weight": item?.weight || [],
                        "isdeleted": item?.isdeleted || '0'
                    }
                    template_schema_json?.tun_data?.rule_data?.push(rule_data);
                }
            })
            template_schema_json.tun_interfaces_data = []
            Array.isArray(value?.tun_interfaces_data) && value?.tun_interfaces_data?.map((item) => {
                if (item) {
                    let tun_interfaces_data = {
                        "interfaceName": item?.interfaceName || "",
                        "keepaliveMethod": item?.keepaliveMethod || "",
                        "weight": item?.weight || "0",
                        "up": item?.up || "2",
                        "down": item?.down || "2",
                        "count": item?.count || "1",
                        "reliability": item?.reliability || "1",
                        "trackAddress1": item?.trackAddress1 || "",
                        "trackAddress2": item?.trackAddress2 || "",
                        "trackAddress3": item?.trackAddress3 || "",
                        "refreshIntervalPing": item?.refreshIntervalPing || "10",
                        "refreshIntervalDNS": item?.refreshIntervalDNS || "10",
                        "hostname": item?.hostname || "",
                        "dns_server": item?.dns_server || "",
                        "retryCount": item?.retryCount || "10",
                        "activeInterval": item?.activeInterval || "30",
                        "standbyInterval": item?.standbyInterval || "20",
                        "failedInterval": item?.failedInterval || "20",
                        "hostname1": item?.hostname1 || "",
                        "hostname2": item?.hostname2 || "",
                        "hostname3": item?.hostname3 || "",
                    };
                    template_schema_json?.tun_interfaces_data?.push(tun_interfaces_data);
                }
            })
            return template_schema_json
        }
    }
    calculateWeight = () => {
        const { template_schema_json } = this.state;
        let sum = 0;
        for (let i = 0; i < template_schema_json?.wan_data?.length - 1; i++) {
            template_schema_json.wan_data[i].weight = Math.floor(100 / template_schema_json?.wan_data?.length).toString();
            sum = parseInt(template_schema_json.wan_data[i].weight) + sum
        }
        template_schema_json.wan_data[template_schema_json.wan_data.length - 1].weight = (100 - sum).toString();
        this.setState({
            template_schema_json
        })
    }
    calculateInterfaceWeight = () => {
        const { addRuleData } = this.state;
        let sum = 0;
        for (let i = 0; i < addRuleData.interfaceName.length - 1; i++) {
            addRuleData.weight[i] = Math.floor(100 / addRuleData.interfaceName.length).toString();
            sum = parseInt(addRuleData.weight[i]) + sum
        }
        if (addRuleData?.interfaceName?.length > 0)
            addRuleData.weight[addRuleData.interfaceName.length - 1] = (100 - sum).toString();
        this.setState({
            addRuleData,
            ruleWeightError: false
        })
    }
    setkeepaliveConfig = (e) => {
        this.closeKeepAlive()
        const name = e.target.value;
        let { template_schema_json } = this.state;
        const status = template_schema_json?.wan_data[0]?.status;
        template_schema_json.wan_data = [];
        this.state.networkViewData.map((network, index) => {
            const priority = status === '1' ? (index + 1).toString() : ''
            template_schema_json.wan_data.push({
                "status": status,
                "statusLoadBal": "0",
                "weight": "",
                "networkName": network.networkName,
                "interfaceName": network.interfaceName,
                "keepaliveMethod": name,
                "priority": priority,
                "up": "2",
                "down": "2",
                "count": "1",
                "reliability": "1",
                "trackAddress1": "inseego.com",
                "trackAddress2": "8.8.4.4",
                "trackAddress3": "",
                "refreshIntervalPing": "10",
                "refreshIntervalDNS": "10",
                "hostname": "www.google.com",
                "retryCount": "10",
                "activeInterval": "30",
                "standbyInterval": "20",
                "failedInterval": "20",
                "hostname1": "www.google.com",
                "hostname2": "www.inseego.com",
                "hostname3": "www.bing.com",
            })
        });
        let keepAliveData = {
            "status": "0",
            "statusLoadBal": "0",
            "weight": "",
            "networkName": "wan",
            "interfaceName": "eth2",
            "keepaliveMethod": name,
            "priority": "",
            "up": "2",
            "down": "2",
            "count": "1",
            "reliability": "1",
            "trackAddress1": "inseego.com",
            "trackAddress2": "8.8.4.4",
            "trackAddress3": "",
            "refreshIntervalPing": "",
            "refreshIntervalDNS": "0",
            "hostname": "www.google.com",
            "retryCount": "10",
            "activeInterval": "30",
            "standbyInterval": "20",
            "failedInterval": "20",
            "hostname1": "www.google.com",
            "hostname2": "www.google.com",
            "hostname3": "www.google.com",
        };
        this.setState({
            keepAliveData,
            template_schema_json
        })
    }
    setswitchWanConfig = (e) => {
        const status = e.target.checked === true ? "1" : "0";
        const { template_schema_json } = this.state;
        let { selectInterface } = this.state;
        let { availablePrioirities, usedPriority } = this.state;
        template_schema_json.wan_data.map((network, index) => {
            if (status === '1') {
                network.statusLoadBal = '0';
                network.weight = '0';
                network.priority = network?.priority === '' ? (index + 1).toString() : network?.priority;
                availablePrioirities = [];
                network.priority === '' ? usedPriority.push((index + 1).toString()) : usedPriority.push(network.priority);
            } else {
                selectInterface = 'wan';
                // LONG-2315 : json format is incorrect for WAN enabled to disabled
                network.priority = ''
            }
            network.status = status;
            return network;
        })
        this.setState({
            template_schema_json,
            selectInterface,
            availablePrioirities,
            usedPriority
        })
    }
    setswitchLoadBal = (e) => {
        this.setState({
            weightError: false
        })
        const status = e.target.checked === true ? "1" : "0";
        //LONG-2656-notification popup for user after enable load balancing
        if (e.target.checked === true) {
            this.setState({
                title: 'Enable Load Balancing',
                message: 'Enabling Loadbalancing will allow you to map the WAN interface specific to tunnel via the Wireguard Configuration page.',
                showMessage: true,
                error: true,
                info: true,
                delete: true
            }, () => $("#errorMessage").modal("show"));
        }
        const { template_schema_json } = this.state;
        let { selectInterface } = this.state;
        template_schema_json.wan_data.map((network) => {
            if (status === '1') {
                network.status = '0';
                this.calculateWeight();
            } else {
                selectInterface = 'wan';
                network.weight = '';
            }
            network.statusLoadBal = status;
            return network;
        })
        this.setState({
            template_schema_json,
            selectInterface,
        })
    }
    setPriorityEth = (e, index) => {
        let { template_schema_json } = this.state;
        let { availablePrioirities, usedPriority } = this.state;
        if (template_schema_json.wan_data[index].priority === '') {
            if (!usedPriority.includes(e.target.value)) {
                usedPriority.push(e.target.value);
            }
            availablePrioirities = availablePrioirities.filter(item => item !== e.target.value);
            template_schema_json.wan_data[index].priority = e.target.value;
        }
        else {
            if (e.target.value === '') { /// selects select option
                if (!availablePrioirities.includes(e.target.value)) {
                    availablePrioirities.push(template_schema_json.wan_data[index].priority)
                };
                const priority = template_schema_json.wan_data[index].priority;
                usedPriority = usedPriority.filter(item => item !== priority);

                template_schema_json.wan_data[index].priority = e.target.value;
            }
            else {
                if (!usedPriority.includes(e.target.value)) {
                    usedPriority.push(e.target.value);
                    const priority = template_schema_json.wan_data[index].priority;
                    usedPriority = usedPriority.filter(item => item !== priority);


                    availablePrioirities = availablePrioirities.filter(item => item !== e.target.value);
                    availablePrioirities.push(priority);
                    template_schema_json.wan_data[index].priority = e.target.value;
                }
            }
        }

        this.setState({
            template_schema_json,
            availablePrioirities,
            usedPriority
        })
    }
    setWanConfig = (e) => {
        const keepAliveData = this.state.template_schema_json.wan_data.filter(item => item.networkName === e.target.value);
        this.setState({
            keepAliveData,
            selectInterface: e.target.value
        })
    }
    setKeepaliveData = (e) => {
        const element = document.getElementById(e.target.name);
        element.style.display = "none";
        this.setState({
            showError: false,
        });
        let keepAliveData = this.state.keepAliveData;
        const name = e.target.name;
        keepAliveData[name] = e.target.value;
        if (name === "trackAddress1") {
            if (keepAliveData.trackAddress2.toLowerCase() === e.target.value.toLowerCase() || keepAliveData.trackAddress3.toLowerCase() === e.target.value.toLowerCase()) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({
                    showError: true,
                });
            } else {
                keepAliveData[name] = e.target.value;
                const element = document.getElementById(e.target.name);
                element.style.display = "none";
                this.setState({
                    showError: false,
                });
            }
        }
        if (name === "trackAddress2" && e.target.value) {
            if (keepAliveData.trackAddress1.toLowerCase() === e.target.value.toLowerCase() || keepAliveData.trackAddress3.toLowerCase() === e.target.value.toLowerCase()) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({
                    showError: true,
                });
            } else {
                keepAliveData[name] = e.target.value;
                const element = document.getElementById(e.target.name);
                element.style.display = "none";
                this.setState({
                    showError: false,
                });
            }
        }
        if (name === "trackAddress3" && e.target.value) {
            if (keepAliveData.trackAddress1.toLowerCase() === e.target.value.toLowerCase() || keepAliveData.trackAddress2.toLowerCase() === e.target.value.toLowerCase()) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({
                    showError: true,
                });
            } else {
                keepAliveData[name] = e.target.value;
                const element = document.getElementById(e.target.name);
                element.style.display = "none";
                this.setState({
                    showError: false,
                });
            }
        }
        this.setState({
            keepAliveData
        })
    }
    validateValue = (e) => {
        const name = e.target.name;
        const errorId = e.target.name;
        let value = e.target.value;
        //LONG-2998-EM - Field validation for track address,Domain name should be included in keepalive configuration
        let DomainName = /^(?:www\.)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,63})+$/.test(value);
        if ((name === 'count' || name === 'up' || name === 'down') && (value === "" ||
            (parseInt(value) < 1 || parseInt(value) > 100))) {
            const element = document.getElementById(errorId);
            element.style.display = "block";
            this.setState({
                showError: true,
            });
        }
        else if (name === 'reliability' || name === 'trackAddress1' || name === 'trackAddress2' ||
            name === 'trackAddress3') {
            let count = 0;
            value = name === 'reliability' ? value : this.state.keepAliveData.reliability;
            if (this.state.keepAliveData.trackAddress1 !== '')
                count++;
            if (this.state.keepAliveData.trackAddress2 !== '')
                count++;
            if (this.state.keepAliveData.trackAddress3 !== '')
                count++;
            if (parseInt(value) < 1 || parseInt(value) > count) {
                const element = document.getElementById('reliability');
                element.style.display = "block";
                this.setState({
                    showError: true,
                });
            } else {
                const element = document.getElementById('reliability');
                element.style.display = "none";
                this.setState({
                    showError: false,
                });
            }
        }
        else if (name === 'refreshIntervalPing' && (value === "" || parseInt(value) < 10 || parseInt(value) > 86400)) {
            const element = document.getElementById(errorId);
            element.style.display = "block";
            this.setState({
                showError: true,
            });
        }
        //Long-1647 Invalid Value Error in Track IP text box
        //LONG-2998-EM - Field validation for track address,Domain name should be included in keepalive configuration
        if (name === 'trackAddress1' || name === "hostname1" || name === "hostname2" || name === "hostname3") {
            if (name === "hostname1" || name === "hostname2" || name === "hostname3") {
                const element = document.getElementById('hostError');
                element.style.display = "none";
                this.setState({
                    showError: false,
                });
            }
            if (value === "") {
                const element = document.getElementById(errorId);
                element.style.display = "block";
                this.setState({
                    showError: true,
                });
            }
            else {
                if (DomainName === false) {
                    if (!(Common.validateIPaddress(e))) {
                        const element = document.getElementById(errorId);
                        element.style.display = "block";
                        this.setState({
                            showError: true,
                        });
                    }
                    else {
                        Common.removeError(e)
                        this.setState({
                            showError: false,
                        });
                    }
                }
                else {
                    Common.removeError(e)
                    this.setState({
                        showError: false,
                    });
                }
            }
            let keepAliveData = this.state.keepAliveData;
            if (((keepAliveData.trackAddress2.toLowerCase() === e.target.value.toLowerCase()) || (e.target.value.toLowerCase().substring(0, 4) === 'www.' && e.target.value.toLowerCase().substring(4, e.target.value.length) === keepAliveData.trackAddress2.toLowerCase()))
                || (keepAliveData.trackAddress3.toLowerCase() === e.target.value.toLowerCase()) || (e.target.value.toLowerCase().substring(0, 4) === 'www.' && e.target.value.toLowerCase().substring(4, e.target.value.length) === keepAliveData.trackAddress3.toLowerCase())) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({
                    showError: true,
                });
            }
        }
        if (name === 'trackAddress2' && value !== "") {
            if (e.target.value !== "") {
                if (DomainName === false) {
                    if (!(Common.validateIPaddress(e)
                        && e.target.value !== '')) {
                        const element = document.getElementById(errorId);
                        element.style.display = "block";
                        element.innerHTML = "Invalid Value";
                        this.setState({
                            showError: true,
                        });
                    }
                    else {
                        Common.removeError(e)
                        this.setState({
                            showError: false,
                        });
                    }
                }
                else {
                    Common.removeError(e)
                    this.setState({
                        showError: false,
                    });
                }
                if (e.target.value !== "") {
                    //LONG-2998-EM - Field validation for track address,Domain name should be included in keepalive configuration
                    let keepAliveData = this.state.keepAliveData;
                    if ((keepAliveData.trackAddress1.toLowerCase() === e.target.value.toLowerCase() ||
                        (e.target.value.toLowerCase().substring(0, 4) === 'www.' &&
                            e.target.value.toLowerCase().substring(4, e.target.value.length) ===
                            keepAliveData.trackAddress1.toLowerCase()))
                        || keepAliveData.trackAddress3.toLowerCase() === e.target.value.toLowerCase() ||
                        (e.target.value.toLowerCase().substring(0, 4) === 'www.' &&
                            e.target.value.toLowerCase().substring(4, e.target.value.length) === keepAliveData.trackAddress3.toLowerCase())) {
                        const element = document.getElementById(e.target.name);
                        element.style.display = "block";
                        element.innerHTML = "Invalid Value-value cannot be same";
                        this.setState({
                            showError: true,
                        });
                    }
                }
            } else {
                const element = document.getElementById(e.target.name);
                element.style.display = "none";
                element.innerHTML = "";
            }

        }
        //LONG-2998-EM - Field validation for track address,Domain name should be included in keepalive configuration
        if (name === 'trackAddress3') {
            if (e.target.value !== "") {
                if (DomainName === false) {
                    if (e.target.value !== '' && !Common.validateIPaddress(e)) {
                        const element = document.getElementById(errorId);
                        element.style.display = "block";
                        element.innerHTML = "Invalid Value";
                        this.setState({
                            showError: true,
                        });
                    }
                    else {
                        Common.removeError(e)
                        this.setState({
                            showError: false,
                        });
                    }
                }
                else {
                    Common.removeError(e)
                    this.setState({
                        showError: false,
                    });
                }
                let keepAliveData = this.state.keepAliveData;
                if (e.target.value) {
                    if (((keepAliveData.trackAddress1.toLowerCase() === e.target.value.toLowerCase()) || (e.target.value.toLowerCase().substring(0, 4) === 'www.' && e.target.value.toLowerCase().substring(4, e.target.value.length) === keepAliveData.trackAddress1.toLowerCase()))
                        || (keepAliveData.trackAddress2.toLowerCase() === e.target.value.toLowerCase()) || (e.target.value.toLowerCase().substring(0, 4) === 'www.' && e.target.value.toLowerCase().substring(4, e.target.value.length) === keepAliveData.trackAddress2.toLowerCase())) {
                        const element = document.getElementById(e.target.name);
                        element.style.display = "block";
                        element.innerHTML = "Invalid Value-value cannot be same";
                        this.setState({
                            showError: true,
                        });
                    }
                }
            }
            else {
                const element = document.getElementById(e.target.name);
                element.style.display = "none";
                element.innerHTML = "";
            }

        }
        // if (name === 'refreshIntervalDNS' && (value === "" || parseInt(value) < 10 || parseInt(value) > 86400)) {
        //     const element = document.getElementById(errorId);
        //     element.style.display = "block";
        //     this.setState({
        //         showError: true,
        //     });
        // }
        // //LONG-2998-EM - Field validation for track address,Domain name should be included in keepalive configuration
        // if (name === 'hostname') {
        //     if (value !== "") {
        //         if (DomainName === false) {
        //             if (!Common.validateIPaddress(e)) {
        //                 const element = document.getElementById(errorId);
        //                 element.style.display = "block";
        //                 this.setState({
        //                     showError: true,
        //                 });
        //             }
        //             else {
        //                 Common.removeError(e)
        //                 this.setState({
        //                     showError: false,
        //                 });
        //             }
        //         } else {
        //             Common.removeError(e)
        //             this.setState({
        //                 showError: false,
        //             });
        //         }
        //     } else {
        //         const element = document.getElementById(errorId);
        //         element.style.display = "block";
        //         this.setState({
        //             showError: true,
        //         });
        //     }

        // }
        if (name === 'retryCount' && (value === "" || parseInt(value) < 10 || parseInt(value) > 86400)) {
            const element = document.getElementById(errorId);
            element.style.display = "block";
            this.setState({
                showError: true,
            });
        }
        if (name === "standbyInterval" && (value === "" || parseInt(value) < 1 || parseInt(value) > 1440)) {
            const element = document.getElementById(errorId);
            element.style.display = "block";
            this.setState({
                showError: true,
            });
        }
        if (name === "activeInterval" && (value === "" || parseInt(value) < 30 || parseInt(value) > 86400)) {
            const element = document.getElementById(errorId);
            element.style.display = "block";
            this.setState({
                showError: true,
            });
        }
        if (name === "failedInterval" && (value === "" || parseInt(value) < 10 || parseInt(value) > 600)) {
            const element = document.getElementById(errorId);
            element.style.display = "block";
            this.setState({
                showError: true,
            });
        }


        if (name === 'hostname1' || name === 'hostname2' || name === 'hostname3') {
            if (value !== "") {
                if (DomainName === false) {
                    if (!Common.validateIPaddress(e)) {
                        const element = document.getElementById(errorId);
                        element.innerHTML = "Invalid hostname"
                        element.style.display = "block";
                        this.setState({
                            showError: true,
                        });
                    }
                    else {
                        Common.removeError(e)
                        this.setState({
                            showError: false,
                        });
                    }
                } else {
                    Common.removeError(e)
                    this.setState({
                        showError: false,
                    });
                }
            } else {
                const element = document.getElementById(errorId);
                element.style.display = "block";
                this.setState({
                    showError: true,
                });
            }
        }
        if (this.state.keepAliveData.hostname1 !== '' && this.state.keepAliveData.hostname2 !== '' && this.state.keepAliveData.hostname3 !== '')
            this.checkHostnames();
    }
    checkHostnames = () => {
        const str1 = this.state.keepAliveData.hostname1.toLowerCase().substring(0, 4) === 'www.' ?
            this.state.keepAliveData.hostname1.toLowerCase().substring(4, this.state.keepAliveData.hostname1.length) :
            this.state.keepAliveData.hostname1.toLowerCase();
        const str2 = this.state.keepAliveData.hostname2.toLowerCase().substring(0, 4) === 'www.' ?
            this.state.keepAliveData.hostname2.toLowerCase().substring(4, this.state.keepAliveData.hostname2.length) :
            this.state.keepAliveData.hostname2.toLowerCase();
        const str3 = this.state.keepAliveData.hostname3.toLowerCase().substring(0, 4) === 'www.' ?
            this.state.keepAliveData.hostname3.toLowerCase().substring(4, this.state.keepAliveData.hostname3.length) :
            this.state.keepAliveData.hostname3.toLowerCase();
        if (str1 === str2 || str1 === str3 || str2 === str3) {
            const element = document.getElementById('hostError');
            if (element) {
                element.style.display = "block";
                this.setState({
                    showError: true,
                });
            }
        } else {
            const element = document.getElementById('hostError');
            if (element) {
                element.style.display = "none";
                this.setState({
                    showError: false,
                });
            }
        }
    }
    validateTunnelValues = (e) => {
        const name = e.target.name;
        const errorId = `${e.target.name}_tunnel`;
        let value = e.target.value;
        let DomainName = /^(?:www\.)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,63})+$/.test(value);

        if ((name === 'count' || name === 'up' || name === 'down') && (value === "" ||
            (parseInt(value) < 1 || parseInt(value) > 100))) {
            const element = document.getElementById(errorId);
            if (element) {
                element.style.display = "block";
                this.setState({
                    showTunnelerror: true,
                });
            }
        }
        else if (name === 'reliability' || name === 'trackAddress1' || name === 'trackAddress2' ||
            name === 'trackAddress3') {
            let count = 0;
            value = name === 'reliability' ? value : this.state.interfaces_keepalive_data.reliability;
            if (this.state.interfaces_keepalive_data.trackAddress1 !== '')
                count++;
            if (this.state.interfaces_keepalive_data.trackAddress2 !== '')
                count++;
            if (this.state.interfaces_keepalive_data.trackAddress3 !== '')
                count++;
            if (parseInt(value) < 1 || parseInt(value) > count) {
                const element = document.getElementById('reliability_tunnel');
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showTunnelerror: true,
                    });
                }
            } else {
                const element = document.getElementById('reliability_tunnel');
                if (element) {
                    element.style.display = "none";
                    this.setState({
                        showTunnelerror: false,
                    });
                }
            }
        }
        else if (name === 'refreshIntervalPing' && (value === "" || parseInt(value) < 10 || parseInt(value) > 86400)) {
            const element = document.getElementById(errorId);
            if (element) {
                element.style.display = "block";
                this.setState({
                    showTunnelerror: true,
                });
            }
        }

        //Long-1647 Invalid Value Error in Track IP text box
        if (name === 'trackAddress1' || name === "hostname1" || name === "hostname2" || name === "hostname3") {
            if (name === "hostname1" || name === "hostname2" || name === "hostname3") {
                const element = document.getElementById('hostError_tunnel');
                if (element) {
                    element.style.display = "none";
                    this.setState({
                        showError: false,
                    });
                }
            } if (value === "") {
                const element = document.getElementById(errorId);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showTunnelerror: true,
                    });
                }
            }
            else {
                if (DomainName === false) {
                    if (!Common.validateIPaddress(e)) {
                        const element = document.getElementById(errorId);
                        if (element) {
                            element.style.display = "block";
                            this.setState({
                                showTunnelerror: true,
                            });
                        }
                    }
                    else {
                        const element = document.getElementById(errorId);
                        if (element) {
                            element.style.display = 'none';
                        }
                        this.setState({
                            showTunnelerror: false,
                        });
                    }
                }
                else {
                    const element = document.getElementById(errorId);
                    if (element) {
                        element.style.display = 'none';
                    }
                    this.setState({
                        showTunnelerror: false,
                    });
                    this.setTunnelKeepaliveData(e);
                }
            }
            let keepAliveData = this.state.interfaces_keepalive_data;
            if ((keepAliveData.trackAddress2.toLowerCase() === e.target.value.toLowerCase() ||
                (e.target.value.toLowerCase().substring(0, 4) === 'www.' && e.target.value.toLowerCase().substring(4, e.target.value.length) === keepAliveData.trackAddress2.toLowerCase())
                || (keepAliveData.trackAddress3.toLowerCase() === e.target.value.toLowerCase() ||
                    (e.target.value.toLowerCase().substring(0, 4) === 'www.' &&
                        e.target.value.toLowerCase().substring(4, e.target.value.length) === keepAliveData.trackAddress3.toLowerCase())))) {
                const element = document.getElementById(errorId);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showTunnelerror: true,
                    });
                }
            }
        }

        if ((name === 'trackAddress2' || name === 'trackAddress3') && value !== "") {
            if (DomainName === false) {
                if (e.target.value !== '' && !Common.validateIPaddress(e)) {
                    const element = document.getElementById(errorId);
                    if (element) {
                        element.style.display = "block";
                        this.setState({
                            showTunnelerror: true,
                        });
                    }
                }
                else {
                    const element = document.getElementById(errorId);
                    if (element) {
                        element.style.display = 'none';
                    }
                    this.setState({
                        showTunnelerror: false,
                    });
                }
            }

            else {
                const element = document.getElementById(errorId);
                if (element) {
                    element.style.display = 'none';
                }
                this.setState({
                    showTunnelerror: false,
                });
            }
            if (e.target.value !== "") {
                //LONG-2998-EM - Field validation for track address,Domain name should be included in keepalive configuration
                let keepAliveData = this.state.interfaces_keepalive_data;
                if ((keepAliveData.trackAddress1.toLowerCase() === e.target.value.toLowerCase() ||
                    (e.target.value.toLowerCase().substring(0, 4) === 'www.' &&
                        e.target.value.toLowerCase().substring(4, e.target.value.length) ===
                        keepAliveData.trackAddress1.toLowerCase()))
                    || keepAliveData.trackAddress3.toLowerCase() === e.target.value.toLowerCase() ||
                    (e.target.value.toLowerCase().substring(0, 4) === 'www.' &&
                        e.target.value.toLowerCase().substring(4, e.target.value.length) === keepAliveData.trackAddress3.toLowerCase())) {
                    const element = document.getElementById(errorId);
                    if (element) {
                        element.style.display = "block";
                        this.setState({
                            showTunnelerror: true,
                        });
                    }
                }
            }
        }
        //LONG-2998-EM - Field validation for track address,Domain name should be included in keepalive configuration
        if (name === 'trackAddress3' && e.target.value !== "") {
            if (DomainName === false) {
                if (e.target.value !== '' && !Common.validateIPaddress(e)) {
                    const element = document.getElementById(errorId);
                    if (element) {
                        element.style.display = "block";
                        this.setState({
                            showTunnelerror: true,
                        });
                    }
                }
                else {
                    Common.removeError(e)
                    this.setState({
                        showTunnelerror: false,
                    });
                }
            }
            else {
                Common.removeError(e)
                this.setState({
                    showTunnelerror: false,
                });
            }
            let keepAliveData = this.state.keepAliveData;
            if (e.target.value) {
                if (((keepAliveData.trackAddress1.toLowerCase() === e.target.value.toLowerCase()) || (e.target.value.toLowerCase().substring(0, 4) === 'www.' && e.target.value.toLowerCase().substring(4, e.target.value.length) === keepAliveData.trackAddress1.toLowerCase()))
                    || (keepAliveData.trackAddress2.toLowerCase() === e.target.value.toLowerCase()) || (e.target.value.toLowerCase().substring(0, 4) === 'www.' && e.target.value.toLowerCase().substring(4, e.target.value.length) === keepAliveData.trackAddress2.toLowerCase())) {
                    const element = document.getElementById(errorId);
                    if (element) {
                        element.style.display = "block";
                        element.innerHTML = "Invalid Value-value cannot be same";
                        this.setState({
                            showTunnelerror: true,
                        });
                    }
                }
            }

        }
        if (name === 'retryCount' && (value === "" || parseInt(value) < 10 || parseInt(value) > 86400)) {
            const element = document.getElementById(errorId);
            if (element) {
                element.style.display = "block";
                this.setState({
                    showTunnelerror: true,
                });
            }
        }
        if (name === "standbyInterval" && (value === "" || parseInt(value) < 1 || parseInt(value) > 1440)) {
            const element = document.getElementById(errorId);
            if (element) {
                element.style.display = "block";
                this.setState({
                    showTunnelerror: true,
                });
            }
        }
        if (name === "activeInterval" && (value === "" || parseInt(value) < 30 || parseInt(value) > 86400)) {
            const element = document.getElementById(errorId);
            if (element) {
                element.style.display = "block";
                this.setState({
                    showTunnelerror: true,
                });
            }
        }
        if (name === "failedInterval" && (value === "" || parseInt(value) < 10 || parseInt(value) > 600)) {
            const element = document.getElementById(errorId);
            if (element) {
                element.style.display = "block";
                this.setState({
                    showTunnelerror: true,
                });
            }
        }
        if (name === 'hostname1' || name === 'hostname2' || name === 'hostname3') {
            if (value !== "") {
                if (DomainName === false) {
                    if (!Common.validateIPaddress(e)) {
                        const element = document.getElementById(errorId);
                        if (element) {
                            element.style.display = "block";
                            this.setState({
                                showTunnelerror: true,
                            });
                        }
                    }
                    else {
                        Common.removeError(e)
                        this.setState({
                            showTunnelerror: false,
                        });
                    }
                } else {
                    Common.removeError(e)
                    this.setState({
                        showTunnelerror: false,
                    });
                }
            } else {
                const element = document.getElementById(errorId);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showTunnelerror: true,
                    });
                }
            }
        }
        if (name === 'dns_server') {
            if (value === "") {
                const element = document.getElementById(errorId);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showTunnelerror: true,
                    });
                }
            }
            else {
                if (!Common.validateIPaddress(e)) {
                    const element = document.getElementById(errorId);
                    if (element) {
                        element.style.display = "block";
                        this.setState({
                            showTunnelerror: true,
                        });
                    }
                }
                else {
                    const element = document.getElementById(errorId);
                    if (element) {
                        element.style.display = 'none';
                    }
                    this.setState({
                        showTunnelerror: false,
                    });
                    this.setTunnelKeepaliveData(e);
                }
            }

        }
        if (this.state.interfaces_keepalive_data.hostname1 !== '' &&
            this.state.interfaces_keepalive_data.hostname2 !== '' &&
            this.state.interfaces_keepalive_data.hostname3 !== '')
            this.checkTunnelHostnames();
    }
    checkTunnelHostnames = () => {
        const str1 = this.state.interfaces_keepalive_data.hostname1.toLowerCase().substring(0, 4) === 'www.' ?
            this.state.interfaces_keepalive_data.hostname1.toLowerCase().substring(4, this.state.interfaces_keepalive_data.hostname1.length) :
            this.state.interfaces_keepalive_data.hostname1.toLowerCase();
        const str2 = this.state.interfaces_keepalive_data.hostname2.toLowerCase().substring(0, 4) === 'www.' ?
            this.state.interfaces_keepalive_data.hostname2.toLowerCase().substring(4, this.state.interfaces_keepalive_data.hostname2.length) :
            this.state.interfaces_keepalive_data.hostname2.toLowerCase();
        const str3 = this.state.interfaces_keepalive_data.hostname3.toLowerCase().substring(0, 4) === 'www.' ?
            this.state.interfaces_keepalive_data.hostname3.toLowerCase().substring(4, this.state.interfaces_keepalive_data.hostname3.length) :
            this.state.interfaces_keepalive_data.hostname3.toLowerCase();
        if (str1 === str2 || str1 === str3 || str2 === str3) {
            const element = document.getElementById('hostError_tunnel');
            if (element) {
                element.style.display = "block";
                this.setState({
                    showTunnelerror: true,
                });
            }
        } else {
            const element = document.getElementById('hostError_tunnel');
            if (element) {
                element.style.display = "none";
                this.setState({
                    showTunnelerror: false,
                });
            }
        }
    }
    setWeight = (e, network) => {
        let { template_schema_json } = this.state;
        template_schema_json.wan_data.map((item) => {
            if (item.networkName === network.networkName) {
                item.weight = e.target.value.toString();
            }
            return item;
        })
        this.setState({
            template_schema_json
        }, () => {
            let sum = 0;
            let weightError = false;
            for (let i = 0; i < this.state.template_schema_json.wan_data.length; i++) {
                sum = parseInt(this.state.template_schema_json.wan_data[i].weight) + sum;
                if (this.state.template_schema_json.wan_data[i].weight === '0') {
                    weightError = true;
                }
            }
            if (!weightError) {
                if (sum !== 100) {

                    weightError = true;
                } else {
                    weightError = false;
                }
            }

            this.setState({
                weightError
            })
        })
    }

    checkKeepAliveError = () => {
        let result = false
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        return result
    }

    saveKeepAliveData = () => {
        let templateData = JSON.parse(JSON.stringify(this.state.template_schema_json));
        let wan_data = templateData.wan_data;
        // LONG-1632 : Fix for saved configurations are not retained in edit page
        let changed_wan_data = wan_data.map((network) => {
            if (network.networkName === this.state.keepAliveData.networkName) {
                network = this.state.keepAliveData;
                return network;
            } else {
                return network;
            }
        })
        templateData.wan_data = changed_wan_data;
        this.setState({
            template_schema_json: templateData
        });
        this.closeKeepAlive();
    }

    openKeepAlive = (network) => {
        this.setState({
            keepAlive: true,
            keepAliveData: JSON.parse(JSON.stringify(network))
        })
    }

    closeKeepAlive = () => {
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;"
        }
        this.setState({
            keepAlive: false,
            showError: false
        })
    }

    getPriorityOptions = () => {
        let options = [];
        for (let i = 1; i <= this.state.template_schema_json.wan_data.length; i++) {
            options.push(<option value={i.toString()}
                disabled={this.state.usedPriority.includes(i.toString())}>{i}</option>)
        }

        return options;
    }


    checkTunnelError = () => {
        let result = this.state.showTunnelerror;
        const elements = document.getElementsByClassName('error-txt tunnel');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        const { interfaces_keepalive_data } = this.state;
        if (!result) {
            if (interfaces_keepalive_data.keepaliveMethod === 'ping') {
                if ((
                    // !this.props.deviceGroup && 
                    interfaces_keepalive_data.trackAddress1 === '') || interfaces_keepalive_data.reliability === '' ||
                    interfaces_keepalive_data.up === '' || interfaces_keepalive_data.count === '' ||
                    interfaces_keepalive_data.down === '') {
                    result = true;
                }

                if (interfaces_keepalive_data.refreshIntervalPing === '')
                    result = true;
            } else {
                if (interfaces_keepalive_data.retryCount === '' || interfaces_keepalive_data.failedInterval === '' ||
                    interfaces_keepalive_data.activeInterval === '' || interfaces_keepalive_data.standbyInterval === '' ||
                    interfaces_keepalive_data.hostname1 === '' || interfaces_keepalive_data.hostname2 === '' ||
                    interfaces_keepalive_data.hostname3 === '' || interfaces_keepalive_data.dns_server === '')
                    result = true;
            }
        }
        return result;
    }
    setTunnelKeepaliveConfig = (e) => {
        this.closeTunnelKeepAlive();
        const name = e ? e.target.value : (this.state.template_schema_json && this.state.template_schema_json.tun_interfaces_data &&
            this.state.template_schema_json.tun_interfaces_data.length > 0 && this.state.template_schema_json.tun_interfaces_data[0]?.keepaliveMethod);
        let { template_schema_json } = this.state;
        template_schema_json.tun_interfaces_data.map((network, index) => {
            let network_data = {
                "interfaceName": network.interfaceName,
                "keepaliveMethod": name,
                "weight": "0",
                "up": "2",
                "down": "2",
                "count": "1",
                "reliability": "1",
                "trackAddress1": "",
                "trackAddress2": "",
                "trackAddress3": "",
                "refreshIntervalPing": "10",
                "refreshIntervalDNS": "10",
                "hostname": "www.google.com",
                "dns_server": "",
                "retryCount": "10",
                "activeInterval": "30",
                "standbyInterval": "20",
                "failedInterval": "20",
                "hostname1": "www.google.com",
                "hostname2": "www.inseego.com",
                "hostname3": "www.bing.com",
            };
            const selectedNetwork = this.state.tunnelNetworks.filter(item => item.interfaceName === network.interfaceName)[0];
            if (name === 'ping') {
                network_data.trackAddress1 = selectedNetwork.remote_tunnelIp;
            } else {
                network_data.dns_server = "8.8.4.4";
            }
            template_schema_json.tun_interfaces_data[index] = network_data;
            return network;
        });
        this.setState({
            template_schema_json
        })
    }
    setTunnelKeepaliveData = (e) => {
        const element = document.getElementById(`${e.target.name}_tunnel`);
        if (element) {
            element.style.display = "none";
            this.setState({
                showError: false,
            });
        }
        let tunnelKeepAliveData = this.state.interfaces_keepalive_data;
        const name = e.target.name;
        tunnelKeepAliveData[name] = e.target.value;
        if (name === "trackAddress1") {
            if (tunnelKeepAliveData.trackAddress2.toLowerCase() === e.target.value.toLowerCase() ||
                tunnelKeepAliveData.trackAddress3.toLowerCase() === e.target.value.toLowerCase()) {
                const element = document.getElementById(`${e.target.name}_tunnel`);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showTunnelerror: true,
                    });
                }
            } else {
                tunnelKeepAliveData[name] = e.target.value;
                const element = document.getElementById(`${e.target.name}_tunnel`);
                if (element) {
                    element.style.display = "none";
                    this.setState({
                        showTunnelerror: false,
                    });
                }
            }
        }

        if (name === "trackAddress2") {
            if (tunnelKeepAliveData.trackAddress1.toLowerCase() === e.target.value.toLowerCase() ||
                tunnelKeepAliveData.trackAddress3.toLowerCase() === e.target.value.toLowerCase()) {
                const element = document.getElementById(`${e.target.name}_tunnel`);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showTunnelerror: true,
                    });
                }
            } else {
                tunnelKeepAliveData[name] = e.target.value;
                const element = document.getElementById(`${e.target.name}_tunnel`);
                if (element) {
                    element.style.display = "none";
                    this.setState({
                        showTunnelerror: false,
                    });
                }
            }
        }

        if (name === "trackAddress3") {
            if (tunnelKeepAliveData.trackAddress1.toLowerCase() === e.target.value.toLowerCase() || tunnelKeepAliveData.trackAddress2.toLowerCase() === e.target.value.toLowerCase()) {
                const element = document.getElementById(`${e.target.name}_tunnel`);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showTunnelerror: true,
                    });
                }
            } else {
                tunnelKeepAliveData[name] = e.target.value;
                const element = document.getElementById(`${e.target.name}_tunnel`);
                if (element) {
                    element.style.display = "none";
                    this.setState({
                        showTunnelerror: false,
                    });
                }
            }
        }
        if (name === "dns_server" || name === 'hostname') {
            tunnelKeepAliveData[name] = e.target.value;
            const element = document.getElementById(`${e.target.name}_tunnel`);
            if (element) {
                element.style.display = "none";
                this.setState({
                    showTunnelerror: false,
                });
            }
        }

        this.setState({
            interfaces_keepalive_data: tunnelKeepAliveData
        })
    }
    saveKeepAliveData = () => {
        let templateData = JSON.parse(JSON.stringify(this.state.template_schema_json));
        let wan_data = templateData.wan_data;
        // LONG-1632 : Fix for saved configurations are not retained in edit page
        let changed_wan_data = wan_data.map((network) => {
            if (network.networkName === this.state.keepAliveData.networkName) {
                network = this.state.keepAliveData;
                return network;
            } else {
                return network;
            }
        })
        templateData.wan_data = changed_wan_data;
        this.setState({
            template_schema_json: templateData
        });
        this.closeKeepAlive();
    }
    openTunnelKeepAlive = (network) => {
        let { template_schema_json } = this.state;
        template_schema_json.tun_interfaces_data.map((item) => {
            if (item.interfaceName === network.interfaceName) {
                network.trackAddress1 = item.trackAddress1
            }
        });
        this.setState({
            tunnelKeepalive: true,
            interfaces_keepalive_data: JSON.parse(JSON.stringify(network))
        })
    }

    closeTunnelKeepAlive = () => {
        const elements = document.getElementsByClassName("error-txt tunnel");
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;"
        }
        this.setState({
            tunnelKeepalive: false,
        })
    }
    saveTunnelKeepAliveData = () => {
        let templateData = (this.state.template_schema_json);
        let tun_data = templateData.tun_interfaces_data;
        // LONG-1632 : Fix for saved configurations are not retained in edit page
        let changed_tun_data = tun_data.map((network) => {
            if (network.interfaceName === this.state.interfaces_keepalive_data.interfaceName) {
                network = this.state.interfaces_keepalive_data;
                return network;
            } else {
                return network;
            }
        })
        templateData.tun_interfaces_data = changed_tun_data;
        this.setState({
            template_schema_json: templateData
        });
        this.closeTunnelKeepAlive();
    }
    openAddRule = (rule) => {
        if (rule) {
            this.setState({
                addRule: true,
                update: true,
                addRuleData: JSON.parse(JSON.stringify(rule))
            }, () => {
                let availableTunnelNetworks = [];
                availableTunnelNetworks = this.state.addRuleData.interfaceName.length > 0 ? this.state.allNetworks.filter(network =>
                    !this.state.addRuleData.interfaceName.includes(network.interfaceName)) : this.state.allNetworks;
                this.setState({
                    availableTunnelNetworks,
                    selectedZone: rule.interfaceName.length > 0 ? this.state.allNetworks.filter(network =>
                        network.interfaceName === rule.interfaceName[0])[0]?.network_zone : ""
                })
            })
        } else {
            let addRuleData = {
                "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "rule_name": "",
                "src_ip": ["ANY"],
                "src_port": "ANY",
                "dest_ip": ["ANY"],
                "dest_port": "ANY",
                "proto": "all",
                "order": "",
                "interfaceName": [],
                "weight": [],
                "isdeleted": '0'
            }
            this.setState({
                addRuleData,
                addRule: true,
                availableTunnelNetworks: this.state.allNetworks
            })
        }
    }
    closeAddRule = () => {
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;"
        }
        this.setState({
            addRule: false,
            update: false,
            selectedZone: ''
        })
    }
    saveData = () => {
        let data = {}
        data.template_name = this.state.template_name;
        data.template_desc = this.state.template_desc;
        data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
        data.template_type = this.state.template_type;
        data.collection_version = this.state.collection_version;
        data.fw_version = this.state.fw_version;
        data.template_schema_json = this.state.template_schema_json;
        if (this.state.selectedTemplateId === "") {
            data.template_version = {
                chimay: this.state.chimay,
                cbr: this.state.cbr,
                mifi: this.state.mifi
            }
        }
        if (data.template_schema_json.wan_data[0].status === '0') {
            data.template_schema_json.wan_data = this.state.template_schema_json.wan_data.filter(item =>
                item.networkName === this.state.selectInterface);
        }
        data.template_schema_json.wan_data.map((network) => {
            if (network.keepaliveMethod === 'ping')
                network.refreshIntervalDNS = '';
            else
                network.refreshIntervalPing = '';
            return network;
        })
        this.setState({
            showLoader: true,
        });
        if (this.state.selectedTemplateId !== '') {
            ApiCall(urlMapping.updateTemplate(this.state.selectedTemplateId, data), (response) => {
                this.setState({
                    showLoader: false,
                });

                if (response?.Success) {
                    this.props.refreshList();
                    this.props.closeAddTemplate();

                } else {
                    $("#errorMessage").modal("hide");
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({ showRefreshModal: true })
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Update Template',
                            message: response?.message,
                            show: true,
                            error: true,
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            });
        } else {
            ApiCall(urlMapping.createTemplate(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success) {
                    this.props.refreshList();
                    this.props.openApplyTemplate(response.data.id);
                    this.props.closeAddTemplate();
                } else {
                    $("#errorMessage").modal("hide");
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({ showRefreshModal: true })
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Create Template',
                            message: response?.message,
                            show: true,
                            error: true,
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            });
        }
    }
    checkError = () => {
        let result = false;
        if (this.props.applying)
            result = true;
        let elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        elements = document.getElementsByClassName('form-control w-90 error-field-interface');
        if (elements.length > 0)
            result = true;
        //LONG-2541-Apply button is enabled without selecting WAN interface in auto wan disabled case
        if (this.state.template_schema_json.wan_data[0]?.status === "0" && this.state.selectInterface === "") {
            result = true;
        }
        if (this.state.template_name === '' ||
            this.state.template_type === '')
            result = true;
        if (this.props.apply) {
            if (this.state.template_schema_json.tun_data.rule_data.length > 0) {
                // var valueArr = this.state.template_schema_json.tun_data.rule_data.map(function (item) { return item.order });
                // var isDuplicate = valueArr.some(function (item, idx) {
                //     return valueArr.indexOf(item) !== idx
                // });
                // if (isDuplicate)
                //     result = true;
                if (this.state.template_schema_json.tun_data.rule_data.filter(rule =>
                    rule.interfaceName.length === 0).length > 0)
                    result = true;
            }
            if (this.state.template_schema_json.wan_data[0]?.status === '1') {
                if (this.state.template_schema_json.wan_data.filter(item => item.priority === '').length > 0)
                    result = true;
            }
        }
        if (this.state.template_schema_json.wan_data[0]?.statusLoadBal === '1') {
            let sum = 0;

            for (let i = 0; i < this.state.template_schema_json.wan_data.length; i++) {
                sum = parseInt(this.state.template_schema_json.wan_data[i].weight) + sum;
                if (this.state.template_schema_json.wan_data[i].weight === "") {
                    result = true;
                }
                if (this.state.template_schema_json.wan_data[i].weight === '0') {
                    result = true;
                }
            }
            if (sum !== 100) {
                result = true;
            }
        }
        return result;
    }
    //LONG-2656-notification popup for user after enable load balancing
    resetPopupLoadBalancing = () => {
        let { template_schema_json } = this.state;
        template_schema_json.wan_data[0].statusLoadBal = 0
        let { selectInterface } = this.state;
        template_schema_json.wan_data.map((network) => {
            if (this.state.template_schema_json.wan_data[0]?.status === '1') {
                network.status = '0';
                this.calculateWeight();
            } else {
                selectInterface = 'wan';
                network.weight = '';
            }
            network.statusLoadBal = this.state.template_schema_json.wan_data[0]?.status;
            return network;
        })
        this.setState({
            template_schema_json,
            selectInterface,
            showMessage: false,
            error: false,
        })

    }
    resetPopup = () => {
        if (this.state.showMessage) {
            this.setState({
                showMessage: false,
                error: false,
                info: false,
                delete: false
            })
        }
        else {
            this.setState({
                show: false,
                error: false
            }, () => {
                $("#errorMessage").modal("hide");
                this.props.refreshList();
                this.props.closeAddTemplate();
            });
        }
    }
    getNetworkName = (name) => {
        const network = this.state.networkViewData.filter(item => item.networkName === name)[0];
        let networkName = ''
        if (network)
            networkName = network.name;
        return networkName;
    }
    applyTemplate = () => {
        let { template_schema_json } = this.state;
        if (template_schema_json.wan_data[0].status === '0' && template_schema_json.wan_data[0].statusLoadBal === '0') {
            template_schema_json.wan_data = this.state.template_schema_json.wan_data.filter(item =>
                item.networkName === this.state.selectInterface);
        }
        template_schema_json.wan_data.map((network) => {
            if (network.statusLoadBal === '1') {
                network.priority = '';
            } else {
                network.weight = '';
            }
            if (network.keepaliveMethod === 'ping')
                network.refreshIntervalDNS = '';
            else
                network.refreshIntervalPing = '';
            return network;
        })
        this.props.dispatch({
            type: Action.SET_APPLY_TEMPLATE,
        });
        this.props.applyTemplate(template_schema_json);
    }
    debounceApply = debounce((fn, event) => {
        fn(event)
    }, 500);
    checkRuleName = (e) => {
        const element = document.getElementById(e.target.name);
        if (e.target.value === "") {
            element.innerHTML = "Invalid Value";
            element.style.display = "block"
            this.setState({
                showError: true
            })
        }
        let valid = true;
        if (Common.validateAlphaNumericUnder(e)) {
            if (
                this.state.template_schema_json.tun_data.rule_data.filter(
                    (item) => (item.rule_name.toUpperCase() === e.target.value.toUpperCase() && item.uuid !== this.state.addRuleData.uuid &&
                        item.rule_name === e.target.value && item.isdeleted !== "1")
                ).length > 0
            ) {
                valid = false;
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = "block";
                    element.innerHTML = "Rule already exists!";
                    this.setState({
                        showError: true,
                    });
                }
            }
            else if (e.target.value.length < 1 || e.target.value.length > 20) {
                valid = false;
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = "block";
                    element.innerHTML = "Enter 1 to 20 characters";
                    this.setState({
                        showError: true,
                    });
                }
            } else {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = "none";
                    element.innerHTML = "";
                    this.setState({
                        showError: false,
                    });
                }
            }
            const data = this.state.addRuleData;
            data[e.target.name] = e.target.value;
            this.setState({
                addRuleData: data
            })
        } else {
            valid = false;
            element.innerHTML = "Invalid Value";
            element.style.display = "block"
            this.setState({
                showError: true
            })
        }
        return valid;
    }
    validateIPaddress = (ipaddress, name) => {
        if (ipaddress !== '') {
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
                return (true);
            }
            const element = document.getElementById(name);
            element.style.display = 'block';
            this.setState({
                showError: true
            })
            return (false);
        }
    }
    validateIPaddressWithSubnet = (ipaddress, name) => {
        if (ipaddress !== '') {
            if (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-9]|0[0-9]|1[0-9]|2[0-9]|3[0-2])$/.test(ipaddress)) {
                return (true);
            }
            const element = document.getElementById(name);
            element.style.display = 'block';
            this.setState({
                showError: true
            })
            return (false);
        }
    }
    setRuleData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: false
            })
        }
        let { addRuleData } = this.state;
        const name = e.target.name;
        if (this.state.addRuleData.proto === 'tcp' || this.state.addRuleData.proto === 'udp')
            if ((name === 'src_port' && this.state.addRuleData.dest_port === "ANY") || (name === 'dest_port' && this.state.addRuleData.src_port === "ANY")) {
                const elements = document.querySelectorAll('#src_port,#dest_port');
                elements.forEach(element => {
                    element.style.display = 'none';
                    this.setState({
                        showError: false
                    })
                })
            }
        //LONG-3523-EM - User should notify with error message when providing incorrect configuration
        if (name === 'proto') {
            if (e.target.value === 'icmp' || e.target.value === 'all') {
                const elements = document.querySelectorAll('#src_port,#dest_port');
                elements.forEach(element => {
                    element.style.display = 'none';
                    this.setState({
                        showError: false
                    })
                })
                addRuleData.src_port = "ANY";
                addRuleData.dest_port = "ANY";
            }
            if (e.target.value === 'icmp' || e.target.value === 'all') {
                const element = document.getElementById('src_ip' || 'dest_ip');
                element.innerHTML = "Source or Destination IP should be given in case of Ping/Any protocol";
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
            else {
                const element = document.getElementById('src_ip' || 'dest_ip');
                element.style.display = 'none';
                this.setState({
                    showError: false
                })
            }
            if (e.target.value === 'tcp' || e.target.value === 'udp') {
                if ((this.state.addRuleData.src_port === 'ANY' && this.state.addRuleData.dest_port === 'ANY')
                    || (this.state.addRuleData.src_port === '' && this.state.addRuleData.dest_port === '')) {
                    const element = document.getElementById('src_port' || 'dest_port');
                    element.innerHTML = "Source or Destination port should be given for TCP/UDP protocol";
                    element.style.display = 'block';
                    this.setState({
                        showError: true
                    })
                }
                else {
                    const elements = document.querySelectorAll('#src_port,#dest_port');
                    elements.forEach(element => {
                        element.style.display = 'none';
                        this.setState({
                            showError: false
                        })
                    })
                }
            }
        }
        if (name === 'src_port' || name === 'dest_port') {
            e.target.value = e.target.value.toUpperCase();
        }
        addRuleData[name] = (e.target.value).trim();
        this.setState({
            addRuleData
        }, () => {
            let found = false;
            if (this.state.addRuleData.src_ip !== '' && this.state.addRuleData.src_ip[0] !== 'ANY')
                found = true;
            if (this.state.addRuleData.dest_ip !== '' && this.state.addRuleData.dest_ip[0] !== 'ANY')
                found = true;
            if (this.state.addRuleData.src_port !== '' && this.state.addRuleData.src_port !== 'ANY')
                found = true;
            if (this.state.addRuleData.dest_port !== '' && this.state.addRuleData.dest_port !== 'ANY')
                found = true;
            if (this.state.addRuleData.proto === "tcp" || this.state.addRuleData.proto === "udp")
                found = true;
            if (!found) {
                let element = document.getElementById('src_ip');
                if (element) {
                    element.innerHTML = "At least one value of source and destination IP and ports is required.";
                    element.style.display = 'block';
                    this.setState({
                        showError: true
                    })
                }
            } else {
                if (String(document?.getElementsByName("src_ip")?.[0].value) === '') {
                    let element = document.getElementById('src_ip');
                    if (element) {
                        element.innerHTML = "";
                        element.style.display = 'none';
                        this.setState({
                            showError: false
                        })
                    }
                }
            }
        })
    }
    portValidation = (e) => {
        const element = document.getElementById(e.target.name);
        let value = (e.target.value).trim();
        if (value === "") {
            element.style.display = 'block';
            element.innerHTML = "Invalid Value";
            this.setState({
                showError: true
            })
        }
        else if (value === "ANY" && this.state.addRuleData.proto !== 'udp' && this.state.addRuleData.proto !== 'tcp') {
            element.style.display = 'none';
            this.setState({
                showError: false
            })
        }
        else if (value === "ANY" && (this.state.addRuleData.proto === 'udp' || this.state.addRuleData.proto === 'tcp') &&
            (this.state.addRuleData.src_port === "ANY" && this.state.addRuleData.dest_port === "ANY")) {
            element.innerHTML = "Source or Destination port should be given for TCP/UDP protocol";
            element.style.display = 'block';
            this.setState({
                showError: true
            })
        }
        else if (value !== "ANY" && (this.state.addRuleData.proto === 'all' || this.state.addRuleData.proto === 'icmp')) {
            element.innerHTML = "Protocols such as ICMP and ALL do not support specified ports.";
            element.style.display = 'block';
            this.setState({
                showError: true
            })
        }
        let result = true;
        const portValues = value.split(',');
        portValues.map((items) => {
            const ports = items.split(',');
            ports.map((item) => {
                const values = item.split(':');
                if (values.length > 1) {
                    const regex = /^[0-9]*$/;
                    values.forEach(port => {
                        if (!regex.test(port))
                            result = false;
                    })
                    if (parseInt(values[0]) > parseInt(values[1])) {
                        result = false;
                    }
                    if (parseInt(values[0]) < 0 || parseInt(values[0]) > 65535 ||
                        parseInt(values[1]) < 0 || parseInt(values[1]) > 65535) {
                        result = false;
                    }
                } else if (values.length === 1) {
                    const regex = /^[0-9]*$/;
                    values.forEach(port => {
                        if (!regex.test(port))
                            result = false;
                    })
                    if (parseInt(values[0]) < 0 || parseInt(values[0]) > 65535) {
                        result = false;
                    }
                }
                if (!result) {
                    if (value !== "") {
                        if (element && value !== "ANY") {
                            element.style.display = 'block';
                            element.innerHTML = "Port must be between 0 to 65535";
                            this.setState({
                                showError: true
                            })
                        }
                    }

                }
            })
        })
        if (result) {
            let { addRuleData } = this.state;
            addRuleData[e.target.name] = value;
            this.setState({
                addRuleData
            })
        }
    }
    setInterface = (e, index) => {
        Common.removeError(e)
        this.setState({
            showError: false,
        });
        let { addRuleData } = this.state;
        let { availableTunnelNetworks } = this.state;
        let selectedZone = this.state.selectedZone ? this.state.selectedZone : '';
        // const selectedZone = this.state.addRuleData.interfaceName[0] ?
        //     this.state.allNetworks.filter(item => item.interfaceName === this.state.addRuleData.interfaceName[0])[0]?.network_zone : '';
        const targetZone = this.state.allNetworks.filter(item => item.interfaceName === e.target.id)[0] ?
            this.state.allNetworks.filter(item => item.interfaceName === e.target.id)[0].network_zone : '';
        if (e.target.checked) {
            if (addRuleData.interfaceName[index] === '') {
                if (selectedZone === '') {
                    selectedZone = this.state.allNetworks.filter(item => item.interfaceName === e.target.id)[0].network_zone;
                }
                if (selectedZone === '' || selectedZone === targetZone) {
                    addRuleData.interfaceName[index] = e.target.id;
                    availableTunnelNetworks = availableTunnelNetworks.filter(item => item.interfaceName !== e.target.id);
                }
                // else {
                //     const element = document.getElementById(e.target.name);
                //     element.style.display = 'block';
                //     this.setState({
                //         showError: true
                //     })
                // }
            } else {
                const temp = addRuleData.interfaceName[index];
                addRuleData.interfaceName[index] = e.target.id;
                availableTunnelNetworks = availableTunnelNetworks.filter(item => item.interfaceName !== e.target.id);
                availableTunnelNetworks.push(this.state.allNetworks.filter(item => item.interfaceName === temp)[0]);
            }
        } else {
            addRuleData.interfaceName[index] = "";
            addRuleData.weight[index] = "";
            if (this.state.allNetworks.filter(network => network.interfaceName === e.target.id).length > 0)
                availableTunnelNetworks.push(this.state.allNetworks.filter(item => item.interfaceName === e.target.id)[0]);
        }
        if (addRuleData.interfaceName.length === 0 || addRuleData.interfaceName[0] === '')
            selectedZone = '';
        this.setState({
            addRuleData,
            availableTunnelNetworks,
            selectedZone
        }
        )
    }
    setInterfacesWeight = (e, index) => {
        let { addRuleData } = this.state;
        addRuleData.weight[index] = e.target.value;
        this.setState({
            addRuleData
        }, () => {
            let sum = 0;
            let weightError = false;
            for (let i = 0; i < addRuleData.weight.length; i++) {
                sum = parseInt(addRuleData.weight[i]) + sum;
                if (addRuleData.weight[i] === '0') {
                    weightError = true;
                }
            }
            if (!weightError) {
                if (sum !== 100) {
                    weightError = true;
                } else {
                    weightError = false;
                }
            }
            this.setState({
                ruleWeightError: weightError
            })
        })
    }
    handleDeleteSrcIP = item => {
        let data = this.state.addRuleData;
        data.src_ip = data.src_ip.filter(i => i !== item);
        if (data.src_ip.length === 0) {
            data.src_ip.push('ANY');
        }
        this.setState({
            addRuleData: data
        });
    };
    handleDeleteDestIP = item => {
        let data = this.state.addRuleData;
        data.dest_ip = data.dest_ip.filter(i => i !== item);
        if (data.dest_ip.length === 0) {
            data.dest_ip.push('ANY');
        }
        this.setState({
            addRuleData: data
        });
    };
    handleKeyDown = evt => {
        let data = this.state.addRuleData;
        let valid = false;
        if ((evt.target.name === "dest_ip" && $("input[name=src_ip]").val() === "")
            || (evt.target.name === "src_ip" && $("input[name=dest_ip]").val() === "")) {
            const elements = document.querySelectorAll("#src_ip,#dest_ip");
            elements.forEach(element => {
                element.style.display = 'none';
                this.setState({
                    showError: false
                })
            })
        }
        else {
            const element = document.getElementById(evt.target.name);
            element.style.display = 'none';
            this.setState({
                showError: false
            })
        }
        if (evt.target.value && (["Enter", "Tab", " ", ","].includes(evt.key) || evt.type === "blur") && evt.target.value !== 'ANY') {
            evt.preventDefault();
            let ip;
            if (evt.target.value.includes('/') && this.validateIPaddressWithSubnet(evt.target.value, evt.target.name)) {
                ip = new Netmask(evt.target.value);
                valid = this.validateIPaddress(evt.target.value.split('/')[0], evt.target.name) && ip.contains(evt.target.value.split('/')[0]);

            } else
                valid = this.validateIPaddress(evt.target.value, evt.target.name);

            if (valid) {
                //LONG-3523-EM - User should notify with error message when providing incorrect configuration
                if (evt.target.name === 'src_ip') {
                    if (evt.target.value && !data.src_ip.includes(evt.target.value)) {
                        if (data.src_ip[0] === 'ANY')
                            data.src_ip = [];
                        data.src_ip = [...data.src_ip, evt.target.value];
                        this.setState({
                            addRuleData: data,
                        }, () => {
                            if (this.state.addRuleData.src_ip !== "" && $("input[name=dest_ip]").val() === "") {
                                const element = document.getElementById('dest_ip');
                                element.style.display = 'none';
                                this.setState({
                                    showError: false
                                })
                            }
                            else if (this.state.addRuleData.src_ip[0] === "ANY") {
                                const element = document.getElementById('src_ip');
                                element.style.display = 'block';
                                this.setState({
                                    showError: true
                                })
                            }
                        })
                    }
                    $("input[name=src_ip]").val("");
                } else {
                    if (evt.target.value && !data.dest_ip.includes(evt.target.value)) {
                        if (data.dest_ip[0] === 'ANY')
                            data.dest_ip = [];
                        data.dest_ip = [...data.dest_ip, evt.target.value];
                        this.setState({
                            addRuleData: data,
                        }, () => {
                            if (this.state.addRuleData.dest_ip !== "" && String(document?.getElementsByName("src_ip")?.[0].value) === '') {
                                const element = document.getElementById('src_ip');
                                element.style.display = 'none';
                                this.setState({
                                    showError: false
                                })
                            }
                        })
                    }
                    $("input[name=dest_ip]").val("");
                }
            }
            else {
                const element = document.getElementById(evt.target.name);
                element.innerHTML = "Invalid Value";
                element.style.display = 'block';
                this.setState({
                    showError: false
                })
            }
        }
        else
            if (evt.type === "blur" && (this.state.addRuleData.proto === 'all' || this.state.addRuleData.proto === 'icmp') &&
                (this.state.addRuleData.src_ip[0] === 'ANY' && this.state.addRuleData.dest_ip[0] === 'ANY')) {
                const element = document.getElementById(evt.target.name);
                element.innerHTML = "At least one value of source and destination IP and ports is required.";
                element.style.display = 'block';
                this.setState({
                    showError: false
                })
            }
        //LONG-3523-EM - User should notify with error message when providing incorrect configuration
        // if (this.state.addRuleData.src_ip[0] === "ANY") {
        //     if (evt.target.name === "src_ip") {
        //         const element = document.getElementById('src_ip');
        //         element.innerHTML = "Invalid Value";
        //         element.style.display = 'block';
        //         this.setState({
        //             showError: true
        //         })
        //     }
        //     else if (evt.target.name === "dest_ip") {
        //         const element = document.getElementById('dest_ip');
        //         element.innerHTML = "Invalid Value";
        //         element.style.display = 'block';
        //         this.setState({
        //             showError: true
        //         })
        //     }
        // }
        // else {
        //     if (evt.target.name === "src_ip") {
        //         const element = document.getElementById('src_ip');
        //         element.style.display = 'none';
        //         this.setState({
        //             showError: false
        //         })
        //     }
        //     else if (evt.target.name === "dest_ip") {
        //         const element = document.getElementById('dest_ip');
        //         element.style.display = 'none';
        //         this.setState({
        //             showError: false
        //         })
        //     }
        // }
    };
    addRules = () => {
        const data = this.state.template_schema_json;
        let found = false;
        data.tun_data.rule_data.map((item, index) => {
            if (item.uuid === this.state.addRuleData.uuid) {
                found = true;
                data.tun_data.rule_data[index] = this.state.addRuleData;
            }
        });
        if (!found)
            data.tun_data.rule_data.push(this.state.addRuleData);
        this.setState(
            {
                template_schema_json: data,
                update: false,
                selectedRule: [],
                addRuleData: {
                    "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
                    "rule_name": "",
                    "src_ip": ["ANY"],
                    "src_port": "ANY",
                    "dest_ip": ["ANY"],
                    "dest_port": "ANY",
                    "proto": "all",
                    "order": "",
                    "interfaceName": [],
                    "weight": [],
                    "isdeleted": '0'
                }
            },
            () => this.closeAddRule()
        );
    }
    addInterface = () => {
        const { addRuleData } = this.state;
        if (addRuleData.interfaceName.filter(item => item === '').length === 0 &&
            this.state.availableTunnelNetworks.length > 0) {
            addRuleData.interfaceName.push('');
        }
        this.setState({
            addRuleData
        }, () =>
            this.calculateInterfaceWeight())
    }
    checkRuleError = () => {
        let result = false;
        let found = false;
        let elements = document.getElementsByClassName('error-txt rule');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        elements = document.getElementsByClassName('form-control w-90 error-field-interface');
        if (elements.length > 0)
            result = true;
        //EMGT-276-EM - Tunnel load balancing page field validation	
        if (this.state.addRuleData.rule_name === '') {
            result = true
        }
        if (this.state.addRuleData.order === '')
            result = true;
        if (this.state.addRuleData.src_ip !== '' && this.state.addRuleData.src_ip[0] !== 'ANY')
            found = true;
        if (this.state.addRuleData.dest_ip !== '' && this.state.addRuleData.dest_ip[0] !== 'ANY')
            found = true;
        if (this.state.addRuleData.src_port !== '' && this.state.addRuleData.src_port !== 'ANY')
            found = true;
        if (this.state.addRuleData.dest_port !== '' && this.state.addRuleData.dest_port !== 'ANY')
            found = true;
        if (!found) {
            result = true;
        }
        else if ((this.state.addRuleData.src_port === 'ANY' && this.state.addRuleData.dest_port === 'ANY'
        ) && (this.state.addRuleData.proto === 'tcp' || this.state.addRuleData.proto === 'udp'))
            result = true;
        else if ((this.state.addRuleData.proto === 'all' || this.state.addRuleData.proto === 'icmp') &&
            (this.state.addRuleData.src_port !== 'ANY' || this.state.addRuleData.dest_port !== 'ANY'))
            result = true;
        else if (this.state.ruleWeightError === true)
            result = true;
        if (this.props.apply) {
            if (this.state.addRuleData.interfaceName.length < 1 || this.state.addRuleData.interfaceName.filter(item =>
                item === '').length > 0)
                result = true;
            else if (this.state.addRuleData.weight.length < 1 || this.state.addRuleData.weight.filter(item =>
                item === '').length > 0)
                result = true;
        }
        return result;
    }
    selectRule = (e, rule) => {
        let { selectedRule } = this.state;
        if (e.target.checked === true) {
            selectedRule.push(rule);
        } else {
            selectedRule = selectedRule.filter(item => item.uuid !== rule.uuid);
        }
        this.setState({
            selectedRule
        })
    }
    // LONG-3180 : Page freeze issue when clicking on cancel for Bootstrap CR WAN template in Bootstrap templates page
    closeAll = () => {
        if (this.props.template_schema_json !== undefined) {
            this.setState({
                template_schema_json: this.props.template_schema_json,
                applied: false
            })
        }
        if (!this.props.edit && !this.props.reapply) {
            this.props.closeAddTemplate()
        }
        else {
            this.props.closeApplyTemplate()
        }
    }
    deleteRule = () => {
        let { template_schema_json } = this.state;
        // let rule_data = JSON.parse(JSON.stringify(template_schema_json.tun_data.rule_data));
        if (this.props.edit) {
            this.state.selectedRule.map((item) => {
                let found = false;
                this.props.template_schema_json.tun_data.rule_data.map((rule) => {
                    if (item.uuid === rule.uuid) {
                        //EMGT-50-EM - Page freeze observed when deleting more than 1 rules from tunnel load balancing rules page
                        this.state.template_schema_json.tun_data.rule_data.filter(value => value.uuid === item.uuid)[0].isdeleted = "1";
                        found = true;
                    }
                    return rule;
                })

                if (found === false) {
                    template_schema_json.tun_data.rule_data = template_schema_json.tun_data.rule_data.filter(finalValue => finalValue.uuid !== item.uuid);
                }
            })
        } else {
            this.state.selectedRule.map((item) => {
                template_schema_json.tun_data.rule_data = template_schema_json.tun_data.rule_data.filter(finalValue => finalValue.uuid !== item.uuid);
            })
        }
        template_schema_json.tun_data.rule_data.filter(item => item.isdeleted !== "1").map((rule, index) => {
            // if (rule.isdeleted !== "1")
            rule.order = (index + 1).toString();
            return rule;
        })

        this.setState({
            template_schema_json,
            selectedRule: []
        })
    }
    setPort = (e) => {
        //LONG-3523-EM - User should notify with error message when providing incorrect configuration
        if (e.target.value === "") {
            if (e.target.name === "src_port") {
                const element = document.getElementById('src_port');
                element.innerHTML = "Invalid Value";
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
            else if (e.target.name === "dest_port") {
                const element = document.getElementById('dest_port');
                element.innerHTML = "Invalid Value";
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }

        }
        if (this.state.addRuleData.dest_port !== "") {
            const element = document.getElementById('src_port');
            element.innerHTML = "Invalid Value";
            element.style.display = 'none';
            this.setState({
                showError: false
            })

        }
        else if (this.state.addRuleData.src_port !== "") {
            const element = document.getElementById('dest_port');
            element.innerHTML = "Invalid Value";
            element.style.display = 'none';
            this.setState({
                showError: false
            })
        }
        if (e.target.name === "src_port" || e.target.name === "dest_port") {
            if (Common.validateNumeric(e)) {
                if (parseInt(e.target.value) < 1 || parseInt(e.target.value) > 65535) {
                    const element = document.getElementById(e.target.name);
                    element.style.display = 'block';
                    this.setState({
                        showError: true
                    })
                }
            } else {
                const element = document.getElementById(e.target.name);
                element.style.display = 'block';
                element.innerHTML = "Invalid Value";
                this.setState({
                    showError: true
                })
            }
        }
        let { addRuleData } = this.state;
        addRuleData[e.target.name] = e.target.value;
        this.setState({
            addRuleData
        })
    }
    handleDeleteInterface = (index) => {
        const element = document.getElementById("interfaces");
        element.style.display = 'none';
        this.setState({
            showError: false,
        });
        let { addRuleData } = this.state;
        let { availableTunnelNetworks } = this.state;
        let { selectedZone } = this.state;
        const temp = addRuleData.interfaceName[index];
        addRuleData.interfaceName.splice(index, 1);
        if (temp && this.state.allNetworks.filter(network => network.interfaceName === temp).length > 0) {
            availableTunnelNetworks.push(this.state.allNetworks.filter(item => item.interfaceName === temp)[0]);
        }
        addRuleData.weight.splice(index, 1);
        if (addRuleData.interfaceName.length === 0)
            selectedZone = '';
        this.setState({
            addRuleData,
            availableTunnelNetworks,
            selectedZone
        }, () => this.calculateInterfaceWeight())
    }
    getOrderOptions = () => {
        let options = [];
        const nonDeletedRules = this.state.template_schema_json.tun_data.rule_data.filter(i => i.isdeleted !== "1")
        if (this.state.update) {
            for (let i = 1; i <= nonDeletedRules.length; i++) {
                //EMGT-276-EM - Tunnel load balancing page field validation	
                options.push(<option disabled={nonDeletedRules.filter((item, index) => {
                    if (item.order === i.toString()) {
                        return item;
                    }
                }).length} value={i.toString()}>
                    {i}</option>);
            }
        } else {
            for (let i = 1; i <= nonDeletedRules.length + 1; i++) {
                //EMGT-276-EM - Tunnel load balancing page field validation	
                options.push(<option disabled={nonDeletedRules.filter((item, index) => {
                    if (item.order === i.toString()) {
                        return item;
                    }
                }).length} value={i.toString()}>
                    {i}</option>);
            }
        }
        return options;
    }
    setSwitchMWAN3 = (e) => {
        const status = e.target.checked === true ? "1" : "0";
        let { template_schema_json } = this.state;
        template_schema_json.enforceMwan3 = status;
        this.setState({
            template_schema_json
        })
    }
    render() {
        return (
            <div>
                <div className="tab-box">
                    <div className={this.state.keepAlive ? "disabled-slide" : ""}></div>
                    <div className="gray-bg p-3 mb-3 rounded">
                        <div className="d-inline-flex align-items-center">
                            <span className="mr-4">Exclude default route table
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Enabling this will skip the static and dynamic routes added in the default routing table and the routes added in MWAN3 routing tables will be effective">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </span>
                            <label className="switch">
                                <input type="checkbox" name="enforceMwan3"
                                    disabled={this.props.view || this.props.reapply}
                                    onChange={this.setSwitchMWAN3}
                                    checked={this.state.template_schema_json.enforceMwan3 === "1" ? true : false} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <ul className="tab-nav nav nav-pills bg-gray" id="wanconfig-tab" role="tablist">
                        <li className="nav-item"><a className="nav-link active" id="wanconfig-config-tab" data-toggle="pill" href="#wanconfig-config"
                            role="tab" aria-controls="wanconfig-config" aria-selected="true" onClick={() => this.setState({ selectedConfig: "wan" })}>Configuration</a></li>
                        <li className="nav-item"><a className="nav-link" id="tunnelconfig-tab" data-toggle="pill" href="#tunnel-config"
                            role="tab" aria-controls="tunnel-config" aria-selected="false" onClick={
                                () => this.setState({ selectedConfig: "tunnel" })}>Policy Based Routing</a></li>

                    </ul>
                    {this.state.showLoader && <Loader />}
                    <div className="tab-content mt-2" id="wanconfig-tabContent">
                        <div className="tab-pane active" id="wanconfig-config" role="tabpanel" aria-labelledby="wanconfig-config-tab">
                            <div className="wan-box p-2">
                                <div>
                                    <strong>WAN Configuration</strong>
                                </div>
                                <div className="d-inline-flex align-items-center mb-4  mr-3">
                                    <span className="mr-5">Auto WAN
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ensure WAN redundancy">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </span>
                                    <label className="switch">
                                        <input type="checkbox" name="status"
                                            disabled={this.props.view || this.props.reapply}
                                            onChange={(e) => this.setswitchWanConfig(e)}
                                            checked={(this.state.template_schema_json.wan_data.length > 0 ?
                                                this.state.template_schema_json.wan_data[0].status === "1" : false)}
                                        />
                                        <span className="slider round"></span></label>
                                </div>
                                <div className="d-inline-flex align-items-center mb-4 ml-3">
                                    <span className="mr-5">Load Balancing
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Load balancing">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </span>
                                    <label className="switch">
                                        <input type="checkbox" name="statusLoadBal"
                                            disabled={this.props.view || this.props.reapply}
                                            onChange={(e) => this.setswitchLoadBal(e)}
                                            checked={(this.state.template_schema_json.wan_data.length > 0 &&
                                                this.state.template_schema_json.wan_data[0].statusLoadBal === "1" ? true : false)}
                                        />
                                        <span className="slider round"></span></label>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Keepalive Monitor
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Helps to check the network connectivity">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                            <select className="form-control"
                                                name="keepaliveMethod"
                                                disabled={this.props.view || this.props.reapply}
                                                onChange={(e) => this.setkeepaliveConfig(e)}
                                                value={this.state.template_schema_json && this.state.template_schema_json.wan_data && this.state.template_schema_json.wan_data.length > 0 && this.state.template_schema_json.wan_data[0]?.keepaliveMethod}
                                            >
                                                <option value="ping">Ping</option>
                                                <option value="dns">DNS</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="dhcp-header">
                                    <div className="row">
                                        <div className="col"></div>
                                        <div className="col">Port</div>
                                        {(this.state.template_schema_json.wan_data.length > 0 &&
                                            this.state.template_schema_json.wan_data[0].status === "0" &&
                                            this.state.template_schema_json.wan_data[0].statusLoadBal === "0") &&
                                            <div className="col text-center">Selection</div>}
                                        {(this.state.template_schema_json.wan_data.length > 0 &&
                                            this.state.template_schema_json.wan_data[0].status === "0" &&
                                            this.state.template_schema_json.wan_data[0].statusLoadBal === "1") &&
                                            <div className="col text-center">Weight
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="*Weight must be between 1 to 100 
                                                *Sum of weights of all the networks must be 100">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div></div>}

                                        {this.state.template_schema_json.wan_data.length > 0 &&
                                            this.state.template_schema_json.wan_data[0].status === "1" &&
                                            <div className="col">Priority</div>
                                        }
                                        <div className="col text-center">Keepalive Config</div>
                                    </div>
                                </div>

                                <div className="dhcp-body">
                                    {this.state.template_schema_json.wan_data.map((network, index) => {
                                        return <div className="row align-items-center border-bottom pb-2 mb-2">
                                            <div className="col">
                                                <strong>{this.getNetworkName(network.networkName)}</strong>
                                            </div>
                                            <div className="col">
                                                {network.interfaceName}
                                            </div>
                                            {
                                                this.state.template_schema_json.wan_data[index].status === "0" ?
                                                    (this.state.template_schema_json.wan_data[index].statusLoadBal === "0" ?
                                                        <div className="col text-center">
                                                            <label className="checkbox round">
                                                                <input type="radio" name="selection"
                                                                    onChange={(e) => this.setWanConfig(e)}
                                                                    value={network.networkName}
                                                                    checked={this.state.selectInterface === network.networkName}
                                                                    disabled={this.props.view || this.props.reapply} />
                                                                <div className="check"></div>
                                                            </label>
                                                        </div>

                                                        : <div className="col text-center">
                                                            <input type="number" name="weight"
                                                                className={this.state.weightError ? "form-control w-90 error-field" : "form-control w-90"}
                                                                min="1" max="100"
                                                                onChange={(e) => this.setWeight(e, network)}
                                                                value={parseInt(network.weight)}
                                                                disabled={this.props.view || this.props.reapply} />
                                                        </div>)
                                                    : <div className="col">
                                                        <select className="form-control" name="priority"
                                                            disabled={this.props.view || this.props.reapply}
                                                            onChange={(e) => this.setPriorityEth(e, index)}
                                                            value={network.priority}
                                                        >
                                                            <option value="">Select</option>

                                                            {this.getPriorityOptions()}

                                                        </select>
                                                    </div>
                                            }
                                            {
                                                <div className="col text-center">
                                                    <img className={(network.status === "1" || network.statusLoadBal === "1" || this.state.selectInterface === network.networkName) &&
                                                        !this.props.info && !this.props.view && !this.props.reapply ? '' : 'disabled'}
                                                        src="/images/svg-icons/wanedit.svg"
                                                        alt=""
                                                        onClick={(network.status === "1" || network.statusLoadBal === "1" || this.state.selectInterface === network.networkName) &&
                                                            !this.props.info && !this.props.view && !this.props.reapply ?
                                                            () => this.openKeepAlive(network) : undefined}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    })}
                                </div>
                            </div>
                            {/* Interfaces */}
                            {
                                this.state.template_schema_json?.tun_interfaces_data?.length !== 0 &&
                                <div className="tab-pane active wan-box p-2" id="Interfaces">
                                    <strong>Wireguard Configuration</strong>
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Keepalive Monitor
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Helps to check the network connectivity">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <select className="form-control"
                                                    name="keepaliveMethod"
                                                    disabled={this.props.view || this.props.reapply}
                                                    onChange={(e) => this.setTunnelKeepaliveConfig(e)}
                                                    value={this.state.template_schema_json && this.state.template_schema_json.tun_interfaces_data &&
                                                        this.state.template_schema_json.tun_interfaces_data.length > 0 && this.state.template_schema_json.tun_interfaces_data[0]?.keepaliveMethod}
                                                >
                                                    <option value="ping">Ping</option>
                                                    <option value="dns">DNS</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dhcp-header">
                                        <div className="row">
                                            <div className="col">Overlay:Underlay</div>

                                            <div className="col text-center">Remote Device</div>

                                            <div className="col text-center">Keepalive Config</div>
                                        </div>
                                    </div>
                                    <div className="dhcp-body">
                                        {(this.props.view || this.props.apply || this.props.reapply || this.props.edit) &&
                                            this.state.template_schema_json.tun_interfaces_data.map((network, index) => {
                                                return <div className="row align-items-center wanTable">
                                                    <div className="col text-center">
                                                        {network.interfaceName}:
                                                        {this.state.template_schema_json.wan_data[0]?.statusLoadBal === '1' &&
                                                            this.state.tunnelNetworks.filter(item => item && item.interfaceName === network.interfaceName)[0]?.networkTunnelLink}
                                                    </div>
                                                    <div className="col text-center">
                                                        {this.state.tunnelNetworks.filter(item => item && item.interfaceName === network.interfaceName)[0]?.remote_deviceName}

                                                    </div>
                                                    {
                                                        <div className="col text-center">
                                                            <img
                                                                src="/images/svg-icons/wanedit.svg"
                                                                onClick={
                                                                    !this.props.info && !this.props.view && !this.props.reapply ?
                                                                        () => { this.setTunnelKeepaliveConfig(); this.openTunnelKeepAlive(network) } : undefined}
                                                                alt=""
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            })}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="tab-pane" id="tunnel-config" role="tabpanel" aria-labelledby="tunnelconfig-tab">

                            {/* <ul className="tab-nav nav nav-pills bg-gray">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#Interfaces">Interfaces</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#Rules">Rules</a>
                                </li>
                            </ul> */}
                            <div className="tab-content">
                                {/* <div className="tab-pane active" id="Interfaces">
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Keepalive Method
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Helps to check the network connectivity">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <select className="form-control"
                                                    name="keepaliveMethod"
                                                    disabled={this.props.view || this.props.reapply}
                                                    onChange={(e) => this.setTunnelKeepaliveConfig(e)}
                                                    value={this.state.template_schema_json && this.state.template_schema_json.tun_interfaces_data &&
                                                        this.state.template_schema_json.tun_interfaces_data.length > 0 && this.state.template_schema_json.tun_interfaces_data[0]?.keepaliveMethod}
                                                >
                                                    <option value="ping">Ping</option>
                                                    <option value="dns">DNS</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dhcp-header">
                                        <div className="row">
                                            <div className="col">Overlay:Underlay</div>

                                            <div className="col text-center">Remote Device</div>

                                            <div className="col text-center">Keepalive Config</div>
                                        </div>
                                    </div>
                                    <div className="dhcp-body">
                                        {(this.props.view || this.props.apply || this.props.reapply || this.props.edit) &&
                                            this.state.template_schema_json.tun_interfaces_data.map((network, index) => {
                                                return <div className="row align-items-center border-bottom pb-2 mb-2">
                                                    <div className="col text-center">
                                                        {network.interfaceName}:
                                                        {this.state.template_schema_json.wan_data[0]?.statusLoadBal === '1' &&
                                                            this.state.tunnelNetworks.filter(item => item && item.interfaceName === network.interfaceName)[0]?.networkTunnelLink}
                                                    </div>
                                                    <div className="col text-center">
                                                        {this.state.tunnelNetworks.filter(item => item && item.interfaceName === network.interfaceName)[0]?.remote_deviceName}

                                                    </div>
                                                    {
                                                        <div className="col text-center">
                                                            <img
                                                                src="/images/svg-icons/wanedit.svg"
                                                                onClick={
                                                                    !this.props.info && !this.props.view && !this.props.reapply ?
                                                                        () => { this.setTunnelKeepaliveConfig(); this.openTunnelKeepAlive(network) } : undefined}
                                                                alt=""
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            })}
                                    </div>
                                </div> */}
                                <div className="tab-pane active" id="Rules">

                                    <div className="page-sub-title mt-2">
                                        <div className="d-flex align-items-center">
                                            <strong>Rule</strong>
                                            {(!this.props.info && !this.props.view) && <img src="/images/svg-icons/add.svg" className="add-btn ml-2" alt=""
                                                onClick={() => this.openAddRule()} />}
                                        </div>
                                        {!this.props.info && !this.props.view && !this.props.reapply && <div className="device-action">
                                            <span className={this.state.selectedRule.length !== 1 ?
                                                "icon-box disabled" : "icon-box"}>
                                                <img src="/images/svg-icons/edit.svg" alt=""
                                                    disabled={this.state.selectedRule.length !== 1}
                                                    onClick={this.state.selectedRule.length === 1 ? () => this.openAddRule(this.state.selectedRule[0]) : undefined}
                                                />
                                            </span>
                                            <span className={this.state.selectedRule.length < 1 ?
                                                "icon-box disabled" : "icon-box"}
                                                onClick={this.state.multipleSelect === "" ? undefined :
                                                    () => this.deleteRule()}
                                            >
                                                <img src="/images/svg-icons/delete.svg" alt="" disabled={this.state.multipleSelect === ""} /></span>
                                        </div>}

                                    </div>
                                    <table className="table table-border">
                                        <thead>
                                            <tr>
                                                {!this.props.info && !this.props.view && !this.props.reapply && <th />}
                                                <th>Order</th>
                                                <th>Name</th>
                                                <th>Matching Criteria</th>
                                                <th>Interfaces</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.template_schema_json.tun_data.rule_data.filter(item => item.isdeleted !== '1').map((rule) => {
                                                return (
                                                    <tr>
                                                        {
                                                            // !this.props.info && !this.props.view && !this.props.reapply) && 
                                                            <td>
                                                                <input type="checkbox" checked={this.state.selectedRule.filter(item =>
                                                                    (item.uuid === rule.uuid)).length > 0} onClick={(e) => this.selectRule(e, rule)} /></td>}
                                                        <td>{rule.order}</td>
                                                        <td>{rule.rule_name}</td>
                                                        <td>
                                                            <div className="">Source IP: {rule.src_ip}</div>
                                                            <div className="">Destination IP: {rule.dest_ip}</div>
                                                            <div className="">Source Port: {rule.src_port}</div>
                                                            <div className="">Destination Port: {rule.dest_port}</div>
                                                            <div className="">Protocol: {Common.getRuleProtocol(rule.proto)}</div>
                                                        </td>
                                                        <td>
                                                            {rule.interfaceName.map((interfaceData, index) => {
                                                                return (<div className="d-flex">
                                                                    <div>Interface: {interfaceData}</div>
                                                                    {rule.weight[index] !== '100' && <div className="ml-2">Weight: {rule.weight[index]}</div>}
                                                                </div>)
                                                            })}
                                                        </td>
                                                    </tr>)
                                            }
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    {!this.props.info && !this.props.view && <div className="s-footer">
                        {/* 1665 reapply page issue */}
                        <button className="btn btn-light" onClick={(this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeAddTemplate() :
                            () => this.closeAll()}>
                            {/* 1665 reapply page issue */}
                            {this.props.apply && !this.props.edit && !this.props.reapply ? 'Back' : 'Cancel'}</button>
                        <button className="btn btn-primary" disabled={this.checkError()}
                            onClick={
                                this.props.apply
                                    ? (event) => this.debounceApply(this.applyTemplate, event)
                                    : () => this.saveData()}
                        >{this.props.apply ? 'Apply' : (this.state.selectedTemplateId !== '' ? 'Update' : 'Save')}</button>
                    </div>}
                    <div className={this.state.keepAlive || this.state.addRule || this.state.tunnelKeepalive ? "disabled-slide" : ""}></div>
                </div>

                <div className={this.state.keepAlive ? "sidebar-slide second-open zindex10 w-350 r-757" : "sidebar-slide zindex10"}>
                    <div className="s-header">
                        <h1 className="ss-title">Keepalive Configuration</h1>
                        <button>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" onClick={() => this.closeKeepAlive()} />
                        </button>
                    </div>


                    <div className="s-body">
                        {(this.state.autoWanstatus === "0" && this.state.template_schema_json.wan_data[0]?.keepaliveMethod === 'ping') || (this.state.autoWanstatus === "1" && this.state.keepAliveDataPriority[0]?.keepaliveMethod === 'ping') ? <>
                            <div className="form-group w-75">
                                <label>Track Address1
                                    {
                                        // !this.props.deviceGroup && 
                                        <span className="red-txt">*</span>}
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="The host(s) to test if the interface is still alive. 
                                        Note: To handle the device group scenario, the default value is set as localtunnel[interface name].net. At the device backend, this domain name was mapped to the remote device Mgmt. Plane IP as a local host.">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="text" className="form-control" name="trackAddress1"
                                    onChange={(e) => this.setKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.keepAliveData.trackAddress1}
                                    onBlur={(e) => this.validateValue(e)}
                                />
                                <div className="error-txt" id="trackAddress1">Invalid Value</div>
                            </div>
                            <div className="form-group w-75">
                                <label>Track Address2
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="The host(s) to test if interface is still alive.">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="text" className="form-control" name="trackAddress2"
                                    onChange={(e) => this.setKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.keepAliveData.trackAddress2}
                                    onBlur={(e) => this.validateValue(e)}
                                />
                                <div className="error-txt" id="trackAddress2">Invalid Value</div>
                            </div>
                            <div className="form-group w-75">
                                <label>Track Address3
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="The host(s) to test if interface is still alive.">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="text" className="form-control" name="trackAddress3"
                                    onChange={(e) => this.setKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.keepAliveData.trackAddress3}
                                    onBlur={(e) => this.validateValue(e)}
                                />
                                <div className="error-txt" id="trackAddress3">Invalid Value</div>
                            </div>
                            <div className="form-group w-75">
                                <label>Reliablity
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Number of Track Address hosts that must reply for the test to be considered as successful.">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <input type="number" min="1" className="form-control" name="reliability"
                                    onChange={(e) => this.setKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.keepAliveData.reliability}
                                    onBlur={(e) => this.validateValue(e)}
                                />
                                <div className="error-txt" id="reliability">
                                    Invalid value
                                </div>
                            </div>
                            <div className="form-group w-75">
                                <label>Refresh Interval Ping
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Number of seconds between each test"> <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="number" min="10" max="86400" className="form-control" name="refreshIntervalPing"
                                    onChange={(e) => this.setKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={parseInt(this.state.keepAliveData.refreshIntervalPing)}
                                    onBlur={(e) => this.validateValue(e)}
                                />
                                <span className="units outside">Seconds</span>
                                <div className="error-txt" id="refreshIntervalPing">
                                    Invalid value
                                </div>
                            </div>
                            <div className="form-group w-75">
                                <label>Up
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Number of successful tests to considered link as alive"> <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="number" min="0" className="form-control" name="up"
                                    onChange={(e) => this.setKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={parseInt(this.state.keepAliveData.up)}
                                    onBlur={(e) => this.validateValue(e)}
                                />
                                <div className="error-txt" id="up">
                                    Invalid value
                                </div>
                            </div>
                            <div className="form-group w-75">
                                <label>Down
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="number of unsuccessful tests needed to pronounce the connection inactive">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="number" min="0" className="form-control" name="down"
                                    onChange={(e) => this.setKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={parseInt(this.state.keepAliveData.down)}
                                    onBlur={(e) => this.validateValue(e)}
                                />
                                <div className="error-txt" id="down">
                                    Invalid value
                                </div>
                            </div>
                            <div className="form-group w-75">
                                <label>Count
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Number of checks to send to each host with each test">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="number" min="0" className="form-control" name="count"
                                    onChange={(e) => this.setKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={parseInt(this.state.keepAliveData.count)}
                                    onBlur={(e) => this.validateValue(e)}
                                />
                                <div className="error-txt" id="count">
                                    Invalid value
                                </div>
                            </div>
                            <br />
                        </> :
                            <>
                                {(
                                    this.state.template_schema_json.wan_data[0]?.keepaliveMethod === 'dns') &&
                                    <>
                                        <div className="form-group w-75">
                                            <label>Retry Count
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Number of retries"> <img src="/images/svg-icons/info.svg" alt="" /></div>
                                            </label>
                                            <input type="number" min="10" max="86400" className="form-control" name="retryCount"
                                                onChange={(e) => this.setKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={parseInt(this.state.keepAliveData.retryCount)}
                                                onBlur={(e) => this.validateValue(e)}
                                            />
                                            <span className="units outside">Seconds</span>
                                            <div className="error-txt" id="retryCount">
                                                Value must be between 10 to 86400 seconds
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Active Interval
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Timespan in seconds when the interface is actively routed"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                            <input type="number" min="30" max="86400" className="form-control" name="activeInterval"
                                                onChange={(e) => this.setKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={this.state.keepAliveData.activeInterval}
                                                onBlur={(e) => this.validateValue(e)}
                                            />
                                            <span className="units outside">Seconds</span>
                                            <div className="error-txt" id="activeInterval">
                                                Value must be between 30 to 86400 seconds
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Standby Interval
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Timespan in seconds when the interface is online but not active."> <img src="/images/svg-icons/info.svg" alt="" /></div>
                                            </label>
                                            <input type="number" min="1" max="1440" className="form-control" name="standbyInterval"
                                                onChange={(e) => this.setKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={parseInt(this.state.keepAliveData.standbyInterval)}
                                                onBlur={(e) => this.validateValue(e)}
                                            />
                                            <span className="units outside">Seconds</span>
                                            <div className="error-txt" id="standbyInterval">
                                                Value must be between 1 to 1440 seconds
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Failed Interval
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Timespan in seconds when DNS is failed."> <img src="/images/svg-icons/info.svg" alt="" /></div>
                                            </label>
                                            <input type="number" min="10" max="600" className="form-control" name="failedInterval"
                                                onChange={(e) => this.setKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={parseInt(this.state.keepAliveData.failedInterval)}
                                                onBlur={(e) => this.validateValue(e)}
                                            />
                                            <span className="units outside">Seconds</span>
                                            <div className="error-txt" id="failedInterval">
                                                Value must be between 10 to 600 seconds
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Hostname1
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Domain name to be resolved"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                            <input type="text" className="form-control" name="hostname1"
                                                onChange={(e) => this.setKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={this.state.keepAliveData.hostname1}
                                                onBlur={(e) => this.validateValue(e)}
                                            />
                                            <div className="error-txt" id="hostname1">
                                                Invalid hostname
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Hostname2
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Domain name to be resolved"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                            <input type="text" className="form-control" name="hostname2"
                                                onChange={(e) => this.setKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={this.state.keepAliveData.hostname2}
                                                onBlur={(e) => this.validateValue(e)}
                                            />
                                            <div className="error-txt" id="hostname2">
                                                Invalid hostname

                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Hostname3
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Domain name to be resolved"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                            <input type="text" className="form-control" name="hostname3"
                                                onChange={(e) => this.setKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={this.state.keepAliveData.hostname3}
                                                onBlur={(e) => this.validateValue(e)}
                                            />
                                            <div className="error-txt" id="hostname3">
                                                Invalid hostname
                                            </div>
                                        </div>
                                        <div className="error-txt" id="hostError">
                                            All host names must be unique
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>

                    {!this.props.view && <div className="s-footer">
                        <button className="btn btn-light" onClick={() => this.closeKeepAlive()}>Cancel</button>
                        <button className="btn btn-primary" disabled={this.state.showError || this.checkKeepAliveError()} onClick={() => this.saveKeepAliveData()}>Update</button>
                    </div>
                    }


                    {/* add rule */}
                    <div className={this.state.addRule ? "sidebar-slide second-open zindex10 w-350 r-757" : "sidebar-slide zindex10"}>
                        <div className="s-header">
                            <h1 className="ss-title">Rule Configuration</h1>
                            <button>
                                <img src="/images/svg-icons/s-cancel.svg" alt=""
                                    onClick={this.closeAddRule} />
                            </button>
                        </div>
                        <div className="s-body customSBody">
                            <div className="form-group">
                                <label>Name<span className="red-txt">*</span><div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Name of the rule"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                <input type="text" className="form-control" name="rule_name"
                                    onChange={(e) => this.setRuleData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.addRuleData.rule_name}
                                    onBlur={(e) => this.checkRuleName(e)}
                                />
                                <div className="error-txt rule" id="rule_name">Invalid Value</div>
                            </div>
                            <div className="form-group">
                                <label>Source IP<div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match traffic from the specified source IP address">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                <div className="allowedip-box with-arrow">
                                    {
                                        this.state.addRuleData.src_ip.map((item, index) => {
                                            return (
                                                (item !== null && item !== 'null' && item !== '') && <div className="tag-item" key={item} // onDoubleClick={() => this.setEditIP(item)}
                                                >{item}
                                                    <button
                                                        type="button"
                                                        className="button"
                                                        onClick={() => this.handleDeleteSrcIP(item)}
                                                    >
                                                        <img src="/images/svg-icons/cancel.svg" alt="" />
                                                    </button>
                                                </div>
                                            )
                                        })}
                                    <input type="text" className="form-control" name="src_ip"
                                        onKeyDown={(e) => this.handleKeyDown(e)}
                                        onBlur={(e) => this.handleKeyDown(e)}
                                        readOnly={this.props.view || this.props.reapply}
                                    />
                                </div>
                                <div className="error-txt rule" id="src_ip">Invalid Value</div>
                            </div>
                            <div className="form-group">
                                <label>Source Port<div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match traffic from the specified source port(s) or port range, if relevant protocol is specified. Port range should be specified as Ex: 12345:12347">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                <input type="text" className="form-control" name="src_port"
                                    onChange={(e) => this.setRuleData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.addRuleData.src_port}
                                    onBlur={this.portValidation}
                                />
                                <div className="error-txt rule" id="src_port">Invalid Value</div>
                            </div>
                            <div className="form-group">
                                <label>Destination IP<div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match traffic directed to the specified destination IP address">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                <div className="allowedip-box with-arrow">
                                    {
                                        this.state.addRuleData.dest_ip.map((item, index) => {
                                            return (
                                                (item !== null && item !== 'null' && item !== '') && <div className="tag-item" key={item}
                                                >{item}
                                                    <button
                                                        type="button"
                                                        className="button"
                                                        onClick={() => this.handleDeleteDestIP(item)}
                                                    >
                                                        <img src="/images/svg-icons/cancel.svg" alt="" />
                                                    </button>
                                                </div>
                                            )
                                        })}
                                    <input type="text" className="form-control" name="dest_ip"
                                        onKeyDown={(e) => this.handleKeyDown(e)}
                                        onBlur={(e) => this.handleKeyDown(e)}
                                        readOnly={this.props.view || this.props.reapply}
                                    />
                                </div>
                                <div className="error-txt rule" id="dest_ip">Invalid Value</div>
                            </div>
                            <div className="form-group">
                                <label>Destination Port<div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match traffic directed at the given destination port(s) or port range, if relevant protocol is specified. Port range should be specified as Ex: 12345:12347"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                <input type="text" className="form-control" name="dest_port"
                                    onChange={(e) => this.setRuleData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.addRuleData.dest_port}
                                    onBlur={this.portValidation}
                                />
                                <div className="error-txt rule" id="dest_port">Invalid Value</div>
                            </div>
                            <div className="form-group">
                                <label>Protocol<div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match traffic using the given protocol."> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                <select className="form-control" name='proto' onChange={this.setRuleData}
                                    value={this.state.addRuleData.proto}>
                                    <option value='tcp'>TCP</option>
                                    <option value='udp'>UDP</option>
                                    <option value='icmp'>ICMP</option>
                                    <option value='all'>ALL</option>
                                </select>
                            </div>
                            <div className={this.state.update ? "form-group disabled" : "form-group"}>
                                <label>Order<span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Priority of the rule"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                <select
                                    className="form-control"
                                    name='order'
                                    onChange={this.setRuleData}
                                    value={this.state.addRuleData.order}
                                    disabled={this.state.update}
                                >
                                    <option value="">Select</option>
                                    {this.getOrderOptions()}
                                </select>
                            </div>

                            <div className="page-sub-title mt-2 border-top pt-3">
                                <div className="d-flex align-items-center">
                                    <strong>Interfaces</strong>
                                    <img src="/images/svg-icons/add.svg" className="add-btn ml-2" alt=""
                                        onClick={this.addInterface} />
                                </div>
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Interface<div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="interfaces to be assigned"> <img src="/images/svg-icons/info.svg" alt="" /></div></th>
                                        <th>Weight<div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Weight of the interface"> <img src="/images/svg-icons/info.svg" alt="" /></div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.addRuleData.interfaceName.map((item, index) => {
                                        return (<tr>
                                            <td className="w-50">
                                                <div className="dropdown">
                                                    <ul className="chip-list z-index-9 bg-white radius">
                                                        {item &&
                                                            <li className={this.state.allNetworks.filter(network => network.interfaceName === item).length > 0 ?
                                                                "" : "form-control w-90 error-field-interface"}>{item}
                                                                {/* <img src="/images/svg-icons/cancel.svg" id={item}
                                                                    onClick={(e) => this.setInterface(e, index)} alt='' /> */}
                                                            </li>
                                                        }
                                                    </ul>
                                                    <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                                    </div>

                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <ul>
                                                            {
                                                                this.state?.availableTunnelNetworks?.map((network) => {
                                                                    return ((this.state.selectedZone === '' || network?.network_zone === this.state.selectedZone) &&
                                                                        <li><input type="checkbox"
                                                                            name="interfaces" id={network?.interfaceName} checked={!this.state.availableTunnelNetworks.includes(network)}
                                                                            onChange={(e) => this.setInterface(e, index)} /> {network?.interfaceName}</li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="w-50">
                                                <input className={this.state.ruleWeightError ? "form-control w-90 error-field" : "form-control w-90"}
                                                    type="text" name='weight' onChange={(e) => this.setInterfacesWeight(e, index)}
                                                    disabled={this.state.addRuleData?.interfaceName?.length < 2}
                                                    value={this.state.addRuleData.weight[index] === "100" ? '-' : this.state.addRuleData.weight[index]}></input>
                                            </td>
                                            <td> <button
                                                type="button"
                                                className="button"
                                                onClick={() => this.handleDeleteInterface(index)}
                                            >
                                                <img src="/images/svg-icons/cancel.svg" alt="" />
                                            </button></td>
                                        </tr>)
                                    })

                                    }
                                    <div className="error-txt rule" id="interfaces">Select interfaces of same zone</div>

                                </tbody>
                            </table>
                        </div>
                        {!this.props.info && !this.props.view && !this.props.reapply && <div className="s-footer">
                            <button className="btn btn-light" onClick={() => this.closeAddRule()}>Cancel</button>
                            <button className="btn btn-primary"
                                disabled={this.checkRuleError()}
                                onClick={() => this.addRules()}
                            >
                                {this.state.update ? 'Update' : 'Add'}</button>
                        </div>}
                    </div>

                    {/* close add rule */}

                </div>
                <div className={this.state.tunnelKeepalive ? "sidebar-slide second-open zindex10 w-350 r-757" : "sidebar-slide zindex10"}>
                    <div className="s-header">
                        <h1 className="ss-title">Keepalive Configuration</h1>
                        <button>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" onClick={() => this.closeTunnelKeepAlive()} />
                        </button>
                    </div>


                    <div className="s-body">
                        {(this.state.interfaces_keepalive_data.keepaliveMethod === 'ping') ? <>
                            <div className="form-group w-75">
                                <label>Track Address1
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="The host(s) to test if the interface is still alive. 
                                        Note: To handle the device group scenario, the default value is set as localtunnel[interface name].net. At the device backend, this domain name was mapped to the remote device Mgmt. Plane IP as a local host.">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="text" className="form-control" name="trackAddress1"
                                    onChange={(e) => this.setTunnelKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.interfaces_keepalive_data.trackAddress1}
                                    onBlur={(e) => this.validateTunnelValues(e)}
                                />
                                <div className="error-txt tunnel" id="trackAddress1_tunnel">Invalid Value</div>
                            </div>
                            <div className="form-group w-75">
                                <label>Track Address2
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="The host(s) to test if interface is still alive.">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="text" className="form-control" name="trackAddress2"
                                    onChange={(e) => this.setTunnelKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.interfaces_keepalive_data.trackAddress2}
                                    onBlur={(e) => this.validateTunnelValues(e)}
                                />
                                <div className="error-txt tunnel" id="trackAddress2_tunnel">Invalid Value</div>
                            </div>
                            <div className="form-group w-75">
                                <label>Track Address3
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="The host(s) to test if interface is still alive.">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="text" className="form-control" name="trackAddress3"
                                    onChange={(e) => this.setTunnelKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.interfaces_keepalive_data.trackAddress3}
                                    onBlur={(e) => this.validateTunnelValues(e)}
                                />
                                <div className="error-txt tunnel" id="trackAddress3_tunnel">Invalid Value</div>
                            </div>
                            <div className="form-group w-75">
                                <label>Reliablity
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Number of Track Address hosts that must reply for the test to be considered as successful.">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <input type="number" min="0" className="form-control" name="reliability"
                                    onChange={(e) => this.setTunnelKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={this.state.interfaces_keepalive_data.reliability}
                                    onBlur={(e) => this.validateTunnelValues(e)}
                                />
                                <div className="error-txt tunnel" id="reliability_tunnel">
                                    Invalid value
                                </div>
                            </div>
                            <div className="form-group w-75">
                                <label>Refresh Interval Ping
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Number of seconds between each test"> <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="number" min="10" max="86400" className="form-control" name="refreshIntervalPing"
                                    onChange={(e) => this.setTunnelKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={parseInt(this.state.interfaces_keepalive_data.refreshIntervalPing)}
                                    onBlur={(e) => this.validateTunnelValues(e)}
                                />
                                <span className="units outside">Seconds</span>
                                <div className="error-txt tunnel" id="refreshIntervalPing_tunnel">
                                    Invalid value
                                </div>
                            </div>
                            <div className="form-group w-75">
                                <label>Up
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Number of successful tests to considered link as alive">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="number" min="1" max="100" className="form-control" name="up"
                                    onChange={(e) => this.setTunnelKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={parseInt(this.state.interfaces_keepalive_data.up)}
                                    onBlur={(e) => this.validateTunnelValues(e)}

                                />
                                <div className="error-txt tunnel" id="up_tunnel">
                                    Invalid value
                                </div>
                            </div>
                            <div className="form-group w-75">
                                <label>Down
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="number of unsuccessful tests needed to pronounce the connection inactive">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="number" min="1" max="100" className="form-control" name="down"
                                    onChange={(e) => this.setTunnelKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={parseInt(this.state.interfaces_keepalive_data.down)}
                                    onBlur={(e) => this.validateTunnelValues(e)}
                                />
                                <div className="error-txt tunnel" id="down_tunnel">
                                    Invalid value
                                </div>
                            </div>
                            <div className="form-group w-75">
                                <label>Count
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="number of queries sent to an address defined as track_ip during a test">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <input type="number" min="1" max="100" className="form-control" name="count"
                                    onChange={(e) => this.setTunnelKeepaliveData(e)}
                                    readOnly={this.props.view || this.props.reapply}
                                    value={parseInt(this.state.interfaces_keepalive_data.count)}
                                    onBlur={(e) => this.validateTunnelValues(e)}
                                />
                                <div className="error-txt tunnel" id="count_tunnel">
                                    Invalid value
                                </div>
                            </div>
                            <br />
                        </> :
                            <>
                                {(
                                    this.state.interfaces_keepalive_data.keepaliveMethod === 'dns') &&
                                    <>
                                        <div className="form-group w-75">
                                            <label>DNS Server
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="DNS server to resolve the DNS queries"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                            <input type="text" className="form-control" name="dns_server"
                                                onChange={(e) => this.setTunnelKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={this.state.interfaces_keepalive_data.dns_server}
                                                onBlur={(e) => this.validateTunnelValues(e)}
                                            />
                                            <div className="error-txt tunnel" id="dns_server_tunnel">
                                                Invalid IP address
                                            </div>
                                        </div>


                                        <div className="form-group w-75">
                                            <label>Retry Count
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Number of retries"> <img src="/images/svg-icons/info.svg" alt="" /></div>
                                            </label>
                                            <input type="number" min="10" max="86400" className="form-control" name="retryCount"
                                                onChange={(e) => this.setTunnelKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={parseInt(this.state.interfaces_keepalive_data.retryCount)}
                                                onBlur={(e) => this.validateTunnelValues(e)}
                                            />
                                            <span className="units outside">Seconds</span>
                                            <div className="error-txt tunnel" id="retryCount_tunnel">
                                                Value must be between 10 to 86400 seconds
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Active Interval
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Timespan in seconds when the interface is actively routed"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                            <input type="number" min="30" max="86400" className="form-control" name="activeInterval"
                                                onChange={(e) => this.setTunnelKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={this.state.interfaces_keepalive_data.activeInterval}
                                                onBlur={(e) => this.validateTunnelValues(e)}
                                            />
                                            <span className="units outside">Seconds</span>
                                            <div className="error-txt tunnel" id="activeInterval_tunnel">
                                                Value must be between 30 to 86400 seconds
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Standby Interval
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Timespanin seconds when the interface is online but not active."> <img src="/images/svg-icons/info.svg" alt="" /></div>
                                            </label>
                                            <input type="number" min="1" max="1440" className="form-control" name="standbyInterval"
                                                onChange={(e) => this.setTunnelKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={parseInt(this.state.interfaces_keepalive_data.standbyInterval)}
                                                onBlur={(e) => this.validateTunnelValues(e)}
                                            />
                                            <span className="units outside">Seconds</span>
                                            <div className="error-txt tunnel" id="standbyInterval_tunnel">
                                                Value must be between 1 to 1440 seconds
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Failed Interval
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Timespan in seconds when DNS is failed."> <img src="/images/svg-icons/info.svg" alt="" /></div>
                                            </label>
                                            <input type="number" min="10" max="600" className="form-control" name="failedInterval"
                                                onChange={(e) => this.setTunnelKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={parseInt(this.state.interfaces_keepalive_data.failedInterval)}
                                                onBlur={(e) => this.validateTunnelValues(e)}
                                            />
                                            <span className="units outside">Seconds</span>
                                            <div className="error-txt tunnel" id="failedInterval_tunnel">
                                                Value must be between 10 to 600 seconds
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Hostname1
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Domain name to be resolved"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                            <input type="text" className="form-control" name="hostname1"
                                                onChange={(e) => this.setTunnelKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={this.state.interfaces_keepalive_data.hostname1}
                                                onBlur={(e) => this.validateTunnelValues(e)}
                                            />
                                            <div className="error-txt tunnel" id="hostname1_tunnel">
                                                Invalid hostname
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Hostname2
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Domain name to be resolved"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                            <input type="text" className="form-control" name="hostname2"
                                                onChange={(e) => this.setTunnelKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={this.state.interfaces_keepalive_data.hostname2}
                                                onBlur={(e) => this.validateTunnelValues(e)}
                                            />
                                            <div className="error-txt tunnel" id="hostname2_tunnel">
                                                Invalid hostname
                                            </div>
                                        </div>
                                        <div className="form-group w-75">
                                            <label>Hostname3
                                                <span className="red-txt">*</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title="Domain name to be resolved"> <img src="/images/svg-icons/info.svg" alt="" /></div></label>
                                            <input type="text" className="form-control" name="hostname3"
                                                onChange={(e) => this.setTunnelKeepaliveData(e)}
                                                readOnly={this.props.view || this.props.reapply}
                                                value={this.state.interfaces_keepalive_data.hostname3}
                                                onBlur={(e) => this.validateTunnelValues(e)}
                                            />
                                            <div className="error-txt tunnel" id="hostname3_tunnel">
                                                Invalid hostname
                                            </div>
                                        </div>
                                        <div className="error-txt tunnel" id="hostError_tunnel">
                                            All host names must be unique
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                    {!this.props.view && <div className="s-footer">
                        <button className="btn btn-light" onClick={() => this.closeTunnelKeepAlive()}>Cancel</button>
                        <button className="btn btn-primary" disabled={this.checkTunnelError()} onClick={() => this.saveTunnelKeepAliveData()}>Update</button>
                    </div>
                    }
                </div>
                {(this.state.showMessage) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopupLoadBalancing()}
                        info={this.state.info}
                        onSubmit={() => this.resetPopup()}
                    />
                }
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        applying: state.displayAddTemplate.applying,
    }
};
export default connect(mapStateToProps)(WanConfigCR);
