import React from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus, EventType } from '@azure/msal-browser';
import { useSelector, useDispatch } from 'react-redux';
import { loginRequest, b2cPolicies } from '../authConfig';
import { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { compareIssuingPolicy } from '../utils/claimUtils';
import { Loader } from './Loader';
import * as Action from '../redux/actionTypes';
import { ErrorPopup } from './errorPopup';
import $ from "jquery";
import ErrorHandlePopup from '../components/ErrorHandlePopup';
import userApiCall from "../redux/API/ADUserApiCall";
import userUrlMapping from "../redux/API/ADUserAPI";
import moment from 'moment';

const ADHeader = (props) => {
    const { instance, inProgress } = useMsal();
    const [showLoader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [verifyCall, setVerifiedCall] = useState(true);
    const [showRefreshModal, setShowRefreshModal] = useState(false);
    const [policyName, setPolicyName] = useState(b2cPolicies.names.signUpSignIn);
    // const [email, setEmail] = useState('');
    const newState = useSelector(state => state.auth);
    const dispatch = useDispatch();
    let activeAccount;
    if (instance) {
        if (localStorage.getItem('ssoUser') === 'true') {

            instance.config.auth.authority = `${process.env.REACT_APP_AAD_APP_AUTHORITY}B2C_1_PTK_TO_SDEDGE_SIGNUP_SIGNIN`;
        }
        activeAccount = instance.getAllAccounts();
    }
    
    useEffect(() => {
        if (instance && activeAccount.length > 0) {
            activeAccount = instance.getAllAccounts();
            let getMinutes = 0;
        if (localStorage.getItem('ADtoken')) {
            const now = moment().unix();
            const tokenValues = jwt(localStorage.getItem('ADtoken'));
            const duration = moment.duration(moment.unix(tokenValues?.exp).diff(moment.unix(now)));
            getMinutes = duration.asMinutes();
        }
           
                instance.setActiveAccount(activeAccount[0]);
                if ((localStorage.getItem('ADtoken') === null ||localStorage.getItem('ADtoken') === undefined
                 || getMinutes < 50) && !showRefreshModal)
                    acquireToken();
                // setEmail(activeAccount[0].idTokenClaims.emails[0]);
                if (localStorage.getItem('ssoUser') === 'true' && activeAccount.length > 0) {
                setVerifiedCall(true);
                createSsoUser(activeAccount[0].idTokenClaims.emails[0]);
            }
        }
        async function login() {
            // if (
            //     localStorage.getItem('ssoUser') !== 'true' && 
            //     sessionStorage.getItem('ssoUser') !== 'true' &&
            //     activeAccount.length === 0) {
            handleLoginRedirect();
            // }
        }
        login();
    }, []);

    useEffect(() => {
        if (newState?.logout === true)
            handleLogoutRedirect()
    }, [newState?.logout])

    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS ||
                    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                    event.eventType === EventType.SSO_SILENT_SUCCESS) &&
                event.payload.account
            ) {
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                const account = event.payload.account;
                instance.setActiveAccount(account);
                if (event.payload.idTokenClaims['acr'] === policyName) {
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) => {
                                if (account.idToken) {
                                    const tokenValues = jwt(account.idToken);
                                    localStorage.setItem('userId', tokenValues.sub);
                                    localStorage.setItem('tenantId', tokenValues.tid);
                                    localStorage.setItem('ADtoken', account.idToken);
                                }
                            })
                }
                if (event.payload.idTokenClaims['tfp'] === 'B2C_1_PTK_TO_SDEDGE_SIGNUP_SIGNIN') {
                    if (event.payload.idToken) {
                        const tokenValues = jwt(event.payload.idToken);
                        localStorage.setItem('userId', tokenValues.sub);
                        localStorage.setItem('ADtoken', event.payload.idToken);
                        sessionStorage.setItem('ssoUser', true);
                        // createSsoUser(tokenValues.emails[0]);
                        // dispatch({
                        //     type: Action.SET_SSOUSER,
                        //     payload: true
                        // });
                    }
                }
                if (event.payload.idTokenClaims['acr'] === b2cPolicies.names.changePassword) {
                    // alert('Password changed successfully!')
                    setShowError(true);
                    $("#errorMessage").modal("show")
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims['acr'] === b2cPolicies.names.changePassword
                    // ||
                    //     compareIssuingPolicy(event.payload.idTokenClaims['acr'] === b2cPolicies.names.ssoLogin)
                )) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: activeAccount[0]
                    };
                    instance.loginRedirect(signUpSignInFlowRequest);
                }


            }
            if (event.error && event.error.errorMessage.includes('AADB2C90077')) {
                // window.location.reload();
                setShowRefreshModal(true);
                instance.removeEventCallback(callbackId);
                // handleLogoutRedirect();
            }
            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.setPassword.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }
                else if (event.error.errorCode === 'no_token_request_cache_error') {
                    // dispatch({
                    //     type: Action.LOGOUT,
                    // });
                    // handleLogoutRedirect();
                    setLoader(false);
                    setShowRefreshModal(true);
                    instance.removeEventCallback(callbackId);
                }
            }
        });
        // Function to acquire an access token
        // Call the acquireToken function when the component mounts
        // acquireToken();
        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    useEffect(() => {
        if (newState?.logout !== true && !showRefreshModal) {
            acquireToken();
        }
    })

   
    const acquireToken = async () => {
        let getMinutes = 0;
        if (localStorage.getItem('ADtoken')) {
            const now = moment().unix();
            const tokenValues = jwt(localStorage.getItem('ADtoken'));
            const duration = moment.duration(moment.unix(tokenValues?.exp).diff(moment.unix(now)));
            getMinutes = duration.asMinutes();
        }
        if ((localStorage.getItem('ADtoken') === null || localStorage.getItem('ADtoken') === undefined) ||
            (localStorage.getItem('ADtoken') !== null && localStorage.getItem('ADtoken') !== undefined &&
                getMinutes < 12)) {
            if (activeAccount.length > 0 && inProgress === "none") {
                try {
                    const request = {
                        account: activeAccount[0],
                        scopes: [instance.config.auth.clientId],
                    };
                    await instance.acquireTokenSilent(request)
                        .then(function (response) {
                            setLoader(false);
                            const tokenValues = jwt(response.idToken);
                            localStorage.setItem('userId', tokenValues.sub);
                            localStorage.setItem('tenantId', tokenValues.tid);
                            if (localStorage.getItem('ssoUser') === 'true') {
                                localStorage.setItem('ADtoken', response.idToken);
                            } else {
                                localStorage.setItem('ADtoken', response.idToken);
                            }
                        })
                        .catch(function (error) {
                            if (error) {
                                // extract, if exists, claims from the error object
                                if (error.claims) {
                                    // request.claims = error.claims,
                                    // call acquireTokenPopup in case of InteractionRequiredAuthError failure
                                    instance.acquireTokenPopup(request)
                                        .then(function (response) {
                                            const tokenValues = jwt(response.idToken);
                                            localStorage.setItem('userId', tokenValues.sub);
                                            localStorage.setItem('tenantId', tokenValues.tid);
                                            if (localStorage.getItem('ssoUser') === 'true') {
                                                localStorage.setItem('ADtoken', response.idToken);
                                            } else {
                                                localStorage.setItem('ADtoken', response.idToken);
                                            }
                                        })
                                        .catch(function (error) {
                                            // setTokenCall(Math.random());
                                            console.log(error);
                                        });
                                }
                                else {
                                    // setTokenCall(Math.random());
                                }
                            }
                        });
                } catch (error) {
                    console.error("Failed to acquire token:", error);
                    // You can handle the error or initiate a new login flow here
                }
            }
        }
        else if ((localStorage.getItem('ADtoken') !== null && localStorage.getItem('ADtoken') !== undefined) &&
            getMinutes < 1) {
            setLoader(false);  
            dispatch({
                type: Action.LOGOUT,
            });
            localStorage.clear();
            sessionStorage.clear();
        } else {
            console.log('acc', activeAccount);          
        }
    };

    const handleLoginRedirect = () => {
        if ((!activeAccount || !activeAccount.length > 0)) {
            // dispatch({
            //     type: Action.LOGIN,
            // });
            setLoader(true);
            instance.loginRedirect(loginRequest).catch((error) => {
                setLoader(false);
                window.location.reload();
            })

            // setLoader(true);
        } else {
            instance.setActiveAccount(activeAccount[0]);
            let getMinutes = 0;
            const now = moment().unix();
            const duration = moment.duration(moment.unix(activeAccount[0].idTokenClaims?.exp).
            diff(moment.unix(now)));
            getMinutes = duration.asMinutes();
            if( getMinutes < 1) {
                setLoader(false);     
                dispatch({
                    type: Action.LOGOUT,
                });
                localStorage.clear();
                sessionStorage.clear();
            }
            setLoader(false);
        }

    };
    const handleLogoutRedirect = () => {
        if (localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true') {
            instance.logoutRedirect({
                onRedirectNavigate: () => {
                    return true;
                },
            }).then(() => {
            }).catch((error) => console.log('logout', error));
           localStorage.clear();
            sessionStorage.clear();
            dispatch({
                type: Action.LOGOUT_SUCCESS,
            });
        } else {
            instance.config.auth.authority = `${process.env.REACT_APP_AAD_APP_AUTHORITY}B2C_1A_INSEEGO_SDEDGE_SIGNUP_SIGNIN`;
            instance.logoutRedirect({
                onRedirectNavigate: () => {
                    return true;
                },
            })
                .then(() => {
                })
                .catch((error) => console.log('logout', error));
            localStorage.clear();
            sessionStorage.clear();
            dispatch({
                type: Action.LOGOUT_SUCCESS,
            });
        }
    };
    const handleProfileEdit = () => {
        if (inProgress === InteractionStatus.None) {
            instance.loginRedirect({
                authority: b2cPolicies.authorities.changePassword.authority,
                account: activeAccount[0]
            })
        }
    };

    const resetPopup = () => {
        setShowError(false);
        dispatch({
            type: Action.LOGOUT,
        });
        // handleLogoutRedirect();
    }
    const createSsoUser = async (email) => {
        if (verifyCall && localStorage.getItem('verifiedUser') !== "true") {
            const data = {
                email
            }
            await userApiCall(userUrlMapping.verifySSOUser(data), (response) => {
                if (response.status === true && (response.statusCode === "USER_ALREADY_VERIFIED" ||
                    response.statusCode === "USER_REGISTERED_AND_VERIFIED_SUCCESSFULLY")) {
                    setLoader(false);
                    localStorage.setItem('verifiedUser', true);
                    window.location.reload();
                } else {
                    setLoader(false);
                    dispatch({
                        type: Action.LOGOUT,
                    });
                    // handleLogoutRedirect();
                }
            }, (error) => {
                console.log('error', error);
            });
        } else {
            dispatch({
                type: Action.LOGIN_SUCCESS,
            });
        }
    }
    return (
        <>
            {showLoader && <Loader />}
            {process.env.REACT_APP_AD_USER === 'TRUE' && (newState.authenticated ||
                localStorage.getItem('verifiedUser') === "true") && <div className="action d-flex align-items-center justify-content-between">
                    <div className="user-box d-flex align-items-center">

                        <span className="short-name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            title={localStorage.getItem('ssoUser') === 'true' ? 'Inseego Support' :
                                `${JSON.parse(localStorage.getItem('user'))?.firstName} ${JSON.parse(localStorage.getItem('user'))?.lastName}`}>
                            {localStorage.getItem('ssoUser') === 'true' ? 'I S' : 
                            `${JSON.parse(localStorage.getItem('user'))?.firstName?.charAt(0)}${JSON.parse(localStorage.getItem('user'))?.lastName?.charAt(0)}`}</span>
                        <i className="d-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>

                        <div className="user-list dropdown-menu">
                            <ul>
                                <li onClick={handleLogoutRedirect}><img src="/images/svg-icons/exit.svg" alt="" /> Logout</li>
                                {localStorage.getItem('ssoUser') !== 'true' && <li
                                    onClick={handleProfileEdit}
                                ><img src="/images/svg-icons/password.svg" alt="" /> Change Password</li>}
                            </ul>
                        </div>
                    </div>
                </div>}
            {(showError) &&
                <ErrorPopup
                    title='Reset Password'
                    message='Password has been changed. Please login with the new password.'
                    error={false}
                    reset={resetPopup}
                />
            }
            {(showRefreshModal) &&
                <ErrorHandlePopup
                />
            }
        </>
    )
}
export default ADHeader;
