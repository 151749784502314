/* eslint-disable array-callback-return */
import React, { Component } from "react";
import $ from 'jquery';
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import { ErrorPopup } from '../../layOuts/errorPopup';
import { Netmask } from 'netmask';
import * as Action from '../../redux/actionTypes';
import ErrorHandlePopup from "../ErrorHandlePopup";
import * as Common from "../../utils/common";
import Select from 'react-select';

class EditTunnel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            deviceData: undefined,
            showMessage: false,
            showError: false,
            tunnel_name: "",
            closeTunnel: true,
            is_change_in_tunnel: false,
            device_A_zone: true,
            device_B_zone: true,
            BGP_device_A_zone: true,
            BGP_device_B_zone: true,
            deviceA_networks: [],
            deviceB_networks: [],
            checkValue: true,
            options_deviceA: [],
            options_deviceB: [],
            isAxiosError: false,
            template_operation_id: this.props?.data?.settings?.template_operation_id || ""
        }
    };

    componentDidMount() {
        let options_deviceA = [];
        let options_deviceB = [];
        this.props.deviceA_networks.map((item) => {
            if (item.network_zone === 'wan') {
                options_deviceA.push({
                    label: item.name, value: item.networkName,
                    isDisabled: this.state.deviceData.device_A.used_tunnel_link_interface.includes(item.networkName)
                })
            }
        });
        this.props.deviceB_networks.map((item) => {
            if (item.network_zone === 'wan') {
                options_deviceB.push({
                    label: item.name, value: item.networkName,
                    isDisabled: this.state.deviceData.device_B.used_tunnel_link_interface.includes(item.networkName)
                })
            }
        });
        this.setState({
            options_deviceA,
            options_deviceB,
            deviceA_networks: this.props.deviceA_networks,
            deviceB_networks: this.props.deviceB_networks,
        })
    };

    static getDerivedStateFromProps = (props, state) => {
        if (props.data) {
            state.template_operation_id = props?.data?.settings?.template_operation_id || ""
            state.deviceData = props.data;
            state.deviceA_networks = props.deviceA_networks;
            state.deviceB_networks = props.deviceB_networks;
            state.deviceA_tunnelLink = props.data.device_A.tunnel_link;
            state.deviceB_tunnelLink = props.data.device_B.tunnel_link;
            let options_deviceA = [];
            let options_deviceB = [];
            props.deviceA_networks.map((item) => {
                if (item.network_zone === 'wan') {
                    options_deviceA.push({
                        label: item.name, value: item.networkName,
                        isDisabled: state.deviceData.device_A.used_tunnel_link_interface.includes(item.networkName)
                    })
                }
            });
            props.deviceB_networks.map((item) => {
                if (item.network_zone === 'wan') {
                    options_deviceB.push({
                        label: item.name, value: item.networkName,
                        isDisabled: state.deviceData.device_B.used_tunnel_link_interface.includes(item.networkName)
                    })
                }
            });
            state.options_deviceA = options_deviceA;
            state.options_deviceB = options_deviceB;
        }
        return state;
    };

    handleKeyDown = evt => {
        this.removeError(evt);
        let valid = false;
        if (evt.target.value && (["Enter", "Tab", " ", ","].includes(evt.key) || evt.type === "blur")) {
            evt.preventDefault();
            const items = evt.target.name;
            let ip;
            if (evt.target.value.includes('/') && this.validateIPaddress(evt.target.value.split('/')[0], evt.target.name)) {
                ip = new Netmask(evt.target.value);
                valid = this.validateIPaddress(evt.target.value.split('/')[0], evt.target.name) && ip.contains(evt.target.value.split('/')[0]);

            } else
                valid = this.validateIPaddress(evt.target.value, evt.target.name);

            let data = this.state.deviceData;
            if (valid && items === "device_A_allowedIPs") {
                if (evt.target.value && !data.device_A.allowed_ips.includes(evt.target.value)) {
                    data.device_A.allowed_ips = [...data.device_A.allowed_ips, evt.target.value];
                    this.setState({
                        deviceData: data,
                        edit: false,
                        //EMGT-60-In storm breaker, Update option is disabled while edit tunnel
                        checkValue: false,
                        is_change_in_tunnel: true
                    });
                }
                $("input[name=device_A_allowedIPs]").val("");
            }
            else {
                if (valid && items === "device_B_allowedIPs") {
                    let data = this.state.deviceData;
                    if (evt.target.value && !data.device_B.allowed_ips.includes(evt.target.value)) {

                        data.device_B.allowed_ips = [...data.device_B.allowed_ips, evt.target.value];
                        this.setState({
                            deviceData: data,
                            edit: false,
                            //EMGT-60-In storm breaker, Update option is disabled while edit tunnel
                            checkValue: false,
                            is_change_in_tunnel: true
                        });
                    }
                    $("input[name=device_B_allowedIPs]").val("");
                }
            }
        }
    };

    handleEditIP = evt => {
        this.removeError(evt);
        let valid = false;
        if (evt.target.value && (["Enter", "Tab", " ", ","].includes(evt.key) || evt.type === "blur")) {
            evt.preventDefault();
            const items = evt.target.name;
            let ip;
            if (evt.target.value.includes('/') && this.validateIPaddress(evt.target.value.split('/')[0], evt.target.name)) {
                ip = new Netmask(evt.target.value);
                valid = this.validateIPaddress(evt.target.value.split('/')[0], evt.target.name) && ip.contains(evt.target.value.split('/')[0]);

            } else
                valid = this.validateIPaddress(evt.target.value, evt.target.name);

            let data = this.state.deviceData;
            if (valid && items === "device_A_allowedIPs") {
                if (evt.target.value && !data.device_A.allowed_ips.includes(evt.target.value)) {
                    data.device_A.allowed_ips = [...data.device_A.allowed_ips, evt.target.value];
                    this.setState({
                        deviceData: data,
                        edit: false
                    });
                }
                $("input[name=device_A_allowedIPs]").val("");
            }
            else {
                if (valid && items === "device_B_allowedIPs") {
                    let data = this.state.deviceData;
                    if (evt.target.value && !data.device_B.allowed_ips.includes(evt.target.value)) {

                        data.device_B.allowed_ips = [...data.device_B.allowed_ips, evt.target.value];
                        this.setState({
                            deviceData: data,
                            edit: false
                        });
                    }
                    $("input[name=device_B_allowedIPs]").val("");
                }
            }
        }
    };

    handleDeleteA = item => {
        let data = this.state.deviceData;
        data.device_A.allowed_ips = data.device_A.allowed_ips.filter(i => i !== item)
        this.setState({
            deviceData: data,
            is_change_in_tunnel: true
        });
    };

    handleDeleteB = item => {
        let data = this.state.deviceData;
        data.device_B.allowed_ips = data.device_B.allowed_ips.filter(i => i !== item)
        this.setState({
            deviceData: data,
            is_change_in_tunnel: true
        });
    };

    setValue = (e) => {
        this.removeError(e);
        let value = '';
        const name = e.target.name;
        //LONG-2930 - EM - Front-end Issues-3, update button is enabled without changing any configuration in tunnel page 
        if (e.target.name === 'tunnel_keep_alive_status') {
            let data = this.state.deviceData;
            data.settings.tunnel_keep_alive_status = e.target.checked;
            value = e.target.checked;
            this.setState({
                [name]: value,
                checkValue: false,
                deviceData: data,
                is_change_in_tunnel: true
            });
        } else if (e.target.name === 'tunnel_keep_alive_interval') {
            let data = this.state.deviceData;
            data.settings.tunnel_keep_alive_interval = parseInt(e.target.value);
            this.setState({
                [name]: value,
                checkValue: false,
                deviceData: data,
                is_change_in_tunnel: true
            });
            if (e.target.value === '' || parseInt(e.target.value) > 120 || parseInt(e.target.value) < 1) {
                const element = document.getElementById(name);
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }

        } else if (e.target.name === 'tunnel_name') {
            let data = this.state.deviceData;
            data.settings.tunnel_name = e.target.value;
            this.setState({
                [name]: value,
                deviceData: data
            });
        }
        else if (e.target.name === 'private_ip') {
            let data = this.state.deviceData;
            data.device_B.private_ip = e.target.value;
            this.setState({
                checkValue: false,
                deviceData: data
            })
        }
        else {
            value = e.target.value;
            let data = this.state.deviceData;
            if (name === 'device_A_endpoint') {
                data.device_A.endpoint_details = value;
                if (this.state.deviceData.device_A.endpoint_details === value) {
                    this.setState({
                        checkValue: false
                    })
                }
                else {
                    this.setState({
                        checkValue: true
                    })
                }
            } else if (name === 'device_B_endpoint') {
                data.device_B.endpoint_details = value;
                if (this.state.deviceData.device_B.endpoint_details === value) {
                    this.setState({
                        checkValue: false
                    })
                }
                else {
                    this.setState({
                        checkValue: true
                    })
                }
            }
            if (name === 'device_A_tunnel_link') {
                data.device_A.tunnel_link = value;
            } else if (name === 'device_B_tunnel_link') {
                data.device_B.tunnel_link = value;
            }
            this.setState({
                [name]: value, is_change_in_tunnel: true,
                deviceData: data
            });
        }
    };

    setEndpoint = (e) => {
        const value = e.target.dataset.value;
        const name = e.target.dataset.name;
        let data = this.state.deviceData;
        if (name === 'device_A_endpoint') {
            data.device_A.endpoint_details = value;
            const element = document.getElementById("device_A_endpoint");
            if (element) {
                element.style.display = 'none';
            }
            this.setState({
                showError: false,
            });
        } else if (name === 'device_B_endpoint') {
            data.device_B.endpoint_details = value;
            const element = document.getElementById("device_B_endpoint");
            if (element) {
                element.style.display = 'none';
            }
            this.setState({
                showError: false,
            });
        }
        this.setState({
            [name]: value,
            is_change_in_tunnel: true,
            deviceData: data,
            checkValue: false
        });
    };

    setAllowedIP = (evt) => {
        this.removeError(evt);
        const value = evt.target.dataset.value;
        let data = this.state.deviceData;
        const name = evt.target.dataset.name;
        if (name === "device_A_allowedIPs") {
            if (!data.device_A.allowed_ips.includes(value)) {
                data.device_A.allowed_ips = [...data.device_A.allowed_ips, value];
                this.setState({
                    deviceData: data,
                    is_change_in_tunnel: true
                });
            }
            $("input[name=device_A_allowedIPs]").val("");
        }
        else {
            if (name === "device_B_allowedIPs") {
                let data = this.state.deviceData;
                if (value && !data.device_B.allowed_ips.includes(value)) {

                    data.device_B.allowed_ips = [...data.device_B.allowed_ips, value];
                    this.setState({
                        deviceData: data,
                        is_change_in_tunnel: true
                    });
                }
                $("input[name=device_B_allowedIPs]").val("");
            }
        }
    };

    validateIPaddress = (ipaddress, name) => {
        //LONG-2998-EM - Field validation for track address,Domain name should be included in keepalive configuration,Scenario 2
        let DomainName = /^(www.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]+?\.[a-zA-Z]{2,3}$/.test(ipaddress)
        if (ipaddress !== '') {
            if (DomainName === false) {
                if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
                    return (true)
                }
                const element = document.getElementById(name);
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
                return (false);
            }
            else {
                const element = document.getElementById(name);
                element.style.display = "none";
                this.setState({
                    showError: false,
                });
                return (true);
            }
        }
    };

    removeError = (e) => {
        const element = document.getElementById(e.target.name);
        if (element)
            element.style.display = 'none';
        this.setState({
            showError: false
        })
    };

    showPopup = () => {
        //LONG-2981-EM - Create tunnel pop-up should display only on particular condition
        if (this.state.BGP_device_A_zone === true || this.state.BGP_device_B_zone === true) {
            this.setState({
                title: 'Update tunnel',
                message: "BGP Session is enabled between Device-A to Device-B via Data Tunnel with redistribution of Default network.",
                showMessage: true,
                error: false,
                closeTunnel: false
            }, () => $("#errorMessage").modal("show"));
        }
        else {
            this.setState({
                title: 'Update Tunnel',
                message: "Apply BGP Template to share routes dynamically for all networks available in the device(or)Apply Static Route Template to add routes manually for certain networks to enable communication between each other.",
                showMessage: true,
                error: false,
                delete: true,
                closeTunnel: false
            }, () => $("#errorMessage").modal("show"));
            this.updateTunnel();
        }
    };

    checkDeviceTask = async () => {
        let deviceMac = [];
        deviceMac.push(this.state.deviceData.device_A.device_mac, this.state.deviceData.device_B.device_mac);
        let data = { device_mac: deviceMac, operation_type: ['Create Tunnel', 'Update Tunnel', 'TunnelConfiguration', 'Firmware Upgrade', 'BGPConfiguration'] }
        this.setState({
            showLoader: true
        });
        const trueArr = [];
        await ApiCall(urlMapping.checkDeviceTask(data), response => {
            this.setState({
                showLoader: false,
                show: false
            });
            $("#errorMessage").modal("hide");
            if (response?.success === true) {
                const data = response?.data;
                Object?.keys(data)?.forEach(item => {
                    if (data?.[item]?.status === true) {
                        trueArr?.push(data?.[item]);
                    }
                });
                if (trueArr?.length > 0) {
                    this.setState({
                        title: 'Device Task',
                        // message: trueArr[0].message,
                        message: "An operation on similar/dependent template is in-progress, please wait until the operation completes",
                        show: true,
                        error: true,
                        delete: false,
                        toggledClearRows: !this.state.toggledClearRows,
                    }, () => $("#errorMessage").modal("show"));
                }
                this.setState({
                    show: false,
                    error: false,
                    delete: false
                })
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Device Task',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
        return trueArr;
    };

    checkError = () => {
        let result = false;
        // EMGT-273-EM - Remote Endpoint Field Validation in Tunnel Page
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.checkValue || this.state.showError || (this.state.deviceData.settings.tunnel_name === '' || this.state.show ||
            // Disabled Update button when any one device is offline 
            (this.state.deviceData.device_A.device_status === 0 || this.state.deviceData.device_B.device_status === 0)))
            result = true;
        if (this.state.deviceData?.device_B?.device_id === "" && this.state.deviceData.device_A?.endpoint_details === "") {
            result = true;
        }
        if (this.state.deviceData?.device_B?.device_id === "" && this.state.deviceData.device_B?.private_ip === "") {
            result = true;
        }
        return result;
    };

    updateTunnel = async () => {
        //LONG-2527-EM - [Create Tunnel - Apply BGP Templates...] pop-up should not display while updating data tunnel
        // this.resetPopup();
        const haveTask = await this.checkDeviceTask();
        let data = this.state.deviceData;
        data.device_A.endpoint_details = this.state.device_A_endpoint === undefined ? data.device_A.endpoint_details : this.state.device_A_endpoint;
        data.device_A.allowed_ips = this.state.device_A_allowedIPs === undefined ? data.device_A.allowed_ips : this.state.device_A_allowedIPs;
        data.device_B.endpoint_details = this.state.device_B_endpoint === undefined ? data.device_B.endpoint_details : this.state.device_B_endpoint;
        data.device_B.allowed_ips = this.state.device_B_allowedIPs === undefined ? data.device_B.allowed_ips : this.state.device_B_allowedIPs;
        data.settings.tunnel_name = this.state.tunnel_name === "" ? data.settings.tunnel_name : this.state.tunnel_name;
        data.settings.is_change_in_tunnel = this.state.is_change_in_tunnel;
        data.settings.template_operation_id = this.state?.template_operation_id;
        // LONG-2215 : Toggle Button to Add interface into default data zone
        data.device_A.add_zone_in_firewall = data.device_A.add_zone_in_firewall ? true : false;
        data.device_B.add_zone_in_firewall = data.device_B.add_zone_in_firewall ? true : false;
        data.device_A.add_bgp_in_tunnel = data.device_A.add_bgp_in_tunnel ? true : false;
        data.device_B.add_bgp_in_tunnel = data.device_B.add_bgp_in_tunnel ? true : false;
        if (data.settings.tunnel_keep_alive_status === 0)
            data.settings.tunnel_keep_alive_interval = 0;
        this.setState({
            showLoader: true,
        });
        let id = this.props.tunnel_id;

        if (haveTask.length === 0) {
            ApiCall(urlMapping.updateTunnel(data, id), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success) {
                    this.setState({
                        // showLoader: false,
                        //LONG-2930 - EM - Front-end Issues-3, Update tunnel popup should be displayed while clicking on update button
                        title: 'Update tunnel',
                        message: response?.message,
                        show: true,
                        error: false,
                        closeTunnel: true
                    }, () => {
                        $("#errorMessage").modal("show")
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({
                            showRefreshModal: true
                        });
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Create Tunnel',
                            message: response?.message,
                            show: true,
                            error: true,
                            closeTunnel: false
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            });
        } else {
            this.setState({
                showLoader: false
            });
        }
    };

    // LONG-2215 : Toggle Button to Add interface into default data zone
    handleChange = (e, name) => {
        let data = this.state.deviceData;
        if (name === "device_A_zone") {
            if (e.target.checked) {
                data.device_A.add_zone_in_firewall = true
            }
            else {
                data.device_A.add_zone_in_firewall = false
            }
            this.setState({
                data,
                keepZoneAEnabled: true,
                is_change_in_tunnel: true,
                //EMGT-340-After select the checkbox of "Add interface to Zone" and "Enable BGP" in Dynamic tunnel creation Apply button is not enable after tunnel creation
                checkValue: false,
            })
        }
        else if (name === "device_B_zone") {
            if (e.target.checked) {
                data.device_B.add_zone_in_firewall = true
            }
            else {
                data.device_B.add_zone_in_firewall = false
            }
            this.setState({
                data,
                keepZoneBEnabled: true,
                is_change_in_tunnel: true,
                //EMGT-340-After select the checkbox of "Add interface to Zone" and "Enable BGP" in Dynamic tunnel creation Apply button is not enable after tunnel creation
                checkValue: false,
            })
        }
        else if (name === "BGP_device_A_zone") {
            if (e.target.checked) {
                data.device_A.add_bgp_in_tunnel = true
            }
            else {
                data.device_A.add_bgp_in_tunnel = false
            }
            this.setState({
                data,
                keepBgpAEnabled: true,
                is_change_in_tunnel: true,
                //EMGT-340-After select the checkbox of "Add interface to Zone" and "Enable BGP" in Dynamic tunnel creation Apply button is not enable after tunnel creation
                checkValue: false,
            })
        }
        else if (name === "BGP_device_B_zone") {
            if (e.target.checked) {
                data.device_B.add_bgp_in_tunnel = true
            }
            else {
                data.device_B.add_bgp_in_tunnel = false
            }
            this.setState({
                data,
                keepBgpBEnabled: true,
                is_change_in_tunnel: true,
                //EMGT-340-After select the checkbox of "Add interface to Zone" and "Enable BGP" in Dynamic tunnel creation Apply button is not enable after tunnel creation
                checkValue: false,
            })
        }
    };

    closeEditTunnel = () => {
        this.props.dispatch({
            type: Action.CLOSE_EDIT_TUNNEL,
        });
    };

    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
            if (this.props.getTunnelList) {
                this.props.getTunnelList();
            }
            if (this.props.getTunnelInfo) {
                this.props.getTunnelInfo();
            }
            if (this.state.closeTunnel)
                this.closeEditTunnel();
        })
    };

    setEditIP = (key) => {
        this.setState({
            edit: true
        })
    };

    getDeviceNetworks = (device) => {
        let optionsArray = [];
        if (device === 'device_A') {
            this.props.deviceA_networks.map((item) => {
                if (item.network_zone === 'wan') {
                    optionsArray.push({
                        label: item.name, value: item.networkName,
                        isDisabled: this.state.deviceData.device_A.used_tunnel_link_interface.includes(item.networkName)
                    })
                }
            });
        }
        else {
            this.props.deviceB_networks.map((item) => {
                if (item.network_zone === 'wan') {
                    optionsArray.push({
                        label: item.name, value: item.networkName,
                        isDisabled: this.state.deviceData.device_A.used_tunnel_link_interface.includes(item.networkName)
                    })
                }
            });
        }
        return optionsArray;
    };

    getNetworkname = (value, device) => {
        let network = "";
        let networks = device === 'device_A' ? this.props.deviceA_networks : this.props.deviceB_networks;
        networks.map((item) => {
            if (item.network_zone === 'wan' && item.networkName === value) {
                network = item.name;
            }
        })
        return network;
    };

    setTunnelLink = (e, device) => {
        let data = this.state.deviceData;
        if (device === 'device_A_tunnel_link') {
            data.device_A.tunnel_link = e && e.value ? e.value : '';
        } else {
            data.device_B.tunnel_link = e && e.value ? e.value : '';
        }
        this.setState({
            deviceData: data,
            checkValue: false,
            //LONG-3500 : Update Tunnel operation is not happening when selecting WAN interface and click on update
            is_change_in_tunnel: true,
        });
    };

    validateIPaddressWithSubnetMask = (e) => {
        if (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-9]|0[0-9]|1[0-9]|2[0-9]|3[0-2])$/.test(e))
            return false;
        return true;
    };

    handleAllowedIpDeviceA = (e, key) => {
        if (e.key === 'Enter' || key === "blur") {
            const value = e.target.value.trim();
            const element = document.getElementById(`device_A_allowedIPs`);
            if (element) {
                if (value !== "") {
                    if (
                        this.validateIPaddressWithSubnetMask(value) ||
                        !value.includes('/') ||
                        value.split('/')[1] < 8 ||
                        value.split('/')[1] > 32
                    ) {
                        element.style.display = 'block';
                        element.innerHTML = 'Invalid IP';
                        this.setState({
                            showError: true
                        });
                    } else if (this.state.deviceData?.device_A?.allowed_ips?.includes(value)) {
                        element.style.display = 'block';
                        element.innerHTML = 'Entered IP already added!';
                        this.setState({
                            showError: true
                        });
                    } else {
                        element.style.display = 'none';
                        element.innerHTML = "";
                        let { deviceData } = this.state;
                        deviceData.device_A.allowed_ips = [...deviceData?.device_A?.allowed_ips, value];
                        this.setState({
                            deviceData,
                            checkValue: false,
                            is_change_in_tunnel: true,
                            showError: false
                        });
                        $("input[name=device_A_allowedIPs]").val("");
                    }
                } else {
                    element.style.display = 'none';
                    element.innerHTML = "";
                    this.setState({
                        showError: false
                    });
                }
            }
        }
    };

    setDeviceAAllowedIP = (e) => {
        const value = e.target.value;
        const element = document.getElementById(`device_A_allowedIPs`);
        if (element) {
            if (!this.state.deviceData?.device_A?.allowed_ips?.includes(value)) {
                element.style.display = 'none';
                element.innerHTML = "";
                let { deviceData } = this.state;
                deviceData.device_A.allowed_ips = [...deviceData?.device_A?.allowed_ips, value];
                this.setState({
                    deviceData,
                    checkValue: false,
                    is_change_in_tunnel: true,
                    showError: false
                });
                $("input[name=device_A_allowedIPs]").val("");
            }
        }
    };

    handleAllowedIpDeviceB = (e, key) => {
        if (e.key === 'Enter' || key === "blur") {
            const value = e.target.value.trim();
            const element = document.getElementById(`device_B_allowedIPs`);
            if (element) {
                if (value !== "") {
                    if (
                        this.validateIPaddressWithSubnetMask(value) ||
                        !value.includes('/') ||
                        value.split('/')[1] < 8 ||
                        value.split('/')[1] > 32
                    ) {
                        element.style.display = 'block';
                        element.innerHTML = 'Invalid IP';
                        this.setState({
                            showError: true
                        });
                    } else if (this.state.deviceData?.device_B?.allowed_ips?.includes(value)) {
                        element.style.display = 'block';
                        element.innerHTML = 'Entered IP already added!';
                        this.setState({
                            showError: true,
                        });
                    } else {
                        element.style.display = 'none';
                        element.innerHTML = "";
                        let { deviceData } = this.state;
                        deviceData.device_B.allowed_ips = [...deviceData?.device_B?.allowed_ips, value];
                        this.setState({
                            deviceData,
                            checkValue: false,
                            is_change_in_tunnel: true,
                            showError: false
                        });
                        $("input[name=device_B_allowedIPs]").val("");
                    }
                } else {
                    element.style.display = 'none';
                    element.innerHTML = "";
                    this.setState({
                        showError: false
                    });
                }
            }
        }
    };

    setDeviceBAllowedIP = (e) => {
        const value = e.target.value;
        const element = document.getElementById(`device_B_allowedIPs`);
        if (element) {
            if (!this.state.deviceData?.device_B?.allowed_ips?.includes(value)) {
                element.style.display = 'none';
                element.innerHTML = "";
                let { deviceData } = this.state;
                deviceData.device_B.allowed_ips = [...deviceData?.device_B?.allowed_ips, value];
                this.setState({
                    deviceData,
                    checkValue: false,
                    is_change_in_tunnel: true,
                    showError: false
                });
                $("input[name=device_B_allowedIPs]").val("");
            }
        }
    };

    render() {
        if (this.state.deviceData !== undefined)
            return (
                <div>
                    {this.state.showLoader && <Loader />}
                    <div className={this.props.open && this.state.deviceData !== undefined ? "slide-overlay open" : "slide-overlay"}></div>
                    <div className={this.props.open && this.state.deviceData !== undefined ? "sidebar-slide open" : "sidebar-slide"}>
                        <div className="s-header">
                            <h1 className="ss-title">Edit Tunnel</h1>
                            <button onClick={() => this.closeEditTunnel()}>
                                <img src="/images/svg-icons/s-cancel.svg" alt="" />
                            </button>
                        </div>
                        <div className="s-body">
                            {this.state.showLoader && <Loader />}
                            <div className="graybg-title d-flex align-items-center justify-content-between">
                                <div>Device A</div>
                            </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="device-icon">
                                            {/* LONG-2371 : Fixed IP Icon show in Data Tunnel Page */}
                                            {this.state.deviceData.device_A.is_fixed_ip === 1 &&
                                                <>
                                                    <div className="ip-img">
                                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="This device is having Fixed IP address">
                                                            <img src="/images/ip.png" alt="" />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <img src="/images/svg-icons/wifi-router.svg" alt="" />
                                            <div className={this.state.deviceData.device_A.device_status === 1 ? "status online" : "status offline"}></div>
                                        </div>
                                        <div className="d-title flex-grow-1 ml-3">
                                            <strong>{this.state.deviceData.device_A.device_name}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="d-flex align-items-center mt-3">
                                        <input type="checkbox"
                                            checked={this.state.deviceData.device_A.add_zone_in_firewall}
                                            // LONG-2374 : Disabled add interface to zone for mifi model
                                            disabled={(this.state.deviceData.device_A.add_zone_in_firewall && !this.state.keepZoneAEnabled)
                                                || Common.getModels(this.state.deviceData?.device_A?.device_model) === "Moretti_Model" ||
                                                Common.getModels(this.state.deviceData?.device_A?.device_model) === "Duvel_Model"}
                                            onChange={(e) => this.handleChange(e, "device_A_zone")}
                                        />
                                        <label className="mb-0 ml-2">
                                            Add Interface to Zone
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Add tunnel interface to the default Data Tunnel Zone">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="d-flex align-items-center mt-3">
                                        <input type="checkbox"
                                            checked={this.state.deviceData.device_A.add_bgp_in_tunnel}
                                            // LONG-2374 : Disabled enable BGP for mifi model
                                            disabled={(this.state.deviceData.device_A.add_bgp_in_tunnel && !this.state.keepBgpAEnabled)
                                                || !(
                                                    Common.getModels(this.state.deviceData?.device_A?.device_model) === "CHIMAY" ||
                                                    Common.getModels(this.state.deviceData?.device_A?.device_model) === "vCPE"
                                                )}
                                            onChange={(e) => this.handleChange(e, "BGP_device_A_zone")}
                                        />
                                        <label className="mb-0 ml-2">
                                            Enable BGP
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Enable BGP for Dynamic Routing of available networks"
                                            >
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label>Public Key
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="The key used to encrypt data. Each peer has its own public key">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </label>
                                        <input
                                            type="text"
                                            name="device_A_publicKey"
                                            onChange={(e) => this.setValue(e)}
                                            minLength="35" maxLength="50"
                                            disabled
                                            title={this.state.deviceData.device_A.public_key}
                                            value={this.state.deviceData.device_A.public_key}
                                            className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group with-arrow">
                                        <label>Remote Endpoint Details
                                            {this.state.deviceData?.device_B?.device_id === "" && <span className="red-txt">*</span>}
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="The public facing Internet IP address of the remote side of the tunnel">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </label>
                                        <input type="text"
                                            name="device_A_endpoint"
                                            onChange={(e) => this.setValue(e)}
                                            onBlur={(e) => this.validateIPaddress(e.target.value, e.target.name)}
                                            value={(this.state.deviceData.device_A.endpoint_details === null ||
                                                this.state.deviceData.device_A.endpoint_details === 'null') ? '' :
                                                this.state.deviceData.device_A.endpoint_details}
                                            className="form-control" />
                                        <i className="drop-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                        <div className="dropdown-menu max-height-200" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                {this.state.deviceData.device_A.available_endpoint_details.map((item) => {
                                                    if (item)
                                                        return (<li
                                                            data-name="device_A_endpoint" data-value={item}
                                                            onClick={(e) => this.setEndpoint(e)}
                                                        >{item}</li>)
                                                })}
                                            </ul>
                                        </div>
                                        <div className="error-txt" id="device_A_endpoint">Invalid value</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div>
                                        <label>Listen Port
                                            <span className="red-txt">*</span>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Listen port for data tunnel">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </label>
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={this.state.deviceData.device_A.own_port} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* Hiding remote allowed ips for Chimay and CBR devices */}
                                    {
                                        !(
                                            Common.getModels(this.state.deviceData?.device_A?.device_model) === "CHIMAY" ||
                                            Common.getModels(this.state.deviceData?.device_A?.device_model) === "vCPE"
                                        ) &&
                                        <>
                                            <div className="form-group">
                                                <div className="d-flex align-items-center justify-content-between mb-1">
                                                    <label className="mb-0">Remote Allowed IPs
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="These are the CIDR formatted remote subnets of the opposite side of the tunnel">
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="allowedip-box with-arrow">
                                                    {
                                                        this.state.deviceData?.device_A?.allowed_ips?.map((item) => {
                                                            if (item !== null && item !== 'null' && item !== '') {
                                                                return (
                                                                    <div className="tag-item" key={item}>
                                                                        {item}
                                                                        <button
                                                                            type="button"
                                                                            className="button"
                                                                            disabled={this.props.view}
                                                                            onClick={() => {
                                                                                let { deviceData } = this.state;
                                                                                deviceData.device_A.allowed_ips = deviceData?.device_A?.allowed_ips?.filter(val => String(val) !== String(item));
                                                                                this.setState({
                                                                                    deviceData,
                                                                                    checkValue: false,
                                                                                    is_change_in_tunnel: true
                                                                                });
                                                                            }}
                                                                        >
                                                                            <img src="/images/svg-icons/cancel.svg" alt="" />
                                                                        </button>
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                    }
                                                    <input
                                                        type="text"
                                                        name="device_A_allowedIPs"
                                                        onKeyDown={(e) => this.handleAllowedIpDeviceA(e)}
                                                        onBlur={(e) => this.handleAllowedIpDeviceA(e, "blur")}
                                                        autoComplete="off"
                                                    />
                                                    <i
                                                        className="drop-arrow"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false">
                                                    </i>
                                                    <div
                                                        className="dropdown-menu max-height-200"
                                                        aria-labelledby="dropdownMenuButton"
                                                    >
                                                        <ul>
                                                            {this.state.deviceData?.device_B?.available_allowed_ips
                                                                ?.filter(item => String(item) !== "0.0.0.0/0")
                                                                ?.map((item) => {
                                                                    if (item) {
                                                                        return (
                                                                            <li
                                                                                key={item}
                                                                                data-name="device_A_allowedIPs"
                                                                                data-value={item}
                                                                                onClick={(e) => this.setDeviceAAllowedIP(e)}
                                                                            >
                                                                                {item}
                                                                            </li>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="error-txt" id="device_A_allowedIPs">Invalid value</div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>WAN Binding{this.state.deviceData.device_A.load_balance === '1' &&
                                            <span className="red-txt">*</span>}</label>
                                        <div
                                            className="tooltip-c"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="Select the WAN interface to be mapped with the tunnel.">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                        <Select
                                            options={this.state.options_deviceA}
                                            isClearable={true}
                                            isSearchable={false}
                                            className="custom-dropdown w-145"
                                            classNamePrefix="react-select-container"
                                            onChange={(e) => this.setTunnelLink(e, 'device_A_tunnel_link')}
                                            value={{
                                                label: this.getNetworkname(this.state.deviceData.device_A.tunnel_link, 'device_A'),
                                                value: this.state.deviceData.device_A.tunnel_link
                                            }}
                                            isDisabled={this.state.deviceData.device_A.load_balance === '0'}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.deviceData.device_B.device_id !== ""
                                    ? <>
                                        <div className="graybg-title d-flex align-items-center justify-content-between mt-4">
                                            <div>Device B</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="device-icon">
                                                        {this.state.deviceData.device_B.is_fixed_ip === 1 &&
                                                            <>
                                                                <div className="ip-img">
                                                                    <div
                                                                        className="tooltip-c"
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="This device is having Fixed IP address">
                                                                        <img src="/images/ip.png" alt="" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        <img src="/images/svg-icons/wifi-router.svg" alt="" />
                                                        <div className={this.state.deviceData.device_B.device_status === 1 ? "status online" : "status offline"}></div>
                                                    </div>
                                                    <div className="d-title flex-grow-1 ml-3">
                                                        <strong>{this.state.deviceData.device_B.device_name}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex align-items-center mt-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.deviceData.device_B.add_zone_in_firewall}
                                                        disabled={(this.state.deviceData.device_B.add_zone_in_firewall && !this.state.keepZoneBEnabled)
                                                            || Common.getModels(this.state.deviceData?.device_B?.device_model) === "Moretti_Model" ||
                                                            Common.getModels(this.state.deviceData?.device_B?.device_model) === "Duvel_Model"}
                                                        onChange={(e) => this.handleChange(e, "device_B_zone")}
                                                    />
                                                    <label className="mb-0 ml-2">
                                                        Add Interface to Zone
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Add tunnel interface to the default Data Tunnel Zone"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="d-flex align-items-center mt-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.deviceData.device_B.add_bgp_in_tunnel}
                                                        disabled={
                                                            (
                                                                this.state.deviceData.device_B.add_bgp_in_tunnel &&
                                                                !this.state.keepBgpBEnabled
                                                            ) || !(
                                                                Common.getModels(this.state.deviceData.device_B.device_model) === "CHIMAY" ||
                                                                Common.getModels(this.state.deviceData.device_B.device_model) === "vCPE"
                                                            )
                                                        }
                                                        onChange={(e) => this.handleChange(e, "BGP_device_B_zone")}
                                                    />
                                                    <label className="mb-0 ml-2">
                                                        Enable BGP
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Enable BGP for Dynamic Routing of available networks"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label>Public Key
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="The key used to encrypt data. Each peer has its own public key"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="device_B_publicKey"
                                                        onChange={(e) => this.setValue(e)}
                                                        minLength="35" maxLength="50"
                                                        disabled
                                                        title={this.state.deviceData.device_B.public_key}
                                                        value={this.state.deviceData.device_B.public_key}
                                                        className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group with-arrow">
                                                    <label>Remote Endpoint Details
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="The public facing Internet IP address of the remote side of the tunnel"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="device_B_endpoint"
                                                        onChange={(e) => this.setValue(e)}
                                                        onBlur={(e) => this.validateIPaddress(e.target.value, e.target.name)}
                                                        value={
                                                            (this.state.deviceData.device_B.endpoint_details === null ||
                                                                this.state.deviceData.device_B.endpoint_details === 'null')
                                                                ? ''
                                                                : this.state.deviceData.device_B.endpoint_details
                                                        }
                                                        className="form-control" />
                                                    <i className="drop-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                    <div className="dropdown-menu max-height-200" aria-labelledby="dropdownMenuButton">
                                                        <ul>
                                                            {this.state.deviceData.device_B.available_endpoint_details.map((item) => {
                                                                if (item)
                                                                    return <li
                                                                        data-name="device_B_endpoint" data-value={item}
                                                                        onClick={(e) => this.setEndpoint(e)}
                                                                    >{item}</li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                    <div className="error-txt" id="device_B_endpoint">Invalid value</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div>
                                                    <label>Listen Port
                                                        <span className="red-txt">*</span>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Listen port for data tunnel"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={this.state.deviceData.device_B.own_port} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* Hiding remote allowed ips for Chimay and CBR devices */}
                                                {
                                                    !(
                                                        Common.getModels(this.state.deviceData.device_B.device_model) === "CHIMAY" ||
                                                        Common.getModels(this.state.deviceData.device_B.device_model) === "vCPE"
                                                    ) &&
                                                    <>
                                                        <div className="form-group">
                                                            <div className="d-flex align-items-center justify-content-between mb-1">
                                                                <label className="mb-0">Remote Allowed IPs
                                                                    <div
                                                                        className="tooltip-c"
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="These are the CIDR formatted remote subnets of the opposite side of the tunnel">
                                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="allowedip-box with-arrow">
                                                                {
                                                                    this.state.deviceData?.device_B?.allowed_ips?.map((item) => {
                                                                        if (item !== null && item !== 'null' && item !== '') {
                                                                            return (
                                                                                <div className="tag-item" key={item}>
                                                                                    {item}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        disabled={this.props.view}
                                                                                        onClick={() => {
                                                                                            let { deviceData } = this.state;
                                                                                            deviceData.device_B.allowed_ips = deviceData.device_B.allowed_ips.filter(val => String(val) !== String(item));
                                                                                            this.setState({
                                                                                                deviceData,
                                                                                                checkValue: false,
                                                                                                is_change_in_tunnel: true
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        <img src="/images/svg-icons/cancel.svg" alt="" />
                                                                                    </button>
                                                                                </div>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })
                                                                }
                                                                <input
                                                                    type="text"
                                                                    name="device_B_allowedIPs"
                                                                    onKeyDown={(e) => this.handleAllowedIpDeviceB(e)}
                                                                    onBlur={(e) => this.handleAllowedIpDeviceB(e, "blur")}
                                                                    autoComplete="off"
                                                                />
                                                                <i
                                                                    className="drop-arrow"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                </i>
                                                                <div
                                                                    className="dropdown-menu max-height-200"
                                                                    aria-labelledby="dropdownMenuButton"
                                                                >
                                                                    <ul>
                                                                        {this.state.deviceData?.device_A?.available_allowed_ips
                                                                            ?.filter(item => String(item) !== "0.0.0.0/0")
                                                                            ?.map((item) => {
                                                                                if (item) {
                                                                                    return (
                                                                                        <li
                                                                                            key={item}
                                                                                            data-name="device_B_allowedIPs"
                                                                                            data-value={item}
                                                                                            onClick={(e) => this.setDeviceBAllowedIP(e)}
                                                                                        >
                                                                                            {item}
                                                                                        </li>
                                                                                    );
                                                                                }
                                                                                return null;
                                                                            })}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="error-txt" id="device_B_allowedIPs">Invalid value</div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>WAN Binding
                                                        {
                                                            this.state.deviceData.device_B.load_balance === '1' &&
                                                            <span className="red-txt">*</span>
                                                        }
                                                    </label>
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Select the WAN interface to be mapped with the tunnel."
                                                    >
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                    <Select
                                                        name="device_B_tunnel_link"
                                                        options={this.state.options_deviceB}
                                                        isClearable={true}
                                                        isSearchable={false}
                                                        className="custom-dropdown w-145"
                                                        classNamePrefix="react-select-container"
                                                        onChange={(e) => this.setTunnelLink(e, 'device_B_tunnel_link')}
                                                        value={{
                                                            label: this.getNetworkname(this.state.deviceData.device_B.tunnel_link, 'device_B'),
                                                            value: this.state.deviceData.device_B.tunnel_link
                                                        }}
                                                        isDisabled={this.state.deviceData.device_B.load_balance === '0'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : <>
                                        <div className="graybg-title d-flex align-items-center justify-content-between mt-4">
                                            <div>External Endpoint</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Public Key
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="The key used to encrypt data. Each peer has its own public key"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="device_B_publicKey"
                                                        onChange={(e) => this.setValue(e)}
                                                        minLength="35" maxLength="50"
                                                        disabled
                                                        title={this.state.deviceData.device_B.public_key}
                                                        value={this.state.deviceData.device_B.public_key}
                                                        className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group with-arrow">
                                                    <label>Remote Endpoint Details
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="The public facing Internet IP address of the remote side of the tunnel"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="device_B_endpoint"
                                                        onChange={(e) => this.setValue(e)}
                                                        onBlur={(e) => this.validateIPaddress(e.target.value, e.target.name)}
                                                        value={
                                                            (this.state.deviceData.device_B.endpoint_details === null ||
                                                                this.state.deviceData.device_B.endpoint_details === 'null')
                                                                ? ''
                                                                : this.state.deviceData.device_B.endpoint_details
                                                        }
                                                        className="form-control" />
                                                    <div className="error-txt" id="device_B_endpoint">Invalid value</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div>
                                                    <label>Listen Port
                                                        <span className="red-txt">*</span>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Listen port for data tunnel"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        value={this.state.deviceData.device_B.own_port} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group with-arrow">
                                                    <label>Local Tunnel IP
                                                        <span className="red-txt">*</span>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="The tunnel IP address of the external endpoint device.">
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="private_ip"
                                                        title={this.state.deviceData?.device_B?.private_ip}
                                                        value={this.state.deviceData?.device_B?.private_ip}
                                                        onChange={(e) => this.setValue(e)}
                                                        onBlur={(e) => this.validateIPaddress(e.target.value, e.target.name)}
                                                        className="form-control"
                                                    />
                                                    <div className="error-txt" id="private_ip">Invalid value</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }
                            <div className="gray-bg p-3">
                                <div className="page-title">Settings</div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tunnel Name
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of tunnel">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                            <input
                                                type="text"
                                                disabled
                                                value={this.state.deviceData.settings.tunnel_name}
                                                className="form-control"
                                                name="tunnel_name"
                                                onChange={(e) => this.setValue(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                            <label className="mb-0">Keep Alive
                                                <div
                                                    className="tooltip-c"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="Keep alive status of tunnel"
                                                >
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.deviceData.settings.tunnel_keep_alive_status === true}
                                                    name="tunnel_keep_alive_status"
                                                    onChange={(e) => this.setValue(e)}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        (this.state.deviceData.settings.tunnel_keep_alive_status === true) &&
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Keep Alive Interval
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Keep alive interval of tunnel"
                                                    >
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <div>
                                                    <input type="number"
                                                        value={this.state.deviceData.settings.tunnel_keep_alive_interval}
                                                        className="form-control"
                                                        min="1"
                                                        max="120"
                                                        name="tunnel_keep_alive_interval"
                                                        onChange={(e) => this.setValue(e)}
                                                    /></div>
                                                <span className="units">Seconds</span>
                                                <div className="error-txt" id="tunnel_keep_alive_interval">Keep alive interval can be 1 to 120</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="s-footer">
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeEditTunnel()}
                                >Cancel</button>
                                <button
                                    className="btn btn-primary"
                                    disabled={this.checkError()}
                                    onClick={() => this.showPopup()}
                                >Update</button>
                            </div>
                            {(this.state.show) &&
                                <ErrorPopup
                                    title={this.state.title}
                                    message={this.state.message}
                                    error={this.state.error}
                                    reset={() => this.resetPopup()}
                                />
                            }
                        </div>
                    </div>
                    {this.state.showRefreshModal && <ErrorHandlePopup />}
                    {
                        this.state.isAxiosError &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.setState({ isAxiosError: false })}
                        />
                    }
                    {
                        this.state.showMessage &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            reset={() => this.updateTunnel()}
                        />
                    }
                </div>
            )
        else
            return (<div className="white-bg no-data">No Data</div>)
    }
};

const mapStateToProps = (state) => {
    return ({
        open: state.displayAddTunnel.openEditTunnel,
        tunnel_id: state.displayAddTunnel.tunnel_id
    });
};

export default connect(mapStateToProps)(EditTunnel);