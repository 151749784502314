/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as Action from "../../redux/actionTypes";
import SideMenu from "../../layOuts/SideMenu";
import Header from "../../layOuts/Header";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from "../../layOuts/Loader";
import { ErrorPopup } from "../../layOuts/errorPopup";
import AddNewDevice from "./AddNewDevice";
import $ from "jquery";
import moment from "moment";
import * as Constant from "../../utils/constants";
import ErrorHandlePopup from "../ErrorHandlePopup";
import Select from 'react-select';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import './DataTableDemo.css';

class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [{
        label: 'Config File',
        value: 'Config File'
      }],
      showRefreshModal: false,
      multipleSelect: "",
      toggledClearRows: false,
      showLoader: false,
      selectedTemplate: 1,
      title: "",
      selectedOption: [],
      message: "",
      show: false,
      error: false,
      delete: false,
      approve: false,
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      first: 1,
      last: 10,
      rows: 10,
      next: true,
      prev: true,
      firstDefault: 0,
      url: "",
      url2: "",
      modelList: [],
      deviceName: [],
      deviceId: "",
      deviceModel: "",
      unique: [],
      device_model: "",
      sortOn: false,
      deviceData: [],
      favDevices: false,
      favKey: false,
      sortOrder: 1,
      flag: 0,
      sortField: '',
      duplicate: false,
      devices_having: [],
      devices_to_approve: [],
      isAxiosError: false,
      searchValue: "",
      searchClicked: false,
      columns: [
        {
          field: 'device_name', header: 'Device Name', value: 'Device Name', body: this.deviceNameTemplate,
          frozen: false,
          alignFrozen: 'left',
          sortable: true,
          style: { width: '170px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_status', header: 'Status', value: 'Status', body: this.statusTemplate, frozen: false,
          sortable: true,
          style: { width: '150px', flexGrow: 1, minWidth: '110px' }
        },
        {
          field: 'device_serial', header: 'Serial No.', value: 'Serial No.',
          body: this.deviceSerialTemplate,
          frozen: false,
          sortable: true,
          style: { width: '170px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_mac', header: 'MAC Address', frozen: false, value: 'MAC Address',
          sortable: false,
          body: this.deviceMacTemplate,
          style: { width: '200px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_model', header: 'Model No.', frozen: false, value: 'Model No.',
          sortable: false,
          body: this.deviceModelTemplate,
          style: { width: '150px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_imei', header: 'Device IMEI', frozen: false, value: 'Device IMEI',
          sortable: false,
          body: this.deviceImeiTemplate,
          style: { width: '200px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'created_date', header: 'Registered on', frozen: false, value: 'Registered on',
          sortable: true,
          body: this.registeredTemplate,
          style: { width: '220px', flexGrow: 1, minWidth: '180px' }
        },
        {
          field: 'Action', header: '', body: this.actionTemplate, value: 'Action',
          style: { flexGrow: 1, flexBasis: '120px' },
          sortable: false,
          alignFrozen: 'right', frozen: true
        }

      ],
    };
  }

  returnClassStatus = (device_status) => {
    switch (device_status) {
      case 0:
        return "status offline";
      case 1:
        return "status online";
      case 2:
        return "status added";
      case 3:
        return "status available";
      case 4:
        return "status approve";
      default:
        return "status offline";
    }
  };

  returnStatus = (device_status) => {
    switch (device_status) {
      case 0:
        return "Offline";
      case 1:
        return "Online";
      case 2:
        return "Added";
      case 3:
        return "Available";
      case 4:
        return "Auto Approved";
      default:
        return "Offline";
    }
  };

  componentDidMount() {
    this.getDeviceList();
    let newArray = this.state.columns;
    const localValue = JSON.parse(localStorage.getItem(Constant.DEVICE_LIST));

    if (!!localValue) {
      newArray = [];
      localValue?.map((item) => {
        const column = this.valueReturn(item);
        if (!!column) newArray.push(column);
      });
    }
    this.setState({ selectedOption: newArray });
  }

  getFavDevices = () => {
    this.setState({
      favDevices: !this.state.favDevices,
      pageNo: 1
    }, () => this.getDeviceList())
  }

  sortAlphaNum = (a, b) => {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    var aA = a.replace(reA, "");
    var bA = b.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.replace(reN, ""), 10);
      var bN = parseInt(b.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  };

  getTime = (time) => {
    let date = moment.utc(time);
    date = date.local();
    return date.format('DD MMMM YYYY hh:mm:ss A');
  }

  newDeviceSort = (columnSort) => {
    if (this.state.flag < 2) {
      this.setState({
        sortField: columnSort.sortField,
        sortOrder: columnSort.sortOrder,
        order: columnSort.sortOrder === 1 ? 'ascending' : 'descending',
        sortOn: true,
        flag: this.state.flag + 1,
      }, () => this.getDeviceList("sort")
      )
    } else {
      this.setState({
        sortField: null,
        order: 'descending',
        sortOn: true,
        flag: 0,
      }, () => this.getDeviceList("sort")
      )
    }
  }

  //Get DeviceType List
  getDeviceList = (sort) => {
    if (this.state.deviceModel !== "") {
      this.listFromModel()
    }
    else {
      this.setState({
        showLoader: true,
      });
      let sorting_parameter = sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date'
      let sorting_type = sort === 'sort' ? this.state.order : 'descending'
      let pinned = this.state.favDevices === true ? 1 : 0;
      ApiCall(
        urlMapping.getUnconfiguredDevicelist(
          sorting_parameter, sorting_type, pinned,
          this.state.pageNo,
          this.state.pageSize
        ),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.results) {
            const data = response?.results;
            const totalCount = response?.count;
            const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
            const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
            if (data) {
              if (data.length === 0 && pinned === 1 && this.state.favKey) {
                this.setState(
                  {
                    favDevices: !this.state.favDevices,
                    favKey: !this.state.favKey
                  }, () => this.getDeviceList())
              }
              else {
                if (data?.length === 0 && pinned === 1) {
                  this.setState(
                    {
                      title: "Set as Favorite",
                      message: "No devices set as favorite to show. Select device and set as favorite from : option",
                      show: true,
                      error: false,
                      delete: false,
                      toggledClearRows: !this.state.toggledClearRows,
                      favDevices: !this.state.favDevices,
                    },
                    () => $("#errorMessage").modal("show")
                  );
                } else {
                  this.setState({
                    next: response.next ? false : true,
                    prev: response.previous ? false : true,
                    first: first,
                    last: last,
                    deviceData: data,
                    totalCount,
                    //LONG-2014 MA- After Selecting multiple device in on boarding page and click refresh button , delete button was not disable
                    toggledClearRows: !this.state.toggledClearRows,
                    //long-2171
                    multipleSelect: []
                  });

                  this.setState({
                    unique: [...new Set(data.map((obj) => obj.device_model))],
                  });
                }
              }
            }
          }
          else {
            if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
              this.setState({
                showRefreshModal: true,
                showLoader: false
              })
            } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
              this.setState({
                title: 'Tenant Error',
                message: response.response.data.message,
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
            else {
              this.setState({
                showLoader: false
              })
            }
          }
        }
      );
    }
  };

  setAsFavorite = (pinned) => {
    let data;
    this.setState({ showLoader: true });
    const device_ids = [];
    this.state.multipleSelect.forEach((element) => {
      device_ids.push(element.device_id);
    });
    data = {
      device_ids,
      pinning: pinned === true ? 1 : 0
    };
    ApiCall(
      urlMapping.setAsFavorite(
        data,
      ),
      (response) => {
        if (response?.success) {
          this.setState(
            {
              title: pinned === false ? "Remove from Favorite" : "Set as Favorite",
              message: response?.message,
              show: true,
              error: false,
              showLoader: false,
              favKey: true,
            },
            () => {
              $("#errorMessage").modal("show");
              this.getDeviceList();
            }
          );
        } else {
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({ showLoader: false, showRefreshModal: true });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Pin Device",
                message: response?.message,
                show: true,
                error: true,
                showLoader: false
              },
              () => {
                $("#errorMessage").modal("show");
              }
            );
          }
        }
      })
  }

  configDevice = () => {
    let data = { device_mac: [] };
    const mac = [];
    this.state.multipleSelect.forEach((element) => {
      mac.push(element.device_mac);
    });
    data.device_mac = mac;
    this.setState({
      showLoader: true,
    });
    ApiCall(urlMapping.configDevice(data), (response) => {
      if (response?.success === true) {
        this.setState(
          {
            showLoader: false,
            title: "Device Configuration",
            message: response?.message,
            show: true,
            error: false,
            delete: false,
            toggledClearRows: !this.state.toggledClearRows,
          },
          () => $("#errorMessage").modal("show")
        );
        this.getDeviceList();
        this.setState({
          show: false,
          error: false,
          delete: false,
        });
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          })
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState(
            {
              showLoader: false,
              title: "Device Configuration",
              message: response?.message,
              show: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
  };

  confirmDelete = () => {
    this.setState(
      {
        title:
          this.state.multipleSelect.length > 1
            ? "Delete Devices"
            : "Delete Device",
        message:
          this.state.multipleSelect.length > 1
            ? `This operation will delete the devices from 5G SD EDGE Manager and onboard the devices again when ZTP got triggered from the devices either manually or through a scheduler. Click OK to proceed or click Cancel to cancel the operation?${"\n"}Note: Do Factory Reset from the Devices Local UI to stop ZTP trigger through the scheduler`
            : `This operation will delete the device from 5G SD EDGE Manager and onboard the device again when ZTP got triggered from the device either manually or through a scheduler. Click OK to proceed or click Cancel to cancel the operation?${"\n"}Note: Do Factory Reset from the Device Local UI to stop ZTP trigger through the scheduler`,
        show: true,
        error: true,
        delete: true,
      },
      () => {
        $("#errorMessage").modal("show");
      }
    );
  };

  // confirmApprove = () => {
  //   this.setState(
  //     {
  //       title:
  //         this.state.multipleSelect.length > 1
  //           ? "Approve Devices"
  //           : "Approve Device",
  //       message:
  //         this.state.multipleSelect.length > 1
  //           ? "Are you sure you want to approve device(s)?"
  //           : "Are you sure you want to approve device?",
  //       show: true,
  //       error: false,
  //       delete: false,
  //       approve: true,
  //       duplicate: false
  //     },
  //     () => {
  //       $("#errorMessage").modal("show");
  //     }
  //   );
  // };

  deleteDevices = async (value) => {
    let data = { device_ids: [] };
    if (value === "duplicate") {
      data.device_ids = this.state.devices_to_approve;
    }
    else {
      // const haveTask = await this.checkDeviceTask();
      const ids = [];
      this.state.multipleSelect.forEach((element) => {
        ids.push(element.device_id);
      });
      data.device_ids = ids;
      this.setState({
        showLoader: true,
      });
    }
    // if (haveTask.length === 0) {
    ApiCall(urlMapping.deleteDevice(data), (response) => {
      this.setState({
        showLoader: false
      });
      if (response?.success === true) {
        let newPage = this.state.pageNo;
        if (this.state.pageNo > 1) {
          newPage = this.state.totalCount -
            (this.state.pageSize * (this.state.pageNo - 1)) - this.state.multipleSelect?.length === 0 ?
            this.state.pageNo - 1 : this.state.pageNo;
        }
        $("#errorMessage").modal("hide");
        this.setState(
          {
            title: "Delete Devices",
            message: response?.message + ' to operation',
            show: true,
            error: false,
            delete: false,
            toggledClearRows: !this.state.toggledClearRows,
            pageNo: newPage,
          },
          () => $("#errorMessage").modal("show")
        );
        this.getDeviceList();
      } else {
        $("#errorMessage").modal("hide");
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          })
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState(
            {
              title: "Delete Devices",
              message: response?.message,
              show: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
    // } 
    // else {
    //   this.setState({
    //     showLoader: false,
    //   });
    // }
  };

  multipleSelect(rows) {
    if (!rows.selectedRows.length) this.setState({ multipleSelect: "" });
    else {
      this.setState({ multipleSelect: rows.selectedRows });
    }
  }

  setTemplate = (e) => {
    this.setState({ selectedTemplate: e.target.value });
  };

  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
        delete: false,
        approve: false,
        duplicate: false,
        devices_having: [],
        devices_to_approve: [],
        multipleSelect: "",
      },
      () => {
        $("#errorMessage").modal("hide");
      }
    );
  };

  // checkDeviceTask = async () => {
  //   let deviceMac = [];

  //   this.state.multipleSelect.map(item => (
  //     deviceMac.push(item.device_mac)
  //   ));

  //   let data = { device_mac: deviceMac, operation_type: ['Firmware Upgrade'] }
  //   this.setState({
  //     showLoader: true
  //   });

  //   const trueArr = [];
  //   await ApiCall(urlMapping.checkDeviceTask(data), response => {
  //     this.setState({
  //       showLoader: false,
  //       show: false
  //     });
  //     $("#errorMessage").modal("hide");

  //     if (response.success === true) {
  //       const data = response.data;

  //       Object.keys(data).forEach(item => {
  //         if (data[item].status === true) {
  //           trueArr.push(data[item]);
  //         }
  //       });

  //       if (trueArr.length > 0) {
  //         this.setState({
  //           title: 'Device Task',
  //           message: trueArr[0].message,
  //           show: true,
  //           error: true,
  //           delete: false,
  //           toggledClearRows: !this.state.toggledClearRows,
  //         }, () => $("#errorMessage").modal("show"));
  //       }
  //       this.setState({
  //         show: false,
  //         error: false,
  //         delete: false
  //       })

  //     } else {
  //       $("#errorMessage").modal("hide");
  //       if (response.response.status === 401) {
  //         this.setState({
  //           showRefreshModal: true
  //         })
  //       } else {
  //         this.setState({
  //           title: 'Device Task',
  //           message: response.message,
  //           show: true,
  //           error: true,
  //           delete: false
  //         }, () => $("#errorMessage").modal("show"));
  //       }
  //     }
  //   })
  //   return trueArr;
  // }

  approveDevice = async (value) => {
    let data = { device_ids: [] };
    // const haveTask = await this.checkDeviceTask();
    const id = [];
    this.state.multipleSelect.forEach((element) => {
      id.push(element.device_id);
    });
    data.device_ids = id;
    // page freeze issue fixed
    this.setState({
      showLoader: true,
      show: false
    });
    // if (haveTask.length === 0) {
    ApiCall(urlMapping.approveDevice(data), (response) => {
      if (response?.success) {
        const newPage =
          this.state.totalCount -
            this.state.pageSize * (this.state.pageNo - 1) -
            this.state.multipleSelect.length ===
            0 && this.state.pageNo > 1
            ? this.state.pageNo - 1
            : this.state.pageNo;
        $("#errorMessage").modal("hide");
        let message = "";
        // Fixed page break issue if devices were approved without activating license
        if (response?.failed_devices && response?.failed_devices?.length > 0) {
          response?.failed_devices?.map((msg) => {
            message = message === '' ? message + msg : message + `\n ${msg}`
          })
        } else {
          message = response?.message;
        }
        this.setState(
          {
            showLoader: false,
            title: "Approve Devices",
            message: message,
            show: true,
            error: false,
            delete: false,
            approve: false,
            pageNo: newPage,
            multipleSelect: [],
            toggledClearRows: !this.state.toggledClearRows
          },
          () => {
            this.getDeviceList();
            $("#errorMessage").modal("show");
          }
        );
      }
      else if (response?.data?.devices_having) {
        this.setState(
          {
            title: "Approve Devices",
            message: response?.message,
            duplicate: true,
            show: true,
            error: true,
            delete: false,
            approve: false,
            showLoader: false,
            devices_having: response?.data?.devices_having,
            devices_to_approve: response?.data?.devices_to_approve
          },
          () => $("#errorMessage").modal("show")
        )
      }
      else {
        $("#errorMessage").modal("hide");
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          })
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false,
            title: "Approve Devices",
            message: response?.message,
            show: true,
            error: true,
            delete: false,
            approve: false,
          }, () => $("#errorMessage").modal("show"));
        }
      }
    });
    // } else {
    //   this.setState({
    //     showLoader: false,
    //     show: false
    //   });
    // }
  };

  handleAddDevice = () => {
    this.props.dispatch({
      type: Action.OPEN_ADD_DEVICE,
    });
  };

  handleChange = (e) => {
    let array = [];
    this.state.selectedOption.map((option) => (
      array.push(option.value)
    ));
    const result = e.target.value;
    if (result === "selectAll") {
      array = [];
      if (e.target.checked) {
        this.state.columns.map((column) => (
          array.push(column.value)
        ));
      } else {
        array = ["Action"];
      }
    } else {
      if (e.target.checked) array.push(result);
      else array.splice(array.indexOf(result), 1);
    }
    if (array.includes("Action")) {
      array.splice(array.indexOf("Action"), 1);
      array.push("Action");
    }
    const selectedVlaues = [];
    array.map((item) => {
      const column = this.valueReturn(item);
      selectedVlaues.push(column);
    });
    localStorage.setItem(Constant.DEVICE_LIST, JSON.stringify(array));
    this.setState({ selectedOption: selectedVlaues });
  };

  valueReturn = (item) => {
    return this.state.columns.filter(
      (column) => column.value === item
    )[0];
  };

  getModelList = () => {
    this.setState((prevState) => ({ showFilter: !prevState.showFilter }));
    ApiCall(urlMapping.getNewModelList(), (response) => {
      if (response?.success) {
        this.setState({ modelList: response?.data });
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({ showRefreshModal: true })
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };

  getDetailFromDevice = (e) => {
    if (e && e.label) {
      this.setState({
        deviceId: e.value
      })
    }
    else {
      this.setState({
        deviceId: '',
      })
    }
  };

  getOptions = () => {
    let options = [];
    options = this.state.deviceName.map((device) => {
      return { label: device.device_name, value: device.device_name }
    })
    return options;
  }

  filterOptions = (option, inputValue) => {
    const { label } = option;
    const otherKey = this.getOptions().filter(
      (opt) => opt.label.toLowerCase() === label.toLowerCase() && opt.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return label.toLowerCase().includes(inputValue.toLowerCase()) || otherKey.length > 0;
  }

  getModelDeviceList = (e) => {
    if (e.target.value === "Select") {
      this.setState({ deviceModel: "", deviceName: [] });
    } else {
      this.setState({ deviceModel: e.target.value });
      let data = { device_model: e.target.value };
      ApiCall(urlMapping.getNewDeviceList(data), (response) => {
        if (response?.success) {
          this.setState({ deviceName: response?.data });
        } else {
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({
              showRefreshModal: true
            });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
        }
      });
    }
  };

  listFromModel = () => {
    let data;
    this.setState({ showLoader: true })
    // LONG-1885-Filter functionality is not working
    if (this.state.deviceId) {
      data = { device_model: this.state.deviceModel, device_name: this.state.deviceId };
    }
    else {
      data = { device_model: this.state.deviceModel }
    }
    data.pinned = this.state.favDevices === true ? 1 : 0
    ApiCall(urlMapping.getNewDeviceListByModel(data, this.state.pageNo, this.state.pageSize), (response) => {
      //LONG-3811-EM - Pagination issue in onboarding page while doing filter functionality
      if (response?.results) {
        //LONG-3497-EM - Issues in Filter option
        let filterData
        // if (this.state.deviceId !== "") {
        //   filterData = response?.results?.filter(item => item.hostname === this.state.deviceId)
        // }
        // else {
        filterData = response?.results
        // }
        const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
        const last = response?.next ? this.state.pageSize * this.state.pageNo : filterData?.length;
        this.setState({
          deviceData: filterData,
          next: response?.next ? false : true,
          prev: response?.previous ? false : true,
          first: first,
          last: last,
          showLoader: false,
          showFilter: false,
          totalCount: response.count,
          // showIcon: true
        });
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({ showLoader: false, showRefreshModal: true });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState({ showLoader: false, showIcon: true })
        }
      }
    });
    // this.setState({ deviceModel: "", deviceId: "" })
  };

  clearDropdown = (type) => {
    if (type === "Model") {
      this.setState({ deviceModel: "", deviceName: [], deviceId: "" });
    } else {
      this.setState({ deviceId: "" });
    }
  };

  resetValue = () => {
    this.setState({
      deviceModel: "",
      deviceId: "",
      deviceName: [],
      showFilter: false,
      modelList: [],
      pageNo: 1,
      showIcon: false
    }, () =>
      this.getDeviceList(),
      //LONG-3497-EM - Issues in Filter option
      // this.getModelList()
    );
  };

  hideDropdown = (e) => {
    var popup = $("#parentDiv");
    if (
      !$("#open").is(e.target) &&
      !popup.is(e.target) &&
      popup.has(e.target).length === 0
    ) {
      this.setState({
        showFilter: false,
        deviceModel: this.state.showIcon ? this.state.deviceModel : '',
        modelList: []
      })
    }
  };

  checkAddedDevice = () => {
    let result = false;
    if (
      this.state.multipleSelect.filter(
        (item) => item.device_status === 2 || item.device_status === 4
      ).length > 0
    )
      result = true;
    return result;
  };

  deviceNameTemplate = (row) => {
    return (
      <span title={row.device_name} className="fav-icon">
        {row.is_pinned &&
          <img src="/images/svg-icons/star-filled-yellow.svg" alt="" />}
        {row.is_duplicate &&
          <img
            src="/images/svg-icons/failed-temp-alert.svg"
            style={{ width: "13px" }}
            title="This device has duplicate MAC or IMEI"
            alt="" />}
        {row.device_name !== "" &&
          row.device_name !== "null" &&
          row.device_name !== null
          ? row.device_name
          : "-"}
      </span>
    )
  }

  actionTemplate = (row) => {
    return (
      <div className="da-box dropleft">
        <img className="a-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="/images/svg-icons/ellipsis.svg" alt="" />
        <div className="drop-box dropdown-menu">
          <ul>
            <li
              className={
                (row?.device_model !== "V2000" ||
                  (this.props?.userRole === "Viewer_Group" ||
                    this.props?.userRole === "Operator_Group"))
                  ? "disabled"
                  : ""}
              onClick={() =>
                (row?.device_model !== "V2000" ||
                  (this.props?.userRole === "Viewer_Group" ||
                    this.props?.userRole === "Operator_Group"))
                  ? undefined
                  : this.setState({ multipleSelect: [row] }, () => this.confirmDelete())
              }
            >
              <img
                className="delete"
                src="/images/svg-icons/delete.svg"
                alt=""
              />{" "}
              Delete
            </li>
            <li
              onClick={() => this.setState({ multipleSelect: [row] }, () => this.setAsFavorite(row.is_pinned ? false : true))}>
              {row.is_pinned ? <img src="/images/svg-icons/star.svg" alt="" /> :
                <img src="/images/svg-icons/star-filled.svg" alt="" />}
              {" "}
              {row.is_pinned ? "Remove from Favorite" : "Set as Favorite"}
            </li>
          </ul>
        </div>
      </div>
    )
  }

  statusTemplate = (row) => {
    return (
      <div id={`col${row.device_id}`}>
        <span id={`icon${row.device_id}`}> <i className={this.returnClassStatus(row.device_status)}></i>
          {this.returnStatus(row.device_status)}</span>
      </div>
    )
  }

  registeredTemplate = (row) => {
    return (
      <span className="date-time" title={this.getTime(row.created_date)} >
        {this.getTime(row.created_date)}
      </span>
    )
  }

  deviceSerialTemplate = (row) => {
    return (
      <span title={row.device_serial}>
        {(row.device_serial !== "" && row.device_serial !== "null" && row.device_serial !== null) ? row.device_serial : '-'}</span>
    )
  }

  deviceModelTemplate = (row) => {
    return (
      <span title={row.device_model}>
        {(row.device_model !== "" && row.device_model !== "null" && row.device_model !== null) ? row.device_model : '-'}</span>
    )
  }

  deviceImeiTemplate = (row) => {
    return (
      <span title={row.device_imei}>
        {(row.device_imei !== "" && row.device_imei !== "null" && row.device_imei !== null) ? row.device_imei : '-'}</span>
    )
  }

  deviceMacTemplate = (row) => {
    return (
      <span title={row.device_mac.toUpperCase()}>
        {row.device_mac.toUpperCase()}</span>
    )
  }

  approveDuplicate = () => {
    this.setState(
      {
        title: "",
        message: "",
        show: false,
        error: false,
        duplicate: false,
        showLoader: true,
      }
    )
    // let devices_having = this.state.deviceDuplicateMac
    let data = {
      device_ids: {
        devices_having: this.state.devices_having,
        devices_to_approve: this.state.devices_to_approve,
      }
    };

    ApiCall(urlMapping.clearDevice(data), (response) => {
      if (response?.success) {
        this.setState({
          showLoader: false,
        })
        this.approveDevice()
      }
      else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          })
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState(
            {
              showLoader: false,
              title: "Clear Device",
              message: response?.message,
              show: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    })
  }

  onCancel = () => {
    this.setState(
      {
        title: "",
        message: "",
        show: false,
        error: false,
        duplicate: false,
      }, () => this.deleteDevices("duplicate")
    );
  }

  rowClassName = (row) => {
    return {
      'rowRed': row.is_duplicate,
    };
  }

  onSelectRow = (e) => {
    if (e.value.length === 0) {
      this.setState({
        multipleSelect: ''
      })
    } else {
      this.setState({
        multipleSelect: e.value
      })
    }
  }

  setPerPage = (e) => {
    this.setState({
      pageNo: 1,
      pageSize: e.value
    }, () => {
      this.state.sortOn === true && !this.state.searchClicked
        ? this.getDeviceList('sort')
        : (this.state.sortOn === true && this.state.searchClicked
          ? this.handleSearch('sort')
          : (this.state.searchClicked
            ? this.handleSearch()
            : (this.state.showIcon
              ? this.listFromModel()
              : this.getDeviceList()
            )
          )
        )
    });
  };

  nextPageChangeHandle = () => {
    this.setState({
      pageNo: this.state.pageNo + 1
    }, () => {
      this.state.sortOn === true && !this.state.searchClicked
        ? this.getDeviceList('sort')
        : (this.state.sortOn === true && this.state.searchClicked
          ? this.handleSearch('sort')
          : (this.state.searchClicked
            ? this.handleSearch()
            : (this.state.showIcon
              ? this.listFromModel()
              : this.getDeviceList()
            )
          )
        )
    });
  };

  prevPageChangeHandle = () => {
    this.setState({
      pageNo: this.state.pageNo - 1
    }, () => {
      this.state.sortOn === true && !this.state.searchClicked
        ? this.getDeviceList('sort')
        : (this.state.sortOn === true && this.state.searchClicked
          ? this.handleSearch('sort')
          : (this.state.searchClicked
            ? this.handleSearch()
            : (this.state.showIcon
              ? this.listFromModel()
              : this.getDeviceList()
            )
          )
        )
    });
  }

  firstPageChangeHandle = () => {
    this.setState({
      pageNo: 1
    }, () => {
      this.state.sortOn === true && !this.state.searchClicked
        ? this.getDeviceList('sort')
        : (this.state.sortOn === true && this.state.searchClicked
          ? this.handleSearch('sort')
          : (this.state.searchClicked
            ? this.handleSearch()
            : (this.state.showIcon
              ? this.listFromModel()
              : this.getDeviceList()
            )
          )
        )
    });
  }

  lastPageChangeHandle = () => {
    this.setState({
      pageNo: Math.ceil(this.state.totalCount / this.state.pageSize)
    }, () => {
      this.state.sortOn === true && !this.state.searchClicked
        ? this.getDeviceList('sort')
        : (this.state.sortOn === true && this.state.searchClicked
          ? this.handleSearch('sort')
          : (this.state.searchClicked
            ? this.handleSearch()
            : (this.state.showIcon
              ? this.listFromModel()
              : this.getDeviceList()
            )
          )
        )
    });
  }

  handleOnChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
    if (e.target.value === "") {
      this.state.sortOn === true ? this.getDeviceList('sort') : this.getDeviceList();
    }
  }

  handleKeypress = (e) => {
    if (e.key === 'Enter') {
      this.setState(
        {
          pageSize: 10,
          searchClicked: true,
          showFilter: false,
          deviceModel: "",
          deviceId: "",
          deviceName: [],
          modelList: [],
          pageNo: 1,
          showIcon: false
        },
        () => { this.state.sortOn === true ? this.handleSearch('sort') : this.handleSearch() });
    }
  }

  handleSearch = (sort) => {
    this.setState({
      showLoader: true
    });
    let data = {
      device_data: this.state.searchValue,
      sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
      sorting_type: sort === 'sort' ? this.state.order : 'descending',
      pinned: this.state.favDevices === true ? 1 : 0,
      approved: 0
    };
    ApiCall(
      urlMapping.searchDeviceList(
        this.state.pageNo,
        this.state.pageSize,
        data
      ),
      (response) => {
        if (response) {
          if (response?.results) {
            const first =
              this.state.pageSize * (this.state.pageNo - 1) + 1;
            const last = response.next
              ? this.state.pageSize * this.state.pageNo
              : response?.count;
            this.setState(
              {
                showLoader: false,
                next: response?.next ? false : true,
                prev: response?.previous ? false : true,
                first: first,
                last: last,
                deviceData: response?.results.filter(
                  (item) => item.device_delete_status === 0
                ),
                totalCount: response?.count,
              }
            );
          } else {
            if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
              this.setState({ showRefreshModal: true });
            } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
              this.setState({
                title: 'Tenant Error',
                message: response.response.data.message,
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
          }
        }
      }
    )
  }

  clearSearch = () => {
    this.setState(
      {
        searchValue: "",
        pageNo: 1,
        searchClicked: false
      },
      () => { this.state.sortOn === true ? this.getDeviceList('sort') : this.getDeviceList() });
  };

  generateActivation = () => {
    this.setState({ showLoader: true });
    ApiCall(urlMapping.addDevicePTK(), (response) => {
      if (response) {
        this.setState({ showLoader: false });
        const csvLines = Object.values(response).join('').split('\n');
        if (csvLines[csvLines.length - 1].includes('[object Object]')) {
          csvLines.pop();
        }
        const csvData = csvLines.join('\n');
        const blobData = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        const url = window.URL.createObjectURL(blobData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({ showRefreshModal: true, showLoader: false });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => {
            $("#errorMessage").modal("show");
          });
        } else if (
          response?.isAxiosError &&
          response?.success === undefined &&
          response?.response?.data?.success === undefined
        ) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => {
            $("#errorMessage").modal("show");
          });
        } else {
          this.setState({ showLoader: false });
        }
      }
    });
  };

  render() {
    const { role } = this.props;
    let colArray = this.state.selectedOption.length === 0 ? this.state.columns : this.state.selectedOption;
    const dynamicColumns = colArray.map((col, i) => {
      return <Column key={col.field} field={col.field} resizable={col.resizable}
        sortable={col.sortable}
        header={col.header} body={col.body} style={col.style} alignFrozen={col.alignFrozen} frozen={col.frozen} />;
    });

    const template2 = {
      layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink',
      'RowsPerPageDropdown': (options) => {
        const dropdownOptions = [
          { label: 10, value: 10 },
          { label: 20, value: 20 },
          { label: 30, value: 30 },
          { label: 40, value: 40 },
          { label: 50, value: 50 }
        ];

        return (
          <React.Fragment>
            <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Rows per page: </span>
            <Dropdown value={this.state.pageSize} options={dropdownOptions} onChange={(e) => this.setPerPage(e)} />
          </React.Fragment>
        );
      },
      'CurrentPageReport': (options) => {
        return (
          <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
            {this.state.first} - {this.state.last} of {this.state.totalCount}
          </span>
        )
      },
      'FirstPageLink': () => {
        return (
          <button type="button"
            className=" p-link1"
            onClick={() => this.firstPageChangeHandle()}
            disabled={this.state.prev}
          >
            <img
              className={this.state.prev ? "p-link3" : "p-link2"}
              src={this.state.prev ? "images/svg-icons/arrow-pre-disabled.svg" : "/images/svg-icons/arrow-pre.svg"}
              alt=""
            />
          </button>
        )
      },
      'PrevPageLink': () => {
        return (
          <button type="button"
            className=" p-link1"
            onClick={() => this.prevPageChangeHandle()}
            disabled={this.state.prev}
          >
            <i className="pi pi-chevron-left"></i>
          </button>
        )
      },
      'NextPageLink': () => {
        return (
          <button
            type="button"
            className="p-link1"
            onClick={() => this.nextPageChangeHandle()}
            disabled={this.state.next}
          >
            <i className="pi pi-chevron-right"></i>
          </button>
        )
      },
      'LastPageLink': () => {
        return (
          <button type="button"
            onClick={() => this.lastPageChangeHandle()}
            className=" p-link1"
            disabled={this.state.next}
          >
            <img
              className={this.state.next ? "p-link3" : "p-link2"}
              src={this.state.next ? "images/svg-icons/arrow-next-disabled.svg" : "/images/svg-icons/arrow-next.svg"}
              alt=""
            />
          </button>
        )
      },
    };

    return (
      <div onMouseDown={(e) => this.hideDropdown(e)}>
        <SideMenu />
        {this.state.showLoader && <Loader />}
        <section
          id="rhsMain"
          className={this.props.open ? "rhs-main" : "rhs-main expand-w"}
        >
          <Header />
          <div className="white-bg  page-height">
            <div className="ta-box">
              <h1 className="page-title">
                Onboarding Devices{" "}
                {/* <img
                  src="/images/svg-icons/add.svg"
                  className={
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                      ? "add-btn disabled"
                      : "add-btn"
                  }
                  alt=""
                  onClick={() =>
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                      ? undefined
                      : this.handleAddDevice()
                  }
                /> */}
                <span className="fav-list" onClick={this.getFavDevices}>
                  {this.state.favDevices ? <img src="/images/svg-icons/star-filled-yellow.svg" alt="" /> :
                    <img src="/images/svg-icons/star.svg" alt="" />}
                  Favorite
                </span>
              </h1>
              <div className="action-btn d-flex">
                <div className="search-box">
                  <input
                    type="text"
                    className="show"
                    placeholder="Enter device name or MAC address"
                    onChange={(e) => this.handleOnChange(e)}
                    value={this.state.searchValue}
                    onKeyPress={(e) => this.handleKeypress(e)} />
                  {this.state.searchValue && (
                    <>
                      <img
                        className="clear-icon show"
                        src="/images/svg-icons/cancel.svg"
                        onClick={() => this.clearSearch()}
                        alt=""
                      />
                    </>
                  )}
                  <button
                    className="search-btn"
                    disabled={this.state.searchValue === ""}
                    onClick={() => {
                      this.setState(
                        {
                          pageSize: 10,
                          searchClicked: true,
                          showFilter: false,
                          deviceModel: "",
                          deviceId: "",
                          deviceName: [],
                          modelList: [],
                          pageNo: 1,
                          showIcon: false
                        },
                        () => { this.state.sortOn === true ? this.handleSearch('sort') : this.handleSearch() });
                    }
                    }
                  >
                    <img src="/images/svg-icons/search.svg" alt="" />
                  </button>
                </div>
                <button
                  onClick={() =>
                    this.setState({
                      searchValue: "",
                      pageNo: 1,
                      searchClicked: false
                    }, () => {
                      this.state.showIcon
                        ? this.listFromModel()
                        : (this.state.sortOn === true
                          ? this.getDeviceList('sort')
                          : this.getDeviceList())
                    })
                  }
                  title="Refresh">
                  <img src="/images/svg-icons/refreshing.svg" alt="" />
                  {/* Refresh */}
                </button>
                <button
                  className="button-lst"
                  disabled={this.state.multipleSelect === "" || this.state.favDevices
                    || this.state.multipleSelect.filter(item => item.is_pinned === false).length === 0}
                  onClick={() => this.setAsFavorite(true)} title="Set As Favorite"
                >
                  <img src="/images/svg-icons/star-filled.svg" alt="" />
                </button>
                <button
                  className="button-lst"
                  disabled={this.state.multipleSelect === "" || this.state.multipleSelect.filter(item => item.is_pinned === true).length === 0}
                  onClick={() => this.setAsFavorite(false)} title="Remove from Favorite"
                >
                  <img src="/images/svg-icons/star.svg" alt="" />
                </button>
                {/* <button
                  className="button-lst"
                  disabled={
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group") ||
                    this.state.multipleSelect.length === 0 ||
                    (this.state.multipleSelect.length > 0 &&
                      this.checkAddedDevice())
                  }
                  onClick={() => this.confirmApprove()} title="Approve"
                >
                  <img src="/images/svg-icons/check.svg" alt="" />                  
                </button> */}
                {/* LONG-2511-Onboarding page delete button was enabled default without selecting device */}
                <button
                  className="button-lst"
                  disabled={
                    this.state.multipleSelect?.[0]?.device_model !== "V2000" ||
                    this.state.multipleSelect.length === 0 ||
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                  }
                  onClick={() => this.confirmDelete()} title="Delete"
                >
                  <img src="/images/svg-icons/delete.svg" alt="" />
                  {/* Delete */}
                </button>
                <button
                  className={(this.props?.userRole === "Viewer_Group" ||
                    this.props?.userRole === "Operator_Group")
                    ? "button-lst disabled"
                    : "button-lst"}
                  onClick={() =>
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                      ? undefined
                      : this.handleAddDevice()
                  }
                  title="Link Generation for V2000"
                >
                  <img src="/images/link.png" />
                </button>
                <button
                  className="dropdown-toggle filter-btn"
                  data-toggle="dropdown" title="Columns"
                >
                  <img src="/images/svg-icons/columns.svg" alt="" />
                  {/* Columns */}
                </button>
                <div
                  className="filter-section dropdown-menu dropdown-menu-right"
                  onChange={(e) => this.handleChange(e)}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="selectAll"
                      checked={
                        this.state.selectedOption.length ===
                        this.state.columns.length
                      }
                      style={{ cursor: "pointer" }}
                    />
                    <label className="form-check-label">Select All</label>
                  </div>
                  {this.state.columns.map((item, index) => {
                    if (item.value !== "Action")
                      return (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item.value}
                            checked={JSON.stringify(
                              this.state.selectedOption
                            ).includes(item.value)}
                            style={{ cursor: "pointer" }}
                          />
                          <label className="form-check-label">
                            {item.value}
                          </label>
                        </div>
                      );
                  })}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Action"
                      checked={true}
                      disabled
                      style={{ cursor: "not-allowed" }}
                    />
                    <label className="form-check-label">Action</label>
                  </div>
                </div>
                <div className="btn-group">
                  {/* disabled filter button if no data */}
                  <button
                    type="button"
                    className="dropdown-toggle  filter-btn"
                    onClick={(e) => this.getModelList()}
                    disabled={this.state.deviceData.length === 0} title="Filter"
                  >
                    {(this.state.showIcon) ?
                      <img src="/images/svg-icons/info-icon.svg" alt="" />
                      :
                      <img src="/images/svg-icons/filter.svg" alt="" />
                    }
                    {/* Filter */}
                  </button>
                  {this.state.showFilter && <div
                    className={
                      this.state.showFilter
                        ? "dropdown-menu show  dropdown-menu-right filter-section width360 overflow-visible"
                        : "dropdown-menu dropdown-menu-right filter-section width360 overflow-visible"
                    }
                    id="parentDiv"
                  >
                    <div className="form-group">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>Model</label>
                        <span onClick={() => this.clearDropdown("Model")}>
                          Clear
                        </span>
                      </div>
                      <select
                        className="form-control"
                        value={
                          this.state.deviceModel
                            ? this.state.deviceModel
                            : "Select"
                        }
                        onChange={(e) => this.getModelDeviceList(e)}
                      >
                        <option value="Select">Select Model</option>
                        {this.state.modelList.map((model) => {
                          if (model.device_model !== "")
                            return (
                              <option value={model.device_model}>
                                {model.device_model}
                              </option>
                            );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <div className="d-flex align-items-center justify-content-between">
                        <label>Device Name</label>
                        <span onClick={() => this.clearDropdown()}>Clear</span>
                      </div>
                      <Select options={this.getOptions()}
                        placeholder={"Select Device"}
                        filterOption={(o, i) => this.filterOptions(o, i)}
                        isClearable
                        isSearchable
                        className="filter-dropdown"
                        classNamePrefix="react-select-container"
                        value={
                          this.getOptions().filter(option =>
                            option.value === this.state.deviceId)
                        }
                        onChange={(e) => this.getDetailFromDevice(e)}
                      />
                    </div>

                    <div className="action-box">
                      <button
                        className="btn btn-light"
                        onClick={() => this.resetValue()}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.setState({
                            pageNo: 1,
                            pageSize: this.state.pageSize,
                            showIcon: true,
                            searchClicked: false,
                            searchValue: ""
                          }, () => this.listFromModel())
                        }}
                        // Filter Apply Button Disable issue fixed  
                        disabled={this.state.deviceModel === this.state.deviceId || this.state.deviceModel === ""}
                      >
                        Apply
                      </button>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
            <div className="card">
              <DataTable
                value={this.state.deviceData}
                rowClassName={this.rowClassName}
                onSort={(columnSort) => {
                  this.newDeviceSort(columnSort);
                }}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
                scrollable scrollHeight="400px"
                loading={this.state.loading}
                removableSort
                resizableColumns
                columnResizeMode="fit"
                showGridlines
                paginator
                paginatorTemplate={template2}
                first={this.state.firstDefault}
                rows={this.state.pageSize}
                paginatorClassName="p-jc-end"
                selectionMode="checkbox"
                selection={this.state.multipleSelect}
                onSelectionChange={e => this.onSelectRow(e)}
                dataKey="device_id" scrollDirection="both" className="p-mt-3">
                <Column selectionMode="multiple" style={{ flexGrow: 1, flexBasis: '80px' }} ></Column>
                {dynamicColumns}
              </DataTable>
            </div>
            {this.props.openAddDevice && (
              <AddNewDevice getDeviceList={() => this.getDeviceList()} />
            )}
          </div>
        </section>
        {this.state.show && (
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            approve={this.state.approve}
            duplicate={this.state.duplicate}
            factoryReset={this.state.factoryReset}
            onCancel={() => this.onCancel()}
            reset={() => this.resetPopup()}
            onSubmit={
              this.state.duplicate ?
                () => this.approveDuplicate() :
                this.state.delete
                  ? () => this.deleteDevices()
                  : this.state.approve
                    ? () => this.approveDevice()
                    : undefined
            }
          />
        )}
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    openAddDevice: state.displayAddDevice.openAddDevice,
    SsoUser: state.auth.SsoUser,
    userRole: JSON.parse(localStorage.getItem('user'))?.userRole
  };
};
export default withRouter(connect(mapStateToProps)(DeviceList));