
import React, { Component } from 'react'
import urlMapping from '../../../redux/API/api'
import apiCall from '../../../redux/API/apiCall'
import { Loader } from "../../../layOuts/Loader";
import ErrorHandlePopup from '../../ErrorHandlePopup';
import $ from 'jquery';
import { ErrorPopup } from '../../../layOuts/errorPopup';
var interval = null;

export default class DeviceGroupNetworkView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      id: this.props.id || "",
      showLoader: false,
      toggleStatus: false,
      networkViewData: [],
      networkTableData: [],
      isAxiosError: false,
      networkColumns: [
        {
          name: "SSID",
          value: "SSID",
          minWidth: "150px",
          selector: "SSID",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.SSID}>
              {
                (
                  row.SSID !== "" &&
                  row.SSID !== "null" &&
                  row.SSID !== null
                )
                  ? row.SSID
                  : '-'

              }</span>
          ),
        },
        {
          name: "VLAN",
          value: "VLAN",
          minWidth: "150px",
          selector: "VLAN",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.VLAN}>
              {
                (
                  row.VLAN !== "" &&
                  row.VLAN !== "null" &&
                  row.VLAN !== null
                )
                  ? row.VLAN
                  : '-'
              }
            </span>
          ),
        },
        {
          name: "Name",
          value: "name",
          minWidth: "150px",
          selector: "name",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.name}>
              {
                (row.name !== ""
                  && row.name !== "null"
                  && row.name !== null
                )
                  ? row.name
                  : '-'
              }</span>
          ),
        },
        {
          name: "Network Name",
          value: "networkName",
          minWidth: "150px",
          selector: "networkName",
          sortable: true,
          center: true,
          cell: (row) => (
            <span
              title={row.networkName}>
              {
                (
                  row.networkName !== "" &&
                  row.networkName !== "null" &&
                  row.networkName !== null
                ) ? row.networkName
                  : '-'
              }
            </span>
          ),
        },
        {
          name: "Network IP",
          value: "network_ip",
          minWidth: "150px",
          selector: "network_ip",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.network_ip}>
              {
                (row.network_ip !== "" &&
                  row.network_ip !== "null" &&
                  row.network_ip !== null
                )
                  ? row.network_ip
                  : '-'
              }
            </span>
          ),
        },
        {
          name: "Port",
          value: "port",
          minWidth: "150px",
          selector: "port",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.port}>
              {
                (
                  row.port !== "" &&
                  row.port !== "null" &&
                  row.port !== null
                )
                  ? row.port
                  : '-'
              }
            </span>
          ),
        }
      ]
    }
  };

  componentDidMount() {
    this.getNetworkView();
  };

  componentWillUnmount() {
    clearInterval(interval)
    interval = null
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.id) {
      this.setState({
        id: nextProps.id || ""
      }, () => this.getNetworkView())
    }
  };

  getNetworkView = () => {
    let data = {
      group_id: this.state.id
    }
    if (String(data.group_id) !== "") {
      this.setState({ showLoader: true })
      apiCall(urlMapping.groupNetworks(data), (response) => {
        this.setState({ showLoader: false })
        if (response?.success) {
          let networkTableData = response?.data?.filter(
            (item) => item.name !== "Control Tunnel" && item.network_zone !== 'datatunnel'
          )
          this.setState({
            networkViewData: networkTableData,
            networkTableData
          })
        }
        else {
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({ showRefreshModal: true })
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else {
            let networkTableData = []
            this.setState({ networkViewData: [], networkTableData })
          }
        }
      })
    }
  };

  manageAutoRefresh = (e) => {
    if (e.target.checked === true) {
      interval = setInterval(() => {
        this.setState({ toggleStatus: true })
        this.getNetworkView();
      }, 5000)
    } else {
      clearInterval(interval)
      interval = null
      this.setState({ toggleStatus: false })
    }
  };

  getFirstRow = () => {
    for (let i = 2; i < 8; i++) {
      return (
        this.state.networkViewData.length > i
          ? (
            <li className={`top-list-${i - 1}`}>
              <p><strong> {this.state.networkViewData[i].name}</strong></p>
              <div className="n-box">
                <div>Network: {this.state.networkViewData[i].networkName} </div>
                <div>Port: {this.state.networkViewData[i].port} </div>
                <div>VLAN: {this.state.networkViewData[i].VLAN}</div>
                <div>SSID: {this.state.networkViewData[i].SSID}</div>
              </div>
            </li>
          )
          : (
            <li className={`top-list-${i - 1} no-data-a`}>
              <p><strong>Network {i - 1}</strong></p>
              <div className="n-box">
                No Configured
              </div>
            </li>)
      )
    }
  };

  render() {
    return (
      <>
        {this.state.showLoader && <Loader />}
        {
          (this.state.networkTableData.length > 0)
            ? <>
              <div className="white-bg pl-2 pr-2">
                <div className="ta-box set-pos mb-0">
                  <div className="action-btn d-flex align-items-center">
                    <button
                      className="button-lst"
                      onClick={() => this.getNetworkView()}
                      disabled={this.state.toggleStatus === true}
                    ><img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh</button>
                    <div className="d-flex align-items-center">
                      <label className="switch mr-2 ml-3 z-999">
                        <input
                          type="checkbox"
                          onClick={(e) => this.manageAutoRefresh(e)}>
                        </input>
                        <span className="slider round"></span>
                      </label>
                      <span>Auto Refresh</span>
                    </div>
                  </div>
                </div>
                <div className="networks-view">
                  <div className="row no-gutters">
                    {
                      this.state.networkViewData.length > 0 &&
                      this.state.networkViewData.map((item, index) => {
                        return (
                          <div className="col-md-3 col-sm-6 mb-3">
                            <div className="network-card rounded">
                              <div className="network-header rounded-top d-flex justify-content-between p-2">
                                <div className="">
                                  Network Name <strong>{item.name}</strong>
                                </div>
                                <div className="text-right">
                                  Protocol <strong>
                                    {
                                      (item?.protocol === "staticAddr")
                                        ? "Static Address"
                                        : item?.protocol !== "ippt"
                                          ? "DHCP Client"
                                          : "IPPT"
                                    }</strong>
                                </div>
                              </div>
                              <div className="p-2 network-body">
                                <ul>
                                  <li>
                                    <span>IP Address:</span>
                                    <strong>{item.ip_address ? item.ip_address : "--"}</strong>
                                  </li>
                                  <li>
                                    <span>Netmask:</span>
                                    <strong>{item.netmask ? item.netmask : "--"}</strong>
                                  </li>
                                  <li>
                                    <span>Gateway:</span>
                                    <strong>{item.gateway ? item.gateway : "--"}</strong>
                                  </li>
                                  <li>
                                    <span>DNS:</span>
                                    <strong>{item.DNS ? item.DNS : "--"}</strong>
                                  </li>
                                  <li>
                                    <span>DHCP Server:</span>
                                    <strong>{item.dhcpServer === '1' ? 'Enabled' : 'Disabled'}</strong>
                                  </li>
                                  {item.dhcpServer === "1" &&
                                    <li>
                                      <span>Range:</span>
                                      <strong>{item.dhcp_range}</strong>
                                    </li>
                                  }
                                  {item.dhcpServer === "1" &&
                                    <li>
                                      <span>Lease Time:</span>
                                      <strong>{item.leaseTime}</strong>
                                    </li>
                                  }
                                  <li>
                                    <span>Bridge:</span>
                                    <strong>{item.bridgeStatus}</strong>
                                  </li>
                                  <li>
                                    <span>Type:</span>
                                    <strong>{item.network_zone}</strong>
                                  </li>
                                  <li>
                                    <span>VLAN ID:</span>
                                    <strong>{item.VLAN ? item.VLAN : "--"}</strong>
                                  </li>
                                </ul>
                              </div>
                              <div className="network-footer">
                                <div className="border-top row no-gutters align-items-center">
                                  <div className="col-sm-6 p-2">
                                    <strong>Mapped SSID</strong>
                                  </div>
                                  <div className="col-sm-6 border-left p-2 mssid-value">
                                    <div className="nf-list">
                                      {item.ssid ? <span>{item.ssid}</span> : <span>NA</span>}
                                    </div>
                                  </div>
                                </div>
                                <div className="border-top row no-gutters align-items-center">
                                  <div className="col-sm-6 p-2">
                                    <strong>Mapped Interface</strong>
                                  </div>
                                  <div className="col-sm-6 border-left p-2 mi-value">
                                    <div className="nf-list">
                                      <strong>Bridge Name</strong>
                                      {
                                        item.Bridge_name
                                          ? <span>{item.Bridge_name}</span>
                                          : <span>NA</span>
                                      }
                                    </div>
                                    <div className="nf-list">
                                      <strong>Interface Added</strong>
                                      {
                                        item.interfaceName
                                          ? <span>{item.interfaceName}</span>
                                          : <span>NA</span>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </>
            : <div className="white-bg pl-2 pr-2 no-tunnels">
              No networks found for this device group
            </div>
        }
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {
          this.state.isAxiosError
          &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </>
    )
  }
};