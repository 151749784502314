import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
export default class Tunnel extends Component {
    render() {
        return (
            <div id="tunnels" className="container">
                <h3>Tunnels</h3>
               
                <Carousel showThumbs={false}
                    showArrows={true}
                    centerMode={false}
                    showStatus={false}>
                    {/* tunnel step 1 */}
                    <div className="tunnel-step-1">
                        <p style={{ textAlign: "left" }}>Click on <strong>"+"</strong> button to add new Tunnels.</p>
                        <div className="img-box">
                            <img src="/images/user-guide/add-tunnel.png" alt="" />
                        </div>
                    </div>
                    {/* close tunnel step 1 */}

                    {/* tunnel step 2 */}

                    <div className="tunnel-step-2">
                        <p style={{ textAlign: "left" }}>User can select  <strong>Device A</strong> from create tunnel page and click on next</p>
                        <div className="img-box">
                            <img src="/images/user-guide/tunnel-1.png" alt="" />
                        </div>
                    </div>
                    {/* close tunnel step 2 */}

                    {/* tunnel step 3 */}
                    <div className="tunnel-step-3">
                        <p style={{ textAlign: "left" }}>User can select <strong>Device B</strong> from create tunnel page and click on next</p>
                        <div className="img-box">
                            <img src="/images/user-guide/tunnel-2.png" alt="" />
                        </div>
                    </div>
                    {/* close tunnel step 3 */}

                    {/* tunnel step 4 */}
                    <div className="tunnel-step-4">
                        <p style={{ textAlign: "left" }}>For selected <strong>Device A and B</strong>, Public key will be auto-populated. User can select endpoint and allowed IP from the drop-down list or manually can update the IP details</p>
                        <div className="img-box">
                            <img src="/images/user-guide/tunnel-3.png" alt="" />
                        </div>
                    </div>
                    {/* close tunnel step 4 */}

                    {/* tunnel step 3 */}
                    <div className="tunnel-step-5">
                        <p style={{ textAlign: "left" }}>Click on <strong>Tunnel Name</strong> to see the tunnel details like Wireguard Configuration, tunnel topology, tunnel data usage and graph.</p>
                        <div className="img-box">
                            <img src="/images/user-guide/tunnel-details.png" alt="" />
                        </div>
                    </div>
                </Carousel>
                {/* close tunnel step 3 */}

                <div className="text-right">
                    <button className="btn btn-light mr-3" onClick={() => { this.props.goToStep(4); this.props.stepChange(4) }}>Back</button>
                    <button className="btn btn-light mr-3" data-dismiss="modal" aria-label="Close"
                        onClick={() => this.props.callIntro()}>Complete</button>
                </div>
            </div>
        )
    }
}
