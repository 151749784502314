/* eslint-disable array-callback-return */
import React, { Component } from "react";
import $ from 'jquery';
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import * as Common from '../../../utils/common';
import { ErrorPopup } from '../../../layOuts/errorPopup';
import * as Action from "../../../redux/actionTypes";
import apiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import isEqual from 'lodash/isEqual';
class WiFiView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      networkViewData: [],
      selectedTemplateId: this.props.id ? this.props.id : "",
      view: false,
      title: '',
      message: '',
      show: false,
      error: false,
      showPassword: false,
      isAxiosError: false,
      template_schema_json: {
        radio: {
          twog: {
            mode: "",
            channel: "",
            bw: "",
            enable: "",
          },
          fiveg: {
            mode: "",
            channel: "",
            bw: "",
            enable: "",
          },
        },
        ssids: [],
      },
      ssidData: [
        {
          radio: "2g",
          network: "",
          hidden: "1",
          enabled: "0",
          ssid: "inseego",
          encryption: "open",
          password: "",
          isolate: "0",
        },
      ],
      "auth_server": "",
      "auth_port": "",
      "auth_secret": "",
      servers: [],
      server_data: [],
      columns: [
        {
          name: "SSID ",
          selector: "ssid",
          cell: (row) => (
            <span title={row.ssid} style={{ width: "auto" }} className={this.props.link ? "cursor-pointer" : ''}
            >{
                this.props.link ?
                  <Link to={{}} className="txt-blue" onClick={() => this.viewSSID(row)} >{" "}{row.ssid}
                  </Link>
                  : row.ssid
              }</span>
          ),
        },
        {
          name: "Security ",
          selector: "encryption",
          center: true,
          cell: (row) => (<span title={Common.getEncrytionValue(row.encryption)}>
            {Common.getEncrytionValue(row.encryption)}</span>
          ),
        },
        {
          name: "Passphrase ",
          selector: "password",
          cell: (row) => (
            <span
              title={row.password}
              className={row.password !== "" ? "mask" : ""}
            >
              {row.password !== "" ? row.password : "-"}
            </span>
          ),
        },
        {
          name: "Broadcast ",
          selector: "hidden",
          cell: (row) => (
            <label className="switch gray">
              <input type="checkbox" checked={row.hidden === "0"} />
              <span className="slider round" style={{ cursor: "not-allowed" }}></span>
            </label>
          ),
        },
        {
          name: "Radio ",
          selector: "radio",
          cell: (row) => (<span title={Common.getRadioValues(row.radio)}>
            {Common.getRadioValues(row.radio)}</span>)
        },
        {
          name: "Enable ",
          selector: "enabled",
          cell: (row) => (
            <label className="switch gray">
              <input type="checkbox" checked={row.enabled === "1"} />
              <span className="slider round" style={{ cursor: "not-allowed" }}></span>
            </label>
          ),
        },
      ],
      applied: false
    };
  }
  componentDidMount() {
    this.getConfigInfo();
    this.getExternalServers();
    if (this.props.template_schema_json !== undefined) {
      this.setState({
        template_schema_json: this.undefinedValueCheck(this.props.template_schema_json),
        view: this.props.view,
      });
      if (this.props.apply) {
        let { networkViewData } = this.props;
        let { template_schema_json } = this.props;
        template_schema_json = this.undefinedValueCheck(template_schema_json);
        networkViewData = networkViewData?.filter(item => item.name !== "Control Tunnel" &&
          item.name !== "Data Tunnel");
        if (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
          || this.props.selectedModel === 'Stormbreaker' || this.props.selectedModel === 'Firestorm')
          this.filterSSID(template_schema_json);
        networkViewData = networkViewData?.filter(item => (item.network_zone === 'lan' && item.bridgeStatus === 'Enabled'))
        networkViewData = (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
          || this.props.selectedModel === 'Stormbreaker' || this.props.selectedModel === 'Firestorm') ?
          networkViewData?.filter(item => item.name === 'Default') : networkViewData;
        if (networkViewData?.length > 0 && !this.props.edit && !this.props.view && !this.props.reapply
          && (this.props.templateData.default_type === '' ||
            this.props.templateData.default_type === null)) {
          template_schema_json.ssids.map((item) => {
            item.network = networkViewData[0].networkName
          })
        }
        this.setState({ networkViewData, template_schema_json });
      }
      if (!this.props.info) {
        const { columns } = this.state;
        const index = columns.findIndex(item => item.name === 'Network Name');
        if (index < 0) {
          columns.splice(1, 0, {
            name: "Network Name",
            selector: "network",
            minWidth: '150px',
            cell: (row) => {
              return (this.state.view || this.props.reapply ? (<span>{this.getNetworkName(row.network)}</span>) :
                (<select className="form-control" onChange={(e) => this.handleChange(e, row)} defaultValue={row.network}>
                  {
                    (this.state.networkViewData?.map((item) => {
                      return (<option value={item.networkName}>{item.name}</option>)
                    })
                    )}
                </select>))
            }
          });
        }
        this.setState({
          columns
        })
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.apply) {
      if (nextProps.networkViewData && !isEqual(this.props.template_schema_json, nextProps.template_schema_json)) {
        let { networkViewData } = nextProps;
        let template_schema_json = this.undefinedValueCheck(JSON.parse(JSON.stringify(nextProps.template_schema_json)));
        networkViewData = networkViewData?.filter(item => item.name !== "Control Tunnel" &&
          item.name !== "Data Tunnel");
        if (nextProps.selectedModel === 'Moretti_Model' || nextProps.selectedModel === 'Duvel_Model'
          || nextProps.selectedModel === 'Stormbreaker' || nextProps.selectedModel === 'Firestorm')
          this.filterSSID(template_schema_json);
        if (nextProps.networkViewData) {
          networkViewData = nextProps?.networkViewData.filter(item => (item.network_zone === 'lan' &&
            item.bridgeStatus === 'Enabled'))
          this.setState({
            networkViewData
          })
        }
        if (networkViewData?.length > 0 && !nextProps.edit && !nextProps.reapply
          && !nextProps.view && (nextProps.templateData.default_type === '' ||
            nextProps.templateData.default_type === null)) {
          template_schema_json.ssids.map((item) => {
            item.network = networkViewData[0].networkName
          })
        }
        this.setState({
          networkViewData,
          template_schema_json
        }, () => this.getServersData());
      }
    }
  }
  undefinedValueCheck = (value) => {
    if (value) {
      let { template_schema_json } = this.state;
      template_schema_json.radio.twog.mode = value?.radio?.twog?.mode || "11axg";
      template_schema_json.radio.twog.channel = value?.radio?.twog?.channel || "auto";
      template_schema_json.radio.twog.bw = value?.radio?.twog?.bw || "HT20";
      template_schema_json.radio.twog.enable = value?.radio?.twog?.enable || "1";
      template_schema_json.radio.fiveg.mode = value?.radio?.fiveg?.mode || "11axa";
      template_schema_json.radio.fiveg.channel = value?.radio?.fiveg?.channel || "auto";
      template_schema_json.radio.fiveg.bw = value?.radio?.fiveg?.bw || "HT80";
      template_schema_json.radio.fiveg.enable = value?.radio?.fiveg?.enable || "1";
      template_schema_json.ssids = [];
      Array.isArray(value?.ssids) && value?.ssids?.map((item) => {
        if (item) {
          let ssids = {
            "id": item?.id || Math.random().toString(36).substring(2, 11).toUpperCase(),
            "radio": item?.radio || "both",
            "network": item?.network || "",
            "hidden": item?.hidden || "0",
            "enabled": item?.enabled || "1",
            "ssid": item?.ssid || "",
            "encryption": item?.encryption || "none",
            "password": item?.password || "",
            "isolate": item?.isolate || "0",
            "isdeleted": item?.isdeleted || "0",
            "isedited": item?.isedited || "0",
            "auth_server": item?.auth_server || "",
            "auth_port": item?.auth_port || "",
            "auth_secret": item?.auth_secret || "",
            "radius_server": item?.radius_server || "",
            "prim_auth_server": item?.prim_auth_server || "",
            "prim_auth_port": item?.prim_auth_port || "",
            "prim_auth_secret": item?.prim_auth_secret || "",
            "sec_auth_server": item?.sec_auth_server || "",
            "sec_auth_port": item?.sec_auth_port || "",
            "sec_auth_secret": item?.sec_auth_secret || ""
          }
          template_schema_json.ssids.push(ssids);
        }
      })
      return template_schema_json;
    }
  }
  getConfigInfo = () => {
    this.setState({
      showLoader: true
    })
    apiCall(urlMapping.getConfigInfo(), (response) => {
      if (response?.success) {
        this.setState({
          showLoader: false,
          is_auth_server_configured: response?.data?.is_auth_server_configured
        })
        this.getAuthServerConfiguration();
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showRefreshModal: true,
            showLoader: false
          });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  getAuthServerConfiguration = () => {
    this.setState({
      showLoader: true,
    })
    apiCall(urlMapping.authServerConfiguration(), (response) => {
      if (response?.success) {
        this.setState({
          showLoader: false,
          auth_server: response?.data?.local?.server_ip,
          auth_port: response?.data?.[response?.data?.settings?.enable]?.port,
          auth_secret: response?.data?.settings?.secret_passphrase
        })
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    });
  }
  filterSSID = (template_schema_json) => {
    let enabledSSID = [];
    if (template_schema_json.ssids.length > 0) {
      enabledSSID = [template_schema_json.ssids[0].id];
      if (template_schema_json.ssids[0].radio === '2g' && template_schema_json.ssids.length > 1) {
        if (template_schema_json.ssids[1].radio === '5g') {
          enabledSSID.push(template_schema_json.ssids[1].id)
        }
      }
      if (template_schema_json.ssids[0].radio === '5g' && template_schema_json.ssids.length > 1) {
        if (template_schema_json.ssids[1].radio === '2g') {
          enabledSSID.push(template_schema_json.ssids[1].id)
        }
      }
    }
    this.setState({ enabledSSID })
  }
  viewSSID = (data) => {
    const x = document.getElementById("passphrase");
    x.type = "password"
    this.setState({
      viewSSID: true,
      ssidData: JSON.parse(JSON.stringify(data)),
      showPassword: false
    });
  };
  showPass = () => {
    const x = document.getElementById("passphrase");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  closeSSID = () => {
    this.setState({ viewSSID: false });
  };
  handleChange = (e, row) => {
    let { template_schema_json } = this.state;
    template_schema_json.ssids.map((item) => {
      if (item.ssid === row.ssid)
        item.network = e.target.value;
      return item;
    });
    this.setState({
      template_schema_json
    })
  }
  getExternalServers = () => {
    this.setState({ showLoader: true });
    apiCall(urlMapping.getServer(), (response) => {
      if (response?.success && response?.data) {
        this.setState({
          showLoader: false,
          server_data: response?.data,
        }, () => this.getServersData());
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          })
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState({
            showLoader: false,
            server_data: []
          }, () => this.getServersData());
        }
      }
    })
  }
  getServersData = () => {
    let template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
    // EMGT-1036 radius server set as blank if its deleted from external servers
    template_schema_json.ssids.map((item) => {
      if (this.state.server_data?.filter(server => server.profile_name === item.radius_server).length < 1) {
        item.radius_server = '';
        item.prim_auth_server = "";
        item.prim_auth_port = "";
        item.prim_auth_secret = "";
        item.sec_auth_server = "";
        item.sec_auth_port = "";
        item.sec_auth_secret = "";
      }
    });
    this.setState({ template_schema_json })
  }
  checkError = () => {
    let { template_schema_json } = this.state;
    let result = false;
    template_schema_json.ssids.map((item) => {
      if (item.network === '')
        result = true;
      // added validation for radius server
      if ((item.encryption === 'wpa2' || item.encryption === 'wpa3' ||
        item.encryption === 'wpa3-mixed') && item.radius_server === '')
        result = true;
    });
    return result;
  }
  applyTemplate = () => {
    let { template_schema_json } = this.state;
    let found = false;
    if (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
      || this.props.selectedModel === 'Stormbreaker' || this.props.selectedModel === 'Firestorm') {
      template_schema_json.ssids.map((ssid) => {
        if (ssid.encryption === 'ccmp' || ssid.encryption === 'ccmpaes' || ssid.encryption === 'wpa2' ||
          ssid.encryption === 'wpa3' || ssid.encryption === 'wpa3-mixed')
          found = true;
      })
    }
    if (found) {
      this.setState({
        title: 'Apply Template',
        message: "Selected security option is not supported for Mi-Fi,please edit the template and apply again!",
        show: true,
        error: true,
      }, () => $("#errorMessage").modal("show"));
    } else {
      if (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
        || this.props.selectedModel === 'Stormbreaker' || this.props.selectedModel === 'Firestorm')
        template_schema_json.ssids = template_schema_json.ssids.filter(ssid => this.state.enabledSSID.includes(ssid.id))
      if (this.props.selectedModel === 'CHIMAY') {
        template_schema_json.ssids.map((item) => {
          if (item.encryption === 'wpa2' || item.encryption === 'wpa3' || item.encryption === 'wpa3-mixed') {
            if (item.radius_server === 'SD-Edge_ZTNA-B') {
              item.auth_server = this.state.auth_server;
              item.auth_port = this.state.auth_port;
              item.auth_secret = this.state.auth_secret;
              item.prim_auth_server = "";
              item.prim_auth_port = "";
              item.prim_auth_secret = "";
              item.sec_auth_server = "";
              item.sec_auth_port = "";
              item.sec_auth_secret = ""
            } else {
              this.state.server_data.map((server) => {
                if (server.profile_name === item.radius_server) {
                  item.auth_server = "";
                  item.auth_port = "";
                  item.auth_secret = "";
                  item.prim_auth_server = server?.primary_server?.prim_auth_server;
                  item.prim_auth_port = server?.primary_server?.prim_auth_port;
                  item.prim_auth_secret = server?.primary_server?.prim_auth_secret;
                  item.sec_auth_server = server?.secondary_server?.sec_auth_server;
                  item.sec_auth_port = server?.secondary_server?.sec_auth_port;
                  item.sec_auth_secret = server?.secondary_server?.sec_auth_secret
                }
              })
            }
            return item;
          }
        })
      }
      this.props.dispatch({
        type: Action.SET_APPLY_TEMPLATE,
      });
      this.props.applyTemplate(template_schema_json);
    }
  }
  getNetworkName = (name) => {
    const network = this.state.networkViewData.filter(item => item.networkName === name)[0];
    let networkName = ''
    if (network)
      networkName = network.name;
    return networkName;
  }
  resetPopup = () => {
    this.setState({
      show: false,
      error: false,
    }, () => {
      $("#errorMessage").modal("hide");
      if (this.state.hide) {
        this.props.refreshList();
        this.props.closeAddTemplate();
      }
    })
  }
  render() {
    return (
      <div className="wifi-view">
        <div className="tab-box">
          <div className="gray-bg p-3 rounded">
            <p>
              <strong>Radio</strong>
            </p>
            <div className="row">
              <div className="col-md-6">
                <div className="border rounded p-3">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span>
                      <strong>2.4GHz</strong>
                    </span>
                    <label className="switch gray">
                      <input
                        type="checkbox"
                        checked={
                          this.state.template_schema_json.radio.twog.enable ===
                          "1"
                        }
                      />
                      <span className="slider round" style={{ cursor: "not-allowed" }}></span>
                    </label>
                  </div>
                  <div className="dhcp-header">
                    <div className="row">
                      <div className="col">Mode</div>
                      <div className="col">Channel</div>
                      <div className="col">BW</div>
                    </div>
                  </div>
                  <div className="dhcp-body">
                    <div className="row">
                      <div className="col">
                        {Common.getMode(this.state.template_schema_json.radio.twog.mode)}
                      </div>
                      <div className="col">
                        {this.state.template_schema_json.radio.twog.channel === 'auto' ? "Auto" :
                          this.state.template_schema_json.radio.twog.channel}
                      </div>
                      <div className="col">
                        {Common.getBW(this.state.template_schema_json.radio.twog.bw)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="border rounded p-3">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span>
                      <strong>5GHz</strong>
                    </span>
                    <label className="switch gray">
                      <input
                        type="checkbox"
                        checked={
                          this.state.template_schema_json.radio.fiveg.enable ===
                          "1"
                        }
                      />
                      <span className="slider round" style={{ cursor: "not-allowed" }}></span>
                    </label>
                  </div>
                  <div className="dhcp-header">
                    <div className="row">
                      <div className="col">Mode</div>
                      <div className="col">Channel</div>
                      <div className="col">BW</div>
                    </div>
                  </div>
                  <div className="dhcp-body">
                    <div className="row">
                      <div className="col">
                        {Common.getMode(this.state.template_schema_json.radio.fiveg.mode)}
                      </div>
                      <div className="col">
                        {this.state.template_schema_json.radio.fiveg.channel === 'auto' ? 'Auto' :
                          this.state.template_schema_json.radio.fiveg.channel}
                      </div>
                      <div className="col">
                        {Common.getBW(this.state.template_schema_json.radio.fiveg.bw)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.template_schema_json.ssids.length > 0 && (
            <div className="gray-bg p-3 rounded mt-3">
              <p>
                <strong>SSID</strong>
              </p>
              {this.state.template_schema_json.ssids.filter(item => item.isdeleted !== "1").map((item) => {
                return (
                  <div className={(this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
                    || this.props.selectedModel === 'Stormbreaker' || this.props.selectedModel === 'Firestorm') &&
                    !this.state.enabledSSID.includes(item.id) ?
                    "row mb-3 disabled" : "row mb-3"}>
                    <div className="table-autoheight col-md-12">
                      <div className="lan-table">
                        <DataTable
                          columns={this.state.columns}
                          data={this.state.template_schema_json.ssids.filter((element) => element === item)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!this.props.view && (
            <div className="s-footer">
              <button
                className="btn btn-light"
                onClick={(this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeAddTemplate() :
                  () => this.props.closeApplyTemplate()}
              >
                {this.props.apply && !this.props.reapply ? "Back" : "Cancel"}
              </button>
              <button
                className="btn btn-primary"
                disabled={this.checkError() || this.state.applied}
                onClick={this.props.apply ? () => { this.applyTemplate(); this.setState({ applied: true }); } : ""}
              >
                {this.props.apply
                  ? "Apply"
                  : this.state.selectedTemplateId !== ""
                    ? "Update"
                    : "Save"}
              </button>
            </div>
          )}
          <div className={this.state.viewSSID ? "disabled-slide" : ""}></div>
        </div>
        <div
          className={
            this.state.viewSSID === true
              ? "sidebar-slide zindex10 w-350 second-open  r-757"
              : "sidebar-slide zindex10 w-350"
          }
        >
          <div className="s-header">
            <h1 className="ss-title">View SSID</h1>
            <button>
              <img
                src="/images/svg-icons/s-cancel.svg"
                alt=""
                onClick={() => this.closeSSID()}
              />
            </button>
          </div>
          <div className="s-body">
            <div className="form-group">
              <label>
                SSID
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="The broadcasted SSID of the wireless network"
                >
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <input
                type="text"
                className="form-control"
                name="ssid"
                value={this.state.ssidData.ssid}
                readOnly={this.props.apply || this.props.view}
              />
            </div>
            <div className="form-group">
              <label>
                Security
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Wireless encryption method"
                >
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <input
                type="text"
                className="form-control"
                name="encryption"
                value={Common.getEncrytionValue(this.state.ssidData.encryption)}
                readOnly={this.props.apply || this.props.view}
              />
            </div>
            {(this.state.ssidData.encryption === 'wpa2' || this.state.ssidData.encryption === 'wpa3' ||
              this.state.ssidData.encryption === 'wpa3-mixed') && <div className="form-group mb-0">
                <label>Server
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Select RADIUS server">
                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="radius_server"
                  value={this.state.ssidData.radius_server}
                  readOnly={this.props.apply || this.props.view}
                />
              </div>}
            <div className="form-group">
              <label>
                Passphrase
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="This is a string that specifies the pre-shared passphrase"
                >
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <input
                type="password"
                className="form-control"
                name="password"
                id="passphrase"
                value={this.state.ssidData.password}
                minLength="8"
                maxLength="20"
                readOnly={this.props.apply || this.props.view}
              />
              <div className="sh-icon">
                <img
                  src={
                    this.state.showPassword
                      ? "/images/show.png"
                      : "/images/hide.png"
                  }
                  alt=""
                  onClick={() => this.showPass()}
                />
              </div>
              <div className="error-txt" id="password">
                Invalid value
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="mr-5">
                Broadcast
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Specifies the SSID to be broadcast or hidden"
                >
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </span>
              <label className="switch">
                <input
                  type="checkbox"
                  name="hidden"
                  checked={this.state.ssidData.hidden === "0"}
                />
                <span className="slider round" style={{ cursor: "not-allowed" }}></span>
              </label>
            </div>
            <div className="form-group">
              <label>
                Radio Type
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Identifier for the underlying radio hardware"
                >
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <input
                type="text"
                className="form-control"
                name="radio"
                // LONG-1786_radio type name should be proper
                value={Common.getRadioValues(this.state.ssidData.radio)}
                readOnly={this.props.apply || this.props.view}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <span className="mr-5">
                Enable
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Enable/Disable the wireless network"
                >
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </span>
              <label className="switch">
                <input
                  type="checkbox"
                  name="enabled"
                  checked={this.state.ssidData.enabled === "1"}
                />
                <span className="slider round" style={{ cursor: "not-allowed" }}></span>
              </label>
            </div>
          </div>
        </div>
        {(this.state.show) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            reset={() => this.resetPopup()}
          />
        }
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
  };
};
export default connect(mapStateToProps)(WiFiView);
