export const config = (() => {
    return {
"REACT_APP_BaseUrl": "https://sdwandevapimanagement.azure-api.net/",
"REACT_APP_Version": "0.5",
 // server
"REACT_APP_postLogoutRedirectUri":"https://dev1-5gsdedge.inseego.com/dashboard",
"REACT_APP_redirectUri":"https://dev1-5gsdedge.inseego.com/",    
"REACT_APP_validateAuthority":false,
"REACT_APP_navigateToLoginRequestUrl":true,
"REACT_APP_clientId":"65406dfb-f03a-47f6-a98f-c03d616fa6cc",
"REACT_APP_authority":`https://devsdedge.b2clogin.com/devsdedge.onmicrosoft.com/B2C_1A_INSEEGO_SDEDGE_SIGNUP_SIGNIN`,
"REACT_APP_authority_password_change":"https://devsdedge.b2clogin.com/devsdedge.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_INSEEGO_SDEDGE_PASSWORDCHANGE",
    }
});
