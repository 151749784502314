/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import DataTable from "react-data-table-component";
import $ from 'jquery';
import { ErrorPopup } from '../../layOuts/errorPopup';
import { LinkPopup } from '../../layOuts/linkPopup';
import ErrorHandlePopup from "../ErrorHandlePopup";
import * as Action from "../../redux/actionTypes";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import "react-datepicker/dist/react-datepicker.css";
class UpdateFirmware extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            devicesFirmware: false,
            multipleSelect: '',
            multipleSelect1: '',
            showLink: false,
            searchVal: "",
            toggledClearDeviceRows: false,
            deviceList: [],
            updateFirmwareData: [],
            selectedDeviceData: [],
            isheadendcpe: '',
            isAxiosError: false,
            deviceListColumns: [
                {
                    name: "Device Name",
                    selector: "device_name",
                    value: "Device Name",
                    center: false,
                    sortable: true,
                    minWidth: "150px",
                    cell: (row) => (
                        <span className="dnamemac" id={`icon${row.device_id}`} title={row.device_name}>
                            {/* // Changed API to show all the online devices */}
                            <i className={"status online"}></i>
                            <span className="hname dfname">{row.device_name}</span>
                            <span className="maca">{row.device_mac}</span>
                        </span>
                    ),
                },
                {
                    name: "Model",
                    selector: "device_model",
                    value: "Device Model",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_model}>
                        {row.device_model}</span>
                    ),
                },
                {
                    name: "Current Firmware",
                    selector: "device_firmware_version",
                    value: "Firmware",
                    sortable: true,
                    cell: (row) => (<span className="dfversion" title={row.device_firmware_version}>
                        {row.device_firmware_version}</span>
                    ),
                },
                {
                    name: "Target Firmware",
                    selector: "target_firmware_version",
                    value: "Target Firmware",
                    sortable: true,
                    cell: (row) => (<span className="dfversion" title={this.state.updateFirmwareData.fw_version}>
                        {this.state.updateFirmwareData.fw_version}</span>
                    ),
                }
            ],
            deviceListColumnsDiff: [
                {
                    name: "Device Name",
                    selector: "device_name",
                    value: "Device Name",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_name}>
                        {row.device_name}</span>
                    ),
                },
                {
                    name: "MAC Address ",
                    selector: "device_mac",
                    value: "Mac Address",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_mac}>
                        {row.device_mac}</span>
                    ),
                },
                {
                    name: "Firmware ",
                    selector: "device_firmware_version",
                    value: "Firmware",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_firmware_version}>
                        {row.device_firmware_version}</span>
                    ),
                },
                {
                    name: "Model",
                    selector: "device_model",
                    value: "Device Model",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row.device_model}>
                        {row.device_model}</span>
                    ),
                },
            ],
            scheduleLater: false,
            startDate: null,
            show: false,
            delete: false
        };
    }


    static getDerivedStateFromProps = (props, state) => {
        if (props.data) {
            state.updateFirmwareData = props.data
        }
        state.isheadendcpe = props.isheadendcpe
    }

    getDeviceByModel = (device_model) => {
        let model = device_model.replaceAll(/[\[\]']+/g, '');
        let data = { device_model: model };
        this.setState({
            showLoader: true,
        });
        // Changed API to show all the online devices
        ApiCall(urlMapping.getDeviceLookUp(data), (response) => {
            this.setState({
                showLoader: false
            });
            if (response?.success) {
                const onlineDevices = response?.results?.filter(
                    (device) =>
                        device?.is_headendcpe === this.state.isheadendcpe

                );
                this.setState({
                    deviceList: onlineDevices,
                });
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    };
    deviceAdd = () => {
        // Already added device getting replaced after adding another device 
        let selectedDevices = [...this.state.selectedDeviceData]
        this.state.multipleSelect.map((item) => {
            selectedDevices.push(item);
        });
        this.setState({
            selectedDeviceData: selectedDevices,
            deviceList: [],
            multipleSelect: "",
            toggledClearDeviceRows: true,
        });
    };
    multipleSelect(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    }

    multipleSelect1(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect1: "" });
        else {
            this.setState({ multipleSelect1: rows.selectedRows }
            )
        }
    }
    isSubstring = (s1) => {
        this.setState({ searchVal: s1 })
    }

    deleteSelectedDevice = () => {
        let newList = [...this.state.selectedDeviceData];
        if (this.state.multipleSelect1.length > 0) {
            this.state.multipleSelect1.map((item) => (
                newList.splice(newList.indexOf(item), 1)
            ));
        }
        this.setState({
            selectedDeviceData: newList,
            toggledClearSelectedDeviceRows: true,
            multipleSelect1: "",
        });
    };

    confirmUpgrade = () => {
        this.setState(
            {
                title: "Firmware Upgrade",
                message: this.props.data.firmware_description,
                show: true,
                error: true,
                delete: true,
            },
            () => {
                $("#errorMessage").modal("show");
            }
        );
    }

    deviceFirmwareUpgrade = () => {
        this.setState({ showLoader: true });
        let deviceId = [];
        this.state.selectedDeviceData.map(element => (
            deviceId.push(element.device_id)
        ));

        let data = {
            device_ids: deviceId, firmware_id: (this.state.updateFirmwareData.fw_id).toString(),
            scheduled_time: this.state.scheduleLater === true ? parseInt(new Date(this.state.startDate).getTime().toString().slice(0, 10)) : "None"
        }
        ApiCall(urlMapping.deviceFirmwareUpgrade(data), (response) => {
            this.setState({ showLoader: false });
            // LONG-2132
            let messageApi = "";
            if (response?.success) {
                if (response?.data?.FW_Error_devices?.length === 0) {
                    messageApi = response?.message

                }
                if (response?.data?.FW_Upgraded_devices?.length === 0) {
                    messageApi = '';
                }
                if (response?.data?.FW_Upgraded_devices?.length > 0 && response?.data?.FW_Error_devices?.length > 0) {
                    messageApi = response.message + "\n Upgraded Devices MAC Address: ";

                    let mac_keys = '';
                    response?.data?.['FW_Upgraded_devices']?.map((key) => (
                        Object.keys(key).map((value) => mac_keys = mac_keys === '' ? mac_keys + value : mac_keys + ',' + value)
                    ))
                    messageApi = messageApi + mac_keys;
                    mac_keys = '';
                    messageApi = messageApi + "\n Device Version not matched with Firmware Version \n Error Devices MAC Address: ";
                    response?.data?.['FW_Error_devices']?.map((key) => (
                        Object.keys(key).map((value) => mac_keys = mac_keys === '' ? mac_keys + value : mac_keys + ',' + value)
                    ))
                    messageApi = messageApi + mac_keys;

                }
                if (response?.data?.FW_Upgraded_devices?.length === 0 && response?.data?.FW_Error_devices?.length > 0) {
                    response?.data?.['FW_Error_devices']?.map((key) => (
                        Object.values(key).map((value) => messageApi = value + "\n Error Devices MAC Address: ")
                    ))
                    let mac_keys = '';
                    response?.data?.['FW_Error_devices']?.map((key) => (
                        Object.keys(key).map((value) => mac_keys = mac_keys === '' ? mac_keys + value : mac_keys + ',' + value)
                    ))
                    messageApi = messageApi + mac_keys;

                }
                if (response?.data?.FW_Upgraded_devices?.length !== 0) {
                    this.setState({
                        title: 'Firmware Upgrade',
                        message: messageApi + ' \n to operation',
                        showLink: true,
                        id: response.data,
                    }, () => $("#exampleModal").modal("show"));
                } else {
                    this.setState({
                        title: 'Firmware Upgrade',
                        message: messageApi,
                        show: true,
                        error: true,
                    }, () => $("#errorMessage").modal("show"));
                }
            }
            else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Firmware Upgrade',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
    }

    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                if (this.state.title === 'Tenant Error') {
                this.props.dispatch({
                    type: Action.LOGOUT,
                });
            }
                $("#errorMessage").modal("hide");
                this.props.closeUpdateFirmware();
            }
        );
    };

    closeDeviceFirmware = () => {
        this.setState({
            searchVal: "",
            devicesFirmware: false,
            deviceList: [],
            multipleSelect: "",
            toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
        });
    };

    openOperationList = (value) => {
        $("#exampleModal").modal("hide");
        this.props.props.history.push({
            pathname: '/operations',
            state: { data: value }
        })
        this.props.closeUpdateFirmware();
    }
    closeLinkpopup = () => {
        $("#exampleModal").modal("hide");
        $("#errorMessage").modal("hide");
        this.setState({ showLink: false, id: '' }, () =>
            this.props.closeUpdateFirmware());
    }
    scheduleLater = () => {
        this.setState({
            scheduleLater: !this.state.scheduleLater
        });
    }
    setStartDate = (e) => {
        this.setState({
            startDate: e.value
        })
    }
    monthNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
    }

    yearNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="ml-2" style={{ lineHeight: 1 }} />;
    }
    render() {

        return (
            <div>
                <div className={this.state.devicesFirmware ? "disabled-slide-650" : ""}></div>
                {this.state.showLoader && <Loader />}
                <div className={this.props.openUpdateFirmware ? "slide-overlay open" : "slide-overlay"}></div>
                <div className={this.props.openUpdateFirmware ? "sidebar-slide open" : "sidebar-slide"}>
                    <div className="s-header">
                        <h1 className="ss-title">Update Firmware </h1>
                        <button onClick={() => this.props.closeUpdateFirmware()}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Firmware Name
                                        <span className="red-txt"></span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Firmware Name">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <input type="text"
                                        className="form-control"
                                        name="template_name"
                                        readOnly
                                        value={this.state.updateFirmwareData.fw_name}
                                    />
                                    <div className="error-txt" id="template_name">Invalid value</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Targetted Firmware Version
                                        <span className="red-txt"></span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Firmware Vesion">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <input type="text"
                                        className="form-control"
                                        name="template_name"
                                        readOnly
                                        value={this.state.updateFirmwareData.fw_version}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group inline-form">
                                    <label >
                                        <input type="checkbox" checked={this.state.scheduleLater} onChange={this.scheduleLater} />
                                        Schedule Later
                                    </label>
                                    {this.state.scheduleLater &&
                                        <Calendar
                                            id="navigatorstemplate"
                                            value={this.state.startDate}
                                            onChange={(e) => this.setStartDate(e)}
                                            showTime
                                            hourFormat="12"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            hideOnDateTimeSelect={true}
                                            yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                                            minDate={new Date()}
                                        // monthNavigatorTemplate={this.monthNavigatorTemplate}
                                        // yearNavigatorTemplate={this.yearNavigatorTemplate}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="page-sub-title">
                            <div>
                                <strong>Devices</strong>
                                <img src="/images/svg-icons/add.svg" className="add-btn ml-2" alt=""
                                    onClick={() => {
                                        this.setState({ devicesFirmware: true });
                                        this.getDeviceByModel(
                                            (this.state.updateFirmwareData.device_models).toString()
                                        );
                                    }}
                                />
                            </div>
                            <div className="device-action">
                                <span className="text-muted">
                                    {this.state.selectedDeviceData.length} devices added
                                </span>
                                <span className={(this.state.selectedDeviceData.length > 0 && this.state.multipleSelect1.length > 0) ? "icon-box" : "icon-box disabled"}>
                                    <img src="/images/svg-icons/delete.svg" alt="" onClick={() => this.deleteSelectedDevice()} />
                                </span>
                            </div>
                        </div>
                        <div className="device-table">
                            <DataTable
                                columns={this.state.deviceListColumnsDiff}
                                data={this.state.selectedDeviceData}
                                selectableRows
                                highlightOnHover
                                fixedHeader
                                onSelectedRowsChange={(e) => this.multipleSelect1(e)}
                                clearSelectedRows={this.state.toggledClearSelectedDeviceRows}
                            />
                        </div>
                        <div
                            className={
                                this.state.devicesFirmware
                                    ? "sidebar-slide zindex10 w-600 second-open"
                                    : "sidebar-slide zindex10 w-400"
                            }>
                            <div className="s-header">
                                <h1 className="ss-title">Devices</h1>
                                <button onClick={() => this.closeDeviceFirmware()}>
                                    <img src="/images/svg-icons/s-cancel.svg" alt="" />
                                </button>
                            </div>
                            <div className="s-body">
                                <div className="filter-box d-flex align-items-center">
                                    <div className="search-box2 mr-2">
                                        <input type="text" value={this.state.searchVal} placeholder="Search.." onChange={(e) => this.isSubstring((e.target.value.toLowerCase()))} />
                                        <img src="/images/svg-icons/search.svg" alt="" />
                                    </div>

                                </div>
                                <div className="device-table device-firmware-column">
                                    <DataTable
                                        columns={this.state.deviceListColumns}
                                        data={this.state.deviceList?.filter(item => item?.device_name?.toLowerCase()?.includes(this.state?.searchVal) && String(item?.device_firmware_version) === String(this.state.updateFirmwareData?.source_version))}
                                        selectableRows
                                        highlightOnHover
                                        fixedHeader
                                        onSelectedRowsChange={(data) => this.multipleSelect(data)}
                                        clearSelectedRows={this.state.toggledClearDeviceRows}
                                    />
                                </div>
                            </div>
                            <div className="s-footer">
                                <button className="btn btn-light" onClick={() => this.closeDeviceFirmware()}> Cancel</button>
                                <button className="btn btn-primary" disabled={this.state.multipleSelect === ''} onClick={() => { this.deviceAdd(); this.closeDeviceFirmware() }}> Add </button>
                            </div>
                        </div>
                    </div>
                    <div className="s-footer">
                        <button className="btn btn-light" onClick={() => this.props.closeUpdateFirmware()}>
                            Cancel</button>
                        <button className="btn btn-primary" disabled={this.state.selectedDeviceData.length === 0 ||
                            (this.state.scheduleLater === true && this.state.startDate === null)} onClick={() => {
                                this.confirmUpgrade()
                            }}> Update </button>
                    </div>
                </div>
                {this.state.show && (
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        onSubmit={() => this.deviceFirmwareUpgrade()}
                        reset={() => this.resetPopup()}
                    />
                )}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {(this.state.showLink) &&
                    <LinkPopup
                        title={this.state.title}
                        message={this.state.message}
                        open={() => this.openOperationList(this.state.id)}
                        close={() => this.closeLinkpopup()}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        openUpdateFirmware: state.displayUpdateFirmware.openUpdateFirmware
    };
};
export default connect(mapStateToProps)(UpdateFirmware);