import React, { Component } from 'react'
import * as Action from '../../redux/actionTypes';
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import { ErrorPopup } from '../../layOuts/errorPopup';
import ErrorHandlePopup from '../ErrorHandlePopup';
import $ from 'jquery';
import * as Common from '../../utils/common';

class AddNewDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            message: "",
            error: false,
            delete: false,
            showRefreshModal: false,
            isAxiosError: false,
            link: "",
            showLoader: false,
            wanIP: "",
            wanError: false,
            deviceId: '',
            activationMessage: ''
        }
    };

    closeAddDevice = () => {
        this.props.dispatch({
            type: Action.CLOSE_ADD_DEVICE,
        });
    };

    validateIP = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            if (!Common.validateIPaddress(e)) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = 'Invalid WAN IP';
                this.setState({
                    wanError: true
                })
            }
            else {
                element.style.display = 'none';
                element.innerHTML = "";
                this.setState({
                    wanError: false
                })
            }
        }
    };

    generateActivation = () => {
        this.setState({ showLoader: true });
        const data = {
            wan_ip: this.state.wanIP
        }
        ApiCall(urlMapping.addDevicePTK(data), (response) => {
            if (response?.success) {
                this.setState({
                    link: response?.data,
                    showLoader: false,
                    deviceId: response?.device_id,
                    activationMessage: ""
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false,
                        link: "",
                        deviceId: "",
                        activationMessage: ""
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true,
                        link: "",
                        deviceId: '',
                        activationMessage: ""
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true,
                        link: "",
                        deviceId: ''
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (!response?.success && response?.message !== "") {
                    this.setState({
                        activationMessage: response?.message,
                        showLoader: false,
                        link: response?.data,
                        deviceId: response?.device_id
                    })
                }
                else {
                    this.setState({
                        showLoader: false,
                        link: "",
                        deviceId: "",
                        activationMessage: ""
                    });
                }
            }
        })
    };

    downloadActivation = () => {
        this.setState({ showLoader: true });
        const data = {
            device_id: this.state.deviceId
        }
        ApiCall(urlMapping.activationKey(data), (response) => {
            if (response) {
                this.setState({
                    showLoader: false,
                    multipleSelect: "",
                });
                const csvLines = Object.values(response).join('').split('\n');
                if (csvLines[csvLines.length - 1].includes('[object Object]')) {
                    csvLines.pop();
                }
                const csvData = csvLines.join('\n');
                const blobData = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
                const url = window.URL.createObjectURL(blobData);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Activation_Code.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true, showLoader: false });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                } else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true,
                        multipleSelect: "",
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                } else {
                    this.setState({
                        showLoader: false,
                        multipleSelect: "",
                    });
                }
            }
        });
    };

    render() {
        return (
            <div>{this.state.showLoader && <Loader />}
                <div className={this.props.openAddDevice ? "slide-overlay open" : "slide-overlay"}></div>
                <div className={this.props.openAddDevice ? "sidebar-slide open" : "sidebar-slide"}>
                    <div className="s-header">
                        <h1 className="ss-title">Link Generation for V2000</h1>
                        <button
                            onClick={() => this.closeAddDevice()}
                        >
                            <img
                                src="/images/svg-icons/s-cancel.svg"
                                alt=""
                            />
                        </button>
                    </div>
                    <div className="s-body">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                            <div s
                                tyle={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                <label style={{ marginRight: '0.5rem' }}>
                                    WAN IP
                                    <span className="red-txt">*</span>&nbsp;:
                                </label>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: 1
                                    }}
                                >
                                    <input
                                        className='form-control'
                                        type="text"
                                        name="wan_ip"
                                        value={this.state.wanIP}
                                        onChange={(e) => this.setState({ wanIP: e.target.value })}
                                        onBlur={(e) => this.validateIP(e)}
                                        style={{ flex: 1 }}
                                    />
                                </div>
                            </div>
                            <div
                                style={{ marginLeft: '1rem' }}
                                className="error-txt" id="wan_ip"
                            >Invalid WAN IP</div>
                            <button
                                className='btn btn-primary'
                                disabled={this.state.wanIP === "" || this.state.wanError}
                                onClick={() => this.generateActivation()}
                                style={{
                                    alignSelf: 'center',
                                    marginBottom: '2rem',
                                    marginTop: '2rem'
                                }}>
                                Generate Link
                            </button>
                            {this.state.link !== "" &&
                                <div
                                    style={{
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        padding: '1.5rem',
                                        position: 'relative',
                                        textAlign: 'center',
                                        backgroundColor: 'beige'
                                    }}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '0.5rem',
                                            color: '#f00',
                                            fontSize: 'larger'
                                        }}>{this.state.activationMessage}</div>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '0.5rem',
                                            right: '0.5rem'
                                        }}>
                                        <img
                                            title="Download the activation key"
                                            style={{
                                                width: '25px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => this.downloadActivation()}
                                            src="/images/svg-icons/download-arrow.svg" />
                                    </div>
                                    <div className='mt-4'>
                                        <p style={{ marginBottom: '0.5rem' }}>Click the link to onboard a V2000 device using the activation code</p>
                                        <a
                                            href={this.state.link}
                                            target="_blank"
                                            style={{
                                                color: 'blue',
                                                textDecoration: 'underline',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'block'
                                            }} >
                                            {this.state.link}
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        openAddDevice: state.displayAddDevice.openAddDevice,
    });
}

export default connect(mapStateToProps)(AddNewDevice);
