export const LOGIN = "auth/LOGIN";
export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "auth/LOGIN_FAILURE";
export const LOGOUT = "auth/LOGOUT";
export const LOGOUT_SUCCESS = "auth/LOGOUT_SUCCESS";
export const CLOSE_SIDEBAR_MENU = "auth/CLOSE_SIDEBAR_MENU";
export const OPEN_SIDEBAR_MENU = "auth/OPEN_SIDEBAR_MENU";
export const OPEN_ADD_USER = "userlist/OPEN_ADD_USER";
export const CLOSE_ADD_USER = "userlist/CLOSE_ADD_USER";
export const CLOSE_ADD_TUNNEL = "tunnel/CLOSE_ADD_TUNNEL";
export const OPEN_ADD_TUNNEL = "tunnel/OPEN_ADD_TUNNEL";
export const CLOSE_ADD_DEVICE = "devicelist/CLOSE_ADD_DEVICE";
export const OPEN_ADD_DEVICE = "devicelist/OPEN_ADD_DEVICE";
export const OPEN_EDIT_TUNNEL = "tunnel/OPEN_EDIT_TUNNEL";
export const CLOSE_EDIT_TUNNEL = "tunnel/CLOSE_EDIT_TUNNEL";
export const OPEN_WG_TEMPLATE = "template/OPEN_WG_TEMPLATE";
export const CLOSE_WG_TEMPLATE = "template/CLOSE_WG_TEMPLATE";
export const OPEN_STATIC_TEMPLATE = "template/OPEN_STATIC_TEMPLATE";
export const CLOSE_STATIC_TEMPLATE = "template/CLOSE_STATIC_TEMPLATE";
export const OPEN_ADD_TEMPLATE = "template/OPEN_ADD_TEMPLATE";
export const CLOSE_ADD_TEMPLATE = "template/CLOSE_ADD_TEMPLATE";
export const SET_DEVICE_MODEL = "template/SET_DEVICE_MODEL";
export const OPEN_APPlY_TEMPLATE = "template/OPEN_APPLY_TEMPLATE";
export const CLOSE_APPLY_TEMPLATE = "template/CLOSE_APPLY_TEMPLATE";
export const OPEN_SSID = "template/OPEN_SSID";
export const CLOSE_SSID = "template/CLOSE_SSID";
export const OPEN_ATTACH = "template/OPEN_ATTACH";
export const CLOSE_ATTACH = "template/CLOSE_ATTACH";
export const OPEN_UPDATE_FIRMWARE = "deviceFirmware/OPEN_UPDATE_FIRMWARE";
export const CLOSE_UPDATE_FIRMWARE = "deviceFirmware/CLOSE_UPDATE_FIRMWARE";
export const OPEN_APPLY_NETWORK_TEMPLATE = "template/OPEN_APPLY_NETWORK_TEMPLATE";
export const CLOSE_APPLY_NETWORK_TEMPLATE = "template/CLOSE_APPLY_NETWORK_TEMPLATE";
export const DROPDOWN_CHANGE = "devicelist/DROPDOWN_CHANGE";
export const SET_APPLY_TEMPLATE = "template/SET_APPLY_TEMPLATE";
export const RESET_APPLY_TEMPLATE = "template/RESET_APPLY_TEMPLATE";
export const GET_AUTH_DETAILS = "configuration/GET_AUTH_DETAILS";
export const IS_AUTH_SERVER_CONFIGURED = "configuration/IS_AUTH_SERVER_CONFIGURED";
export const OPEN_ADD_SERVER = "configuration/OPEN_ADD_SERVER";
export const CLOSE_ADD_SERVER = "configuration/CLOSE_ADD_SERVER";
export const OPEN_ADD_AD_USER = "userlist/OPEN_ADD_AD_USER";
export const CLOSE_ADD_AD_USER = "userlist/CLOSE_ADD_AD_USER";
export const SET_TENANT = "userlist/SET_TENANT";
export const DELETE_TENANT = "userlist/DELETE_TENANT";
export const SET_SSOUSER = "userlist/SET_SSOUSER";
export const REMOVE_USER = "userlist/REMOVE_USER";
