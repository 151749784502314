import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const SSOUser = (props) => {
    // const [showLoader, setLoader] = useState(true);
    const history = useHistory();
    useEffect(() => {
        if (localStorage.getItem('ssoUser') !== 'true') {
            localStorage.clear();
            sessionStorage.clear();
        }
        if (window.location.search.split('?').length > 0) {
            const parts = window.location.search.split('?')[1]?.split('&source');
            localStorage.setItem('ssoUser', true);
            localStorage.setItem('selectedTenantName', parts[0].split('=')[1].replace(/%20/g, " "));
            history.push('/')
        } else {

        }
    });
    return (
        <>
            {/* {showLoader && <Loader />} */}
        </>
    )
}
export default SSOUser;