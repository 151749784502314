import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from "../../layOuts/Loader";
import ErrorHandlePopup from "../ErrorHandlePopup";
import $ from "jquery";
import { ErrorPopup } from "../../layOuts/errorPopup";
var interval = null;
class DeviceLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      severityArray: [
        "emerg",
        "crit",
        "err",
        "warning",
        "notice",
        "info",
        "debug",
      ],
      deviceLogData: [],
      searchData: [],
      logsData: [],
      severeEmer: true,
      severeCrit: true,
      severeDebug: true,
      severeErr: true,
      severeInfo: true,
      severeNotice: true,
      severeWarn: true,
      toggleStatus: true,
      isAxiosError: false,
    };
  }
  componentDidMount() {
    this.downloadLogs(true);
    interval = setInterval(() => {
      this.setState({ toggleStatus: true });
      this.downloadLogs(false, false);
    }, 4000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.data.hostname !== nextProps.data.hostname) {
      this.setState(
        {
          deviceLogData: [],
          logsData: [],
          searchData: [],
          dropDown: true,
          hostname: nextProps.data.hostname,
          device_model: nextProps.data.device_model,
        },
        () => {
          this.downloadLogs(true);
        }
      );
    }
  }

  componentWillUnmount() {
    clearInterval(interval);
    interval = null;
    this.setState({ toggleStatus: false });
  }

  setDeviceLogData = () => {
    let fullData = this.state.logsData
    if (fullData?.length > 5000) {
      let noOfEntryRemoved = fullData?.length - 5000
      fullData?.splice(0, noOfEntryRemoved)
    }
    let filteredArray = fullData?.filter(logs => this.state.severityArray?.includes(logs?.severity))

    if (this.state.searchValue) {
      filteredArray = filteredArray?.filter(
        (log) => JSON.stringify(log)?.indexOf(this.state.searchValue) > -1
      );
    }
    let arrayData = filteredArray.map((log) => [log?.timestamp, " ", log.facility, ".", log.severity, " ", log.programname, ":", log.message])
    let filteredData = arrayData.map((log) => log.join(''))
    return filteredData
  }

  downloadLogs = (firstTime = false, showLoader = true) => {
    let data = {
      hostname: this.state?.dropDown ? this.state.hostname : this.props.data.hostname,
      device_model: this.state?.dropDown ? this.state.device_model : this.props.data.device_model,
      first_time: firstTime
    };
    if (showLoader) {
      this.setState({ showLoader: true });
    }
    ApiCall(urlMapping.downloadSyslogFile(data), (response) => {
      if (response?.success) {
        this.setState(
          {
            logsData: [...this.state.logsData, ...response?.logs_list],
            showLoader: false,
            dropDown: false,
          },
          () => [...this.state.deviceLogData, ...response?.logs_list]?.length > 0 && firstTime && this.scrollToBottom()
        );
        this.setState({ deviceLogData: this.setDeviceLogData() })
        let data = this.state?.logsData?.map((log) => [log?.timestamp, " ", log.facility, ".", log.severity, " ", log.programname, ":", log.message])
        let filteredData = data?.map((log) => log?.join(''))
        this.setState({ newData: filteredData })
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({ showRefreshModal: true, showLoader: false, playLoading: false });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false,
            dropDown: false,
            deviceLogData: [],
            logsData: [],
          })
        }
      }
    });
  };
  scrollToBottom = () => {
    this.messagesEnd?.scrollIntoView({ behavior: "smooth" });
  };
  handleKeypress = (e) => {
    this.setState({
      severityArray: [
        "emerg",
        "crit",
        "err",
        "warning",
        "notice",
        "info",
        "debug",
      ],
    })
    if (e.key === "Enter") {
      this.searchString();
    }
  };
  searchString = () => {
    if (this.state.searchValue === "") {
      let filteredData = this.state.logsData?.filter(logs => this.state.severityArray?.includes(logs?.severity))
      let data = filteredData.map((log) => [log?.timestamp, " ", log.facility, ".", log.severity, " ", log.programname, ":", log.message])
      let filteredArray = data.map((log) => log.join(''))
      this.setState({ deviceLogData: filteredArray });
    } else {
      let filteredArray = this.state.logsData?.filter(logs => this.state.severityArray?.includes(logs?.severity))
      let data = filteredArray.map((log) => [log?.timestamp, " ", log.facility, ".", log.severity, " ", log.programname, ":", log.message])
      let filteredData = data.map((log) => log.join(''))
      let temp = filteredData?.filter(
        (log) => JSON.stringify(log)?.toLowerCase()?.indexOf((this.state.searchValue).toLowerCase()) > -1
      );
      this.setState({ deviceLogData: temp, searchData: temp });
    }
  };

  clearSearch = () => {
    this.setState(
      {
        searchValue: "",
      },
      () => {
        let filteredData = this.state.logsData?.filter(logs => this.state.severityArray?.includes(logs?.severity))
        let data = filteredData.map((log) => [log?.timestamp, " ", log.facility, ".", log.severity, " ", log.programname, ":", log.message])
        let filteredArray = data.map((log) => log.join(''))
        this.setState({
          deviceLogData: filteredArray,
        })
      }
    );
  };

  handleSeverity = (severityValue) => {
    if (this.state.searchValue !== "") {
      this.searchHandleSeverity(severityValue)
    }
    else {
      let severity = this.state.severityArray;

      if (severity?.includes(severityValue)) {
        severity = severity?.filter((i) => i !== severityValue);
      } else {
        severity?.push(severityValue);
      }

      let filteredData = this.state.logsData?.filter(logs => severity?.includes(logs?.severity))
      let data = filteredData.map((log) => [log?.timestamp, " ", log.facility, ".", log.severity, " ", log.programname, ":", log.message])
      let filteredArray = data.map((log) => log.join(''))
      this.setState({
        deviceLogData: filteredArray,
        severityArray: [...severity],
      })
    }
  };
  searchHandleSeverity = (severityValue) => {
    let severity = this.state.severityArray;
    if (severity?.includes(severityValue)) {
      severity = severity?.filter((i) => i !== severityValue);
    } else {
      severity?.push(severityValue);
    }
    let filteredArray = []
    severity.map((item) => {
      this.state.searchData.map((val) => {
        if (val.includes("." + item)) {
          filteredArray.push(val)
        }
      })
    })
    this.setState({
      deviceLogData: filteredArray,
      severityArray: [...severity],
    })
  }
  manageAutoRefresh = () => {
    if (!this.state.toggleStatus) {
      this.setState({
        showLoader: true,
        searchValue: ''
      })
      interval = setInterval(() => {
        this.setState({ toggleStatus: true });
        this.downloadLogs(false, false);
      }, 2000);
    } else {
      clearInterval(interval);
      interval = null;
      this.setState({ toggleStatus: false });
    }
  };

  render() {
    return (
      <>
        {this.state.showLoader && <Loader />}

        <div className="white-bg">
          <div className="device-logs">
            <div className="page-sub-title">
              <strong>Device Logs</strong>
              <ul className="sdl-box list-unstyled">
                <li
                  className={
                    `${this.state.severityArray?.includes("emerg")
                      ? 'emergency'
                      : ""}`
                  }
                  onClick={() => this.handleSeverity("emerg")}
                >
                  <img src={this.state.severityArray?.includes("emerg") ? "./images/svg-icons/siren.svg" : "./images/svg-icons/siren_disabled.svg"} alt='emergency' />
                  Emergency
                </li>
                <li
                  className={
                    `${this.state.severityArray?.includes("crit")
                      ? 'critical'
                      : ""}`
                  }
                  onClick={() => this.handleSeverity("crit")}
                ><img src={this.state.severityArray?.includes("crit") ? "./images/svg-icons/critical_enabled.svg" : "./images/svg-icons/critical.svg"} alt='critical' />
                  Critical
                </li>
                <li
                  className={
                    `${this.state.severityArray?.includes("err")
                      ? 'error'
                      : ""}`
                  }
                  onClick={() => this.handleSeverity("err")}
                ><img src={this.state.severityArray?.includes("err") ? "./images/svg-icons/error-cross.svg" : "./images/svg-icons/error-cross_disabled.svg"} alt='error' />
                  Error
                </li>
                <li
                  className={
                    `${this.state.severityArray?.includes("warning")
                      ? 'warnings'
                      : ""}`
                  }
                  onClick={() => this.handleSeverity("warning")}
                ><img src={this.state.severityArray?.includes("warning") ? "./images/svg-icons/warning_alert.svg" : "./images/svg-icons/warning.svg"} alt='warning' />
                  Warning
                </li>
                <li
                  className={
                    `${this.state.severityArray?.includes("notice")
                      ? 'notice'
                      : ""}`
                  }
                  onClick={() => this.handleSeverity("notice")}
                ><img src={this.state.severityArray?.includes("notice") ? "./images/svg-icons/notice_enabled.svg" : "./images/svg-icons/notice.svg"} alt='notice' />
                  Notice
                </li>
                <li
                  className={
                    `${this.state.severityArray?.includes("info")
                      ? 'info'
                      : ""}`
                  }
                  onClick={() => this.handleSeverity("info")}
                ><img src={this.state.severityArray?.includes("info") ? "./images/svg-icons/info_enabled.svg" : "./images/svg-icons/info_white.svg"} alt='info' />
                  Info
                </li>
                <li
                  className={
                    `${this.state.severityArray?.includes("debug")
                      ? 'debug'
                      : ""}`
                  }
                  onClick={() => this.handleSeverity("debug")}
                ><img src={this.state.severityArray?.includes("debug") ? "./images/svg-icons/debug_enabled.svg" : "./images/svg-icons/debug.svg"} alt='debug' />
                  Debug
                </li>
              </ul>
              <div className="d-flex align-items-center">
                <button className="btn btn-primary" onClick={() => this.manageAutoRefresh()}>
                  <img className="view-btn w-20" src={this.state.toggleStatus ? "./images/svg-icons/pause-icon.svg" : "./images/svg-icons/play-icon.svg"} alt="pause" />
                </button>
              </div>
              <div className={`search-box2 flex-g-inherit`}>
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) =>
                    !this.state.toggleStatus && this.setState({ searchValue: e.target.value })
                  }
                  disabled={this.state.toggleStatus}
                  value={this.state.searchValue}
                  onKeyPress={(e) => this.handleKeypress(e)}
                />
                {this.state.searchValue && (
                  <img
                    className={`clear-icon show ${this.state.toggleStatus ? 'cursor-not-allowed' : ''}`}
                    src="/images/svg-icons/cancel.svg"
                    onClick={() => !this.state.toggleStatus && this.clearSearch()}
                    alt=""
                    disabled={this.state.toggleStatus}
                  />
                )}
              </div>
            </div>
            {this.state?.deviceLogData?.length > 0 ?
              <>
                <div className="device-logs-body mt-3">
                  {this.state.deviceLogData?.map((log) => {
                    return <p>{log}</p>
                  })}
                  <div
                    ref={(el) => {
                      this.messagesEnd = el;
                    }}
                  ></div>
                </div>
              </> : <div className="white-bg no-data">No logs to display</div>
            }
          </div>
        </div>
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
        {this.state.showRefreshModal && <ErrorHandlePopup />}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    deviceIdWhenDropChanges: state.displayAddDevice.deviceIdWhenDropChanges,
  };
};
export default withRouter(connect(mapStateToProps)(DeviceLogs));
