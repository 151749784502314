import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import moment from 'moment';
import DataTable from "react-data-table-component";
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from 'jquery';
import ErrorHandlePopup from '../ErrorHandlePopup';
import Warnings from '../warnings/Warnings';

class Troubleshooting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showRefreshModal: false,
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            title: '',
            message: '',
            show: false,
            error: false,
            deviceLogData: [],
            showLoader: false,
            showError: false,
            deviceSeverity: this.props?.location?.state?.deviceSeverity ? this.props?.location?.state?.deviceSeverity : '',
            tabChange: this.props?.location?.state?.tabChange ? this.props?.location?.state?.tabChange : false,
            selectedTab: this.props.location?.state?.selectedTab ? this.props.location?.state?.selectedTab : "Snapshots",
            isAxiosError: false,
            columns: [{
                name: "TimeStamp",
                selector: "created_date",
                sortable: true,
                cell: (row) => (
                    <span style={{ width: "auto" }}>
                        {moment.utc(row.created_date).local().format('DD MMMM YYYY hh:mm:ss A')}
                    </span>
                ),
            }, {

            },
            {
                name: "Download Debug Logs",
                selector: "download_url",
                sortable: true,
                //Fix for snapshot button design
                cell: (row) => (
                    row.download_url !== null ? <a href="#/" onClick={() =>
                        this.downloadDeviceLogs(row)}>Download</a> : "Download"
                )
            }],
        }
    }


    componentDidMount() {
        this.getDeviceLogList();
        if (this.state.tabChange === true) {
            $("#pills-ma-snapshot-tab").attr('class', 'nav-link')
            $("#pills-wa-tab").attr('class', 'nav-link active')
            $("#pills-ma-snapshot").attr('class', 'tab-pane fade')
            $("#pills-wa").attr('class', 'tab-content tab-pane fade active show')
            this.setState({
                tabChange: false
            })
        }
        else if (localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true') {
            $("#pills-ma-snapshot-tab").attr('class', 'nav-link');
            $("#pills-wa-tab").attr('class', 'nav-link active');
            $("#pills-ma-snapshot").attr('class', 'tab-pane fade');
            $("#pills-wa").attr('class', 'tab-content tab-pane fade active show');
            this.setState({
                selectedTab: "Warning&Alerts",
            })
        }
    }
    downloadDeviceLogs = (row) => {
        let filename = '';
        filename = row.download_url;
        // EMGT-35 : User not able to download logs in EM snapshot
        this.setState({
            showLoader: true
        })
        ApiCall(urlMapping.deviceLogDownloadNew(filename), (response) => {
            if (parseInt(response?.response?.status) === 200) {
                const url = window.URL.createObjectURL(new Blob([response?.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `EM_snapshot_${filename}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    showLoader: false
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    }
    getDeviceLogList = () => {
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.deviceLogList(this.state.pageNo, this.state.pageSize), (response) => {
            if (response?.results) {
                const data = response?.results;
                const totalCount = response?.count;
                this.setState({
                    deviceLogData: [...data],
                    totalCount,
                    showLoader: false,
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    })
                }
            }
        })
    }

    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
        })
    }

    generateHandler = () => {
        this.setState({ showLoader: true })

        ApiCall(urlMapping.deviceLogDownload(), (response) => {
            this.setState({ showLoader: false })
            if (parseInt(response?.response?.status) === 201) {
                this.getDeviceLogList();
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }

    paginationChange = (e) => {
        this.setState({
            pageNo: e
        }, () => {
            this.getDeviceLogList();
        });
    }
    pageSizeChange = (e) => {
        this.setState({ pageSize: e }, () => { this.getDeviceLogList(); });
    }

    maSnapshotPageRefresh = () => {
        this.getDeviceLogList();
    }

    render() {
        return (
            <div>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                    <Header />
                    <div className="no-bg page-height">
                        <div className="page-header device-head">
                            <ul className="tab-nav nav nav-pills ml-0 mr-0" id="pills-tab" role="tablist">
                                {(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                   (localStorage.getItem('ssoUser') === 'true' || sessionStorage.getItem('ssoUser') === 'true')) && <li className="nav-item" role="presentation">
                                        <a className="nav-link active" id="pills-ma-snapshot-tab" data-toggle="pill" href="#pills-ma-snapshot"
                                            role="tab" aria-controls="pills-ma-snapshot" aria-selected="false" onClick={() =>
                                                this.setState({ selectedTab: "Snapshots" })}>EM Snapshot</a>

                                    </li>}
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="pills-wa-tab"
                                        data-toggle="pill"
                                        href="#pills-wa"
                                        role="tab"
                                        aria-controls="pills-wa"
                                        aria-selected="false"
                                        onClick={() =>
                                            this.setState({
                                                selectedTab: "Warning&Alerts",
                                                deviceSeverity: ''
                                            })
                                        }
                                    >
                                        Warning & Alerts
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {this.state.selectedTab === "Snapshots" && <div className="tab-content" id="pills-tabContent">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="pills-ma-snapshot" role="tabpanel" aria-labelledby="pills-ma-snapshot-tab">
                                    <div className="white-bg">
                                        <div className="ta-box set-pos mb-0">
                                            <div className="action-btn d-flex align-items-center">
                                                <button onClick={() => this.maSnapshotPageRefresh()}><img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh</button>

                                            </div>
                                        </div>
                                        <p>Collect debug logs and operation logs of the 5G SD EDGE Manager.</p>
                                        <div className="row no-gutters">
                                            <div className="col-md-4 col-sm-5">
                                                <div className="form-group ml-1">
                                                    <label>Category</label>
                                                    <select className="form-control">
                                                        <option>Debug Logs</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-2">
                                                <label className="d-block">&nbsp;</label>
                                                <button className="btn btn-primary ml-2 btn-top-space"
                                                    onClick={() => this.generateHandler()}>Generate</button>
                                            </div>
                                        </div>
                                        <div className="table-box">
                                            <div className="d-flex align-items-center page-sub-title">
                                                <strong>Debug Logs</strong>
                                            </div>
                                            <div>

                                            </div>
                                            < DataTable
                                                className="new-device-list"
                                                columns={
                                                    this.state.columns
                                                }
                                                pagination
                                                paginationServer
                                                onChangePage={(e) => this.paginationChange(e)}
                                                onChangeRowsPerPage={(e) => this.pageSizeChange(e)}
                                                paginationTotalRows={this.state.totalCount}
                                                data={this.state.deviceLogData}
                                                highlightOnHover
                                                fixedHeader
                                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {this.state.selectedTab === "Warning&Alerts" && (
                            <div
                                className="tab-content tab-pane fade show"
                                id="pills-wa"
                                role="tabpanel"
                                aria-labelledby="pills-wa-tab"
                            >
                                <Warnings
                                    deviceSeverity={this.state.deviceSeverity}
                                />
                            </div>
                        )}
                        {(this.state.show) &&
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                reset={() => this.resetPopup()}
                            />
                        }
                    </div>
                </section>
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>

        );

    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        userRole: String(state.auth.role)
    });
}
export default withRouter(connect(mapStateToProps)(Troubleshooting));

